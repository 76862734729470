import { pdf } from '@react-pdf/renderer'
import { Button, FileDownloadIcon } from '@velocity/ui/draft'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import PDFDocument from '../../../../components/pdf/PDFDocument'
import { DICTIONARY } from '../../../../constants/dictionary'
import { Profile } from '../../../../model/Profile'
import { Quote, QuoteSummary } from '../../../../model/Quote'
import { getStyles } from '../../../../utils/styles'

const StyledButton = styled(Button)`
  color: ${getStyles().colors.downloadLink.font};
  padding-left: 0;

  :hover {
    text-decoration: underline;
  }

  .MuiButton-label span {
    padding-left: 8px;
    font-size: 14px;
    font-weight: 700;
  }
`

const PDFDownloadLinkWrapper = styled.div`
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: underline;
  }
`

interface Props {
  name: string
  quote: Quote
  quoteSummary: QuoteSummary
  locale: string
  profile: Profile
  displayPrivateCarHireNote: boolean
}

const DownloadLink: React.FC<Props> = ({
  name,
  quote,
  quoteSummary,
  locale,
  profile,
}) => {
  const { t } = useTranslation()

  const generatePDF = () => {
    const filename = `${name}.pdf`

    function savePDF() {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      return (pdfBlob: any) => {
        saveAs(pdfBlob, filename)
      }
    }

    pdf(
      <PDFDocument
        quote={quote}
        quoteSummary={quoteSummary}
        locale={locale}
        profile={profile}
      />,
    )
      .toBlob()
      .then(savePDF())
      .catch((error) => {
        //eslint-disable-next-line no-console
        console.error('Error while generating PDF', error)
      })
  }

  return (
    <PDFDownloadLinkWrapper>
      <StyledButton
        onClick={generatePDF}
        variant="text"
        data-testid="download-link"
      >
        <FileDownloadIcon
          size="xs"
          color={getStyles().colors.downloadLink.font}
        />
        <span>{t(DICTIONARY.DOWNLOAD_QUOTE_LABEL)}</span>
      </StyledButton>
    </PDFDownloadLinkWrapper>
  )
}

export default DownloadLink
