import { Vehicle } from '../model/Quote'
import { ImageUrlType } from '../pages/vehicle-configurator/VehicleGallery'
import { buildUrl } from './urlBuilder'

const ANGLE_1 = 1
const ANGLE_2 = 5
const ANGLE_3 = 9
const ANGLE_4 = 13
const ANGLE_5 = 17
const ANGLE_6 = 22
const ANGLE_7 = 28
const ANGLE_8 = 29
const GALERRY_ANGLES = [
  ANGLE_1,
  ANGLE_2,
  ANGLE_3,
  ANGLE_4,
  ANGLE_5,
  ANGLE_6,
  ANGLE_7,
  ANGLE_8,
]
const IMAGIN_DEFAULT_ANGLE = 1

declare global {
  interface Window {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    carcloudaccess: any
  }
}

const getVehicleMake = function (vehicle: Vehicle) {
  return vehicle.make !== undefined ? vehicle.make.code : undefined
}

const getModelYear = function (vehicle: Vehicle) {
  return vehicle.modelYear !== undefined ? vehicle.modelYear : undefined
}

const getVehicleModel = function (vehicle: Vehicle) {
  return vehicle.model !== undefined ? vehicle.model.text : undefined
}

const getVehicleBodyType = function (vehicle: Vehicle) {
  return vehicle.bodyType !== undefined && vehicle.bodyType.code !== undefined
    ? vehicle.bodyType.code.toLowerCase()
    : undefined
}

const getVehicleTrim = (vehicle: Vehicle) =>
  vehicle.trim !== undefined ? vehicle.trim.code : undefined

const getPowertrainType = (vehicle: Vehicle) =>
  vehicle.powertrainType !== undefined ? vehicle.powertrainType.code : undefined

const getExteriorColour = (vehicle: Vehicle): string => {
  const exteriorColourOptions = vehicle?.selectedOptions?.find((option) =>
    option?.colour?.colourType?.includes('Exterior'),
  )
  return exteriorColourOptions?.optionCode || undefined
}

export const getBillingTagEnv = (env: string) => {
  switch (env) {
    case 'development':
      return 'dev'
    case 'test':
      return 'test'
    case 'preprod':
      return 'acc'
    case 'prod':
      return 'prod'
    default:
      return 'dev'
  }
}

// See https://leaseplan-digital.atlassian.net/wiki/spaces/ENG/pages/3393094789/Imagin+service+billing+tag+guideline+for+Frontend+projects
const getBillingTag = (countryCode: string) =>
  `crg-${countryCode.toLowerCase()}-${getBillingTagEnv(
    process.env.REACT_APP_DATADOG_ENV,
  )}-a`

export const getImaginImage = (vehicle: Vehicle, locale: string) => {
  const exteriorColor = getExteriorColour(vehicle)
  return getImaginImageBase(
    getVehicleMake(vehicle),
    getVehicleModel(vehicle),
    getVehicleBodyType(vehicle),
    getModelYear(vehicle),
    getVehicleTrim(vehicle),
    getPowertrainType(vehicle),
    getLocaleCountry(locale),
    exteriorColor,
  )
}

const getImaginImageBase = (
  make: string,
  model: string,
  bodyType: string,
  modelYear: string,
  trim: string,
  powertrain: string,
  countryCode: string,
  exteriorColor = '',
) => {
  return buildUrl({
    host: process.env.REACT_APP_IMAGIN_HOST_URL,
    path: '/getImage',
    queryParams: {
      modelYear: modelYear,
      make: make,
      modelFamily: model,
      width: '400',
      modelVariant: bodyType,
      customer: process.env.REACT_APP_IMAGIN_CUSTOMER,
      channelName: 'leaseplan.com',
      tailoring: process.env.REACT_APP_IMAGIN_TAILORING,
      angle: IMAGIN_DEFAULT_ANGLE,
      ...(exteriorColor && { paintId: exteriorColor }),
      trim: trim,
      powerTrain: powertrain,
      countryCode: countryCode,
      billingTag: getBillingTag(countryCode),
    },
  })
}

const getLocaleCountry = (locale: string) => locale.split('_')[1]

export const getImaginKey = (
  vehicle: Vehicle,
  locale: string,
  bodyColor?: string,
): string =>
  getVehicleMake(vehicle) +
  getVehicleModel(vehicle) +
  getModelYear(vehicle) +
  getVehicleBodyType(vehicle) +
  getVehicleTrim(vehicle) +
  getPowertrainType(vehicle) +
  getLocaleCountry(locale) +
  (bodyColor || '')

export const getImaginMultipleImages = (
  vehicle: Vehicle,
  locale: string,
  bodyColor?: string,
): ImageUrlType[] => {
  const result: any = []

  GALERRY_ANGLES.forEach((angle) => {
    const countryCode = getLocaleCountry(locale)
    const url = buildUrl({
      host: process.env.REACT_APP_IMAGIN_HOST_URL,
      path: '/getImage',
      queryParams: {
        modelYear: getModelYear(vehicle),
        make: getVehicleMake(vehicle),
        modelFamily: getVehicleModel(vehicle),
        width: '1200',
        modelVariant: getVehicleBodyType(vehicle),
        customer: process.env.REACT_APP_IMAGIN_CUSTOMER,
        channelName: 'leaseplan.com',
        tailoring: process.env.REACT_APP_IMAGIN_TAILORING,
        angle: angle,
        ...(bodyColor && { paintId: bodyColor }),
        trim: getVehicleTrim(vehicle),
        powerTrain: getPowertrainType(vehicle),
        countryCode,
        billingTag: getBillingTag(countryCode),
      },
    })
    result.push({ original: url })
  })

  return result
}
