import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.colorItem.withOptions

export const CheckboxSpacer = styled.div`
  min-width: 1.5rem;
  margin-right: 0.5em;
`
export const ItemContainer = styled.div`
  display: flex;
  margin: 0.5em 0;
`
export const CheckedItem = styled.div`
  position: relative;
  outline: ${s().outline} solid 2px;
  outline-offset: 1px;
  &>svg: {
    display: block;
    color: white;
    background: ${s().background};
    max-width: 100%;
    height: auto;
    width: 10px;
    position: absolute;
    left: 23px;
    top: 0;
  }
`
export const ColorBox = styled.div`
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
  padding: 2px;
  flex-basis: 35px;
  border: 1px solid ${s().border};
`
export const OptionName = styled.div`
  flex-basis: 69%;
  display: flex;
  align-items: center;
`
export const ShowOptionsSwitch = styled.div`
  display: flex;
  fontsize: 0.7rem;
  justify-content: space-between;
  color: ${s().optionsSwitch.font};
  cursor: pointer;
  white-space: nowrap;
}
`
export const ChevronIconDown = styled(ChevronDownIcon)`
  width: 10px;
  color: ${s().downIcon};
  cursor: pointer;
`
export const ChevronIconUp = styled(ChevronUpIcon)`
  width: 10px;
  color: ${s().upIcon};
  cursor: pointer;
`
