import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import DisabledInput from './DisabledInput'
import { InfoLines, Line } from './PublicOrderForm.styled'

interface Props {
  mileage: string
  duration: string
  quote: string
  quoteLabel: string
}

const ContractInfo: FC<Props> = ({ mileage, duration, quote, quoteLabel }) => {
  const { t } = useTranslation()

  return (
    <InfoLines>
      <Line>
        <DisabledInput
          label={t(DICTIONARY.MILEAGE)}
          field={mileage}
        ></DisabledInput>
        <DisabledInput
          label={t(DICTIONARY.DURATION)}
          field={duration}
        ></DisabledInput>
      </Line>
      <Line>
        <DisabledInput label={quoteLabel} field={quote}></DisabledInput>
      </Line>
    </InfoLines>
  )
}

export default ContractInfo
