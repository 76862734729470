import { RefObject, useEffect, useState } from 'react'

import { initState, useEffectHandler } from './useIsDraggingUtil'

export interface DraggingInfo {
  isDragging: boolean
  dragStartMousePositionX: null | number
}

export const useIsDragging = (elementRef: RefObject<HTMLDivElement>) => {
  const { draggingInfo } = initState(
    useState<DraggingInfo>({
      isDragging: false,
      dragStartMousePositionX: null as null | number,
    }),
    elementRef,
  )

  useEffect(useEffectHandler, [elementRef])

  return draggingInfo
}
