import { Container } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const AppContentWrapper = styled.div`
  padding-bottom: 10.4rem;
  padding-top: 6.4rem;
  min-height: calc(100vh - 14.1rem);
`

const NoNavLayout: React.FC = ({ children }) => (
  <>
    <Container>
      <AppContentWrapper>
        <main role="main">{children}</main>
      </AppContentWrapper>
    </Container>
  </>
)

export default NoNavLayout
