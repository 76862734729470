import styled from 'styled-components'

import { getStyles } from '../../../../utils/styles'

const s = () => getStyles().colors.showroom.makeItem

export const BrandName = styled.h3`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  margin: 0;
`
export const BrandNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${s().brandName.font};
`
export const ModelsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 8px 8px;
  border-bottom: 3px solid ${s().modelHeading.border};
`
export const ModelsContainer = styled.ul`
  padding: 0;
`
export const ModelTitleContainer = styled.div`
  display: flex;
  gap: 7px;
`
export const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 23px;
  svg {
    width: 12px;
  }
  svg:first-child {
    position: absolute;
    top: 0;
  }
  svg:last-child {
    position: absolute;
    bottom: 0;
    opacity: 0.5;
  }
  cursor: pointer;
`
export const ModelTitle = styled.h4`
  margin: 0;
`
export const ItemRoot = styled.li`
  background: ${s().background};
  list-style: none;
  padding: 15px 15px 25px;
`
export const ImageContainer = styled.div`
  display: flex;
  padding-right: 15px;
  img {
    width: 36px;
    height: 36px;
  }
`
export const ModelsBox = styled.div`
  padding-left: 51px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`
