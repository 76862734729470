import { useWindowSize } from '@velocity/ui'
import { ChevronRightIcon, DeleteIcon, ArrowIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

import VelocityButton from '../../components/button/VelocityButton'
import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import { Quote } from '../../model/Quote'
import { capitalize, smartCut } from '../../utils/string'
import { getStyles, isRebranding } from '../../utils/styles'
import {
  Price,
  PriceButtonSection,
  PriceLabel,
  StyledDeleteActionButton,
  StyledPriceInfo,
} from '../favourites/PriceInfo.styled'
import { calculateOptionsTotalPrice } from '../quoteSummary/util/vehicleCost'
import {
  PriceSection,
  PriceSetSection,
  StyledButton,
  StyledPriceInfoBox,
} from './PriceInfo.styled'
import { initState } from './util/priceInfo'

const s = () => getStyles().colors.savedQuote.price
const maxLength = 18

export interface PriceInfoProps {
  quote: Quote
  isChecked: boolean
  isInvalid: boolean
  onDelete: () => void
}

const PriceInfo: FC<PriceInfoProps> = (props) => {
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const profile = useProfileContext()
  const currency = useCurrency(locale, profile?.user?.currencyCode)

  const size = useWindowSize()
  const st = initState(props, locale, size, useState<boolean>(false))

  useEffect(() => st.mobileModeEffect(), [st])

  return (
    <StyledPriceInfo>
      <PriceSetSection>
        <PriceSection>
          <StyledPriceInfoBox>
            {st.showDesktopView() && (
              <StyledDeleteActionButton
                onClick={st.handleDeleteQuote}
                data-testid="deleteQuoteButton"
              >
                <DeleteIcon size="xs" color={s().deleteIcon} />
              </StyledDeleteActionButton>
            )}
            <PriceLabel>
              {capitalize(t(DICTIONARY.RETAIL_PRICE_WITHOUT_OPTIONS))}
            </PriceLabel>
          </StyledPriceInfoBox>
          <Price variant="300" data-e2e-id="quotePrice">
            {st.showFormattedCurrency() &&
              currency?.format(props.quote.vehicle.retailPrice)}
          </Price>
        </PriceSection>
        <PriceSection>
          <StyledPriceInfoBox>
            <PriceLabel>
              {capitalize(t(DICTIONARY.RETAIL_PRICE_OF_OPTIONS))}
            </PriceLabel>
          </StyledPriceInfoBox>
          <Price variant="300" data-e2e-id="quotePrice">
            {currency?.format(calculateOptionsTotalPrice(props.quote))}
          </Price>
        </PriceSection>
      </PriceSetSection>
      <PriceButtonSection>
        {isRebranding() ? (
          <VelocityButton
            size="s"
            testId="selectButton"
            endIcon={<ArrowIcon size="s" color="white" />}
            onClick={st.handleQuoteClick}
            disabled={!props.isChecked || props.isInvalid}
          >
            {capitalize(smartCut(t(DICTIONARY.VIEW_QUOTE_DETAILS), maxLength))}
          </VelocityButton>
        ) : (
          <StyledButton
            data-testid="selectButton"
            variant="contained"
            endIcon={<ChevronRightIcon color="white" size="xs" />}
            color="primary"
            onClick={st.handleQuoteClick}
            disabled={!props.isChecked || props.isInvalid}
          >
            {t(DICTIONARY.VIEW_QUOTE_DETAILS)}
          </StyledButton>
        )}
      </PriceButtonSection>
    </StyledPriceInfo>
  )
}

export default PriceInfo
