import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import ContinueButton from './ContinueButton'
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  CloseIconStyled,
  ExpandLessIconStyled,
  PriceBreakdownAccordionLabel,
  PriceRoot,
} from './PriceBreakdown.styled'
import PriceBreakdownContext from './PriceBreakdownContext'

const s = () => getStyles().colors.configurator.priceBreakdown

interface Props {
  currency: Intl.NumberFormat
  isPriceSectionExpanded: boolean
  setPriceSectionExpanded: (value: boolean) => void
}

const PriceBreakdown: FC<Props> = ({
  currency,
  isPriceSectionExpanded,
  setPriceSectionExpanded,
}) => {
  const { state } = useVehicleConfig()
  const { t } = useTranslation()

  const totalInclVat = state.priceCalculation?.priceDetail?.monthly.totalInclVat
  return (
    <PriceRoot>
      {!isPriceSectionExpanded && (
        <ContinueButton expanded={isPriceSectionExpanded} />
      )}
      <AccordionStyled
        square={true}
        expanded={isPriceSectionExpanded}
        onChange={(_: React.ChangeEvent<{}>, isExpanded: boolean) => {
          setPriceSectionExpanded(isExpanded)
        }}
      >
        <AccordionSummaryStyled
          expandIcon={
            isPriceSectionExpanded ? (
              <CloseIconStyled size="s" color={s().closeIcon} />
            ) : (
              <ExpandLessIconStyled />
            )
          }
        >
          {!isNaN(totalInclVat) && (
            <div>
              <span>{currency.format(totalInclVat)}</span>{' '}
              <span>
                {!!totalInclVat &&
                  `${t(DICTIONARY.PER_MONTH_LABEL)} (x
              ${state.priceCalculation?.duration})`}
              </span>
            </div>
          )}
          <PriceBreakdownAccordionLabel>
            {isPriceSectionExpanded
              ? t(DICTIONARY.HIDE_PRICE_BREAKDOWN_LABEL)
              : t(DICTIONARY.SHOW_PRICE_BREAKDOWN)}
          </PriceBreakdownAccordionLabel>
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          <PriceBreakdownContext
            priceInformation={state.priceCalculation}
            currency={currency}
            displayPrivateCarHireNote={
              state?.profile?.product?.displayPrivateCarHireNote
            }
            isPublicFlow={state.profile?.product.publicProduct}
          />
        </AccordionDetailsStyled>
      </AccordionStyled>
    </PriceRoot>
  )
}

export default PriceBreakdown
