import { CONTRIBUTION_TYPE } from '../../../constants/contribution'
import { DICTIONARY } from '../../../constants/dictionary'

const getTitle = (
  type: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): string => {
  switch (type) {
    case CONTRIBUTION_TYPE.MONTHLY_AMOUNT:
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE:
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE_WITH_CONTRIBUTION_PERIOD:
      return translations(
        DICTIONARY.DRIVER_MONTHLY_CONTRIBUTION_CONFIRMATION_DIALOG_TITLE,
      )
    default:
      return translations(
        DICTIONARY.DRIVER_ONE_OFF_PAYMENT_CONFIRMATION_DIALOG_TITLE,
      )
  }
}

const getMessage = (
  type: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): string => {
  switch (type) {
    case CONTRIBUTION_TYPE.MONTHLY_AMOUNT:
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE:
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE_WITH_CONTRIBUTION_PERIOD:
      return translations(
        DICTIONARY.DRIVER_MONTHLY_CONTRIBUTION_CONFIRMATION_DIALOG_MESSAGE,
      )
    default:
      return translations(
        DICTIONARY.DRIVER_ONE_OFF_PAYMENT_CONFIRMATION_DIALOG_MESSAGE,
      )
  }
}

export { getTitle, getMessage }
