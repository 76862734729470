import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../../../constants/dictionary'
import { getStyles } from '../../../../utils/styles'
import { Order } from '../../../vehicle-configurator/util/tableHeadSort'
import { useShowroomConfig } from '../../context/ShowroomContext'
import { Brand } from './BrandsView'
import {
  BrandName,
  BrandNameContainer,
  ChevronContainer,
  ModelsHeading,
  ImageContainer,
  ItemRoot,
  ModelsContainer,
  ModelsBox,
  ModelTitle,
  ModelTitleContainer,
} from './MakeItem.styled'
import ModelLine from './ModelLine'

const s = () => getStyles().colors.showroom.vehicleList.brandsView.make

interface Props {
  item: Brand
  index: number
  list: Brand[]
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
}

const MakeItem: FC<Props> = ({ item, index, list, order, setOrder }) => {
  const { state } = useShowroomConfig()
  const { t } = useTranslation()
  const imageIndex = `${item.makeName}.png`

  return (
    <ItemRoot>
      {(index === 0 || list[index].makeName !== list[index - 1].makeName) && (
        <>
          <BrandNameContainer>
            <ImageContainer>
              {state.images[imageIndex]?.default && (
                <img src={state.images[imageIndex].default} alt="" />
              )}
            </ImageContainer>
            <BrandName>{item.makeName}</BrandName>
          </BrandNameContainer>
          <ModelsBox>
            <ModelsHeading>
              <ModelTitleContainer>
                <ModelTitle>{t(DICTIONARY.MODEL)}</ModelTitle>
                <ChevronContainer
                  data-testid="model-sort-btn"
                  onClick={() => {
                    setOrder((e) => (e === 'asc' ? 'desc' : 'asc'))
                  }}
                >
                  <ChevronUpIcon
                    size="xs"
                    color={s().upIcon}
                    style={{ opacity: order === 'asc' ? 1 : 0.2 }}
                  />
                  <ChevronDownIcon
                    size="xs"
                    color={s().downIcon}
                    style={{ opacity: order === 'asc' ? 0.2 : 1 }}
                  />
                </ChevronContainer>
              </ModelTitleContainer>
              <ModelTitle>{t(DICTIONARY.AVAILABLE_OFFERS)}</ModelTitle>
            </ModelsHeading>
            <ModelsContainer>
              {item.models.map((i, idx) => (
                <ModelLine key={i.name} item={i} index={idx} />
              ))}
            </ModelsContainer>
          </ModelsBox>
        </>
      )}
    </ItemRoot>
  )
}

export default MakeItem
