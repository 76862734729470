export type ButtonOnType = 'left' | 'right' | ''

let rotateDirectionS: ButtonOnType
let isDraggingS: boolean

export const initState = (
  rotateDirection: ButtonOnType,
  isDragging: boolean,
) => {
  rotateDirectionS = rotateDirection
  isDraggingS = isDragging
}

export const isLeftOn = (): boolean =>
  isDraggingS && rotateDirectionS === 'right'
export const isRightOn = (): boolean =>
  isDraggingS && rotateDirectionS === 'left'
