import { TableCell, TableRow, TableSortLabel } from '@material-ui/core'
import React from 'react'

import { TableCellText, TableHeadStyled } from '../Table.styled'
import { VisuallyHidden } from '../util/styles'
import { Order } from '../util/tableHeadSort'

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  headCells: HeadCell[]
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort, headCells } = props
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHeadStyled>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'checkbox'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <TableCellText>{headCell.label}</TableCellText>
              {orderBy === headCell.id ? (
                <VisuallyHidden>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </VisuallyHidden>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHeadStyled>
  )
}

export default EnhancedTableHead
