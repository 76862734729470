import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.rules.required

export const RootContainer = styled.div`
  padding: 0.6em 0.2em 0.6em 0;
  color: ${s().font};
  display: flex;
  flex-direction: column;
  border: 1px solid ${s().border};
  position: relative;
  margin-bottom: 1em;
  ::before {
      width: 5px;
      height: 100%;
      position: absolute;
      background: ${s().background};
      top: 0;
      left: -4px;
      content: "";
    };
  }
`
export const Item = styled.div`
  display: flex;
  padding-right: 0.7em;
  color: ${s().item};
  margin-bottom: 0.2em;
`
export const Operator = styled.div`
  margin: 0 0 6px 39px;
  text-transform: uppercase;
  color: ${s().operator.font};
  font-size: 75%;
  font-weight: bold;
  padding: 0.2em 0.6em;
  border-radius: 0.25em;
`
export const OrOperator = styled(Operator)`
  background: ${s().operator.or};
  display: inline-block;
`
export const Label = styled.div`
  width: 100%;
  text-align: start;
  margin: auto 0;
`
export const Price = styled.div`
  flex-basis: 5rem;
  text-align: end;
  margin: auto;
`
