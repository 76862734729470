import { Accessory } from '../../../model/Quote'

export const customAccessories = (
  isSavedQuote: boolean,
  savedQuoteValue: Accessory[],
  stateValue: Accessory[],
) => (isSavedQuote ? savedQuoteValue : stateValue)

export const calcSelectedServices = (
  isSavedQuote: boolean,
  selectedServicesValue: Map<string, string>,
  stateSelectedServices: Map<string, string>,
) => (isSavedQuote ? selectedServicesValue : stateSelectedServices)

interface ContractOptionInfo {
  contractOptions: Record<string, string>
  yearlyMileages: string
  durations: string
  discountAmount: string
  commissionAmount: string
  downPaymentAmount: string
  includeMaintenance: string
}

export const contractOptionList = (
  isSavedQuote: boolean,
  info: ContractOptionInfo,
) =>
  isSavedQuote
    ? {
        ...info.contractOptions,
        yearlyMileages: info.yearlyMileages,
        durations: info.durations,
        discountAmount: info.discountAmount,
        commissionAmount: info.commissionAmount,
        downPaymentAmount: info.downPaymentAmount,
        includeMaintenance: info.includeMaintenance,
      }
    : info.contractOptions
