import { TFunction } from 'i18next'

import { DICTIONARY } from '../../../constants/dictionary'
import { VoVehicle } from '../../../model/VoVehicle'

interface MoreInfo {
  name: string
  value: string
}

export const getMoreInfoData = (
  vehicle: VoVehicle,
  t: TFunction,
  currency: Intl.NumberFormat,
): MoreInfo[] => {
  const result = [
    {
      name: t(DICTIONARY.MAKE),
      value: vehicle.make.text,
    },
    {
      name: t(DICTIONARY.MODEL),
      value: vehicle.model.text,
    },
    {
      name: t(DICTIONARY.EDITION),
      value: vehicle.localVersion,
    },
    {
      name: t(DICTIONARY.YEAR),
      value: vehicle.modelYear,
    },
    {
      name: t(DICTIONARY.WLTP_CO2),
      value: vehicle?.wltpCO2?.toString(),
    },
    {
      name: t(DICTIONARY.DOORS),
      value: vehicle.doors,
    },
    {
      name: t(DICTIONARY.CAR_BODY),
      value: vehicle.bodyType.text,
    },
    {
      name: t(DICTIONARY.ENG_POWER),
      value: `${vehicle?.horsePower?.toString()} ${t(DICTIONARY.HORSE_POWER)}`,
    },
    {
      name: t(DICTIONARY.FUEL),
      value: vehicle?.engineFuelType?.text,
    },
    {
      name: t(DICTIONARY.SEATS),
      value: vehicle.seatingCapacity,
    },
    ...(vehicle.grossTrailerWeightBraked !== undefined
      ? [
          {
            name: t(DICTIONARY.TRAILER_WEIGHT_BRAKED),
            value: vehicle.grossTrailerWeightBraked,
          },
        ]
      : []),
    {
      name: t(DICTIONARY.RETAIL_PRICE),
      value: currency.format(vehicle.retailPrice),
    },
  ]

  if (vehicle.electricRange && vehicle.electricRange > 0) {
    result.push({
      name: t(DICTIONARY.ELECTRIC_RANGE),
      value: vehicle.electricRange.toString(),
    })
  }
  return result
}
