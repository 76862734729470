import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 77px);
  flex: 1 0 auto;
`
export const PaginatorSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`
export const Title = styled.div`
  font-family: '${getStyles().fonts.heading}';
  font-weight: ${getStyles().fonts.title.weight};
  display: flex;
  justify-content: center;
  font-size: 26px;
  color: ${getStyles().colors.savedQuote.title};
  margin-bottom: 28px;
`
