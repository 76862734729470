export const Color = {
  primary500: '#006262',
  primary400: '#008282',
  primary300: '#339B9B',
  primary200: '#66B4B4',
  primary100: '#99CDCD',
  primary50: '#E3F6F6',
  secondary500: '#072534',
  secondary400: '#0F3549',
  secondary300: '#3F5D6D',
  secondary200: '#6F8692',
  secondary100: '#9FAEB6',
  secondary50: '#E7EBED',
  neutral900: '#000000',
  neutral800: '#2D2D2D',
  neutral700: '#545656',
  neutral600: '#767878',
  neutral500: '#989A9A',
  neutral400: '#BBBBBB',
  neutral300: '#DDDDDD',
  neutral200: '#EEEEEE',
  neutral100: '#F6F6F6',
  neutralWhite: '#FFFFFF',
  textHeadlines: '#0F3549',
  textDefault: '#0F3549',
  textOnPrimary: '#FFFFFF',
  textOnSecondary: '#FFFFFF',
  textOnBrandSupport1: '#0F3549',
  textOnBrandSupport2: '#FFFFFF',
  textOnBrandSupport3: '#FFFFFF',
  brandSupport1C500: '#FFC905',
  brandSupport1C400: '#FFD540',
  brandSupport1C300: '#FFE279',
  brandSupport1C200: '#FFEA9F',
  brandSupport1C100: '#FFF3C6',
  brandSupport1C50: '#FFF9E2',
  brandSupport2C500: '#3BB4B4',
  brandSupport2C400: '#73D2D2',
  brandSupport2C300: '#9DE0E0',
  brandSupport2C200: '#B9E9E9',
  brandSupport2C100: '#D5F1F1',
  brandSupport2C50: '#EAF8F8',
  semanticErrorHigh: '#CF3516',
  semanticErrorLow: '#FEF4F4',
  semanticSuccessHigh: '#067417',
  semanticSuccessLow: '#EDF8EE',
  semanticInfoHigh: '#1C68CA',
  semanticInfoLow: '#F0F6FF',
  grassGreen100: '#66C274',
  freshGreen100: '#BAD43E',
  // to be removed once rebranding is completed for all countries
  white: '#FFFFFF',
  black: '#000000',
  neutral: {
    neutral5: '#00000014',
    neutral20: '#fdfdfd',
    neutral50: '#FAFAFA',
    neutral70: '#FBFBFB',
    neutral100: '#f5f5f5',
    neutral120: '#f2f1f1',
    neutral300: '#d4d4d4',
    neutral370: '#a9a9a9',
    neutral400: '#a3a3a3',
  },
  cyan: {
    cyan400: '#22d3ee',
    cyan450: '#5bc0de',
    cyan500: '#06b6d4',
  },
  emerald: {
    emerald800: '#065f46',
    emerald820: '#005d4a',
    emerald900: '#064e3b',
  },
  amber: {
    amber400: '#fbbf24',
    amber450: '#f0ad4e',
    amber500: '#f59e0b',
  },
  red: {
    red600: '#dc2626',
    red650: '#d32f2f',
    red700: '#b91c1c',
  },
  grey: {
    grey50: '#F9FAFB',
    grey100: '#F3F4F6',
    grey200: '#E5E7EB',
    grey300: '#D1D5DB',
    grey350: '#bdbdbd',
    grey400: '#9CA3AF',
    grey450: '#8A8A8A',
    grey470: '#757575',
    grey500: '#6B7280',
    grey600: '#4B5563',
    grey700: '#374151',
    grey800: '#1F2937',
    grey900: '#111827',
  },
  blue: {
    blue50: '#EFF6FF',
    blue100: '#DBEAFE',
    blue200: '#BFDBFE',
    blue300: '#93C5FD',
    blue400: '#60A5FA',
    blue500: '#3B82F6',
    blue600: '#2563EB',
    blue700: '#0E7490',
    blue800: '#075985',
  },
  green: {
    green200: '#A7F3D0',
    green300: '#6EE7B7',
    green400: '#34D399',
  },
  orange: {
    orange50: '#fff7ed',
    orange100: '#ffedd5',
    orange200: '#fed7aa',
    orange300: '#fdba74',
    orange400: '#fb923c',
    orange500: '#f97316',
    orange600: '#ea580c',
    orange650: '#d54512',
    orange700: '#c2410c',
    orange800: '#9a3412',
    orange900: '#7c2d124',
    orange950: '#431407',
  },
  yellow: {
    yellow200: '#fef08a',
    yellow250: '#FFFF00',
    yellow300: '#fde047',
    yellow370: '#f7cd03',
    yellow400: '#facc15',
    yellow500: '#eab308',
  },
  petrolBlue: {
    petrolBlue: '#004A5D',
    petrolBlue20: '#CCDBDF',
    petrolBlue40: '#99B7BE',
    petrolBlue60: '#66929E',
    petrolBlue80: '#336E7D',
  },
  steel: {
    steel: '#545656',
    steel20: '#DDDDDD',
    steel40: '#BBBBBB',
    steel60: '#989A9A',
    steel80: '#767878',
  },
  teal: {
    teal200: '#99f6e4',
    teal250: '#b1e6de',
    teal300: '#5eead4',
  },
  bloodOrange: '#EE4C14',
  grassGreen: {
    grassGreen: '#009A17',
    grassGreen20: '#CCEBD1',
    grassGreen40: '#99D7A2',
    grassGreen60: '#66C274',
    grassGreen80: '#33AE45',
  },
  freshGreen: {
    freshGreen: '#A9C90E',
    freshGreen20: '#EEF4CF',
    freshGreen40: '#DDE99F',
    freshGreen60: '#CBDF6E',
    freshGreen80: '#BAD43E',
  },
  error: {
    error: '#E7401E',
    error40: '#FAD9D2',
  },
  midOrange: '#F06400',
  thunder: '#2D2D2D',
  mist: '#F6F8FB',
  gallery: '#EEEEEE',
  aquaBlue: {
    aquaBlue: '#00B1AF',
    aquaBlue20: '#CCEFEF',
    aquaBlue40: '#99E0DF',
    aquaBlue60: '#66D0CF',
    aquaBlue80: '#33C1BF',
  },
  sand: '#F6F6F6',
  lightOrange: '#ED8B00',
}
