import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import ExcludedOptionsMessages from './ExcludedOptionsMessages'
import Required from './Required'
import { mapRequirementsToOptions, toFlatOptionList } from './rules'
import {
  ManufacturerName,
  RequiresRulesHelpMessage,
  TitleFont,
} from './RulesPopupItem.styled'

export interface RulesItemProps {
  optionId: number
}

const RulesPopupItem: FC<RulesItemProps> = ({ optionId }) => {
  const { state } = useVehicleConfig()
  const { t } = useTranslation()

  const requiresUserSelection =
    state.requires?.length > 0 || state.pendingChoices?.length > 0

  return (
    <>
      {requiresUserSelection && (
        <>
          <ManufacturerName>
            {
              toFlatOptionList(
                state.vehicleConfiguration.configurationOptions,
              ).find((o: Option) => o.identifier === state.rulesParentId)
                ?.manufacturerName
            }
          </ManufacturerName>
          <RequiresRulesHelpMessage>
            {t(DICTIONARY.REQUIRES_RULES_HELP_MESSAGE)}
          </RequiresRulesHelpMessage>
          <ExcludedOptionsMessages />
          <div style={{ display: 'flex', marginBottom: '0.4em' }}>
            <div style={{ flexBasis: '10%' }}></div>
            <TitleFont style={{ flexBasis: '70%', textAlign: 'start' }}>
              {t(DICTIONARY.DESCRIPTION)}
            </TitleFont>
            <TitleFont style={{ flexBasis: '20%', textAlign: 'end' }}>
              {t(DICTIONARY.PRICE)}
            </TitleFont>
          </div>
          <Required
            requires={mapRequirementsToOptions(
              state.vehicle.options,
              toFlatOptionList(state.vehicleConfiguration.configurationOptions),
              state.requires,
              state.pendingChoices,
              state?.limitedVehicleConfiguration?.excludePremiumTrims,
            )}
          />
        </>
      )}
    </>
  )
}

export default RulesPopupItem
