import { makeStyles, Theme } from '@material-ui/core'
import { InfoIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import VelocityNotice from '../../../components/notice/VelocityNotice'
import { DICTIONARY } from '../../../constants/dictionary'
import { Profile } from '../../../model/Profile'
import { Quote } from '../../../model/Quote'
import { getStyles, isRebranding } from '../../../utils/styles'
import ContentFooter from './ContentFooter'
import ContentHeader from './ContentHeader'

const s = () => getStyles().colors.quoteSummary.template

interface Props {
  quote: Quote
  profile: Profile
  locale: string
  currency: Intl.NumberFormat
  quoteParam: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '40px 15px 30px',
    maxWidth: '1280px',
    margin: '0 auto',
  },
  summaryReview: {
    display: 'flex',
    padding: '15px',
    marginBottom: '20px',
    border: `1px solid ${s().review.border}`,
    borderRadius: '0',
    backgroundColor: s().review.background,
    color: s().review.font,
    alignItems: 'center',
  },
  summaryIcon: {
    marginRight: '10px',
    color: s().icon.font,
  },
  content: {
    background: s().content.background,
    border: `solid 1px ${s().content.border}`,
    padding: '2.5em 4.5em',
    margin: '1.5em 0',
  },
}))

const QuoteSummaryTemplate: React.FC<Props> = ({
  quote,
  profile,
  children,
  locale,
  currency,
  quoteParam,
}) => {
  const isQuoteSummary = window.location.pathname.indexOf('quote-summary') > -1
  const classes = useStyles()
  const [isNewOrder, setIsNewOrder] = useState(false)
  const { t } = useTranslation()

  const displaySummaryReview =
    (profile?.product?.productName || 'corporate') === 'corporate'

  const getSummaryText = (): string => {
    if (isNewOrder) {
      return t(DICTIONARY.NEW_ORDER_SUBMITTED_NOTICE)
    }
    if (!isNewOrder && !isQuoteSummary) {
      return t(DICTIONARY.ORDER_ALREADY_SUBMITTED_NOTICE)
    }
    if (!isNewOrder && isQuoteSummary) {
      return t(
        isRebranding()
          ? DICTIONARY.QUOTE_SUMMARY_REVIEW_AYVENS_LABEL
          : DICTIONARY.QUOTE_SUMMARY_REVIEW_LABEL,
      )
    }
    return ''
  }
  return (
    <div className={classes.root}>
      {displaySummaryReview &&
        (isRebranding() ? (
          <VelocityNotice text={getSummaryText()} />
        ) : (
          <div className={classes.summaryReview} color={s().summary}>
            <InfoIcon size="s" className={classes.summaryIcon} />
            <div>{getSummaryText()}</div>
          </div>
        ))}

      <div className={classes.content}>
        <ContentHeader
          quote={quote}
          currency={currency}
          profile={profile}
          locale={locale}
          quoteParam={quoteParam}
        />

        {children}

        <ContentFooter
          profile={profile}
          quote={quote}
          locale={locale}
          onSubmitQuote={() => {
            setIsNewOrder(true)
          }}
        />
      </div>
    </div>
  )
}

export default QuoteSummaryTemplate
