import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { Quote } from '../../../model/Quote'
import { getImaginImage } from '../../../utils/imagin'
import {
  LabelText,
  PrimaryLabel,
  PrimaryLabelText,
  SecondaryLabel,
  TertiaryLabel,
} from './VehicleList.styled'

interface Props {
  quote: Quote
}

const VehicleImage: FC<Props> = ({ quote }) => {
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const showCommercialLabel =
    !quote.vehicle.recommendedVehicle &&
    !quote.vehicle.tag &&
    quote.vehicle.carCategory &&
    quote.vehicle.carCategory.code === 'V'
  return (
    <Root>
      <img
        src={getImaginImage(quote.vehicle, locale)}
        alt={`${quote.vehicle.make.text} ${quote.vehicle.model.text}`}
      />
      {quote.vehicle.recommendedVehicle && (
        <PrimaryLabel>
          <PrimaryLabelText>{t(DICTIONARY.RECOMMENDED_LABEL)}</PrimaryLabelText>
        </PrimaryLabel>
      )}
      {!quote.vehicle.recommendedVehicle && quote.vehicle.tag && (
        <SecondaryLabel>
          <LabelText>{t(`showroom_tag.${quote.vehicle.tag}.label`)}</LabelText>
        </SecondaryLabel>
      )}
      {showCommercialLabel && (
        <TertiaryLabel>
          <LabelText>{t(DICTIONARY.COMMERCIAL_LABEL)}</LabelText>
        </TertiaryLabel>
      )}
    </Root>
  )
}

export const Root = styled.div`
  position: relative;

  img {
    width: 100%;
  }
`

export default VehicleImage
