import { Helmet } from 'react-helmet'
import React, { FC } from 'react'

interface Props {
  language: string
}

export const CookieBannerScript: FC<Props> = ({ language }) => {
  return language ? (
    <Helmet htmlAttributes={{ lang: language }}>
      {process.env.REACT_APP_ONE_TRUST_ADD_AUTO_BLOCK === 'true' && (
        <script
          type="text/javascript"
          src={`https://cdn.cookielaw.org/consent/${process.env.REACT_APP_COOKIE_DATA_DOMAIN_SCRIPT}/OtAutoBlock.js`}
        />
      )}
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script={process.env.REACT_APP_COOKIE_DATA_DOMAIN_SCRIPT}
      ></script>
    </Helmet>
  ) : null
}
