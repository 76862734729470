import { DICTIONARY, FILTER } from '../../../constants/dictionary'
import {
  ShowroomFiltersResponse,
  ShowroomModel,
} from '../../../model/ShowroomFiltersResponse'

export interface GroupedModel {
  make: string
  model: string
}
const sortModels = (
  filtersResponse: ShowroomFiltersResponse,
): ShowroomModel[] => {
  let result = [] as ShowroomModel[]
  if (filtersResponse?.models) {
    const comparator = (a: ShowroomModel, b: ShowroomModel) =>
      -b.make[0].toUpperCase().localeCompare(a.make[0].toUpperCase())
    result = filtersResponse.models.sort(comparator)
  }
  return result
}

const groupModels = (models: ShowroomModel[]): GroupedModel[] =>
  models.reduce((a, i) => {
    i.models.sort((b, c) => b.localeCompare(c))
    return a.concat(
      i.models.map((e) => ({
        make: i.make,
        model: e,
      })),
    )
  }, [])

const co2EmissionTooltip = (co2Type: string): string => {
  switch (co2Type) {
    case FILTER.CO2_TYPE.NEDC: {
      return DICTIONARY.CO2NEDCFILTER_TOOLTIP
    }
    case FILTER.CO2_TYPE.WLTP: {
      return DICTIONARY.CO2WLTPFILTER_TOOLTIP
    }
    case FILTER.CO2_TYPE.DEFAULT:
    default: {
      return DICTIONARY.CO2FILTER_TOOLTIP
    }
  }
}

export { sortModels, groupModels, co2EmissionTooltip }
