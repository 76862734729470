export const DICTIONARY = {
  EXISTING_CUSTOMER: 'ExistingCustomer',
  CURRENCY_CONVERTION_HELPER_TEXT: 'CurrencyConvertionHelperText',
  MENU_LABEL: 'MenuLabel',
  LOG_OUT_LABEL: 'LogOutLabel',
  LEASE_PLAN_LABEL: 'LeasePlanLabel',
  AYVENS_LABEL: 'AyvensLabel',
  TELEPHONE_LABEL: 'TelLabel',
  PHONE_NUMBER: 'PhoneNumber',
  NOTICE_LEASE_PLAN_LABEL: 'NoticeLeasePlanLabel',
  TERMS_OF_USE_LABEL: 'TermsOfUseLabel',
  DATA_PROTECTION_LABEL: 'DataProtectionLabel',
  COOKIE_POLICY_LABEL: 'CookiePolicyLabel',
  CONTACT_LEASE_PLAN_LABEL: 'ContactLeasePlanLabel',
  BACK_BUTTON: 'Back',
  ORDER_SUBMIT_LABEL: 'OrderSubmitLabel',
  QUOTE_SUMMARY_REVIEW_LABEL: 'QuoteSummaryReviewLabel',
  QUOTE_SUMMARY_REVIEW_AYVENS_LABEL: 'QuoteSummaryReviewAyvensLabel',
  VEHICLE: 'Vehicle',
  DELIVERY: 'Delivery',
  OPTIONS: 'Options',
  PACKS: 'Packs',
  ACCESSORIES: 'Accessories',
  MAINTENANCE: 'Maintenance',
  NOT_INCLUDED: 'NotIncluded',
  LIST_PRICE: 'ListPrice',
  MONTHLY_PRICE_EXCL_RECOVERABLE_VAT_LABEL:
    'MonthlyPriceExclRecoverableVatLabel',
  MONTHLY_PRICE_EXCL_VAT_LABEL: 'MonthlyPriceExclVatLabel',
  MONTHLY_PRICE_INCL_VAT_LABEL: 'MonthlyPriceInclVatLabel',
  MONTHLY_PRICE_INCL_AVERAGE_VAT_LABEL: 'MonthlyPriceInclAverageVatLabel',
  MONTHLY_TOTAL_TAX_LABEL: 'MonthlyTotalTaxLabel',
  MONTHLY_ENVIRONMENTAL_TAX_LABEL: 'MonthlyEnvironmentalTaxLabel',
  MONTHLY_TCO_EXCL_VAT_LABEL: 'MonthlyTcoExclVatLabel',
  MONTHLY_TCO_INCL_VAT_LABEL: 'MonthlyTcoInclVatLabel',
  VAT_EXCL: 'VatExcl',
  DOWNPAYMENT: 'Downpayment',
  DRIVER_MONTHLY_CONTRIBUTION_LABEL: 'DriverMonthlyContributionLabel',
  DRIVER_MONTHLY_CONTRIBUTION_WITH_PERIOD_LABEL:
    'DriverMonthlyContributionWithPeriodLabel',
  RETAIL_PRICE: 'RetailPrice',
  ACCESSORIES_RETAIL_PRICE: 'AccessoriesRetailPrice',
  OPTIONS_RETAIL_PRICE: 'OptionsRetailPrice',
  VEHICLE_RETAIL_PRICE: 'VehicleRetailPrice',
  VEHICLE_WITH_OPTIONS_RETAIL_PRICE: 'VehicleWithOptionsRetailPrice',
  DOORS: 'Doors',
  VEHICLE_BODY_TYPE: 'VehicleBodyType',
  TRANSMISSION: 'Transmission',
  VEHICLE_FUEL_TYPE: 'VehicleFuelType',
  FUEL: 'Fuel',
  ENG_POWER: 'EngPower',
  HORSE_POWER: 'HorsePower',
  SEATS: 'Seats',
  CO2_UNIT: 'Co2Unit',
  DURATION: 'Duration',
  MILEAGE: 'Mileage',
  TOTAL_MILEAGE_LABEL: 'TotalMileageLabel',
  YES: 'Yes',
  NO: 'No',
  DOWNLOAD_QUOTE_LABEL: 'DownloadQuoteLabel',
  QUOTE_SUMMARY_ACCESSORIES_LABEL: 'QuoteSummaryAccessoriesLabel',
  QUOTE_SUMMARY_AGREEMENT_NOTICE: 'QuoteSummaryAgreementNote',
  QUOTE_SUMMARY_AGREEMENT_NOTICE_AYVENS: 'QuoteSummaryAgreementNoteAyvens',
  QUOTE_SUMMARY_TITLE: 'QuoteSummaryTitle',
  QUOTE_SUMMARY_QUOTE_NUMBER: 'QuoteSummaryQuoteNumber',
  QUOTE_SUMMARY_QUOTE_DATE: 'QuoteSummaryDate',
  QUOTE_SUMMARY_VEHICLE_DETAILS_LABEL: 'QuoteSummaryVehicleDetailsLabel',
  QUOTE_SUMMARY_PACKS_AND_OPTIONS_LABEL: 'QuoteSummaryPacksAndOptionsLabel',
  QUOTE_SUMMARY_SERVICES_LABEL: 'QuoteSummaryServicesLabel',
  QUOTE_SUMMARY_CONTRACT_DETAILS_LABEL: 'QuoteSummaryContractDetailsLabel',
  QUOTE_SUMMARY_PRICING_LABEL: 'QuoteSummaryPricingLabel',
  LOGO_DE_COEUR: 'Services.LOGO_DE_COEUR.Label',
  REPLACEMENT_CAR: 'Services.REPLACEMENT_CAR.Label',
  WINTER_TYRES: 'Services.WINTER_TYRES.Label',
  TYRES: 'Services.TYRES.Label',
  ESTIMATED_TAX_BASE: 'EstimatedTaxBase',
  TAX_BASE: 'TaxBase',
  HEADER: 'WltpEmissionsLabel',
  TOTAL_RETAIL_COST_INCL_DELIVERY_LABEL: 'TotalRetailCostInclDeliveryLabel',
  TOTAL_RETAIL_COST_EXCL_DELIVERY_LABEL: 'TotalRetailCostExclDeliveryLabel',
  SELECT_LABEL: 'SelectLabel',
  WLTP_CO2: 'WltpCo2',
  ORDER_ALREADY_SUBMITTED_NOTICE: 'OrderAlreadySubmittedNotice',
  NEW_ORDER_SUBMITTED_NOTICE: 'NewOrderSubmittedNotice',
  INVESTMENT_TOTAL: 'InvestmentTotal',
  WHOLE_LIFE_COST: 'WholeLifeCost',
  COLORS_LABEL: 'Colors',
  EXTERIOR_TITLE_LABEL: 'ExteriorTitleLabel',
  INTERIOR_TITLE_LABEL: 'InteriorTitleLabel',
  TRIM_OTHERS_TITLE_LABEL: 'TrimsOthersTitleLabel',
  SHOW_OPTIONS_LABEL: 'ShowOptionsLabel',
  HIDE_OPTIONS_LABEL: 'HideOptionsLabel',
  SHOW_DETAILS_LABEL: 'ShowDetailsLabel',
  HIDE_LABEL: 'HideLabel',
  ADDITIONAL_DESCRIPTION_LABEL: 'AdditionalDescriptionLabel',
  DESCRIPTION: 'Description',
  PRICE: 'Price',
  REQUIRES_RULES_HELP_MESSAGE: 'RequiresRulesHelpMessage',
  CONFIRM_SELECTION_TITLE: 'ConfirmSelectionTitle',
  CANCEL: 'Cancel',
  AND: 'And',
  OR: 'Or',
  IMAGE_INFO_MESSAGE: 'ImageInfoMessage',
  CONTRACT_OPTIONS_LABEL: 'ContractOptionsLabel',
  CONTRACT_MILEAGE_LABEL: 'ContractMileageLabel',
  CONTRACT_DURATION_LABEL: 'ContractDurationLabel',
  INCLUDE_MAINTENANCE_LABEL: 'IncludeMaintenanceLabel',
  CORPORATE_BOTTOM_QUOTE_DISCLAIMER: 'CorporateBottomQuoteDisclaimer',
  BOTTOM_QUOTE_DISCLAIMER: 'BottomQuoteDisclaimer',
  BOTTOM_QUOTE_DISCLAIMER_AYVENS: 'BottomQuoteDisclaimerAyvens',
  VAT_LABEL: 'VATLabel',
  TOTAL: 'Total',
  SUB_TOTAL_LABEL: 'SubtotalLabel',
  SAVE_QUOTE_LABEL: 'SaveQuoteLabel',
  SAVED_QUOTES_LABEL: 'QuotesLabel',
  QUOTE_CODE_TEXT: 'QuoteCodeText',
  MONTH_LABEL: 'MonthLabel',
  MONTHS_LABEL: 'MonthsLabel',
  SELECTED_OPTIONS_LABEL: 'SelectedOptionsLabel',
  PER_MONTH_LABEL: 'PerMonthLabel',
  SHOW_PRICE_BREAKDOWN: 'ShowPriceBreakdownLabel',
  HIDE_PRICE_BREAKDOWN_LABEL: 'HidePriceBreakdownLabel',
  CONTINUE_LABEL: 'ContinueLabel',
  REMOVE_ALL: 'RemoveAllLabel',
  SHOW_ALL: 'ShowAll',
  SHOW_LESS: 'ShowLess',
  SERVICES_TAB_TITLE: 'ServicesTabTitle',
  DISABLED_FOR_DESELECTION_AS_REQUIRED_TOOLTIP:
    'DisabledForDeselectionAsRequiredTooltip',
  DISABLED_FOR_SELECTION_AS_INCLUDED_TOOLTIP:
    'DisabledForSelectionAsIncludedTooltip',
  DISABLED_FOR_SELECTION_AS_EXCLUDED_TOOLTIP:
    'DisabledForSelectionAsExcludedTooltip',
  SELECT: 'Select',
  DRIVER_MONTHLY_CONTRIBUTION_CONFIRMATION_DIALOG_MESSAGE:
    'DriverMonthlyContributionConfirmationDialogMessage',
  DRIVER_MONTHLY_CONTRIBUTION_CONFIRMATION_DIALOG_TITLE:
    'DriverMonthlyContributionConfirmationDialogTitle',
  DRIVER_ONE_OFF_PAYMENT_CONFIRMATION_DIALOG_MESSAGE:
    'DriverOneOffPaymentConfirmationDialogMessage',
  DRIVER_ONE_OFF_PAYMENT_CONFIRMATION_DIALOG_TITLE:
    'DriverOneOffPaymentConfirmationDialogTitle',
  DRIVER_ONE_OFF_PAYMENT_LABEL: 'DriverOneOffPaymentLabel',
  ACCESSORIES_CONFIRMATION_DIALOG_TITLE: 'AccessoriesConfirmationDialogTitle',
  ACCESSORIES_CONFIRMATION_DIALOG_MESSAGE:
    'AccessoriesConfirmationDialogMessage',
  ADDED_TO_FAVOURITES_MESSAGE: 'AddedFavouritesMessage',
  ADDED_TO_POLICY_FAVOURITES_MESSAGE: 'AddedPolicyFavouritesMessage',
  PDF_QUOTE_NOTE: 'PDF_QuoteNote',
  PDF_QUOTE_NOTE_2: 'PDF_QuoteNote2',
  PDF_QUOTE_NOTE_3: 'PDF_QuoteNote3',
  PDF_QUOTE_NOTE_4: 'PDF_QuoteNote4',
  PDF_BUSINESS_TITLE: 'PDF.business.title',
  PDF_BUSINESS_DISCLAIMER: 'PDF.business.disclaimer',
  PDF_PERSONAL_TITLE: 'PDF.personal.title',
  PDF_PERSONAL_DISCLAIMER: 'PDF.personal.disclaimer',
  PDF_CORPORATE_TITLE: 'PDF.corporate.title',
  PDF_CORPORATE_DISCLAIMER: 'PDF.corporate.disclaimer',
  PDF_CORPORATE_DISCLAIMER_AYVENS: 'PDF.corporate.disclaimer.ayvens',
  CONTRACT_COMMISSION_AMOUNT_LABEL: 'ContractCommissionAmountLabel',
  CONTRACT_DISCOUNT_AMOUNT_LABEL: 'ContractDiscountAmountLabel',
  COMMISSION_AMOUNT: 'CommissionAmount',
  DISCOUNT_AMOUNT: 'DiscountAmount',
  SELECTED_CONTRACT_LABEL: 'SelectedContractLabel',
  POLICY_VALIDATION_BAR_TITLE: 'PolicyValidationBarTitle',
  CONTRACT_NUMBER_LABEL: 'ContractNumberLabel',
  MAKES_FILTER_TOOLTIP: 'MakesFilterTooltip',
  MAKE: 'Make',
  MODEL: 'Model',
  MODELS_FILTER_TOOLTIP: 'ModelsFilterTooltip',
  SELECT_BODY_TYPES: 'SelectBodyTypes',
  BODY_FILTER_TOOLTIP: 'BodyFilterTooltip',
  SELECT_MODELS: 'SelectModels',
  SELECT_MAKES: 'SelectMakes',
  FUEL_FILTER_TOOLTIP: 'FuelFilterTooltip',
  SELECT_FUEL_TYPES: 'SelectFuelTypes',
  SELECT_CATEGORY_TYPES: 'SelectCategoryTypes',
  VEHICLE_CATEGORY: 'VehicleCategory',
  CATEGORY_FILTER_TOOLTIP: 'CategoryFilterTooltip',
  SELECT_TRANSMISSION_TYPES: 'SelectTransmissionTypes',
  TRANSMISSION_FILTER_TOOLTIP: 'TransmissionFilterTooltip',
  VEHICLE_TRANSMISSION_TYPE: 'VehicleTransmissionType',
  DRIVETRAIN_FILTER_TOOLTIP: 'DrivetrainFilterTooltip',
  SELECT_DRIVETRAINS: 'SelectDrivetrains',
  VEHICLE_DRIVETRAIN: 'VehicleDrivetrain',
  VEHICLE_ENERGY_RATING: 'VehicleEnergyRating',
  MONTHLY_PRICE_LABEL: 'MonthlyPriceLabel',
  PRICE_FILTER_TOOLTIP: 'PriceFilterTooltip',
  VEHICLE_CO2_EMISSIONS: 'VehicleCO2Emissions',
  CO2_FILTER_TOOLTIP: 'CO2FilterTooltip',
  ELECTRIC_RANGE: 'ElectricRange',
  ELECTRIC_RANGE_FILTER_TOOLTIP: 'ElectricRangeFilterTooltip',
  FILTERS: 'Filters',
  RESET_ALL_FILTERS: 'ResetAllFilters',
  VEHICLE_MILEAGE: 'VehicleMileage',
  DURATION_FILTER_TOOLTIP: 'DurationFilterTooltip',
  VEHICLE_DURATION: 'VehicleDuration',
  CO2NEDCFILTER_TOOLTIP: 'CO2NEDCFilterTooltip',
  CO2WLTPFILTER_TOOLTIP: 'CO2WLTPFilterTooltip',
  CO2FILTER_TOOLTIP: 'CO2FilterTooltip',
  FIND_YOUR_VEHICLE: 'FindYourVehicle',
  STARTING_FROM: 'StartingFrom',
  VEHICLE_SPECIFICATION_LABEL: 'VehicleSpecificationLabel',
  SHOW: 'Show',
  SHOWING_LABEL: 'ShowingLabel',
  OF_LABEL: 'OfLabel',
  SORT_BY: 'SortBy',
  AZ_SORT_LABEL: 'AZSortLabel',
  ZA_SORT_LABEL: 'ZASortLabel',
  LOWEST_PRICE_SORT_LABEL: 'LowestPriceSortLabel',
  HIGHEST_PRICE_SORT_LABEL: 'HighestPriceSortLabel',
  RECOMMENDED_LABEL: 'RecommendedVehicleLabel',
  COMMERCIAL_LABEL: 'CommercialVehicleLabel',
  NO_CAR_FOUND: 'NoCarFoundLabel',
  CLEAR_FILTERS: 'ClearFiltersLabel',
  AVAILABLE_OFFERS: 'AvailableOffersLabel',
  QUARTERLY_TCO_EXCL_VAT_LABEL: 'QuarterlyTcoExclVatLabel',
  QUARTERLY_TCO_INCL_VAT_LABEL: 'QuarterlyTcoInclVatLabel',
  YEARLY_TCO_EXCL_VAT_LABEL: 'YearlyTcoExclVatLabel',
  YEARLY_TCO_INCL_VAT_LABEL: 'YearlyTcoInclVatLabel',
  MONTHLY_WHOLE_LIFE_COST: 'MonthlyWholeLifeCost',
  MY_FAVOURITES_LABEL: 'MyFavouritesLabel',
  PRIVATE_CAR_HIRE_NOTE: 'PrivateCarHireNote',
  PRIVATE_CAR_HIRE_NOTE_2: 'PrivateCarHireNote2',
  PRIVATE_CAR_HIRE_NOTE_AYVENS: 'PrivateCarHireNoteAyvens',
  PRIVATE_CAR_HIRE_NOTE_2_AYVENS: 'PrivateCarHireNote2Ayvens',
  PRIVATE_CAR_HIRE_NOTE_3: 'PrivateCarHireNote3',
  PRIVATE_CAR_HIRE_NOTE_4: 'PrivateCarHireNote4',
  QUOTE_NUMBER_ORDER_LABEL: 'QuoteNumberOrderLabel',
  PUBLIC_ORDER_FIRST_NAME_LABEL: 'PublicOrderFirstNameLabel',
  PUBLIC_ORDER_LAST_NAME_LABEL: 'PublicOrderLastNameLabel',
  PUBLIC_ORDER_EMAIL_LABEL: 'PublicOrderEmailLabel',
  PUBLIC_ORDER_TELEPHONE_LABEL: 'PublicOrderTelephoneLabel',
  PUBLIC_ORDER_COMPANY_LABEL: 'PublicOrderCompanyLabel',
  PUBLIC_ORDER_MILEAGE_LABEL: 'PublicOrderMileageLabel',
  PUBLIC_ORDER_OPT_IN_LABEL: 'PublicOrderOptInLabel',
  PUBLIC_ORDER_SUBMIT_FORM_CONSENT_TEXT: 'PublicOrderSubmitFormConsentText',
  PUBLIC_ORDER_SUBMIT_FORM_CONSENT_TEXT_AYVENS:
    'PublicOrderSubmitFormConsentTextAyvens',
  PUBLIC_ORDER_OPT_IN_TEXT: 'PublicOrderOptInNewText',
  PUBLIC_ORDER_OPT_IN_TEXT_AYVENS: 'PublicOrderOptInNewTextAyvens',
  SALES_FORCE_LEAD_DESCRIPTION_DURATION_LABEL:
    'SalesForceLeadDescriptionDurationLabel',
  SALES_FORCE_LEAD_DESCRIPTION_QUOTE_PRICE_EXCL_VAT_LABEL:
    'SalesForceLeadDescriptionQuotePriceExclVatLabel',
  EDITION: 'Edition',
  NAME_REQUIRED: 'NameRequired',
  EMAIL_REQUIRED: 'EmailRequired',
  EMPTY_COMPANY_FIELD: 'EmptyCompanyField',
  ALERT_23: 'Alert23',
  NAME_ERROR: 'NameError',
  ERROR_NOT_EMAIL: 'ErrorNotAnEmail',
  INVALID_PHONE_NUMBER: 'InvalidPhoneNumber',
  PUBLIC_ORDER_QUOTE_PRICE_LABEL: 'PublicOrderQuotePriceLabel',
  SUBMIT: 'submit',
  PUBLIC_ORDER_CONSENT_ALERT: 'PublicOrderConsentAlert',
  THANK_YOU_LABEL: 'ThankYouLabel',
  ORDER_MAIL_SENT_MESSAGE: 'OrderMailSentMessage',
  GO_TO_HOME_PAGE: 'GoToHomePage',
  GO_BACK: 'GoBack',
  SALES_FORCE_LEAD_DESCRIPTION_QUOTE_PRICE_INCL_VAT_LABEL:
    'SalesForceLeadDescriptionQuotePriceInclVatLabel',
  UNAUTHORISED_ERROR_TITLE: 'Unauthorised',
  UNAUTHORISED_ERROR_MESSAGE: 'SSOAuthenticationFailureInfo',
  QUOTE_NOT_FOUND_ERROR_TITLE: 'QuoteNotFound',
  QUOTE_NOT_FOUND_ERROR_MESSAGE: 'QuoteNotFoundUrl',
  BENEFIT_IN_KIND: 'BenefitInKind',
  ADD_ACCESSORY: 'AddAccessory',
  VEHICLE_CONFIGURATION_MAINTENANCE_TOOLTIP_TEXT:
    'VehicleConfigurationMaintenanceTooltipText',
  MORE_INFO: 'MoreInfo',
  YEAR: 'Year',
  CAR_BODY: 'CarBody',
  CLOSE: 'Close',
  VEHICLE_DISCOUNTED_PRICE: 'VehicleDiscountedPrice',
  ORDER_APPROVED_TITLE: 'OrderApprovedTitle',
  ORDER_APPROVED_TEXT: 'OrderApprovedText',
  OPTIONS_DISCOUNTED_PRICE: 'OptionsDiscountedPrice',
  RESIDUAL_VALUE: 'ResidualValue',
  ROAD_TAX: 'RoadTax',
  MONTHLY_TYRES: 'Tyres',
  EXCESS_MILEAGE_RATE: 'ExcessMileageRate',
  UNDER_MILEAGE_RATE: 'UnderMileageRate',
  LEASE_COST: 'LeaseCost',
  MONTHLY_INSURANCE: 'MonthlyInsurance',
  VEHICLE_COST: 'VehicleCost',
  TOTAL_LEASE: 'TotalLease',
  MORE_INFORMATION: 'MoreInformation',
  ENGLISH: 'English',
  LANGUAGE_NAME: 'LanguageName',
  NO_OPTIONS_SELECTED: 'NoOptionsSelected',
  RETAIL_PRICE_WITHOUT_OPTIONS: 'RetailPriceWithoutOptions',
  SCHEDULED_MAINTENANCE_TITLE: 'ScheduledMaintenance',
  SCHEDULED_MAINTENANCE_MESSAGE: 'ScheduledMaintenanceInfo',
  SELECTED_CONFIGURATION_LABEL: 'SelectedConfiguration',
  SERVICE_INTERRUPTION_TITLE: 'ServiceInterruption',
  SERVICE_INTERRUPTION_MESSAGE: 'ServiceInterruptionInfo',
  FILTERS_ERROR_MESSAGE: 'FiltersErrorMessage',
  INVALID_QUOTE_ERROR_TITLE: 'InvalidQuoteErrorTitle',
  INVALID_QUOTE_ERROR_MESSAGE: 'InvalidQuoteErrorMessage',
  CO2_LABEL: 'Co2',
  ADD_FAVORITES: 'AddFavouritesLabel',
  ADD_POLICY_FAVORITES: 'AddPolicyFavouritesLabel',
  POLICY_CONFIGURATION_LABEL: 'PolicyConfigurationLabel',
  VEHICLE_RANGE_LABEL: 'VehicleRangeLabel',
  VEHICLE_RANGE_UNIT: 'VehicleRangeUnit',
  FAVOURITE_UNAVAILABLE_LABEL: 'FavouriteUnavailableLabel',
  NO_FAVOURITES_LABEL: 'NoFavouritesLabel',
  NO_SAVED_QUOTES_LABEL: 'NoSavedQuotesLabel',
  VIEW_QUOTE_DETAILS: 'ViewQuoteDetails',
  RETAIL_PRICE_OF_OPTIONS: 'RetailPriceOfOptions',
  ADD_TO_SAVED_QUOTES: 'AddToSavedQuotes',
  ADDED_TO_SAVED_QUOTES_MESSAGE: 'AddedToSavedQuotes',
  SAVED_QUOTES_TITLE: 'SavedQuotesTitleLabel',
  NO_LONGER_IN_POLICY: 'NoLongerInPolicy',
  TRAILER_WEIGHT_BRAKED: 'TrailerWeightBraked',
  GROSS_TRAILER_WEIGHT: 'GrossTrailerWeightInKg',
  INVALID_VEHICLE_CONFIGURATION: 'InvalidVehicleConfiguration',
  VEHICLE_UNAVAILABLE: 'VehicleUnavailable',
  EXCLUDES_RULES_HELP_MESSAGE: 'ExcludesRulesHelpMessage',
  FRENCH_DECOMMISSION_NOTICE: 'FrenchDecommissionNotice',
}

export const DISPLAY = {
  SERVICES: ['LOGO_DE_COEUR', 'REPLACEMENT_CAR', 'TYRES'],
  OPTIONS_SERVICES: ['WINTER_TYRES'],
}

export const FILTER = {
  CO2_TYPE: {
    NEDC: 'nedcCo2',
    WLTP: 'wltpCo2',
    DEFAULT: 'co2',
  },
}
