import { Box, Container, Grid, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

interface Props {
  component: React.ReactNode
}

const TitleStyled = styled(Typography)<Props>`
  font-size: 50px;
  font-weight: ${getStyles().fonts.title.weight};
`

const SubTitleStyled = styled(Typography)<Props>`
  font-size: 20px;
  font-weight: ${getStyles().fonts.title.weight};
  margin-top: 24px;
`

const SomethingWentWrong = (): ReactElement => (
  <Container>
    <Grid container={true} alignItems="center" direction="row">
      <Grid item={true} xs={12}>
        <Box mt={16} textAlign="left">
          <TitleStyled component="h1" data-e2e-id="somethingWentWrongTitle">
            Oops! Something went wrong
          </TitleStyled>
          <SubTitleStyled
            component="h2"
            data-e2e-id="somethingWentWrongSubtitle"
          >
            We&apos;re working on it and we&apos;ll get it fixed as soon as we
            can
          </SubTitleStyled>
        </Box>
      </Grid>
    </Grid>
  </Container>
)

export default SomethingWentWrong
