import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useRef, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import { capitalize } from '../../../utils/string'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const TrailerWeightFilter: FC<Props> = ({ filtersResponse }) => {
  const mounted = useRef(false)
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()
  const timeout = 1000
  const debouncedRequest = useDebounce(
    (newValue) => {
      setCurrentPosition(Number(newValue[0]))
      dispatch({
        type: 'updateMaxGrossTrailerWeightBraked',
        filters: {
          grossTrailerWeightBraked: {
            value: Number(newValue[0]),
            max: filtersResponse.maxGrossTrailerWeightBraked,
          },
        },
      })
    },
    timeout,
    true,
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (mounted.current) {
      setCurrentPosition(Number(state.filters.grossTrailerWeightBraked.value))
    }
  }, [state.filters.grossTrailerWeightBraked])

  return (
    <ShowroomSlider
      track="inverted"
      htmlTitle={{
        text: {
          __html: sanitize(capitalize(t(DICTIONARY.GROSS_TRAILER_WEIGHT))),
        },
        hint: {
          __html: '',
        },
      }}
      value={currentPosition}
      range={{ min: 0, max: filtersResponse.maxGrossTrailerWeightBraked }}
      onChange={(_, newValue) => {
        setCurrentPosition(Number(newValue))
        debouncedRequest(newValue.toString())
      }}
      marks={[
        { value: 0, label: '0' },
        {
          value: filtersResponse.maxGrossTrailerWeightBraked,
          label: `${filtersResponse.maxGrossTrailerWeightBraked}`,
        },
      ]}
    />
  )
}

export default TrailerWeightFilter
