import { Box, Container, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { history } from '../../utils/historyStore'
import { getStyles } from '../../utils/styles'

interface HistoryState {
  error: {
    trace: string
    errorNumber: number
  }
}
interface Props {
  component: React.ReactNode
}

const TitleStyled = styled(Typography)<Props>`
  font-size: 50px;
  font-weight: 700;
  color: ${getStyles().colors.unauthorisedError.font};
`

const SubTitleStyled = styled(Typography)<Props>`
  font-size: 20px;
  font-weight: 200;
  margin-top: 24px;
`

const Unauthorised = (): ReactElement => {
  const { t } = useTranslation()

  const error = (history?.location?.state as HistoryState)?.error

  return (
    <Container>
      <Grid container={true} alignItems="center" direction="row">
        <Grid item={true} xs={12}>
          <Box mt={16} textAlign="left">
            <TitleStyled component="h1" data-testid="unauthorised-error-title">
              {t(DICTIONARY.UNAUTHORISED_ERROR_TITLE)}
            </TitleStyled>
            <SubTitleStyled
              component="h2"
              data-testid="unauthorised-error-message"
            >
              {t(DICTIONARY.UNAUTHORISED_ERROR_MESSAGE)}
            </SubTitleStyled>
          </Box>
        </Grid>
        {error && (
          <Grid item={true} xs={12}>
            <SubTitleStyled
              component="p"
              data-testid="unauthorised-error-details"
            >
              {error.trace} ({error.errorNumber})
            </SubTitleStyled>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

export default Unauthorised
