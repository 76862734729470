import { TFunction, UseTranslationResponse } from 'react-i18next'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import { VehicleConfig } from '../context/vehicleConfigContext'
import { AppState } from '../reducer/VehicleConfigReducer'

let appState: AppState = null
let isExcludedState = false
let isDisabled = false
let tState: TFunction<'translation', undefined>

export const initState = (
  vehicleConfig: VehicleConfig,
  option: Option,
  ruleDialog: boolean,
  { t }: UseTranslationResponse<'translation', undefined>,
) => {
  const { state } = vehicleConfig
  appState = state
  const isRequired = option.state === 'required'
  const isIncluded = option.state === 'included'
  isExcludedState = option.state === 'excluded'
  isDisabled = ruleDialog ? isIncluded : isIncluded || isRequired
  const isSelected = ['required', 'included', 'selected'].includes(option.state)
  tState = t
  return { isIncluded, isSelected, isDisabled }
}

export const isRegular = () =>
  appState.vehicle && !isExcludedState && !isDisabled
export const isDisabledForSelection = () =>
  appState.vehicle && !isExcludedState && isDisabled
export const isExcluded = () => appState.vehicle && isExcludedState
export const getDisabledAsIncluded = () =>
  tState(DICTIONARY.DISABLED_FOR_SELECTION_AS_INCLUDED_TOOLTIP)
export const getDisabledAsRequired = () =>
  tState(DICTIONARY.DISABLED_FOR_DESELECTION_AS_REQUIRED_TOOLTIP)
export const getDisabledForSelection = () =>
  tState(DICTIONARY.DISABLED_FOR_SELECTION_AS_EXCLUDED_TOOLTIP)
