import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { Quote } from '../../../model/Quote'
import { getDriverContributionLabelTranslation } from '../../../utils/driver'
import { checkValue, n } from './priceUtil'

export const totalLease = (
  quote: Quote,
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): Entry[] => {
  if (!quote?.priceDetail) {
    return []
  }
  const priceDetail = quote.priceDetail
  const result: Entry[] = []

  checkValue(
    DICTIONARY.LEASE_COST,
    n(priceDetail.monthly?.fix),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.ROAD_TAX,
    n(priceDetail.monthly?.roadTax),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MAINTENANCE,
    priceDetail.monthly?.maintenance === 0
      ? translations(DICTIONARY.NOT_INCLUDED)
      : n(priceDetail.monthly?.maintenance),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MAINTENANCE,
    priceDetail.monthly?.maintenanceInclVat === 0
      ? translations(DICTIONARY.NOT_INCLUDED)
      : n(priceDetail.monthly?.maintenanceInclVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_TYRES,
    n(priceDetail.monthly?.tyres),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_INSURANCE,
    n(priceDetail.monthly?.insurance),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_PRICE_EXCL_VAT_LABEL,
    n(priceDetail.monthly?.total),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.VAT_LABEL,
    n(priceDetail.monthly?.vat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_ENVIRONMENTAL_TAX_LABEL,
    n(priceDetail.monthly?.environmentalTax),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_TOTAL_TAX_LABEL,
    n(priceDetail.monthly?.totalTax),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_PRICE_INCL_VAT_LABEL,
    n(priceDetail.monthly?.totalInclVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_PRICE_EXCL_RECOVERABLE_VAT_LABEL,
    n(priceDetail.monthly?.totalInclIrrecoverableVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_PRICE_INCL_AVERAGE_VAT_LABEL,
    n(priceDetail.monthly?.totalInclAverageVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_TCO_INCL_VAT_LABEL,
    n(priceDetail.totalCostOwnership?.total),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.MONTHLY_TCO_EXCL_VAT_LABEL,
    n(priceDetail.totalCostOwnership?.totalNoVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.QUARTERLY_TCO_INCL_VAT_LABEL,
    n(priceDetail.quarterly?.tcoTotal),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.QUARTERLY_TCO_EXCL_VAT_LABEL,
    n(priceDetail.quarterly?.tcoTotalNoVat),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.YEARLY_TCO_INCL_VAT_LABEL,
    n(priceDetail.yearly?.tcoTotal),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.YEARLY_TCO_EXCL_VAT_LABEL,
    n(priceDetail.yearly?.tcoTotalNoVat),
    result,
    currency,
  )
  checkValue(
    getDriverContributionLabelTranslation(
      priceDetail.contribution,
      quote.duration,
    ),
    n(priceDetail.contribution?.driver),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.BENEFIT_IN_KIND,
    n(priceDetail.yearly?.bik),
    result,
    currency,
  )

  return result
}
