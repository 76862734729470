import { Box, Table, TableBody, TableCell } from '@material-ui/core'
import { DeleteIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { useCurrency } from '../../../hooks/useCurrency'
import { getStyles, isRebranding } from '../../../utils/styles'
import EnhancedTableHead from '../components/EnhancedTableHead'
import {
  selectedCheckbox,
  StyledCheckbox,
} from '../components/OptionCheckbox.styled'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import {
  DeleteButton,
  TableContainerStyled,
  TableRowStyled,
} from '../Table.styled'
import { Order, stableSort } from '../util/tableHeadSort'
import { getComparator, onAccessoryClick } from './accessories'

const s = () => getStyles().colors.configurator.accessories

const AccessoriesTable: FC = () => {
  const { locale } = useParams<{ locale: string }>()
  const profile = useProfileContext()
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const currency = useCurrency(locale, profile?.user.currencyCode, 2)
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainerStyled>
      <Table aria-labelledby="tableTitle" aria-label="enhanced table">
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={[
            {
              id: 'name',
              numeric: false,
              disablePadding: true,
              label: t(DICTIONARY.DESCRIPTION),
            },
            {
              id: 'price',
              numeric: true,
              disablePadding: true,
              label: t(DICTIONARY.PRICE),
            },
          ]}
        />
        <TableBody>
          {stableSort(
            state.mandatoryAccessories || [],
            getComparator(order, orderBy),
          ).map((row, index) => {
            const hasPrice = currency && row.price?.amount != null
            return (
              <TableRowStyled
                role="checkbox"
                aria-checked={true}
                tabIndex={-1}
                key={index}
                selected={true}
              >
                <TableCell padding="checkbox">
                  <Box p="28px 9px 9px">
                    <StyledCheckbox $itemNotAllowed={true} checked={true} />
                  </Box>
                </TableCell>
                <TableCell colSpan={hasPrice ? 1 : 2}>
                  <p style={selectedCheckbox}>{row.name}</p>
                </TableCell>
                {hasPrice && (
                  <TableCell align="right">
                    <p>{currency.format(row.price.amount)}</p>
                  </TableCell>
                )}
              </TableRowStyled>
            )
          })}
          {stableSort(
            state.accessories || [],
            getComparator(order, orderBy),
          ).map((row, index) => {
            const isItemSelected = state.selectedAccessories.includes(row.id)
            const labelId = `enhanced-table-checkbox-${index}`
            const hasPrice = currency && row.price?.amount != null
            return (
              <TableRowStyled
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Box p="28px 9px 9px">
                    <StyledCheckbox
                      $itemNotAllowed={false}
                      onClick={() => onAccessoryClick(row, state, dispatch)}
                      checked={isItemSelected}
                      aria-labelledby={labelId}
                    />
                  </Box>
                </TableCell>
                <TableCell colSpan={hasPrice ? 1 : 2}>
                  <p style={isItemSelected ? selectedCheckbox : null}>
                    {row.name}
                  </p>
                </TableCell>
                {hasPrice && (
                  <TableCell align="right">
                    <p>{currency.format(row.price.amount)}</p>
                  </TableCell>
                )}
              </TableRowStyled>
            )
          })}

          {stableSort(
            state.customAccessories || [],
            getComparator(order, orderBy),
          ).map((row, index) => {
            const isItemSelected = state.selectedAccessories.includes(row.id)

            return (
              <TableRowStyled
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  {isRebranding() ? (
                    <DeleteButton>
                      <DeleteIcon
                        size="xs"
                        color={s().deleteIcon}
                        onClick={() => {
                          const newAccessories = state.customAccessories.filter(
                            (a) => {
                              return a.id !== row.id
                            },
                          )
                          dispatch({
                            type: 'updateCustomAccessory',
                            customAccessories: newAccessories,
                            recalculatePrice: true,
                          })
                        }}
                      />
                    </DeleteButton>
                  ) : (
                    <Box p="28px 9px 9px">
                      <DeleteIcon
                        size="xs"
                        color={s().deleteIcon}
                        onClick={() => {
                          const newAccessories = state.customAccessories.filter(
                            (a) => {
                              return a.id !== row.id
                            },
                          )
                          dispatch({
                            type: 'updateCustomAccessory',
                            customAccessories: newAccessories,
                            recalculatePrice: true,
                          })
                        }}
                      />
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  <p>{row.name}</p>
                </TableCell>
                <TableCell align="right">
                  <p>{currency.format(row.price.amount)}</p>
                </TableCell>
              </TableRowStyled>
            )
          })}
        </TableBody>
      </Table>
    </TableContainerStyled>
  )
}

export default AccessoriesTable
