import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import InputField from './InputField'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}

const CompanyNameInput: FC<Props> = ({ state, dispatch }) => {
  const { t } = useTranslation()
  const profile = useProfileContext()
  const companyNameValidator = (
    value: string,
    setter: Dispatch<SetStateAction<string>>,
  ) => {
    let error = false

    if (!value) {
      setter(t(DICTIONARY.EMPTY_COMPANY_FIELD))
      error = true
    } else {
      setter(null)
    }
    if (error) {
      dispatch({ type: 'updateCompanyNameError', companyNameError: true })
    } else {
      dispatch({ type: 'updateCompanyNameError', companyNameError: false })
    }
  }
  if (!profile?.product?.displayPublicOrderCompanyField) {
    return null
  }

  return (
    <InputField
      label={t(DICTIONARY.PUBLIC_ORDER_COMPANY_LABEL)}
      customHandler={companyNameValidator}
      name="companyName"
      field={state.companyName}
      setter={(v) => dispatch({ type: 'updateCompanyName', companyName: v })}
    />
  )
}

export default CompanyNameInput
