import styled from 'styled-components'

import { getStyles } from '../utils/styles'

export const MessageList = styled.ul`
  margin: 0;
  padding: 0;
`

export const MessageItem = styled.li`
  padding-bottom: 2px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: ${getStyles().colors.usePolicyValidationMessages.font};
  }
`

export const MessageTitle = styled.span`
  font-weight: 800;
`

export const MessageContent = styled.span``
