import { datadogLogs } from '@datadog/browser-logs'
import { WindowSize } from '@velocity/ui'
import { Dispatch, SetStateAction } from 'react'

import { SCREEN_SIZE } from '../../../constants/display'
import { Option, Quote } from '../../../model/Quote'
import apiClient from '../../../utils/api'
import { PrintableItem } from '../../vehicle-configurator/price/PriceSelectedOptions'

export const initState = (
  quote: Quote,
  locale: string,
  size: WindowSize,
  onDelete: () => void,
  [isMobileMode, setIsMobileMode]: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const formatOptions = (options: Option[]): PrintableItem[] =>
    options.map((o: Option) => ({
      optionId: o.identifier || o.optionId.toString(),
      manufacturerName: o.manufacturerName,
      price: o.price,
    }))
  return {
    handleDeleteQuote: async () => {
      try {
        await apiClient.deleteSavedConfiguration(quote.identifier, locale)
        onDelete()
      } catch (error) {
        datadogLogs.logger.error(
          `Failed to delete saved configuration(${quote.identifier}), error: ${error}`,
        )
      }
    },
    mobileModeEffect: () => {
      const mobileScreen = size.innerWidth < SCREEN_SIZE.MOBILE
      setIsMobileMode(mobileScreen)
    },
    showMobileView: () => isMobileMode,
    getLocalVersion: () => quote.vehicle.localVersion,
    getBodyType: () => quote.vehicle.bodyType.text,
    getFuelType: () => quote.vehicle.engineFuelType.text,
    getSelectedOptions: () => formatOptions(quote.vehicle.selectedOptions),
    getAccessories: () => quote.accessories,
    getServices: () => quote.services,
    getVehicleName: () =>
      `${quote.vehicle.make.text} ${quote.vehicle.model.text}`,
  }
}
