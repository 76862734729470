import { Option } from '../../../model/Quote'
import {
  ConfigurationOptions,
  UserChoice,
  VehicleConfiguration,
} from '../../../model/VehicleConfiguration'
import { AppAction, AppState } from '../reducer/VehicleConfigReducer'
import { isPremiumTrim } from '../util/options'
import { filterPendingChoices, filterRequires } from './requires'
import { OptionRulesModel } from './RulesPopup'

const shouldSkipConfigurationCheck = (
  blsConfig: boolean,
  vehicleConfiguration: VehicleConfiguration,
): boolean => {
  return blsConfig && vehicleConfiguration.configuration === ''
}

const hasPendingChoices = (
  vehicleConfiguration: VehicleConfiguration,
): boolean => {
  return (
    vehicleConfiguration.dependencies.requires?.length > 0 ||
    vehicleConfiguration.dependencies.pendingChoices?.length > 0
  )
}

const processVehicleConfiguration = (
  state: AppState,
  action: AppAction,
  rulesItem: OptionRulesModel[],
): AppState => {
  const newState = { ...state }
  newState.vehicleConfiguration = action.vehicleConfiguration

  const blsConfig = state.profile?.user.blsConfig

  if (!hasPendingChoices(newState.vehicleConfiguration)) {
    return newState
  }

  if (shouldSkipConfigurationCheck(blsConfig, newState.vehicleConfiguration)) {
    return newState
  }

  if (newState.rules.length === 0) {
    newState.showOptionRules = true
    newState.lastVehicleConfiguration = state.vehicleConfiguration
    newState.rulesParentId = state?.selectedOption?.identifier || null
    newState.rules = rulesItem
    newState.requires = action.vehicleConfiguration.dependencies.requires
    newState.pendingChoices =
      action.vehicleConfiguration.dependencies.pendingChoices
    return newState
  }

  if (newState.rules.length > 0) {
    newState.requires = filterRequires(
      action.vehicleConfiguration.dependencies.requires,
      newState.requires,
      newState.vehicleConfiguration.selectedOptions,
    )
    newState.pendingChoices = filterPendingChoices(
      action.vehicleConfiguration.dependencies.pendingChoices || [],
      newState.pendingChoices || [],
      newState.vehicleConfiguration.selectedOptions,
    )
    newState.rules = [...newState.rules]

    return newState
  }

  return newState
}

const mapRequirementsToOptions = (
  vehicleOptions: Option[],
  options: Option[],
  requires: number[][],
  pendingChoices: UserChoice[][],
  excludePremiumTrims = false,
) => {
  if (pendingChoices?.length > 0) {
    return pendingChoicesToOptions(
      vehicleOptions,
      options,
      pendingChoices,
      excludePremiumTrims,
    )
  } else {
    return requiresToOptions(
      vehicleOptions,
      options,
      requires,
      excludePremiumTrims,
    )
  }
}

const requiresToOptions = (
  vehicleOptions: Option[],
  options: Option[],
  requires: number[][],
  excludePremiumTrims = false,
) => {
  return requires
    .map((ids: number[]) => {
      return ids
        .map((id: number) => {
          const found = options.find((o: Option) => o.optionId === id)
          return (
            found ||
            vehicleOptions.find((o: Option) => o.optionId === id) ||
            null
          )
        })
        .filter((requiredOption: Option) => requiredOption !== null)
        .filter((o: Option) => !excludePremiumTrims || !isPremiumTrim(o))
    })
    .filter((requiredOptions) => requiredOptions.length > 0)
}

const pendingChoicesToOptions = (
  vehicleOptions: Option[],
  options: Option[],
  pendingChoices: UserChoice[][],
  excludePremiumTrims = false,
) => {
  return pendingChoices
    .map((choice: UserChoice[]) => {
      return choice
        .map((item: UserChoice) => {
          const found = options.find(
            (o: Option) => o.identifier === item.identifier,
          )
          return (
            found ||
            vehicleOptions.find(
              (o: Option) => o.identifier === item.identifier,
            ) ||
            null
          )
        })
        .filter((requiredOption: Option) => requiredOption !== null)
        .filter((o: Option) => !excludePremiumTrims || !isPremiumTrim(o))
    })
    .filter((requiredOptions) => requiredOptions.length > 0)
}

const pendingChoicesToOptionsToExclude = (
  vehicleOptions: Option[],
  options: Option[],
  pendingChoices: UserChoice[][],
) => {
  return pendingChoices
    .map((choice: UserChoice[]) => {
      return choice
        .filter((item: UserChoice) => item.exclude)
        .map((item: UserChoice) => {
          const found = options.find(
            (o: Option) => o.identifier === item.identifier,
          )
          return (
            found ||
            vehicleOptions.find(
              (o: Option) => o.identifier === item.identifier,
            ) ||
            null
          )
        })
        .filter((requiredOption: Option) => requiredOption !== null)
    })
    .filter((requiredOptions) => requiredOptions.length > 0)
}

function toFlatOptionList(options: ConfigurationOptions): Option[] {
  return [
    ...(options?.colours?.exteriorColours || []),
    ...(options?.colours?.interiorColours || []),
    ...(options?.colours?.trims || []),
    ...(options?.options || []),
    ...(options?.packs || []),
  ]
}

export {
  processVehicleConfiguration,
  mapRequirementsToOptions,
  toFlatOptionList,
  pendingChoicesToOptionsToExclude,
}
