import { Helmet } from 'react-helmet'
import { FC } from 'react'

import useLocale from '../../hooks/useLocale'
import { getCountryConfig } from '../../utils/config'

const LiveChatScript: FC = () => {
  const locale = useLocale()
  const config = getCountryConfig(locale)
  return config.showLiveChat ? (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML:
            "var _sid = '2630'; (function() { var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true; se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s); })(); ",
        },
      ]}
    ></Helmet>
  ) : null
}

export default LiveChatScript
