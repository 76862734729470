import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC } from 'react'

import CO2Label from '../../../components/co2Label/CO2Label'
import SingleCO2Label from '../../../components/co2Label/SingleCO2Label'
import { DICTIONARY } from '../../../constants/dictionary'
import { Profile } from '../../../model/Profile'
import { ShowroomQuoteApi } from '../../../model/ShowroomQuote'
import { getCountryConfig } from '../../../utils/config'
import { capitalize } from '../../../utils/string'
import { ViewMode } from './VehicleList'
import {
  CO2Container,
  SingleCO2LabelSpacer,
  VehicleEdition,
  Root,
  VehiclePrice,
  VehicleTitle,
} from './VehicleList.styled'

interface Props {
  quote: ShowroomQuoteApi
  viewMode: ViewMode
  currencyWithoutDecimals: Intl.NumberFormat
  profile: Profile
}

const VehicleInformation: FC<Props> = ({
  quote,
  viewMode,
  currencyWithoutDecimals,
  profile,
}) => {
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const config = getCountryConfig(locale)
  return (
    <Root viewMode={viewMode}>
      {config.showSingleCO2Label && quote.vehicle.co2 > 0 && (
        <SingleCO2Label selectedValue={quote.vehicle.co2} />
      )}
      {config.showSingleCO2Label &&
        (!quote.vehicle.co2 || quote.vehicle.co2 === 0) && (
          <SingleCO2LabelSpacer />
        )}
      <VehicleTitle>
        {`${quote.vehicle.make.text} ${quote.vehicle.model.text}`}
      </VehicleTitle>
      <VehicleEdition>{quote.vehicle.version.text}</VehicleEdition>
      {!config?.hideShowroomVehiclePrice && (
        <VehiclePrice>
          {capitalize(t(DICTIONARY.LIST_PRICE))}&nbsp;
          {currencyWithoutDecimals?.format(
            Math.round(quote.vehicle.retailPrice),
          )}
        </VehiclePrice>
      )}
      {config?.showTaxBasePrice && (
        <VehiclePrice>
          {`${t(DICTIONARY.TAX_BASE)} ${currencyWithoutDecimals?.format(
            Math.round(quote.vehicle.taxBasePrice),
          )}`}
        </VehiclePrice>
      )}
      {quote.vehicle.efficiencyLabel && viewMode === 'list' && (
        <CO2Container viewMode={viewMode}>
          <CO2Label
            co2Band={quote.vehicle.efficiencyLabel.code}
            co2Value={quote.vehicle.co2}
            showSelected={true}
          />
        </CO2Container>
      )}
    </Root>
  )
}

export default VehicleInformation
