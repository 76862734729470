import { FC } from 'react'
import styled from 'styled-components'

import GlqsAccordion from '../../../components/glqsAccordion/GlqsAccordion'
import { getStyles } from '../../../utils/styles'
import { getComparator, stableSort } from '../util/tableHeadSort'
import ColorItem from './ColorItem'
import ColorItemWithOptions from './ColorItemWithOptions'
import { GroupedOptionsType } from './colors'

interface Props {
  label: string
  filteredOptions: GroupedOptionsType
  colors: Colors
  expand?: boolean
  swatches?: Record<string, string>
  currency: Intl.NumberFormat
  grouped: boolean
}

interface Colors {
  backgroundColor: string
  color: string
}

const ColorAccordion: FC<Props> = ({
  label,
  filteredOptions,
  colors,
  expand = false,
  swatches,
  currency,
  grouped,
}) => {
  return (
    <>
      <GlqsAccordion caption={label} colors={colors} expand={expand}>
        <div style={{ padding: '0 1em' }}>
          {stableSort(
            filteredOptions,
            getComparator('asc', 'manufacturerName'),
          ).map((option, index) => {
            return (
              <Options key={index}>
                {swatches && (
                  <hr
                    style={{
                      color: getStyles().colors.configurator.accordion,
                      opacity: '0.5',
                    }}
                  />
                )}
                {!grouped && (
                  <ColorItem
                    option={Array.isArray(option) ? option[0] : option}
                    swatches={swatches}
                    currency={currency}
                  />
                )}
                {grouped && (
                  <ColorItemWithOptions option={option} currency={currency} />
                )}
              </Options>
            )
          })}
        </div>
      </GlqsAccordion>
    </>
  )
}

const Options = styled.div`
  margin-bottom: 3px;
`

export default ColorAccordion
