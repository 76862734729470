import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { Product } from '../../model/Profile'
import { getStyles, isRebranding } from '../../utils/styles'
import LogoNew from './images/lp-logo-new.png'
import Logo from './images/lp-logo.png'
import { pdfDocStyles } from './PDFDocument'

interface Props {
  parentStyles: typeof pdfDocStyles
  product: Product
}

const pdfStyles = StyleSheet.create({
  title: {
    color: getStyles().colors.pdf.font,
    width: '100%',
    fontSize: getStyles().fonts.pdf.pageTitle.size,
    fontWeight: getStyles().fonts.pdf.title.weight,
    textAlign: 'center',
    fontFamily: getStyles().fonts.pdf.title.font,
  },
  image: {
    width: '18%',
    marginBottom: 30,
  },
  header: {
    flexBasis: '10%',
  },
})

const getTitleTerm = (product: string) => {
  return `PDF.${product || 'corporate'}.title`
}

const PDFHeader: React.FC<Props> = ({ parentStyles, product }) => {
  const { t } = useTranslation()
  return (
    <View wrap={false} fixed={true} style={pdfStyles.header}>
      <View style={parentStyles.row}>
        {isRebranding() ? (
          <Image style={pdfStyles.image} src={LogoNew} />
        ) : (
          <Image style={pdfStyles.image} src={Logo} />
        )}
        <Text style={pdfStyles.title}>
          {' '}
          {t(getTitleTerm(product.productName))}{' '}
        </Text>
      </View>
    </View>
  )
}

export default PDFHeader
