import { useWindowSize } from '@velocity/ui'
import { DeleteIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { Quote } from '../../model/Quote'
import { getImaginImage } from '../../utils/imagin'
import { capitalize } from '../../utils/string'
import { getStyles } from '../../utils/styles'
import {
  Label,
  LocalVersion,
  MiddleSection,
  StyledCarInfo,
  StyledCarInfoWrapper,
  Value,
} from '../favourites/CarInfo.styled'
import { StyledDeleteActionButton } from '../favourites/PriceInfo.styled'
import {
  ImageSection,
  PolicyLabel,
  StyledHeadline,
} from '../favourites/SavedConfigurations.styled'
import { flattenAccessoriesToOptions } from '../quoteSummary/util/accessory'
import { LabelText } from '../showroom/vehicle-list/VehicleList.styled'
import { flattenServicesToOptions } from '../vehicle-configurator/services/service'
import SavedQuotesMoreInfo from './SavedQuotesMoreInfo'
import { initState } from './util/carInfo'

const s = () => getStyles().colors.savedQuote.carInfo

interface Props {
  quote: Quote
  onDelete: () => void
  isPolicyBreached: boolean
  isConfigurationInvalid: boolean
  isVehicleUnavailable: boolean
}

const getLabel = (
  isPolicyBreached: boolean,
  isConfigurationInvalid: boolean,
  isVehicleUnavailable: boolean,
) => {
  if (isPolicyBreached) {
    return DICTIONARY.NO_LONGER_IN_POLICY
  }
  if (isVehicleUnavailable) {
    return DICTIONARY.VEHICLE_UNAVAILABLE
  }
  if (isConfigurationInvalid) {
    return DICTIONARY.INVALID_VEHICLE_CONFIGURATION
  }
  return null
}

const CarInfo: FC<Props> = ({
  quote,
  onDelete,
  isPolicyBreached,
  isConfigurationInvalid,
  isVehicleUnavailable,
}) => {
  const { t } = useTranslation()
  const size = useWindowSize()
  const { locale } = useParams<{ locale: string }>()
  const st = initState(quote, locale, size, onDelete, useState<boolean>(false))
  useEffect(() => st.mobileModeEffect(), [st])
  const label = getLabel(
    isPolicyBreached,
    isConfigurationInvalid,
    isVehicleUnavailable,
  )
  return (
    <StyledCarInfoWrapper>
      <ImageSection>
        <img src={getImaginImage(quote.vehicle, locale)} alt="" />
        {label && (
          <PolicyLabel>
            <LabelText>{t(label)}</LabelText>
          </PolicyLabel>
        )}
      </ImageSection>
      <StyledCarInfo>
        <div>
          <StyledHeadline variant="200" data-e2e-id="carName">
            {st.getVehicleName()}
          </StyledHeadline>
          {st.showMobileView() && (
            <StyledDeleteActionButton
              onClick={st.handleDeleteQuote}
              data-testid="deleteQuoteButton"
            >
              <DeleteIcon size="xs" color={s().deleteIcon} />
            </StyledDeleteActionButton>
          )}
        </div>
        <LocalVersion>{st.getLocalVersion()}</LocalVersion>
        <MiddleSection>
          <div>
            <Label>{capitalize(t(DICTIONARY.VEHICLE_BODY_TYPE))}</Label>
            <Value>{st.getBodyType()}</Value>
          </div>
          <div>
            <Label>{capitalize(t(DICTIONARY.FUEL))}</Label>
            <Value>{st.getFuelType()}</Value>
          </div>
        </MiddleSection>
        <SavedQuotesMoreInfo
          selectedItems={[
            ...st.getSelectedOptions(),
            ...flattenAccessoriesToOptions(st.getAccessories()),
            ...flattenServicesToOptions(st.getServices()),
          ]}
        />
      </StyledCarInfo>
    </StyledCarInfoWrapper>
  )
}

export default CarInfo
