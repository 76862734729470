import {
  CONTACT_DETAILS,
  CountryDefaultLocale,
  DEFAULT_LOCALE,
  LOCALES,
} from '../constants/locale'

export interface Locale {
  languageCode: string
  isoCountryCode: string
  name: string
  locale: string
  region: string
}

export const localeByName = (locale: string): Locale => {
  const localeObj: Locale[] = LOCALES.filter(
    (i) => i.locale === normalizeLocale(locale),
  )
  return localeObj.length > 0 ? localeObj[0] : DEFAULT_LOCALE
}

export const isValidLocale = (locale: string): boolean => {
  const locales = LOCALES.map((localeEntity) => localeEntity.locale)
  return locales.includes(normalizeLocale(locale))
}

export const getCountryDefaultLocale = (countryCode: string): string => {
  return countryCode && CountryDefaultLocale[countryCode.toLocaleUpperCase()]
}

export const getContactDetails = (countryCode: string, product = 'default') => {
  const countryContactDetail =
    countryCode && CONTACT_DETAILS[countryCode.toLowerCase()]
  if (countryContactDetail) {
    return countryContactDetail[product] || countryContactDetail['default']
  }
  return ''
}

export const getLangCountryFromLocale = (
  locale: string,
): { language: string; country: string } => {
  const normalizedLocale = normalizeLocale(locale)
  const localeParts = 2

  const result =
    (normalizedLocale &&
      normalizedLocale.split('_').length === localeParts &&
      normalizedLocale.split('_')) ||
    null
  return {
    language: (result && result[0].toUpperCase()) || '',
    country: (result && result[1].toUpperCase()) || '',
  }
}

export const updateLanguageInPath = (language: string): string => {
  const url = new URL(window.location.href).pathname
  const oldLocale = url.split('/')[1]
  const newLocale = `${language.toLowerCase()}_${oldLocale.split('_')[1]}`
  return url.replace(oldLocale, newLocale)
}

export const normalizeLocale = (locale: string): string => {
  if (locale && locale.indexOf('_') !== -1) {
    const split = locale.split('_')
    return `${split[0].toLowerCase()}_${split[1].toUpperCase()}`
  }
  return locale
}
