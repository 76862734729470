import { CssBaseline } from '@material-ui/core'
import { VelocityProvider } from '@velocity/ui'
import { SnackbarProvider } from 'notistack'
import React from 'react'

const MasterTemplate: React.FC = ({ children }) => {
  return (
    <VelocityProvider>
      <SnackbarProvider>
        <CssBaseline />
        {children}
      </SnackbarProvider>
    </VelocityProvider>
  )
}

export default MasterTemplate
