import Pagination from '@material-ui/lab/Pagination'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { capitalize } from '../../utils/string'
import { getStyles, isRebranding } from '../../utils/styles'
import { SavedQuotesState } from './SavedQuotes'

const s = () => getStyles().colors.savedQuote.pagination
export const ITEMS_PER_PAGE_MIN = 10

interface Props {
  state: SavedQuotesState
  onPageSelect: (a: unknown, b: number) => void
}

const SavedQuotesPagination: FC<Props> = ({ state, onPageSelect }) => {
  const { t } = useTranslation()

  return (
    <VehicleCatalogPaginationPane>
      <PaginationDetails>
        <span>{capitalize(t(DICTIONARY.SHOWING_LABEL))}</span>
        &nbsp;
        <span>
          <strong>{`${state.fromQuote}-${state.toQuote}`}</strong>
        </span>
        &nbsp;
        <span>{t(DICTIONARY.OF_LABEL)}</span>
        &nbsp;
        <span>
          <strong>{state.quotes.length}</strong>
        </span>
      </PaginationDetails>
      <Pagination
        count={Math.ceil(state.quotes.length / state.itemsPerPage)}
        showFirstButton={true}
        showLastButton={true}
        onChange={onPageSelect}
        page={state.currentPage}
      />
    </VehicleCatalogPaginationPane>
  )
}

export const PaginationDetails = styled.div`
  color: ${s().details.font};
  text-transform: ${isRebranding() ? 'none' : 'uppercase'};
  padding-right: 16px;
`
export const VehicleCatalogPaginationPane = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;

  & .MuiPaginationItem-page {
    font-weight: 800;
  }

  & .MuiPaginationItem-page.Mui-selected {
    background: ${s().selected.background};
    color: ${s().selected.font};
  }

  & .MuiPaginationItem-page.Mui-selected:hover {
    color: ${s().selected.hover.font};
  }

  & .MuiPaginationItem-page:hover {
    color: ${s().itemPage.font};
  }
`

export default SavedQuotesPagination
