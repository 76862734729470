import { LimitedVehicleConfiguration } from '../../../model/ContractOptionsType'
import { Option, Service } from '../../../model/Quote'

export const getLimitedOptionsConfiguration = (
  packsAndOptions: Option[],
  limitedVehicleConfiguration: LimitedVehicleConfiguration,
  optionsTabServices: Service[],
): {
  groupedOptions: Map<string, Option[]>
  optionGroupNames: Map<string, string>
  show: boolean
} => {
  const groupedOptions = new Map<string, Option[]>()
  const optionGroupNames = new Map<string, string>()

  packsAndOptions.forEach((option) => {
    const distinctOptionGroups = new Set(option.providedOptionsGroups || [])
    distinctOptionGroups.forEach((providedOptionGroup) => {
      if (
        limitedVehicleConfiguration.requiredOptionGroupIds.includes(
          providedOptionGroup.optionGroupId,
        )
      ) {
        groupedOptions.set(providedOptionGroup.optionGroupId, [
          ...new Set([
            ...(groupedOptions.get(providedOptionGroup.optionGroupId) || []),
            ...[option],
          ]),
        ])
        optionGroupNames.set(
          providedOptionGroup.optionGroupId,
          providedOptionGroup.optionGroupName,
        )
      }
    })
  })

  const show =
    optionGroupNames.size > 0 ||
    (optionsTabServices && optionsTabServices.length > 0)

  return {
    groupedOptions,
    optionGroupNames,
    show,
  }
}
