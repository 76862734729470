import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../../utils/styles'

const s = () => getStyles().colors.showroom.modelLine

interface AccordionProps {
  isExpanded: boolean
}

interface AccordionTransparentProps {
  $isExpanded: boolean
}

export const ChevronDownIconStyled = styled(ChevronDownIcon)`
  display: none;
  color: ${s().downIcon.font};
`
export const ChevronUpIconStyled = styled(
  ChevronUpIcon,
)<AccordionTransparentProps>`
  display: ${(props) => (props.$isExpanded ? 'block' : 'none')};
  color: ${(props) =>
    props.$isExpanded ? s().upIcon.font1 : s().upIcon.font2};
`

export const RootContainer = styled.li<AccordionProps>`
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => (props.isExpanded ? s().font1 : s().font2)};
  border-bottom: 1px solid ${s().border};

  :hover ${ChevronDownIconStyled} {
    display: ${(props) => (props.isExpanded ? 'none' : 'block')};
  }
`
export const ModelNameRow = styled.div`
  svg {
    padding-top: 5px;
  }
  gap: 5px;
  display: flex;
`

export const ModelName = styled.p`
  margin: 0;
`

export const ModelLineStyled = styled.div<AccordionProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  font-weight: ${(props) => (props.isExpanded ? 'bold' : 'normal')};

  :hover {
    background: ${s().hover.background};
    cursor: pointer;
  }
`
export const Versions = styled.ul`
  list-style: none;
  padding: 8px;
`

export const VersionRow = styled.li`
  display: flex;
  flex-direction: column;
`
