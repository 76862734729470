import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

import { Quote } from '../../../model/Quote'
import { getVehicleMakeAndModel } from '../../../pages/quoteSummary/util/vehicle'
import { getStyles } from '../../../utils/styles'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  quote: Quote
  parentStyles: typeof pdfDocStyles
}

const styles = StyleSheet.create({
  makeModel: {
    fontFamily: getStyles().fonts.pdf.title.font,
    width: '100%',
    fontSize: 9,
    fontWeight: getStyles().fonts.pdf.title.weight,
    paddingTop: 0,
    paddingBottom: 0,
  },
})

const MakeAndModelSection: React.FC<Props> = ({ quote, parentStyles }) => {
  return (
    <View wrap={false}>
      <Text style={styles.makeModel}>{getVehicleMakeAndModel(quote)}</Text>
      <Text style={[styles.makeModel, parentStyles.wideDescription]}>
        {quote.vehicle.localVersion}
      </Text>
    </View>
  )
}

export default MakeAndModelSection
