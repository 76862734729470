import styled from 'styled-components'

import { getStyles } from '../../utils/styles'
import { BaseRegularText } from './SavedConfigurations.styled'

const RegularText = styled(BaseRegularText)`
  font-weight: normal;
`
const RegularBoldText = styled(BaseRegularText)`
  font-weight: bold;
`
export const StyledCarInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 40px 26px 0;
  color: ${getStyles().colors.carInfo.font};
  font-size: 1.3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
  }
`

export const StyledCarInfo = styled.div`
  width: 100%;
  div:first-child {
    display: flex;
    justify-content: space-between;
  }
`
export const Label = styled(RegularText)`
  margin-right: 0.4rem;
  display: inline-box;
`
export const Value = styled(RegularBoldText)`
  margin-right: 0.4rem;
  display: inline-box;
  text-transform: capitalize;
`
export const LocalVersion = styled(RegularText)`
  line-height: 1.79;
  letter-spacing: 0.3px;
  margin-bottom: 1.8rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`
export const MiddleSection = styled.div`
  line-height: 0.5rem;
  margin-bottom: 1.2rem;
  div {
    display: flex;
    justify-content: space-between;
  }
`
