import { TFunction } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import { Dispatch, SetStateAction } from 'react'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Calculation } from '../../../../model/Calculation'
import { ShowroomConfig } from '../../context/ShowroomContext'
import { saveShowroomState } from '../../showroomUtil'
import { VehicleTileProps } from '../VehicleTile'

const recalculateQuotePrice: boolean =
  process.env.REACT_APP_SHOWROOM_UPDATE_PRICES === 'true'

interface Props {
  showroomState: ShowroomConfig
  quoteSelected: [boolean, Dispatch<SetStateAction<boolean>>]
  showroomQuote: UseQueryResult<Calculation, unknown>
  props: VehicleTileProps
  isVisible: boolean
  vehicleTitleConfig: {
    currencyCode: string
    displayShowroomMonthlyPrices: boolean
    displayShowroomExclVatLabel: boolean
  }
  actualQuotePrice: [boolean, Dispatch<SetStateAction<boolean>>]
  quotePrice: [number, Dispatch<SetStateAction<number>>]
  viewDisabled: [boolean, Dispatch<SetStateAction<boolean>>]
  policyValidationMessages: [string[], Dispatch<SetStateAction<string[]>>]
  t: TFunction<'translation', undefined>
}

export const initState = (p: Props) => {
  const { state, dispatch } = p.showroomState
  const [isQuoteSelected, setIsQuoteSelected] = p.quoteSelected
  const {
    data: showroomQuote,
    isSuccess: isShowroomQuoteReady,
    isLoading: isShowroomQuoteLoading,
    refetch: fetchShowroomQuote,
    isError: isShowroomQuoteError,
    error: showroomQuoteError,
  } = p.showroomQuote
  const { quote } = p.props
  const {
    currencyCode,
    displayShowroomMonthlyPrices,
    displayShowroomExclVatLabel,
  } = p.vehicleTitleConfig
  const [actualQuotePriceRequested, setActualQuotePriceRequested] =
    p.actualQuotePrice
  const [quotePrice, setQuotePrice] = p.quotePrice
  const [viewDisabled, setViewDisabled] = p.viewDisabled
  const [policyValidationMessages, setPolicyValidationMessages] =
    p.policyValidationMessages

  const handleVehicleSelection = () => {
    dispatch({
      type: 'setQuoteLoading',
      quoteLoading: true,
      selectedQuoteIdentifier: quote.quotationIdentifier,
    })
    saveShowroomState(
      state.filters,
      state.activePageNumber,
      state.sortingValue,
      state.itemsPerPage,
    )
    setIsQuoteSelected(true)
    if (!isShowroomQuoteReady && !isShowroomQuoteLoading) {
      fetchShowroomQuote()
    }
  }
  const disableQuoteLoading = () => {
    isQuoteSelected &&
      dispatch({
        type: 'setQuoteLoading',
        quoteLoading: false,
        selectedQuoteIdentifier: null,
      })
  }
  const recalculateQuoteEffect = () => {
    if (
      p.isVisible &&
      displayShowroomMonthlyPrices &&
      !actualQuotePriceRequested
    ) {
      if (recalculateQuotePrice) {
        setActualQuotePriceRequested(true)
        fetchShowroomQuote()
      } else {
        setQuotePrice(quote.quotePrice)
      }
    }
  }
  const quoteEffect = () => {
    if (isShowroomQuoteReady && showroomQuote) {
      if (showroomQuote.priceDetail) {
        setQuotePrice(showroomQuote.priceDetail.policyBudgetPrice)
      }
      const policyBreached =
        showroomQuote.policyValidationMessages &&
        showroomQuote.policyValidationMessages.length > 0

      if (policyBreached) {
        setViewDisabled(true)
        setPolicyValidationMessages(
          showroomQuote.policyValidationMessages.map(
            (message) => message.description,
          ),
        )
      } else {
        if (isQuoteSelected) {
          dispatch({
            type: 'setSelectedQuote',
            selectedQuote: showroomQuote,
          })
        }
      }
      disableQuoteLoading()
    }
  }
  const errorEffect = () => {
    if (!viewDisabled && isShowroomQuoteError && showroomQuoteError) {
      const error = showroomQuoteError as {
        response: { data: { message: string } }
      }
      const errorMessage = error.response?.data?.message
      const message = errorMessage
        ? p.t(errorMessage)
        : 'Something went wrong, please retry later'
      setViewDisabled(true)
      setPolicyValidationMessages([message])
      disableQuoteLoading()
    }
  }
  const isViewDisabled = () => viewDisabled
  const showMessageList = () => policyValidationMessages.length > 0
  const showVehicleMonthlyPrices = () =>
    displayShowroomMonthlyPrices && policyValidationMessages.length === 0
  const showShowroomExclVat = () => displayShowroomExclVatLabel
  const getPolicyValidationMessages = () => policyValidationMessages
  const getStartingFromLabel = () => p.t(DICTIONARY.STARTING_FROM)
  const getQuotePrice = () => quotePrice

  return {
    handleVehicleSelection,
    disableQuoteLoading,
    recalculateQuoteEffect,
    quoteEffect,
    errorEffect,
    currencyCode,
    isViewDisabled,
    showMessageList,
    showVehicleMonthlyPrices,
    getPolicyValidationMessages,
    getStartingFromLabel,
    getQuotePrice,
    showShowroomExclVat,
  }
}
