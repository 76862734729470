import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.snackMessage

export const MessageBarContent = styled.div`
  display: flex;
  align-items: center;
  background: ${s().background};
  padding: 16px;
  border-radius: 8px;
  & > div {
    margin: 0 10px 0 6px;
    color: ${s().font};
    font-size: 14px;
    font-weight: bold;
  }
  & > svg {
    background: ${s().image.background};
    border-radius: 50%;
    color: ${s().image.font};
    width: 24px;
    height: 24px;
  }
`
