import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { TFunction } from 'i18next'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

import SnackMessage from '../components/notification/SnackMessage'
import { DICTIONARY } from '../constants/dictionary'
import { PolicyValidationMessage } from '../model/Quote'
import { iGen } from '../utils/indexGen'
import {
  MessageContent,
  MessageItem,
  MessageList,
} from './usePolicyValidationMessages.styled'

const getPolicyValidationMessages = (messages: PolicyValidationMessage[]) => (
  <MessageList>
    {messages?.map((message, index) => (
      <MessageItem key={iGen(index)}>
        <WarningRoundedIcon />
        <MessageContent>{message.description}</MessageContent>
      </MessageItem>
    ))}
  </MessageList>
)

const usePolicyValidationMessages = (
  policyValidationMessages: PolicyValidationMessage[],
  publicProduct: boolean,
  translations: TFunction,
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (!policyValidationMessages || policyValidationMessages?.length === 0) {
      closeSnackbar()
      return
    }

    const messagesNode = getPolicyValidationMessages(policyValidationMessages)
    closeSnackbar()
    enqueueSnackbar(messagesNode, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      content: (key, message) => (
        <SnackMessage
          id={key}
          message={message}
          title={translations(DICTIONARY.POLICY_VALIDATION_BAR_TITLE)}
          showTitle={!publicProduct}
        />
      ),
      variant: 'error',
      persist: true,
      preventDuplicate: true,
    })
  }, [
    policyValidationMessages,
    enqueueSnackbar,
    closeSnackbar,
    translations,
    publicProduct,
  ])
}

export default usePolicyValidationMessages
