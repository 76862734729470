import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { PriceDetail } from '../../../model/Quote'
import { getTranslation } from '../../../pages/quoteSummary/components/contentBody/PriceDetailsSection'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  vehicleCost: Entry[]
  totalLease: Entry[]
  parentStyles: typeof pdfDocStyles
  priceDetail: PriceDetail
}

const PriceDetailsSection: React.FC<Props> = ({
  vehicleCost,
  totalLease,
  parentStyles,
  priceDetail,
}) => {
  const { t } = useTranslation()
  const sectionBody = (item: Entry, index: number) => (
    <View style={parentStyles.row} key={index}>
      <Text style={parentStyles.halfDescription}>
        {getTranslation(item.description, t(item.description), priceDetail)}
      </Text>
      <Text style={parentStyles.quarter} />
      <Text style={parentStyles.value}>
        {item.value.toString().replace(/(\u{202F})/gu, '\u00A0')}
      </Text>
    </View>
  )
  return (
    (vehicleCost?.length > 0 || totalLease?.length > 0) && (
      <View wrap={false}>
        <Text style={parentStyles.subTitle}>
          {t(DICTIONARY.QUOTE_SUMMARY_PRICING_LABEL)}
        </Text>

        {(vehicleCost.length > 0 || totalLease.length > 0) && (
          <>{vehicleCost.concat(totalLease).map(sectionBody)}</>
        )}
      </View>
    )
  )
}

export default PriceDetailsSection
