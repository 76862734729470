import { FC } from 'react'
import styled from 'styled-components'

import VelocityInfoIcon from '../../assets/icons/VelocityInfoIcon'
import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.velocity.infoNotice

interface Props {
  text: string
}
const VelocityNotice: FC<Props> = ({ text }) => {
  return (
    <Root data-e2e-id="VelocityNotice">
      <Icon>
        <VelocityInfoIcon />
      </Icon>
      <div>{text}</div>
    </Root>
  )
}

const Root = styled.div`
  background-color: ${s().background};
  border-radius: 4px;
  border-inline-start: 8px solid ${s().background};
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  margin-right: 8px;
  color: ${s().color};
`
const Icon = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`

export default VelocityNotice
