import { CountryConfigType } from '../../model/CountryConfigType'

const config: CountryConfigType = {
  hideShowroomVehiclePrice: true,
  showSingleCO2Label: true,
  showQuoteSummaryAgreementNotice: true,
  showLanguageSelector: true,
  showEmptyWinterTyreSelect: true,
  showTaxBasePrice: true,
}

export default config
