import { Dispatch, RefObject, SetStateAction, useEffect } from 'react'

import { initState, useEffectHandler } from './useHandleDragImageUtil'
import { useIsDragging } from './useIsDragging'

export const useHandleDragImage = (
  wrapper: RefObject<HTMLDivElement>,
  currentIndexState: [number, Dispatch<SetStateAction<number>>],
  imageCount: number,
) => {
  const p = useIsDragging(wrapper)
  const { isDragging, setCurrentIndex, rotateDirection } = initState(
    currentIndexState,
    imageCount,
    p,
  )

  useEffect(useEffectHandler, [isDragging, imageCount, setCurrentIndex])

  return { isDragging, rotateDirection }
}
