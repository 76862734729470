import { FormField } from '@velocity/ui'
import { Select } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC, useEffect, useMemo } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Service } from '../../../model/Quote'
import { getCountryConfig } from '../../../utils/config'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import {
  isLogoDeCoeur,
  isNeededEmptyServiceLevel,
  onServiceClick,
} from './service'
import { StyledRoot } from './Services.styled'

interface Props {
  services: Service[]
}

const Services: FC<Props> = ({ services }) => {
  const hasLogoDeCoeur: boolean = useMemo(
    () => isLogoDeCoeur(services),
    [services],
  )
  const { t } = useTranslation()
  const { state, dispatch } = useVehicleConfig()
  const country = state.profile.user.country
  const { locale } = useParams<{ locale: string }>()
  const config = getCountryConfig(locale)

  useEffect(() => {
    const selectedServices = new Map<string, string>()
    services?.forEach((service) =>
      selectedServices.set(service.id, service.levels[0].id),
    )
    dispatch({
      type: 'updateServices',
      selectedService: selectedServices,
      defaultService: selectedServices,
    })
  }, [dispatch, services, country])

  useEffect(() => {
    if (hasLogoDeCoeur && hasLogoDeCoeur !== state.hasLogoDeCoeur) {
      dispatch({ type: 'initServices', hasLogoDeCoeur })
      dispatch({ type: 'expandServices' })
    }
  }, [hasLogoDeCoeur, state.hasLogoDeCoeur, dispatch])

  return (
    <StyledRoot>
      {services?.map((service) => {
        const selectedValue = () => {
          if (state.selectedServices.get(service.id) === '') {
            return ''
          }
          return `${service.id}.${state.selectedServices.get(service.id)}`
        }
        return (
          <FormField
            key={service.id}
            label={t(`Services.${service.id}.Label`)}
            required={true}
          >
            <Select
              fullWidth={true}
              onChange={(event) => onServiceClick(event, service, dispatch)}
              value={selectedValue()}
              data-e2e-id={service.id}
            >
              {isNeededEmptyServiceLevel(service.id, config) && (
                <option key="emptySelect" value="emptySelect">
                  {t(DICTIONARY.SELECT)}
                </option>
              )}
              {service.levels
                .filter((level) => level.id !== '')
                .map((level) => (
                  <option key={level.id} value={`${service.id}.${level.id}`}>
                    {level.description}
                  </option>
                ))}
            </Select>
          </FormField>
        )
      })}
    </StyledRoot>
  )
}

export default Services
