import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import {
  OptionDescriptionContainer,
  OptionDescriptionButton,
  OptionDescriptionItem,
  OptionDescriptionTitle,
  OptionDescriptionList,
} from '../Table.styled'

interface OptionDescriptionProps {
  description: string[]
  optionId: number
  expanded: number[]
  setRowExpanded: (expanded: number[]) => void
}

const OptionDescription = ({
  description,
  optionId,
  expanded,
  setRowExpanded,
}: OptionDescriptionProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const handleClick = () => {
    if (isOpen) {
      setRowExpanded([...expanded.filter((item: number) => item !== optionId)])
    } else {
      setRowExpanded([...expanded, optionId])
    }
    setIsOpen(!isOpen)
  }

  return (
    description?.length > 0 && (
      <>
        <OptionDescriptionButton
          type="button"
          onClick={handleClick}
          data-testid={`option-description-switch-${optionId}`}
        >
          {isOpen ? t(DICTIONARY.HIDE_LABEL) : t(DICTIONARY.SHOW_DETAILS_LABEL)}
        </OptionDescriptionButton>
        {isOpen && (
          <OptionDescriptionContainer>
            <OptionDescriptionTitle>
              {t(DICTIONARY.ADDITIONAL_DESCRIPTION_LABEL)}
            </OptionDescriptionTitle>
            <OptionDescriptionList>
              {description.map((item: string, index: number) => (
                <OptionDescriptionItem key={index}>
                  {item}
                </OptionDescriptionItem>
              ))}
            </OptionDescriptionList>
          </OptionDescriptionContainer>
        )}
      </>
    )
  )
}

export default OptionDescription
