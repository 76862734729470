export enum SessionItemEnum {
  CASE_ID = 'caseId',
  VEHICLE_ID = 'vehicleId',
  CUSTOMER_ID = 'customerId',
  POLICY_LEVEL = 'policyLevel',
  DRAFT_POLICY = 'draftPolicy',
  SUBMITTED_QUOTE_NUMBER = 'submittedQuoteNumber',
  LAST_PRICE_CALCULATION = 'lastPriceCalculation',
  SHOWROOM_STATE = 'showroomState',
}

const sessionItemKeys: SessionItemEnum[] = [
  SessionItemEnum.CASE_ID,
  SessionItemEnum.VEHICLE_ID,
  SessionItemEnum.CUSTOMER_ID,
  SessionItemEnum.POLICY_LEVEL,
  SessionItemEnum.DRAFT_POLICY,
  SessionItemEnum.SUBMITTED_QUOTE_NUMBER,
  SessionItemEnum.LAST_PRICE_CALCULATION,
  SessionItemEnum.SHOWROOM_STATE,
]

export const cleanSessionStorage = (keysToKeep: string[]) => {
  sessionItemKeys
    .filter((item) => !keysToKeep.includes(item))
    .forEach((item) => window.sessionStorage.removeItem(item))
}

export const saveParameter = (
  parameterName: string,
  parameter: string,
  sessionKeysToKeep: string[],
) => {
  if (parameter) {
    window.sessionStorage.setItem(parameterName, parameter)
    cleanSessionStorage(sessionKeysToKeep)
  }
}

export const cleanLastPriceCalculation = () => {
  window.sessionStorage.removeItem(SessionItemEnum.LAST_PRICE_CALCULATION)
}
