import { Entry } from '../../../model/Entry'
import { createDetail } from './quotation'

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const n = (value: any) => Number(value) || 0

export const checkValue = (
  translation: string,
  value: number | string,
  pricingDetails: Entry[],
  currency: Intl.NumberFormat,
) => {
  if (typeof value === 'number' && value > 0) {
    pricingDetails.push(createDetail(translation, currency.format(value)))
  }
  if (typeof value === 'string') {
    pricingDetails.push(createDetail(translation, value))
  }
}
