import { useRouteMatch } from 'react-router-dom'
import { Route } from 'react-router'

import { ConfiguratorRouteProps } from '../../config/routes'
import {
  INVALID_PUBLIC_PRODUCTS,
  VALID_PUBLIC_PRODUCTS,
} from '../../constants/public'
import { ProfileContextProvider } from '../../context/ProfileContext'
import useLocale from '../../hooks/useLocale'
import RedirectToHome from '../../pages/redirectToHome/RedirectToHome'
import apiClient from '../../utils/api'
import GTMPageEvent from '../gtm/GTMPageEvent'
import RebrandingLoader from '../utils/RebrandingLoader'

const PublicRoute = ({
  component: Component,
  layout: Layout,
  profileNeeded,
  ...rest
}: ConfiguratorRouteProps) => {
  const locale = useLocale()
  const match = useRouteMatch('/:locale/:product')
  const product = match?.params['product']

  product &&
    VALID_PUBLIC_PRODUCTS.includes(product) &&
    apiClient.setProduct(product)

  if (INVALID_PUBLIC_PRODUCTS.includes(product)) {
    return <RedirectToHome />
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ProfileContextProvider
          locale={locale}
          profileNeeded={profileNeeded}
          isOrderSummaryPage={false}
        >
          <RebrandingLoader />
          <GTMPageEvent />
          <Layout>
            <Component {...props} />
          </Layout>
        </ProfileContextProvider>
      )}
    />
  )
}

export default PublicRoute
