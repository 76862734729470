import { UseTranslationResponse } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { PrintableItem } from '../../vehicle-configurator/price/PriceSelectedOptions'

export const initState = (
  selectedItems: PrintableItem[],
  [isToggle, setIsToggle]: [boolean, Dispatch<SetStateAction<boolean>>],
  [showAll, setShowAll]: [boolean, Dispatch<SetStateAction<boolean>>],
  { t }: UseTranslationResponse<'translation', undefined>,
) => {
  const maxOptions = 4
  return {
    onShowDetailsClick: () => {
      setIsToggle((v) => !v)
    },
    onShowAllClick: () => {
      setShowAll((s) => !s)
    },
    showDetailsButton: () => selectedItems.length > 0,
    showAllButton: () => selectedItems.length > maxOptions,
    showDetails: () => isToggle,
    showNoOptionsSelected: () => selectedItems.length === 0,
    getDetailsLabel: () => t(DICTIONARY.SHOW_DETAILS_LABEL),
    getNoOptionsSelected: () => t(DICTIONARY.NO_OPTIONS_SELECTED),
    getSelectedConfigurationTitle: () =>
      t(DICTIONARY.SELECTED_CONFIGURATION_LABEL),
    getShowAllLabel: () =>
      showAll ? t(DICTIONARY.SHOW_LESS) : t(DICTIONARY.SHOW_ALL),
    getSelectedItems: () =>
      selectedItems
        .slice(0, showAll ? selectedItems.length : maxOptions)
        .map((i) => <li key={i.optionId}>{i.manufacturerName}</li>),
  }
}
