import { Dispatch } from 'react'

import { Option } from '../../../model/Quote'
import { AppAction } from '../reducer/VehicleConfigReducer'

const onOptionClick = (dispatch: Dispatch<AppAction>, option: Option) => {
  dispatch({
    type: 'selectOption',
    selectedOption: option,
  })
}

const isPremiumTrim = (option: Option) => {
  const { colour } = option
  return (
    colour &&
    !(colour.colourType || []).includes('Exterior') &&
    (colour.optionMinimumRequiredPrice || option.price) > 0
  )
}

export { onOptionClick, isPremiumTrim }
