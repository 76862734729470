import { Box, Container, Grid, Typography } from '@material-ui/core'
import { CarMissingIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import React, { ReactElement, useEffect } from 'react'
import styled from 'styled-components'

import CarMissingAyvensIcon from '../../assets/images/car-missing.png'
import Spinner from '../../components/spinner/Spinner'
import { DICTIONARY } from '../../constants/dictionary'
import { history } from '../../utils/historyStore'
import { usePublicSavedConfiguration } from '../../utils/reactQueryApi'
import { getStyles, isRebranding } from '../../utils/styles'
import { SpinnerContainer } from '../showroom/vehicle-list/VehicleList.styled'

const s = () => getStyles().colors.savedQuote

interface Props {
  component: React.ReactNode
}

const TitleStyled = styled(Typography)<Props>`
  font-size: 50px;
  font-weight: 700;
  color: ${s().font};
`

const SubTitleStyled = styled(Typography)<Props>`
  font-size: 20px;
  font-weight: 200;
  margin-top: 24px;
`

const SavedQuote = (): ReactElement => {
  const { t } = useTranslation()
  const { locale, product, quoteParam } = useParams<{
    locale: string
    product: string
    quoteParam: string
  }>()
  const {
    data: quote,
    isSuccess: isQuoteSuccess,
    isError: isQuoteError,
    isLoading: isQuoteLoading,
  } = usePublicSavedConfiguration(quoteParam, locale)

  useEffect(() => {
    if (isQuoteSuccess) {
      history.push(`/${locale}/${product}/vehicle-configurator`, {
        vehicleQuote: quote,
      })
    }
  }, [isQuoteSuccess, quote, locale, product])

  return (
    <>
      {isQuoteLoading && (
        <SpinnerContainer>
          <Spinner size={20} data-testid="saved-quote-spinner" />
        </SpinnerContainer>
      )}
      {isQuoteError && (
        <Container>
          <Grid
            container={true}
            alignContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid item={true} xs={4}>
              {isRebranding() ? (
                <img src={CarMissingAyvensIcon} alt="vehicleListMissingCar" />
              ) : (
                <CarMissingIcon size="xxl" color={s().carMissingIcon} />
              )}
            </Grid>
            <Grid item={true} xs={8}>
              <Box textAlign="left">
                <TitleStyled
                  component="h1"
                  data-e2e-id="quote-not-found-error-title"
                >
                  {t(DICTIONARY.QUOTE_NOT_FOUND_ERROR_TITLE)}
                </TitleStyled>
                <SubTitleStyled
                  component="h2"
                  data-e2e-id="quote-not-found-error-message"
                >
                  {t(DICTIONARY.QUOTE_NOT_FOUND_ERROR_MESSAGE)}
                </SubTitleStyled>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default SavedQuote
