import { sanitize } from 'dompurify'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { getStyles, isRebranding } from '../../utils/styles'

const s = () => getStyles().colors.publicOrder.disclaimer

const Disclaimer: FC = () => {
  const { t } = useTranslation()
  return <Root dangerouslySetInnerHTML={getDisclaimerText(t)} />
}

const Root = styled.div`
  background: ${s().background};
  border-color: ${s().border};
  color: ${s().font};
  padding: 20px;
  font-size: 13px;
  margin-top: 40px;
`

export const getDisclaimerText = (translations: TFunction) => {
  return {
    __html: sanitize(
      translations(
        isRebranding()
          ? DICTIONARY.BOTTOM_QUOTE_DISCLAIMER_AYVENS
          : DICTIONARY.BOTTOM_QUOTE_DISCLAIMER,
      ),
    ),
  }
}

export default Disclaimer
