import { Calculation } from '../../../model/Calculation'
import { GenericError } from '../../../model/Error'
import { Profile } from '../../../model/Profile'
import { ShowroomFiltersSelection } from '../../../model/ShowroomFiltersSelection'
import { DefaultSortingOptions } from '../vehicle-list/VehiclesSorting'

export interface ShowroomAction {
  type: string
  locale?: string
  profile?: Profile
  filters?: ShowroomFiltersSelection
  vehicleListButtons?: Record<string, boolean>
  images?: {}
  selectedQuote?: Calculation
  quoteLoading?: boolean
  selectedQuoteIdentifier?: string
  activePageNumber?: number
  sortingValue?: DefaultSortingOptions
  itemsPerPage?: number
  filtersError?: GenericError
  grossTrailerWeightBraked?: number
}

export interface ShowroomState {
  locale: string
  profile: Profile
  filters: ShowroomFiltersSelection
  showFiltersSlider: boolean
  vehicleListButtons: Record<string, boolean>
  images: {}
  selectedQuote: Calculation
  filtersLoaded: boolean
  quoteLoading: boolean
  selectedQuoteIdentifier: string
  activePageNumber: number
  sortingValue: DefaultSortingOptions
  itemsPerPage: number
  filtersError: GenericError
  grossTrailerWeightBraked?: number
}

export const showroomReducer = (
  state: ShowroomState,
  action: ShowroomAction,
): ShowroomState => {
  switch (action.type) {
    case 'loadData':
      return {
        ...state,
        locale: action.locale,
        profile: action.profile,
        images: action.images,
      }
    case 'updateVehicleListButtons':
      return {
        ...state,
        vehicleListButtons: action.vehicleListButtons,
      }
    case 'openFiltersSlider':
      return {
        ...state,
        showFiltersSlider: true,
      }
    case 'closeFiltersSlider':
      return {
        ...state,
        showFiltersSlider: false,
      }
    case 'updateMake':
      return {
        ...state,
        filters: { ...state.filters, make: action.filters.make },
      }
    case 'updateModel':
      return {
        ...state,
        filters: { ...state.filters, model: action.filters.model },
      }
    case 'updateBodyTypes':
      return {
        ...state,
        filters: { ...state.filters, bodyTypes: action.filters.bodyTypes },
      }
    case 'updateFuelTypes':
      return {
        ...state,
        filters: { ...state.filters, fuelTypes: action.filters.fuelTypes },
      }
    case 'updateCategoryTypes':
      return {
        ...state,
        filters: {
          ...state.filters,
          carCategories: action.filters.carCategories,
        },
      }
    case 'updateTransmissionTypes':
      return {
        ...state,
        filters: {
          ...state.filters,
          transmissionTypes: action.filters.transmissionTypes,
        },
      }
    case 'updateDrivetrainTypes':
      return {
        ...state,
        filters: { ...state.filters, drivetrains: action.filters.drivetrains },
      }
    case 'updateMonthlyPrice':
      return {
        ...state,
        filters: {
          ...state.filters,
          monthlyPrice: {
            ...state.filters.monthlyPrice,
            ...action.filters.monthlyPrice,
          },
        },
      }
    case 'updateCO2Emission': {
      const co2Value =
        action.filters.co2Emission.value === undefined
          ? state.filters.co2Emission.value
          : action.filters.co2Emission.value
      return {
        ...state,
        filters: {
          ...state.filters,
          co2Emission: {
            ...state.filters.co2Emission,
            value:
              !state.filters.co2Emission.max &&
              state.filters.co2Emission.max === 0
                ? action.filters.co2Emission.max
                : co2Value,
            max:
              !state.filters.co2Emission.max &&
              state.filters.co2Emission.max === 0
                ? action.filters.co2Emission.max
                : state.filters.co2Emission.max,
          },
        },
      }
    }
    case 'updateMaxGrossTrailerWeightBraked': {
      return {
        ...state,
        filters: {
          ...state.filters,
          grossTrailerWeightBraked: {
            ...state.filters.grossTrailerWeightBraked,
            value: action.filters.grossTrailerWeightBraked?.value,
            max: action.filters.grossTrailerWeightBraked.max,
          },
        },
      }
    }
    case 'updateElectricRange':
      return {
        ...state,
        filters: {
          ...state.filters,
          electricRange: {
            ...state.filters.electricRange,
            ...action.filters.electricRange,
          },
        },
      }
    case 'updateMileage':
      return {
        ...state,
        filters: {
          ...state.filters,
          mileage: {
            ...state.filters.mileage,
            ...action.filters.mileage,
          },
        },
      }
    case 'updateDuration':
      return {
        ...state,
        filters: {
          ...state.filters,
          duration: {
            ...state.filters.duration,
            ...action.filters.duration,
          },
        },
      }
    case 'resetFilters':
      return {
        ...state,
        filters: {
          make: [],
          model: [],
          bodyTypes: [],
          fuelTypes: [],
          carCategories: [],
          transmissionTypes: [],
          drivetrains: [],
          monthlyPrice: {
            ...state.filters.monthlyPrice,
            start: state.filters.monthlyPrice?.min,
            end: state.filters.monthlyPrice?.max,
          },
          co2Emission: {
            ...state.filters.co2Emission,
            value: state.filters.co2Emission.max,
          },
          electricRange: {
            ...state.filters.electricRange,
            value: state.filters.electricRange.max,
          },
          mileage: {
            ...state.filters.mileage,
            value: state.filters.mileage.min,
          },
          duration: {
            ...state.filters.duration,
            value: state.filters.duration.max,
          },
          grossTrailerWeightBraked: {
            ...state.filters.grossTrailerWeightBraked,
            value: 0,
          },
        },
      }
    case 'setShowroomState':
      return {
        ...state,
        filters: action.filters,
        activePageNumber: action.activePageNumber,
        sortingValue: action.sortingValue,
        itemsPerPage: action.itemsPerPage,
      }
    case 'setSortingValue':
      return {
        ...state,
        sortingValue: action.sortingValue,
      }
    case 'setItemsPerPage':
      return {
        ...state,
        itemsPerPage: action.itemsPerPage,
      }
    case 'setActivePageNumber':
      return {
        ...state,
        activePageNumber: action.activePageNumber,
      }
    case 'setSelectedQuote':
      return {
        ...state,
        selectedQuote: { ...action.selectedQuote },
      }
    case 'setFiltersLoaded':
      return {
        ...state,
        filtersLoaded: true,
        filtersError: action.filtersError,
      }

    case 'setQuoteLoading':
      return {
        ...state,
        quoteLoading: action.quoteLoading,
        selectedQuoteIdentifier: action.selectedQuoteIdentifier,
      }
  }
  return state
}

export const defaultShowroomState: ShowroomState = {
  locale: 'en_IE',
  profile: null,
  showFiltersSlider: false,
  vehicleListButtons: {},
  images: {},
  selectedQuote: null,
  filters: {
    make: [],
    model: [],
    bodyTypes: [],
    fuelTypes: [],
    carCategories: [],
    transmissionTypes: [],
    drivetrains: [],
    monthlyPrice: null,
    co2Emission: { value: 0, max: 0 },
    electricRange: { value: 0, max: 0 },
    mileage: {},
    duration: {},
    grossTrailerWeightBraked: { value: 0, max: 0 },
  },
  filtersLoaded: false,
  quoteLoading: false,
  selectedQuoteIdentifier: null,
  activePageNumber: 0,
  sortingValue: 'LOWEST_PRICE_SORT_LABEL',
  itemsPerPage: 12,
  filtersError: null,
}
