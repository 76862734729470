import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { capitalize } from '../../utils/string'
import { getStyles } from '../../utils/styles'
import { PrintableItem } from '../vehicle-configurator/price/PriceSelectedOptions'

const s = () => getStyles().colors.configurator.favorites.moreInfo

interface Props {
  selectedItems: PrintableItem[]
}
const SavedQuoteMoreInfo: FC<Props> = ({ selectedItems }) => {
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)
  const [isToggle, setIsToggle] = useState(false)
  const maxOptions = 4
  return (
    <Root>
      {selectedItems.length > 0 && (
        <Toggle
          data-e2e-id="showDetailsButton"
          onClick={() => {
            setIsToggle((v) => !v)
          }}
        >
          + {t(DICTIONARY.SHOW_DETAILS_LABEL)}
        </Toggle>
      )}
      {selectedItems.length === 0 && (
        <Title>{t(DICTIONARY.NO_OPTIONS_SELECTED)}</Title>
      )}
      {isToggle && (
        <div>
          <Title>
            {capitalize(t(DICTIONARY.SELECTED_CONFIGURATION_LABEL))}
          </Title>
          <ul>
            {selectedItems
              .slice(0, showAll ? selectedItems.length : maxOptions)
              .map((i) => (
                <li key={i.optionId}>{i.manufacturerName}</li>
              ))}
          </ul>
          {selectedItems.length > maxOptions && (
            <ShowAll
              data-e2e-id="showAllLessButton"
              onClick={() => {
                setShowAll((s) => !s)
              }}
            >
              {showAll ? t(DICTIONARY.SHOW_LESS) : t(DICTIONARY.SHOW_ALL)}
            </ShowAll>
          )}
        </div>
      )}
    </Root>
  )
}

const Root = styled.div`
  li {
    font-size: 12px;
  }
  li::marker {
    color: ${s().marker};
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 30px;
  }
`
const Button = styled.button`
  color: ${s().button.font};
  background-color: ${s().button.background};
  cursor: pointer;
  border: none;
`
const Toggle = styled(Button)`
  font-size: 12px;
  padding-left: 0;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
const Title = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  color: ${s().title};
`
const ShowAll = styled(Button)`
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export default SavedQuoteMoreInfo
