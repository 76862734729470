import { FC, ReactElement } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

interface Props {
  startIcon: ReactElement
  onClick: () => void
  className?: string
  testId?: string
}
const IconButton: FC<Props> = ({
  startIcon,
  onClick,
  className,
  testId = 'velocityButton',
}) => {
  return (
    <Root
      data-testid={testId}
      data-e2e-id={testId}
      className={className}
      onClick={() => {
        onClick()
      }}
    >
      <Content>{startIcon && <Icon>{startIcon}</Icon>}</Content>
    </Root>
  )
}

const Icon = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
`
const Content = styled.span`
  display: flex;
`
const Root = styled.button`
  aspect-ratio: 1 / 1;
  border: 1px solid transparent;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: 8px 0;
  border-radius: 999px;
  margin-bottom: 1rem;
  color: ${getStyles().colors.velocity.iconButton.color};
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${getStyles().colors.velocity.iconButton.background};
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  cursor: pointer;
  min-height: 40px;
`

export default IconButton
