import { Text, View } from '@react-pdf/renderer'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { iGen } from '../../../utils/indexGen'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  co2Emissions: Entry[]
  parentStyles: typeof pdfDocStyles
}

const WltpSection: React.FC<Props> = ({ co2Emissions, parentStyles }) => {
  const { t } = useTranslation()
  return (
    !!co2Emissions.length && (
      <View wrap={false}>
        <Text style={parentStyles.subTitle}>{t(DICTIONARY.HEADER)}</Text>
        {co2Emissions.map((emission, index) => (
          <View style={parentStyles.row} key={iGen(index)}>
            <Text style={parentStyles.halfDescription}>
              {parse(emission.description)}
            </Text>
            <Text style={parentStyles.quarter} />
            <Text style={parentStyles.value}>{emission.value}</Text>
          </View>
        ))}
      </View>
    )
  )
}

export default WltpSection
