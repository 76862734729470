import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles } from '../../../utils/styles'
import { Order } from '../util/tableHeadSort'
import {
  DescriptionChevronContainer,
  DescriptionItem,
  DescriptionLabel,
  DescriptionList,
  DescriptionRoot,
  DescriptionsHeader,
} from './VehicleSpecification.styled'

const s = () => getStyles().colors.configurator.specification.description

interface Props {
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
  descriptions: string[]
}

const VehicleSpecificationDescription: FC<Props> = ({
  order,
  setOrder,
  descriptions,
}) => {
  const [sortedDescriptions, setSortedDescriptions] = useState(descriptions)
  const { t } = useTranslation()

  useEffect(() => {
    if (order && order === 'asc') {
      setSortedDescriptions((list) => {
        return [...list].sort((b, c) => b.localeCompare(c))
      })
    }
    if (order && order === 'desc') {
      setSortedDescriptions((list) => {
        return [...list].sort((b, c) => c.localeCompare(b))
      })
    }
  }, [order])
  return (
    <DescriptionRoot>
      <DescriptionsHeader
        data-testid="vehicle-specification-sort-trigger"
        onClick={() => {
          setOrder((e) => (e === 'asc' ? 'desc' : 'asc'))
        }}
      >
        <DescriptionLabel>{t(DICTIONARY.DESCRIPTION)}</DescriptionLabel>
        <DescriptionChevronContainer data-testid="model-sort-btn">
          <ChevronUpIcon
            size="xs"
            color={s().upIcon}
            style={{ opacity: order === 'asc' ? 1 : 0.2 }}
          />
          <ChevronDownIcon
            size="xs"
            color={s().downIcon}
            style={{ opacity: order === 'desc' ? 1 : 0.2 }}
          />
        </DescriptionChevronContainer>
      </DescriptionsHeader>
      <DescriptionList>
        {sortedDescriptions.map((i) => (
          <DescriptionItem
            key={i}
            data-testid="vehicle-specification-descriptions-item"
          >
            {i}
          </DescriptionItem>
        ))}
      </DescriptionList>
    </DescriptionRoot>
  )
}

export default VehicleSpecificationDescription
