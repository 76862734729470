import { CloseIcon } from '@velocity/ui/draft'
import { FC, useState } from 'react'

import { getStyles } from '../../../../utils/styles'
import VehicleTile from '../VehicleTile'
import { CarVersion } from './BrandsView'
import {
  CarDetailsContainer,
  CloseVersionButtonStyled,
  VersionName,
} from './VersionLine.styled'

const s = () => getStyles().colors.showroom.vehicleList.brandsView.version

interface VersionLineProps {
  item: CarVersion
}
const VersionLine: FC<VersionLineProps> = ({ item }) => {
  const [isCarInfoOpened, setIsCarInfoOpened] = useState(false)

  return (
    <>
      {isCarInfoOpened ? (
        <CarDetailsContainer>
          <CloseVersionButtonStyled
            onClick={(e) => {
              e.stopPropagation()
              setIsCarInfoOpened(false)
            }}
          >
            <CloseIcon size="s" color={s().closeIcon} />
          </CloseVersionButtonStyled>
          <VehicleTile
            quote={item.quote}
            viewMode="list"
            animationOnHover={false}
          />
        </CarDetailsContainer>
      ) : (
        <VersionName
          onClick={(e) => {
            e.stopPropagation()
            setIsCarInfoOpened(true)
          }}
        >
          {item.name}
        </VersionName>
      )}
    </>
  )
}

export default VersionLine
