import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'

export const CloseVersionButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

export const CarDetailsContainer = styled.div`
  position: relative;
`
export const VersionName = styled.div`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
