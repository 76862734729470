import { styles as newStyles } from '../config/defaultNewStyles'
import { styles } from '../config/defaultStyles'

export const getStyles = (): typeof newStyles => {
  if (!isRebranding()) {
    return styles
  }

  return newStyles
}

export const saveCountry = () => {
  const country = getCountryFromPath()
  country && window.sessionStorage.setItem('country', country)
}

export const isRebranding = () => {
  return process.env.REACT_APP_ENABLE_REBRANDING === 'true'
}

const getCountryFromPath = () => {
  const path = window.location.pathname

  if (path.indexOf('_') === -1 || path.indexOf('/') === -1) {
    return null
  }

  return path.split('/')[1].split('_')[1]?.toLowerCase()
}
