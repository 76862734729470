import { makeStyles, Theme } from '@material-ui/core'
import { ChevronUpIcon } from '@velocity/ui/draft'
import { ReactElement, useEffect, useRef, useState } from 'react'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.topNav.topScroll

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    bottom: '30px',
    right: '10px',
    height: '56px',
    width: '56px',
    background: s().background,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    opacity: 0.5,
    cursor: 'pointer',
  },
}))

const TopScroll = (): ReactElement => {
  const classes = useStyles()
  const [hideElement, setHideElement] = useState(true)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    window.addEventListener('scroll', () => {
      const position = 240
      mounted.current && setHideElement(window.scrollY <= position)
    })
    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <>
      {!hideElement && (
        <div
          data-testid="scroll-element"
          className={classes.root}
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        >
          <ChevronUpIcon size="s" color={s().upIcon} />
        </div>
      )}
    </>
  )
}

export default TopScroll
