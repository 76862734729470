interface LocaleUrls {
  home: string
  cookie: string
  terms: string
  privacy: string
  contact: string
}

interface Locale {
  languageCode: string
  isoCountryCode: string
  name: string
  locale: string
  region: string
  publicFlowEnabled?: boolean
}

export enum CountryDefaultLanguage {
  IE = 'en',
  NO = 'no',
  FR = 'fr',
  AT = 'de',
  DK = 'da',
  LU = 'fr',
  GB = 'en',
  PT = 'pt',
  SE = 'sv',
}

export enum CountryDefaultLocale {
  IE = 'en_IE',
  NO = 'no_NO',
  FR = 'fr_FR',
  AT = 'de_AT',
  DK = 'da_DK',
  LU = 'fr_LU',
  GB = 'en_GB',
  PT = 'pt_PT',
  SE = 'sv_SE',
}

export enum LocalesEnum {
  EN_IE = 'EN_IE',
  EN_NO = 'EN_NO',
  NO_NO = 'NO_NO',
  EN_FR = 'EN_FR',
  FR_FR = 'FR_FR',
  DE_AT = 'DE_AT',
  EN_DK = 'EN_DK',
  DA_DK = 'DA_DK',
  EN_LU = 'EN_LU',
  FR_LU = 'FR_LU',
  DE_LU = 'DE_LU',
  EN_GB = 'EN_GB',
  PT_PT = 'PT_PT',
  EN_PT = 'EN_PT',
  SV_SE = 'SV_SE',
  EN_SE = 'EN_SE',
}

export type AppLocalesUrls = {
  [key in LocalesEnum]: LocaleUrls
}

export const CORP: AppLocalesUrls = {
  EN_IE: {
    home: 'https://www.leaseplan.com/en-ie',
    cookie: 'https://www.leaseplan.com/en-ie/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-ie/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-ie/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-ie/contact/',
  },
  EN_NO: {
    home: 'https://www.leaseplan.com/en-no',
    cookie: 'https://www.leaseplan.com/en-no/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-no/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-no/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-no/contact/',
  },
  NO_NO: {
    home: 'https://www.leaseplan.com/nb-no',
    cookie: 'https://www.leaseplan.com/nb-no/retningslinjer-for-cookies/',
    terms: 'https://www.leaseplan.com/nb-no/vilkar-for-bruk/',
    privacy: 'https://www.leaseplan.com/nb-no/personvernerklering/',
    contact: 'https://www.leaseplan.com/nb-no/kontakt/',
  },
  EN_FR: {
    // Intentionally redirecting to fr-fr as en-fr doesn't exist
    home: 'https://www.leaseplan.com/fr-fr',
    cookie: 'https://www.leaseplan.com/fr-fr/politique-relative-aux-cookies/',
    terms: 'https://www.leaseplan.com/fr-fr/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-fr/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-fr/contact/',
  },
  FR_FR: {
    home: 'https://www.leaseplan.com/fr-fr',
    cookie: 'https://www.leaseplan.com/fr-fr/politique-relative-aux-cookies/',
    terms: 'https://www.leaseplan.com/fr-fr/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-fr/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-fr/contact/',
  },
  DE_AT: {
    home: 'https://www.leaseplan.com/de-at',
    cookie: 'https://www.leaseplan.com/de-at/cookie-richtlinie/',
    terms: 'https://www.leaseplan.com/de-at/agb/',
    privacy: 'https://www.leaseplan.com/de-at/datenschutz/',
    contact: 'https://www.leaseplan.com/de-at/kontakt/',
  },
  EN_DK: {
    home: 'https://www.leaseplan.com/en-dk',
    cookie: 'https://www.leaseplan.com/en-dk/cookies-policy/',
    terms: 'https://www.leaseplan.com/en-dk/termsofuse/',
    privacy: 'https://www.leaseplan.com/en-dk/privacystatement/',
    contact: 'https://www.leaseplan.com/en-dk/contact/',
  },
  DA_DK: {
    home: 'https://www.leaseplan.com/da-dk',
    cookie: 'https://www.leaseplan.com/da-dk/cookie-politik/',
    terms: 'https://www.leaseplan.com/da-dk/brugerbetingelser/',
    privacy: 'https://www.leaseplan.com/da-dk/erklaering-om-privatlivsfred/',
    contact: 'https://www.leaseplan.com/da-dk/kontakt/',
  },
  EN_LU: {
    home: 'https://www.leaseplan.com/en-lu',
    cookie: 'https://www.leaseplan.com/en-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-lu/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-lu/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-lu/contact/',
  },
  FR_LU: {
    home: 'https://www.leaseplan.com/fr-lu',
    cookie: 'https://www.leaseplan.com/fr-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/fr-lu/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-lu/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-lu/contact/',
  },
  DE_LU: {
    home: 'https://www.leaseplan.com/en-lu',
    cookie: 'https://www.leaseplan.com/en-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-lu/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-lu/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-lu/contact/',
  },
  EN_GB: {
    home: 'https://www.leaseplan.com/en-gb',
    cookie: 'https://www.leaseplan.com/en-gb/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-gb/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-gb/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-gb/contact/',
  },
  PT_PT: {
    home: 'https://www.leaseplan.com/pt-pt/',
    cookie: 'https://www.leaseplan.com/pt-pt/politica-de-cookies/',
    terms: 'https://www.leaseplan.com/pt-pt/termos-de-utilizacao/',
    privacy: 'https://www.leaseplan.com/pt-pt/declaracao-de-privacidade/',
    contact: 'https://www.leaseplan.com/pt-pt/contactos/',
  },
  EN_PT: {
    home: 'https://www.leaseplan.com/pt-pt/',
    cookie: 'https://www.leaseplan.com/en-pt/cookies-policy/',
    terms: 'https://www.leaseplan.com/en-pt/term-of-use/',
    privacy: 'https://www.leaseplan.com/en-pt/privacy-statements/',
    contact: 'https://www.leaseplan.com/en-pt/contact/',
  },
  SV_SE: {
    home: 'https://www.leaseplan.com/sv-se/',
    cookie: 'https://www.leaseplan.com/sv-se/cookiepolicy/',
    terms: 'https://www.leaseplan.com/sv-se/',
    privacy: 'https://www.leaseplan.com/sv-se/privacy-statement/',
    contact: 'https://www.leaseplan.com/sv-se/kontakt/',
  },
  EN_SE: {
    home: 'https://www.leaseplan.com/en-se/',
    cookie: 'https://www.leaseplan.com/en-se/cookiepolicy/',
    terms: 'https://www.leaseplan.com/en-se/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-se/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-se/contact/',
  },
}
export const CORP_AYVENS: AppLocalesUrls = {
  EN_IE: {
    home: 'https://www.ayvens.com/en-ie',
    cookie: 'https://www.ayvens.com/en-ie/cookie-policy/',
    terms: 'https://www.ayvens.com/en-ie/terms-of-use/',
    privacy: 'https://www.ayvens.com/en-ie/privacy-statement/',
    contact: 'https://www.ayvens.com/en-ie/contact/',
  },
  EN_NO: {
    home: 'https://www.ayvens.com/en-no',
    cookie: 'https://www.ayvens.com/en-no/cookie-policy/',
    terms: 'https://www.ayvens.com/en-no/legal-information/',
    privacy: 'https://www.ayvens.com/en-no/privacy-statement/',
    contact: 'https://www.ayvens.com/en-no/contact/',
  },
  NO_NO: {
    home: 'https://www.ayvens.com/nb-no',
    cookie: 'https://www.ayvens.com/nb-no/informasjonskapselspolicy/',
    terms: 'https://www.ayvens.com/nb-no/vilkar-og-betingelser/',
    privacy: 'https://www.ayvens.com/nb-no/personvernerklering/',
    contact: 'https://www.ayvens.com/nb-no/kontakt-oss/',
  },
  EN_FR: {
    // Intentionally redirecting to fr-fr as en-fr doesn't exist
    home: 'https://www.leaseplan.com/fr-fr',
    cookie: 'https://www.leaseplan.com/fr-fr/politique-relative-aux-cookies/',
    terms: 'https://www.leaseplan.com/fr-fr/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-fr/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-fr/contact/',
  },
  FR_FR: {
    home: 'https://www.leaseplan.com/fr-fr',
    cookie: 'https://www.leaseplan.com/fr-fr/politique-relative-aux-cookies/',
    terms: 'https://www.leaseplan.com/fr-fr/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-fr/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-fr/contact/',
  },
  DE_AT: {
    home: 'https://www.leaseplan.com/de-at',
    cookie: 'https://www.leaseplan.com/de-at/cookie-richtlinie/',
    terms: 'https://www.leaseplan.com/de-at/agb/',
    privacy: 'https://www.leaseplan.com/de-at/datenschutz/',
    contact: 'https://www.leaseplan.com/de-at/kontakt/',
  },
  EN_DK: {
    home: 'https://www.leaseplan.com/en-dk',
    cookie: 'https://www.leaseplan.com/en-dk/cookies-policy/',
    terms: 'https://www.leaseplan.com/en-dk/termsofuse/',
    privacy: 'https://www.leaseplan.com/en-dk/privacystatement/',
    contact: 'https://www.leaseplan.com/en-dk/contact/',
  },
  DA_DK: {
    home: 'https://www.leaseplan.com/da-dk',
    cookie: 'https://www.leaseplan.com/da-dk/cookie-politik/',
    terms: 'https://www.leaseplan.com/da-dk/brugerbetingelser/',
    privacy: 'https://www.leaseplan.com/da-dk/erklaering-om-privatlivsfred/',
    contact: 'https://www.leaseplan.com/da-dk/kontakt/',
  },
  EN_LU: {
    home: 'https://www.leaseplan.com/en-lu',
    cookie: 'https://www.leaseplan.com/en-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-lu/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-lu/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-lu/contact/',
  },
  FR_LU: {
    home: 'https://www.leaseplan.com/fr-lu',
    cookie: 'https://www.leaseplan.com/fr-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/fr-lu/conditions-dutilisation/',
    privacy: 'https://www.leaseplan.com/fr-lu/politique-de-confidentialite/',
    contact: 'https://www.leaseplan.com/fr-lu/contact/',
  },
  DE_LU: {
    home: 'https://www.leaseplan.com/en-lu',
    cookie: 'https://www.leaseplan.com/en-lu/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-lu/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-lu/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-lu/contact/',
  },
  EN_GB: {
    home: 'https://www.leaseplan.com/en-gb',
    cookie: 'https://www.leaseplan.com/en-gb/cookie-policy/',
    terms: 'https://www.leaseplan.com/en-gb/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-gb/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-gb/contact/',
  },
  PT_PT: {
    home: 'https://www.ayvens.com/pt-pt/',
    cookie: 'https://www.ayvens.com/pt-pt/politica-de-cookies/',
    terms: 'https://www.ayvens.com/pt-pt/termos-de-utilizacao/',
    privacy: 'https://www.ayvens.com/pt-pt/declaracao-de-privacidade/',
    contact: 'https://www.ayvens.com/pt-pt/suporte-e-faq/',
  },
  EN_PT: {
    home: 'https://www.ayvens.com/en-pt/',
    cookie: 'https://www.ayvens.com/en-pt/cookies-policy/',
    terms: 'https://www.ayvens.com/en-pt/term-of-use/',
    privacy: 'https://www.ayvens.com/en-pt/privacy-statement/',
    contact: 'https://www.ayvens.com/en-pt/services-and-faq/',
  },
  SV_SE: {
    home: 'https://www.leaseplan.com/sv-se/',
    cookie: 'https://www.leaseplan.com/sv-se/cookiepolicy/',
    terms: 'https://www.leaseplan.com/sv-se/',
    privacy: 'https://www.leaseplan.com/sv-se/privacy-statement/',
    contact: 'https://www.leaseplan.com/sv-se/kontakt/',
  },
  EN_SE: {
    home: 'https://www.leaseplan.com/en-se/',
    cookie: 'https://www.leaseplan.com/en-se/cookiepolicy/',
    terms: 'https://www.leaseplan.com/en-se/terms-of-use/',
    privacy: 'https://www.leaseplan.com/en-se/privacy-statement/',
    contact: 'https://www.leaseplan.com/en-se/contact/',
  },
}
export const LOCALES: Locale[] = [
  {
    languageCode: 'en',
    isoCountryCode: 'ie',
    name: 'Ireland',
    locale: 'en_IE',
    region: 'Europe',
    publicFlowEnabled: true,
  },
  {
    languageCode: 'no',
    isoCountryCode: 'no',
    name: 'Norge',
    locale: 'no_NO',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'no',
    name: 'Norway',
    locale: 'en_NO',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'fr',
    name: 'France',
    locale: 'en_FR',
    region: 'Europe',
  },
  {
    languageCode: 'fr',
    isoCountryCode: 'fr',
    name: 'Fran\u00E7ais',
    locale: 'fr_FR',
    region: 'Europe',
  },
  {
    languageCode: 'da',
    isoCountryCode: 'dk',
    name: 'Danmark',
    locale: 'da_DK',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'dk',
    name: 'Denmark',
    locale: 'en_DK',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'lu',
    name: 'Luxembourg',
    locale: 'en_LU',
    region: 'Europe',
  },
  {
    languageCode: 'fr',
    isoCountryCode: 'lu',
    name: 'Luxembourg',
    locale: 'fr_LU',
    region: 'Europe',
  },
  {
    languageCode: 'de',
    isoCountryCode: 'lu',
    name: 'Luxemburg',
    locale: 'de_LU',
    region: 'Europe',
  },
  {
    languageCode: 'pt',
    isoCountryCode: 'pt',
    name: 'Portugal',
    locale: 'pt_PT',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'pt',
    name: 'Portugal',
    locale: 'en_PT',
    region: 'Europe',
  },
  {
    languageCode: 'sv',
    isoCountryCode: 'se',
    name: 'Sverige',
    locale: 'sv_SE',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'se',
    name: 'Sweden',
    locale: 'en_SE',
    region: 'Europe',
  },
  {
    languageCode: 'en',
    isoCountryCode: 'gb',
    name: 'United Kingdom',
    locale: 'en_GB',
    region: 'Europe',
  },
]

export const DEFAULT_LOCALE: Locale = {
  languageCode: 'en',
  isoCountryCode: 'ie',
  name: 'Ireland',
  locale: 'en_IE',
  region: 'Europe',
}

export const CONTACT_DETAILS = {
  ie: {
    default:
      '<strong>LeasePlan Ireland</strong>\n' +
      'LeasePlan House\n' +
      'Central Park\n' +
      'Leopardstown\n' +
      'D18 X5T1\n' +
      'Ireland\n' +
      '<strong>Tel:</strong> +353-1-2407600',
    personal:
      '<strong>LeasePlan Ireland</strong>\n' +
      'LeasePlan House\n' +
      'Central Park\n' +
      'Leopardstown\n' +
      'D18 X5T1\n' +
      'Ireland\n' +
      '<strong>Tel:</strong> +353-1-2461950',
    affiliate:
      '<strong>LeasePlan Ireland</strong>\n' +
      'LeasePlan House\n' +
      'Central Park\n' +
      'Leopardstown\n' +
      'D18 X5T1\n' +
      'Ireland\n' +
      '<strong>Tel:</strong> +353-1-2461950',
  },
  fr: {
    default:
      '<strong>LEASEPLAN FRANCE SAS</strong>\n' +
      '274, Avenue Napoléon Bonaparte\n' +
      '92562 Rueil-Malmaison Cedex\n' +
      'www.leaseplan.fr\n' +
      '<strong>Tel:</strong> +33 1 56 84 10 10\n' +
      'N° TVA intracommunautaire FR 50 313 606 477',
  },
  lu: {
    default:
      '<strong>LEASEPLAN LUXEMBOURG S.A.</strong>\n' +
      '7 Rue Nicolas Brosius,\n' +
      'L-3372 Leudelange\n' +
      'www.leaseplan.lu\n' +
      '<strong>Tel:</strong> +352 40 44 11.1\n' +
      'VAT: LU13-952-966',
  },
  dk: {
    default:
      '<strong>LeasePlan DK</strong>\nMidtager 20\n2605 Brøndby\nDenmark\nwww.leaseplan.dk',
  },
  no: {
    default:
      '<strong>Ayvens Norge AS</strong>\n' +
      'Brynsengveien 10, 0667 Oslo\n' +
      'Postboks 6019 Etterstad, 0601 Oslo\n' +
      'www.ayvens.no\n' +
      '<strong>Tel:</strong> +47 23 06 98 00\n' +
      'NO 945 098 775 MVA',
  },

  gb: {
    default:
      '<strong>LeasePlan UK Limited</strong>\n' +
      '165 Bath Road\n' +
      'Slough\n' +
      'Berkshire\n' +
      'SL1 4AA\n' +
      'United Kingdom\n',
  },

  pt: {
    default:
      '<strong>LeasePlan Portugal</strong>\n' +
      'Lagoas Park\n' +
      'Edifício 6\n' +
      '2740-244\n' +
      'Porto Salvo\n' +
      'Portugal',
  },

  se: {
    default:
      '<strong>LeasePlan Sverige AB</strong>\n' +
      'Råsundavägen 4\n' +
      '169 67 Solna\n' +
      'Sverige\n' +
      '08-470 26 00\n',
  },
}
