import { CONTRIBUTION_TYPE } from '../constants/contribution'
import { DICTIONARY } from '../constants/dictionary'
import { Contribution } from '../model/Quote'

export const getDriverContributionLabelTranslation = (
  contribution: Contribution,
  duration: number,
): string => {
  switch (contribution?.contributionType) {
    case CONTRIBUTION_TYPE.MONTHLY_AMOUNT:
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE: {
      return DICTIONARY.DRIVER_MONTHLY_CONTRIBUTION_LABEL
    }
    case CONTRIBUTION_TYPE.MONTHLY_PERCENTAGE_WITH_CONTRIBUTION_PERIOD: {
      if (contribution?.contributionPeriod === duration) {
        return DICTIONARY.DRIVER_MONTHLY_CONTRIBUTION_LABEL
      } else {
        return DICTIONARY.DRIVER_MONTHLY_CONTRIBUTION_WITH_PERIOD_LABEL
      }
    }
    case CONTRIBUTION_TYPE.ONE_OFF:
    default: {
      return DICTIONARY.DOWNPAYMENT
    }
  }
}
