import { Box, createStyles, Grid, makeStyles } from '@material-ui/core'

import { ReactElement } from 'react'

import { history } from '../../utils/historyStore'
import { getStyles, isRebranding } from '../../utils/styles'
import BusinessIcon from './icons/BusinessIcon'
import CompanyCarIcon from './icons/CompanyCarIcon'

const s = () => getStyles().colors.homePage

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      fontSize: 14,
      textAlign: 'center',
    },
    content: {
      backgroundColor: s().background,
      border: `1px solid ${s().border}`,
      height: '35em',
    },
    heading: {
      color: s().heading,
      fontSize: 30,
      fontWeight: getStyles().fonts.title.weight,
      fontFamily: getStyles().fonts.heading,
      lineHeight: 1.1,
    },
    subHeading: {
      color: s().subHeading,
      fontSize: 17,
      fontWeight: getStyles().fonts.title.weight,
      fontFamily: getStyles().fonts.heading,
      lineHeight: 1.1,
    },
    options: {
      padding: 10,
      marginBottom: '32px',
    },
    option: {
      margin: '0 4px',
      padding: '16px 0',
      textAlign: 'center',
      border: `1px solid ${s().option.border}`,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: s().option.background,
      },
    },
    optionName: {
      color: theme.palette.secondary.main,
      fontSize: 15,
      fontWeight: 600,
    },
  }),
)

const Home = (): ReactElement => {
  const classes = useStyles()
  return (
    <Grid container={true} className={classes.root}>
      <Grid item={true} sm={1} md={2} />
      <Grid item={true} sm={10} md={8}>
        <Box mt={4} className={classes.content}>
          <Box p={4} className={classes.heading}>
            Find your perfect vehicle
          </Box>
          <Box p={3} className={classes.subHeading}>
            What type of lease are you looking for?
          </Box>
          <Grid
            container={true}
            spacing={0}
            direction="row"
            justify="center"
            className={classes.options}
          >
            <Grid item={true} xs={4}>
              <Box
                data-testid="home-business-link"
                component="div"
                className={classes.option}
                onClick={() => history.push('/en_IE/business/showroom')}
              >
                <BusinessIcon />
                <Box component="div" className={classes.optionName}>
                  {isRebranding() ? 'Business lease' : 'Business Lease'}
                </Box>
              </Box>
            </Grid>

            <Grid item={true} xs={4}>
              <Box
                data-testid="home-company-link"
                component="div"
                className={classes.option}
                onClick={() => history.push('/en_IE/showroom?driverCase=true')}
              >
                <CompanyCarIcon />
                <Box component="div" className={classes.optionName}>
                  {isRebranding() ? 'Company car lease' : 'Company Car Lease'}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item={true} sm={1} md={2} />
    </Grid>
  )
}

export default Home
