import axios, { CancelTokenSource } from 'axios'

import { DISCARDED_CALCULATION_REQUEST_MESSAGE } from '../constants/api'
import { AccessoriesResponse } from '../model/AccessoriesResponse'
import { Calculation } from '../model/Calculation'
import { ContractOptionsType } from '../model/ContractOptionsType'
import { ImaginSwatches } from '../model/ImaginSwatches'
import { Profile } from '../model/Profile'
import { PublicOrderFormData } from '../model/PublicOrderFormData'
import { Service } from '../model/Quote'
import { ShowroomFiltersResponse } from '../model/ShowroomFiltersResponse'
import { ShowroomQuote } from '../model/ShowroomQuote'
import { VehicleConfiguration } from '../model/VehicleConfiguration'
import { attachDdSessionId } from './ddSessionUtils'
import { getLangCountryFromLocale } from './locale'
import { buildUrl, serializeParams } from './urlBuilder'

interface CalculationParams {
  locale: string
  contractOptions: Record<string, string>
  savedConfiguration?: boolean
  savedPolicyConfiguration?: boolean
  configHash?: string
  accessories?: string
  services?: string
  temporaryQuote?: boolean
}

const config = {
  ...{ headers: { Pragma: 'no-cache' } },
  baseURL: process.env.REACT_APP_GLQS_API,
  paramsSerializer: serializeParams,
}
const apiClient = axios.create(config)

// Add a request interceptor for specific domain requests
apiClient.interceptors.request.use(attachDdSessionId)

let submitCalculationCancelToken: CancelTokenSource

const countryFromLocale = (locale: string): string => {
  return getLangCountryFromLocale(locale).country
}

const languageFromLocale = (locale: string): string => {
  return getLangCountryFromLocale(locale).language.toLowerCase()
}

const apiClientService = {
  setAuthorizationToken: (token: string) => {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  setCaseIdParameter: (caseId: string) => {
    const params = apiClient.defaults.params || {}
    params.caseId = caseId
    apiClient.defaults.params = params
  },
  setDraftPolicyParameter: (draftPolicy: string) => {
    const params = apiClient.defaults.params || {}
    params.draftPolicy = draftPolicy
    apiClient.defaults.params = params
  },
  setPolicyLevelParameter: (policyLevel: string) => {
    const params = apiClient.defaults.params || {}
    params.policyLevel = policyLevel
    apiClient.defaults.params = params
  },
  setVehicleIdParameter: (vehicleId: string) => {
    const params = apiClient.defaults.params || {}
    params.vehicleId = vehicleId
    apiClient.defaults.params = params
  },
  setCustomerIdParameter: (customerId: string) => {
    const params = apiClient.defaults.params || {}
    params.customerId = customerId
    apiClient.defaults.params = params
  },
  setSubmittedQuoteNumberParameter: (submittedQuoteNumber: string) => {
    const params = apiClient.defaults.params || {}
    params.submittedQuoteNumber = submittedQuoteNumber
    apiClient.defaults.params = params
  },
  setProduct: (product: string) => {
    const params = apiClient.defaults.params || {}
    params.product = product
    apiClient.defaults.params = params
  },
  getAllTranslations: (locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/${countryFromLocale(locale)}/translations/configurator`,
        queryParams: { language: languageFromLocale(locale) },
      }),
    ),
  getSavedConfigurations: (locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/saved-configurations`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getSavedQuote: (identifier: string, locale: string) =>
    apiClient.get<Calculation>(
      buildUrl({
        path: `/api/v2/saved-quotes/id/${identifier}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getSavedQuotes: (locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/saved-quotes`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  setSavedQuotes: (quoteId: string, locale: string) =>
    apiClient.put(
      buildUrl({
        path: `/api/v2/saved-quotes/id/${quoteId}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  deleteSavedQuotes: (quoteId: string, locale: string) =>
    apiClient.delete(
      buildUrl({
        path: `/api/v2/saved-quotes/id/${quoteId}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getSavedConfiguration: (quoteId: string, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/saved-configurations/id/${quoteId}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getSubmittedOrder: (quote: string, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/submitted-order`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
          quoteCalculationNumber: quote,
        },
      }),
    ),
  getQuote: (quote: string, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/quote/${quote}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  submitQuote: (quote: string, locale: string) =>
    apiClient.post(
      buildUrl({
        path: `/api/v2/quote/${quote}/submit`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  deleteSavedConfiguration: (id: string, locale: string) =>
    apiClient.delete(
      buildUrl({
        path: `/api/v2/saved-configurations/id/${id}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getProfile: (locale: string) => {
    if (!locale) {
      return { data: null as Profile }
    }
    return apiClient.get(
      buildUrl({
        path: `/api/v2/${countryFromLocale(locale)}/profile`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    )
  },
  getVehicle: (vehicleId: string | number, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/vehicle/${vehicleId}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getVehicleConfiguration: (
    vehicleId: string | number,
    locale: string,
    configHash?: string,
  ) =>
    apiClient.get<VehicleConfiguration>(
      buildUrl({
        path: `/api/v2/vehicle/${vehicleId}/configuration`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
          configHash,
        },
      }),
    ),
  getContractOptions: (locale: string) =>
    apiClient.get<ContractOptionsType>(
      buildUrl({
        path: `/api/v2/contract-options`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getAccessories: (locale: string) =>
    apiClient.get<AccessoriesResponse>(
      buildUrl({
        path: `/api/v2/accessories`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  getServices: (locale: string) =>
    apiClient.get<Service[]>(
      buildUrl({
        path: `/api/v2/services`,
        queryParams: {
          countryCode: countryFromLocale(locale),
          language: languageFromLocale(locale),
        },
      }),
    ),
  submitCalculation: (
    locale: string,
    contractOptions: Record<string, string>,
    configHash?: string,
    accessories?: string,
    services?: string,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    data?: any,
  ) => {
    !!submitCalculationCancelToken &&
      submitCalculationCancelToken.cancel(DISCARDED_CALCULATION_REQUEST_MESSAGE)
    submitCalculationCancelToken = axios.CancelToken.source()

    return apiClient.post<Calculation>(
      buildUrl({
        path: '/api/v2/calculation',
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
          configHash: configHash,
          mileage: contractOptions.yearlyMileages,
          duration: contractOptions.durations,
          accessories: accessories,
          services: services,
          contractId: contractOptions.contractId || '',
          discountAmount: contractOptions.discountAmount || '',
          commissionAmount: contractOptions.commissionAmount || '',
          downPaymentAmount: contractOptions.downPaymentAmount || '',
          includeMaintenance: contractOptions.includeMaintenance || '',
          temporaryQuote: 'true',
        },
      }),
      { customAccessories: data },
      { cancelToken: submitCalculationCancelToken.token },
    )
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  saveConfiguration: (apiParams: CalculationParams, data: any) => {
    const {
      locale,
      contractOptions,
      accessories,
      configHash,
      services,
      savedConfiguration,
      savedPolicyConfiguration,
    } = apiParams
    return apiClient.post<Calculation>(
      buildUrl({
        path: '/api/v2/calculation',
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
          configHash: configHash,
          mileage: contractOptions.yearlyMileages,
          duration: contractOptions.durations,
          accessories: accessories,
          services: services,
          contractId: contractOptions.contractId || '',
          discountAmount: contractOptions.discountAmount || '',
          commissionAmount: contractOptions.commissionAmount || '',
          downPaymentAmount: contractOptions.downPaymentAmount || '',
          includeMaintenance: contractOptions.includeMaintenance || '',
          savedConfiguration: savedConfiguration ? 'true' : 'false',
          savedPolicyConfiguration: savedPolicyConfiguration ? 'true' : 'false',
          temporaryQuote: 'false',
        },
      }),
      { customAccessories: data },
    )
  },
  getShowroomFilters: (locale: string) =>
    apiClient.get<ShowroomFiltersResponse>(
      buildUrl({
        path: '/api/v2/showroom/filters',
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
        },
      }),
    ),
  getShowroomQuotes: (locale: string, mileage?: string, duration?: string) =>
    apiClient.get<ShowroomQuote[]>(
      buildUrl({
        path: '/api/v2/showroom/quotes',
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
          mileage: mileage || '',
          duration: duration || '',
        },
      }),
    ),
  getVehicleSpecification: (locale: string, vehicleId: string) =>
    apiClient.get<Record<string, string[]>[]>(
      buildUrl({
        path: `/api/v2/vehicle/${vehicleId}/standard-equipment`,
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
          vehicleId: vehicleId,
        },
      }),
    ),
  getShowroomQuote: (
    locale: string,
    vehicleId: number | string,
    mileage: number,
    duration: number,
    quotePriceParameter: string,
    predictedQuotePrice: number,
    signal: AbortSignal,
  ) =>
    apiClient.get<Calculation>(
      buildUrl({
        path: '/api/v2/showroom/quote',
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
          vehicleId: vehicleId || '',
          mileage,
          duration,
          predictedQuotePrice,
          quotePriceParameter,
        },
      }),
      {
        signal,
      },
    ),
  getImaginSwatches: (make: string, colors: string) =>
    apiClient.get<ImaginSwatches>(
      buildUrl({
        host: 'https://cdn.imagin.studio',
        path: '/getPaintSwatches',
        queryParams: {
          make: make,
          paints: colors,
          channelName: 'leaseplan.com',
          tailoring: process.env.REACT_APP_IMAGIN_TAILORING,
          customer: process.env.REACT_APP_IMAGIN_CUSTOMER,
        },
      }),
    ),
  getPublicQuote: (identifier: string, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/public-quote/${identifier}`,
        queryParams: {
          countryCode: countryFromLocale(locale),
        },
      }),
    ),
  getPublicSavedConfiguration: (identifier: string, locale: string) =>
    apiClient.get(
      buildUrl({
        path: `/api/v2/public-quote/${identifier}/calculation`,
        queryParams: {
          countryCode: countryFromLocale(locale),
        },
      }),
    ),
  submitPublicOrderForm: (
    identifier: string,
    locale: string,
    data: PublicOrderFormData,
  ) =>
    apiClient.post(
      buildUrl({
        path: `/api/v2/public-quote/${identifier}/submit`,
        queryParams: {
          countryCode: countryFromLocale(locale),
        },
      }),
      {
        ...data,
        mobile: '',
        comments: '',
      },
    ),
  logout: (locale: string) =>
    locale &&
    apiClient.post(
      buildUrl({
        path: `/api/v2/logout`,
        queryParams: {
          language: languageFromLocale(locale),
          countryCode: countryFromLocale(locale),
        },
      }),
    ),
}

export default apiClientService
