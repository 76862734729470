import { useEffect, useState } from 'react'

import { Quote } from '../model/Quote'
import { useVehicle } from '../utils/reactQueryApi'

export const useValidateFavourite = (locale: string, quote: Quote) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const { data, isSuccess, isError } = useVehicle(
    quote.vehicle.uniqueId,
    locale,
    true,
  )

  useEffect(() => {
    if (isError) {
      setIsInvalid(true)
      setIsChecked(true)
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess && data) {
      setIsInvalid(false)
      setIsChecked(true)
    }
  }, [isSuccess, data])

  return { isInvalid, isChecked }
}
