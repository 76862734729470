import { datadogLogs } from '@datadog/browser-logs'
import { Dispatch, SetStateAction } from 'react'

import apiClient from '../../../utils/api'
import { SavedQuotesState } from '../SavedQuotes'
import { ITEMS_PER_PAGE_MIN } from '../SavedQuotesPagination'

export const initState = (
  locale: string,
  [showPaginator, setShowPaginator]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ],
  [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>],
  [state, setState]: [
    SavedQuotesState,
    Dispatch<SetStateAction<SavedQuotesState>>,
  ],
) => {
  const fromQuote = (page: number, itemsPerPage = state.itemsPerPage) => {
    return itemsPerPage * page
  }
  const toQuote = (page: number, itemsPerPage = state.itemsPerPage) => {
    const result = fromQuote(page) + itemsPerPage
    return result > state.quotes.length ? state.quotes.length : result
  }
  const loadQuotes = async () => {
    try {
      setIsLoading(true)
      const { data: quotes } = await apiClient.getSavedQuotes(locale)
      const maxPage = Math.ceil(quotes.length / state.itemsPerPage)
      const page =
        maxPage < state.currentPage ? state.currentPage - 1 : state.currentPage
      setShowPaginator(quotes.length > ITEMS_PER_PAGE_MIN)
      const newFromQuote = fromQuote(page - 1) + 1
      const newToQuote = newFromQuote + state.itemsPerPage - 1
      setState({
        ...state,
        displayedQuotes: quotes.slice(
          (page - 1) * state.itemsPerPage,
          state.itemsPerPage,
        ),
        quotes,
        fromQuote: newFromQuote,
        toQuote: newToQuote > quotes.length ? quotes.length : newToQuote,
        currentPage: page,
      })
      setIsLoading(false)
    } catch (error) {
      datadogLogs.logger.error(
        `Failed to load saved quotes(${locale}), error: ${error}`,
      )
    }
  }
  return {
    onQuoteDelete: () => {
      loadQuotes()
    },
    onPageSelect: (_: unknown, pg: number) => {
      setState({
        ...state,
        currentPage: pg,
        fromQuote: fromQuote(pg - 1) + 1,
        toQuote: toQuote(pg - 1),
        displayedQuotes: state.quotes.slice(fromQuote(pg - 1), toQuote(pg - 1)),
      })
    },
    quotesReadyEffect: () => {
      loadQuotes()
    },
    showEmptyPage: () => !isLoading && state.quotes.length === 0,
    showQuotes: () => state.quotes.length > 0,
    showSpinner: () => isLoading,
    showPaginator: () => showPaginator,
    getQuotes: () => state.displayedQuotes,
  }
}
