import { Dispatch, SetStateAction } from 'react'

import { VehicleConfig } from '../../context/vehicleConfigContext'

export const initState = (
  vehicleConfig: VehicleConfig,
  descriptionState: [string, Dispatch<SetStateAction<string>>],
  priceState: [number, Dispatch<SetStateAction<number>>],
  showAddButtonState: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const { state, dispatch } = vehicleConfig
  const [description, setDescription] = descriptionState
  const [price, setPrice] = priceState
  const [showAddButton, setShowAddButton] = showAddButtonState
  return {
    onSaveClick: () => {
      if (!description.trim()) {
        setShowAddButton(true)
        setDescription('')
        setPrice(0)
        return
      }
      const newAccessory = {
        id:
          state.customAccessories.length === 0
            ? '1'
            : (
                Math.max(...state.customAccessories.map((i) => Number(i.id))) +
                1
              ).toString(),
        name: description,
        price: { amount: price },
      }
      dispatch({
        type: 'updateCustomAccessory',
        customAccessories: [...state.customAccessories, newAccessory],
        recalculatePrice: true,
      })
      setShowAddButton(true)
      setDescription('')
      setPrice(0)
    },
    onCancelClick: () => {
      setShowAddButton(true)
      setDescription('')
      setPrice(0)
    },
    showAddButton: () => showAddButton,
  }
}
