import { Dispatch, SetStateAction } from 'react'

import { ShowroomFiltersSelection } from '../../../../model/ShowroomFiltersSelection'
import { ShowroomQuote } from '../../../../model/ShowroomQuote'
import { ternaryValue } from '../../../../utils/defaults'
import { Order } from '../../../vehicle-configurator/util/tableHeadSort'
import { Brand, CarVersion } from './BrandsView'
import MakeItem from './MakeItem'

const extractBrandData = (
  quotes: ShowroomQuote[],
  filters = {} as ShowroomFiltersSelection,
): Brand[] => {
  if (!filters.make) {
    return []
  }
  const extracted: Record<string, Record<string, CarVersion[]>> = quotes.reduce(
    (a, i) => {
      const make = i.vehicle.make.code
      const model = i.vehicle.model.code
      const version = {
        name: i.vehicle.version.text,
        quotePrice: i.quotePrice,
        retailPrice: i.vehicle.retailPrice,
        bodyType: i.vehicle.bodyType,
        make,
        model,
        monthlyPrice: i.quotePrice,
        quote: i,
      }
      const r = { ...a }

      if (shouldIncludeItem(filters, i)) {
        if (!r[make]) {
          r[make] = {}
          r[make][model] = [version]
        } else if (!r[make][model]) {
          r[make][model] = [version]
        } else if (
          !r[make][model].some((e: CarVersion) => isAlreadyAdded(e, version))
        ) {
          r[make][model] = [...r[make][model], version]
        }
      }

      return r
    },
    {},
  )
  return Object.keys(extracted).map((i) => ({
    makeName: i,
    models: Object.keys(extracted[i]).map((j) => ({
      name: j,
      versions: extracted[i][j],
    })),
  }))
}

export const shouldIncludeItem = (
  filters: ShowroomFiltersSelection,
  i: ShowroomQuote,
): boolean => {
  return [
    ternaryValue(
      filters.make.length === 0,
      true,
      makeAndModelFilterSelected(
        filters,
        i.vehicle.make.code,
        i.vehicle.model.code,
      ),
    ),
    ternaryValue(
      filters.bodyTypes.length === 0,
      true,
      filters.bodyTypes.map((i1) => i1.code).includes(i.vehicle.bodyType.code),
    ),
    ternaryValue(
      filters.fuelTypes.length === 0,
      true,
      filters.fuelTypes
        .map((i2) => i2.code)
        .includes(i.vehicle.engineFuelType.code),
    ),
    ternaryValue(
      filters.carCategories.length === 0,
      true,
      filters.carCategories
        .map((i3) => i3.code)
        .includes(i.vehicle.carCategory.code),
    ),
    ternaryValue(
      filters.transmissionTypes.length === 0,
      true,
      filters.transmissionTypes
        .map((i4) => i4.code)
        .includes(i.vehicle.transmissionType.code),
    ),
    ternaryValue(
      filters.drivetrains.length === 0,
      true,
      filters.drivetrains
        .map((i5) => i5.code)
        .includes(i.vehicle.drivetrain.code),
    ),
    ternaryValue(
      filters.monthlyPrice == null ||
        (filters.monthlyPrice?.start === filters.monthlyPrice.min &&
          filters.monthlyPrice?.end === filters.monthlyPrice.max),
      true,
      filters.monthlyPrice?.start <= i.quotePrice &&
        i.quotePrice <= filters.monthlyPrice?.end,
    ),
    isCO2(filters, i),
    isGrossTrailerWeightBraked(filters, i),
    isElectricRange(filters, i),
  ].every((i) => i)
}

const isCO2 = (filters: ShowroomFiltersSelection, quote: ShowroomQuote) =>
  ternaryValue(
    filters.co2Emission === null ||
      (!quote.vehicle.co2 &&
        filters.co2Emission.value === filters.co2Emission.max) ||
      filters.co2Emission.value === filters.co2Emission.max,
    true,
    quote.vehicle.co2 <= filters.co2Emission.value,
  )

const isGrossTrailerWeightBraked = (
  filters: ShowroomFiltersSelection,
  quote: ShowroomQuote,
) =>
  ternaryValue(
    [
      quote.vehicle?.grossTrailerWeightBraked === undefined &&
        filters.grossTrailerWeightBraked.value === 0,
    ].some((i) => i),
    true,
    quote.vehicle.grossTrailerWeightBraked >=
      filters.grossTrailerWeightBraked.value,
  )

const isElectricRange = (
  filters: ShowroomFiltersSelection,
  quote: ShowroomQuote,
) =>
  ternaryValue(
    filters.electricRange == null || filters.electricRange.value === 0,
    true,
    quote.vehicle.electricRange >= filters.electricRange.value,
  )

const makeAndModelFilterSelected = (
  filters: ShowroomFiltersSelection,
  make: string,
  model: string,
) => {
  const upperMake = make.toUpperCase()
  let selected = filters.make.includes(upperMake)

  if (
    filters.model.length > 0 &&
    filters.model.map((i) => i.make).includes(upperMake)
  ) {
    const appliedModels = filters.model
      .filter((i) => i.make === upperMake)
      .map((i) => i.model.toUpperCase())
    selected = appliedModels.includes(model.toUpperCase())
  }

  return selected
}

const isAlreadyAdded = (item: CarVersion, current: CarVersion): boolean => {
  return item.name === current.name
}

const toJSXItem = (
  i: Brand,
  index: number,
  list: Brand[],
  order: Order,
  setOrder: Dispatch<SetStateAction<Order>>,
) => (
  <MakeItem
    key={i.makeName}
    item={i}
    index={index}
    list={list}
    order={order}
    setOrder={setOrder}
  />
)

const sortByMakeAndModel = (list: Brand[], order: Order): Brand[] => {
  return [...list]
    .sort((a, b) => a.makeName.localeCompare(b.makeName))
    .map((i: Brand) => {
      return {
        makeName: i.makeName,
        models: [...i.models].sort(
          (
            a: { name: string; versions: CarVersion[] },
            b: { name: string; versions: CarVersion[] },
          ) =>
            (order === 'asc' && a.name.localeCompare(b.name)) ||
            (order === 'desc' && b.name.localeCompare(a.name)),
        ),
      }
    })
}

export { extractBrandData, sortByMakeAndModel, toJSXItem }
