import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import { useQueryParams } from '../../hooks/useQueryParams'
import { getUserLocale } from '../../utils/user'

const RedirectToHome = () => {
  const { isLoading, isAuthenticated, user } = useAuth0()
  const [userLocale, setUserLocale] = useState('')
  const query = useQueryParams()

  useEffect(() => {
    const setDefaultLocale = () => {
      const defaultLocale = getUserLocale(user)
      setUserLocale(defaultLocale)
    }
    if (isAuthenticated && user) {
      setDefaultLocale()
    }
  }, [isAuthenticated, user])

  if (!isLoading && isAuthenticated && userLocale) {
    return <Redirect to={`/${userLocale}/showroom`} />
  }

  if (!isLoading && !isAuthenticated && query.get('enforceLogin') === 'true') {
    return <Redirect to="/auth0" />
  }

  return !isLoading && <Redirect to="/en_IE/view" />
}

export default RedirectToHome
