import React from 'react'

import { useLocaleFromPath } from '../../hooks/useLocaleFromPath'
import { CookieBannerScript } from './CookieBannerScript'
import { RebrandingRedirect } from './RebrandingRedirect'

const HeaderManager: React.FC = ({ children }) => {
  const { locale, language } = useLocaleFromPath()

  return (
    <>
      <RebrandingRedirect locale={locale} />
      <CookieBannerScript language={language} />
      {children}
    </>
  )
}

export default HeaderManager
