import { datadogLogs } from '@datadog/browser-logs'
import { useWindowSize } from '@velocity/ui'
import { ChevronRightIcon, DeleteIcon, ArrowIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect, useState, FC } from 'react'

import VelocityButton from '../../components/button/VelocityButton'
import { DICTIONARY } from '../../constants/dictionary'
import { SCREEN_SIZE } from '../../constants/display'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import useErrorMessages from '../../hooks/useErrorMessages'
import { Quote } from '../../model/Quote'
import { ErrorType } from '../../types/error'
import apiClient from '../../utils/api'
import { history } from '../../utils/historyStore'
import { useSavedConfiguration } from '../../utils/reactQueryApi'
import { capitalize, smartCut } from '../../utils/string'
import { getStyles, isRebranding } from '../../utils/styles'
import { cleanLastPriceCalculation } from '../vehicle-configurator/util/sessionStorageUtil'
import {
  Price,
  PriceButtonSection,
  PriceLabel,
  StyledButton,
  StyledDeleteActionButton,
  StyledPriceInfo,
  StyledPriceInfoBox,
} from './PriceInfo.styled'

const s = () => getStyles().colors.configurator.favorites
const maxLength = 15

interface Props {
  quote: Quote
  refetch: () => void
  invalidFavourite: boolean
  checkedFavourite: boolean
}

const PriceInfo: FC<Props> = ({
  quote,
  refetch,
  invalidFavourite,
  checkedFavourite,
}) => {
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const profile = useProfileContext()
  const currency = useCurrency(locale, profile?.user?.currencyCode)
  const size = useWindowSize()
  const [isMobileMode, setIsMobileMode] = useState(false)

  const [quoteIdentifier, setQuoteIdentifier] = useState<string>()

  const handleConfigurationClick = () => {
    setQuoteIdentifier(quote.identifier)
  }

  const {
    data: savedConfiguration,
    isSuccess: isSavedConfigurationReady,
    isError: isSavedConfigurationError,
    error: savedConfigurationError,
  } = useSavedConfiguration(quoteIdentifier, !!quoteIdentifier, locale)

  useErrorMessages(
    isSavedConfigurationError,
    savedConfigurationError as ErrorType,
    t,
  )

  useEffect(() => {
    const mobileScreen = size.innerWidth < SCREEN_SIZE.MOBILE
    setIsMobileMode(mobileScreen)
  }, [size.innerWidth])

  useEffect(() => {
    if (isSavedConfigurationReady && savedConfiguration) {
      cleanLastPriceCalculation()
      history.push(`/${locale}/vehicle-configurator`, {
        vehicleQuote: savedConfiguration,
      })
    }
  }, [isSavedConfigurationReady, savedConfiguration, locale])

  const handleDeleteConfiguration = async () => {
    try {
      await apiClient.deleteSavedConfiguration(quote.identifier, locale)
      refetch()
    } catch (error) {
      datadogLogs.logger.error(
        `Failed to delete saved configuration for '${quote.identifier}' and '${locale}')`,
      )
    }
  }
  return (
    <StyledPriceInfo>
      <div>
        <StyledPriceInfoBox>
          {!isMobileMode && (
            <StyledDeleteActionButton
              onClick={handleDeleteConfiguration}
              data-testid="deleteQuoteButton"
            >
              <DeleteIcon size="xs" color={s().deleteIcon} />
            </StyledDeleteActionButton>
          )}
          <PriceLabel>
            {capitalize(t(DICTIONARY.RETAIL_PRICE_WITHOUT_OPTIONS))}
          </PriceLabel>
        </StyledPriceInfoBox>
        <Price variant="300" data-e2e-id="quotePrice">
          {!isNaN(quote.vehicle.retailPrice) &&
            currency?.format(quote.vehicle.retailPrice)}
        </Price>
      </div>
      <PriceButtonSection>
        {isRebranding() ? (
          <VelocityButton
            testId="selectButton"
            endIcon={<ArrowIcon size="s" color="white" />}
            onClick={handleConfigurationClick}
            disabled={!checkedFavourite || invalidFavourite}
          >
            {capitalize(smartCut(t(DICTIONARY.SELECT_LABEL), maxLength))}
          </VelocityButton>
        ) : (
          <StyledButton
            data-e2e-id="selectButton"
            variant="contained"
            endIcon={<ChevronRightIcon color="white" size="xs" />}
            color="primary"
            onClick={handleConfigurationClick}
            disabled={!checkedFavourite || invalidFavourite}
          >
            {t(DICTIONARY.SELECT_LABEL)}
          </StyledButton>
        )}
      </PriceButtonSection>
    </StyledPriceInfo>
  )
}

export default PriceInfo
