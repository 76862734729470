import { Tooltip } from '@velocity/ui'
import { CheckIcon } from '@velocity/ui/draft'

import { Option } from '../../../../model/Quote'
import { getStyles } from '../../../../utils/styles'
import TooltipText from '../../components/TooltipText'
import { AppAction, AppState } from '../../reducer/VehicleConfigReducer'
import { CheckedColorBox, ColorBox } from '../ColorItem.styled'
import { onColorBoxClick } from '../colors'

const s = () => getStyles().colors.configurator.colorItem.item

const getBackground = (swatches: Record<string, string>, option: Option) => {
  return (swatches && swatches[option.optionCode]) || option?.colour?.colourHex
}

export const colorItemCheckBox = (
  swatches: Record<string, string>,
  option: Option,
  state: AppState,
  dispatch: (value: AppAction) => void,
) => {
  return (
    <ColorBox
      className="clearBox"
      $itemNotAllowed={false}
      style={{
        background: getBackground(swatches, option),
      }}
      onClick={() => {
        onColorBoxClick(state, dispatch, option)
      }}
    />
  )
}

export const colorItemDisabledCheckBox = (
  toolTipText: string,
  isDisabled: boolean,
  isExcluded: boolean,
  swatches: Record<string, string>,
  option: Option,
) => {
  return (
    <Tooltip text={<TooltipText value={toolTipText} />}>
      <ColorBox
        className="disabledBox"
        $itemNotAllowed={isDisabled || isExcluded}
        style={{
          background: getBackground(swatches, option),
        }}
      />
    </Tooltip>
  )
}

export const colorItemCheckedCheckBox = (
  blsColourConfiguration: boolean,
  swatches: Record<string, string>,
  option: Option,
  state: AppState,
  dispatch: (value: AppAction) => void,
) => {
  return (
    <CheckedColorBox
      className="checkedBox"
      $itemNotAllowed={blsColourConfiguration}
      style={{
        background: getBackground(swatches, option),
      }}
      onClick={() => {
        !blsColourConfiguration && onColorBoxClick(state, dispatch, option)
      }}
    >
      <CheckIcon size="xs" color={s().checkIcon} />
    </CheckedColorBox>
  )
}
