import { ChangeEvent, FC, ReactNode } from 'react'

import QuestionMarkHint, {
  TooltipTextType,
} from '../QuestionMarkHint/QuestionMarkHint'
import { AutocompleteStyled, RootContainer } from './AutocompleteSelect.styled'

interface Props {
  hint: TooltipTextType
  value: unknown[]
  onChange: (event: ChangeEvent<{}>, value: unknown) => void
  renderOption: (option: unknown) => ReactNode
  renderInput: (option: unknown) => ReactNode
  options: unknown[]
  groupBy?: (option: unknown) => string
  getOptionLabel: (option: unknown) => string
  getOptionSelected?: (option: unknown, value: unknown) => boolean
  dataE2eId?: string
}

const AutocompleteSelect: FC<Props> = ({
  hint,
  value,
  onChange,
  options,
  renderOption,
  groupBy,
  getOptionLabel,
  renderInput,
  getOptionSelected,
  dataE2eId,
}) => {
  return (
    <RootContainer>
      <AutocompleteStyled
        data-e2e-id={dataE2eId}
        value={value}
        onChange={onChange}
        groupBy={groupBy}
        multiple={true}
        options={options}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        renderOption={(option) => renderOption(option)}
        filterSelectedOptions={true}
        renderInput={(params) => renderInput(params)}
      />
      <QuestionMarkHint title={hint.title} text={hint.text} />
    </RootContainer>
  )
}

export default AutocompleteSelect
