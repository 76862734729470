import { FC } from 'react'

import {
  Content,
  RootContainer,
  TooltipStyled,
  TooltipTextContent,
  TooltipTextRoot,
  TooltipTextTitle,
} from './QuestionMarkHint.styled'

interface Props {
  title?: string
  text?: string
  htmlText?: {
    __html: string
  }
}

const QuestionMarkHint: FC<Props> = ({ title = '', text, htmlText }) => {
  return (
    <RootContainer>
      <TooltipStyled
        text={<TooltipText title={title} text={text} htmlText={htmlText} />}
      >
        <Content>?</Content>
      </TooltipStyled>
    </RootContainer>
  )
}

export interface TooltipTextType {
  title: string
  text?: string
  htmlText?: {
    __html: string
  }
}

const TooltipText: FC<TooltipTextType> = ({ title, text, htmlText }) => {
  return (
    <TooltipTextRoot>
      {title && <TooltipTextTitle>{title}</TooltipTextTitle>}
      {text && <TooltipTextContent>{text}</TooltipTextContent>}
      {htmlText && (
        <TooltipTextContent
          dangerouslySetInnerHTML={htmlText}
        ></TooltipTextContent>
      )}
    </TooltipTextRoot>
  )
}
export default QuestionMarkHint
