import { UseTranslationResponse } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option, Vehicle } from '../../../model/Quote'
import { getImaginKey, getImaginMultipleImages } from '../../../utils/imagin'
import { VehicleConfig } from '../context/vehicleConfigContext'
import { ImageUrlType, VehicleGalleryProps } from '../VehicleGallery'

export const initState = (
  props: VehicleGalleryProps,
  { state }: VehicleConfig,
  [images, setImages]: [
    ImageUrlType[],
    Dispatch<SetStateAction<ImageUrlType[]>>,
  ],
  [preloadedImages, setPreloadedImages]: [
    HTMLImageElement[],
    Dispatch<SetStateAction<HTMLImageElement[]>>,
  ],
  { t }: UseTranslationResponse<'translation', undefined>,
  [imaginKey, setImaginKey]: [string, Dispatch<string>],
) => {
  const exteriorColours =
    state?.vehicleConfiguration?.configurationOptions?.colours?.exteriorColours
  const vehicleTitle = `${props.vehicle.make.text} ${props.vehicle.model.text} ${props.vehicle.localVersion}`
  const electricRange =
    props.vehicle.electricRange &&
    props.vehicle.electricRange > 0 &&
    `${t(
      DICTIONARY.VEHICLE_RANGE_LABEL,
    )}: ${props.vehicle.electricRange.toString()} ${t(
      DICTIONARY.VEHICLE_RANGE_UNIT,
    )}`
  const getBodyColor = (): string => {
    const exteriorOptionSelected = exteriorColours?.find(
      (colour: Option) => colour.state === 'selected',
    )
    return exteriorOptionSelected?.optionCode || ''
  }
  const isKey = (v: Vehicle): boolean => {
    return (
      getImaginKey(props.vehicle, props.locale, getBodyColor()) !== imaginKey
    )
  }
  return {
    imagePreloadEffect: () => {
      const isImageSetUpdated =
        !images || images.length === 0 || isKey(props.vehicle)
      if (props.vehicle && exteriorColours && isImageSetUpdated) {
        setImaginKey(getImaginKey(props.vehicle, props.locale, getBodyColor()))
        const bodyColour = getBodyColor()
        const imageUrls = getImaginMultipleImages(
          props.vehicle,
          props.locale,
          bodyColour,
        )
        preloadImages(
          imageUrls.map((i: ImageUrlType) => i.original),
          setPreloadedImages,
        )
        setImages(imageUrls)
      }
    },
    onPriceSectionExpandClick: () => {
      props.setPriceSectionExpanded(false)
    },
    getVehicleTitle: () => vehicleTitle,
    getElectricRange: () => electricRange,
    getImageInfo: () => t(DICTIONARY.IMAGE_INFO_MESSAGE),
    getImages: () => images,
    getPreloadedImages: () => preloadedImages,
    showElectricRange: () => !!electricRange,
    showComponent: () => images && !!preloadedImages,
  }
}

const preloadImages = (
  imageUrls: string[],
  callback: Dispatch<SetStateAction<HTMLImageElement[]>>,
) => {
  const promises: Promise<HTMLImageElement>[] = imageUrls.map((i: string) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = i
      img.onload = () => {
        resolve(img)
      }
    })
  })
  Promise.all(promises).then((v: HTMLImageElement[]) => {
    callback(v)
  })
}
