import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import { getStyles } from '../../../utils/styles'

interface Props {
  option: Option
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: '4.4rem',
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  addDescList: {
    marginTop: '0.5em',
    fontSize: '0.75rem',
    paddingLeft: '6.7em',
    display: 'flex',
    flexDirection: 'column',
    '& li': {
      '&::marker': {
        color: getStyles().colors.configurator.colorItem.withOptions.pointer,
      },
    },
  },
}))

const ColorOptionDetails: FC<Props> = ({ option }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <div className={classes.title}>
        {`${t(DICTIONARY.ADDITIONAL_DESCRIPTION_LABEL)}`}
      </div>
      <ul className={classes.addDescList}>
        {option.description &&
          option.description.map((i) => (
            <li key={i}>
              <div></div>
              <div>{i}</div>
            </li>
          ))}
      </ul>
    </>
  )
}

export default ColorOptionDetails
