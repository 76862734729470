import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { NAME_REG_EXP } from '../../constants/validation'
import { capitalize } from '../../utils/string'
import InputField from './InputField'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}

const NameInput: FC<Props> = ({ state, dispatch }) => {
  const { t } = useTranslation()

  const nameValidator = (
    value: string,
    setter: Dispatch<SetStateAction<string>>,
    name: string,
  ) => {
    let error = false

    if (!value) {
      setter(t(DICTIONARY.NAME_REQUIRED))
      error = true
    } else if (!NAME_REG_EXP.test(value)) {
      setter(t(DICTIONARY.NAME_ERROR))
      error = true
    } else {
      setter(null)
    }
    const dispatchType =
      name === 'firstName' ? 'updateFirstNameError' : 'updateLastNameError'
    const newValue =
      name === 'firstName'
        ? { firstNameError: error }
        : { lastNameError: error }
    dispatch({ type: dispatchType, ...newValue })
  }

  return (
    <>
      <InputField
        label={capitalize(t(DICTIONARY.PUBLIC_ORDER_FIRST_NAME_LABEL))}
        name="firstName"
        customHandler={nameValidator}
        field={state.firstName}
        setter={(value) =>
          dispatch({ type: 'updateFirstName', firstName: value })
        }
      />
      <InputField
        label={t(DICTIONARY.PUBLIC_ORDER_LAST_NAME_LABEL)}
        name="lastName"
        customHandler={nameValidator}
        field={state.lastName}
        setter={(value) =>
          dispatch({ type: 'updateLastName', lastName: value })
        }
      />
    </>
  )
}

export default NameInput
