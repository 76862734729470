import { datadogLogs } from '@datadog/browser-logs'
import { WindowSize } from '@velocity/ui'
import { Dispatch, SetStateAction } from 'react'

import { SCREEN_SIZE } from '../../../constants/display'
import apiClient from '../../../utils/api'
import { history } from '../../../utils/historyStore'
import { cleanLastPriceCalculation } from '../../vehicle-configurator/util/sessionStorageUtil'
import { PriceInfoProps } from '../PriceInfo'

export const initState = (
  props: PriceInfoProps,
  locale: string,
  size: WindowSize,
  [isMobileMode, setIsMobileMode]: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  return {
    handleQuoteClick: () => {
      cleanLastPriceCalculation()
      history.push(`/${locale}/quote-summary/${props.quote.calculationNumber}`)
    },
    mobileModeEffect: () => {
      const mobileScreen = size.innerWidth < SCREEN_SIZE.MOBILE
      setIsMobileMode(mobileScreen)
    },
    handleDeleteQuote: async () => {
      try {
        await apiClient.deleteSavedQuotes(props.quote.identifier, locale)
        props.onDelete()
      } catch (error) {
        datadogLogs.logger.error(
          `Failed to delete saved quote(${props.quote.identifier}), error: ${error}`,
        )
      }
    },
    showFormattedCurrency: () => !isNaN(props.quote.vehicle.retailPrice),
    showDesktopView: () => !isMobileMode,
  }
}
