import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import { SCREEN_SIZE } from '../../constants/display'
import { getStyles } from '../../utils/styles'

export const Root = styled(Grid)`
  display: flex;
  @media (min-width: ${SCREEN_SIZE.MOBILE}px) and (max-width: ${SCREEN_SIZE.TABLET}px) {
    flex-direction: column;
  }
`
export const MainContainer = styled(Grid)`
  width: 75%;
  padding-left: 1rem;
  background-color: ${getStyles().colors.showroom.background};
  @media (min-width: ${SCREEN_SIZE.MOBILE}px) and (max-width: ${SCREEN_SIZE.TABLET}px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    width: 100%;
  }
`
export const FiltersContainer = styled(Grid)`
  width: 25%;
  padding-right: 1rem;
  background-color: ${getStyles().colors.showroom.filters.background};
  @media (min-width: ${SCREEN_SIZE.MOBILE}px) and (max-width: ${SCREEN_SIZE.TABLET}px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    display: none;
  }
`
