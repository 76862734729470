import { Color } from '../utils/color'

export const styles = {
  fonts: {
    pdf: {
      page: 'LeasePlan',
      weight: {
        light: 400,
        medium: 600,
        bold: 800,
      },
      itemLabel: { weight: 800 },
      itemValue: { weight: 600 },
      title: { size: 24, font: 'LeasePlan', weight: 800 },
      pageTitle: { size: 17 },
      left: { size: 12 },
      right: { size: 12 },
    },
    velocity: {
      button: {
        fontWeight: 'bold',
        font: 'LeasePlan',
        size: '0.75rem',
      },
      typography: {
        body: 'LeasePlan',
      },
      chip: 'LeasePlan',
      input: 'LeasePlan',
    },
    body: { font: 'LeasePlan', weight: 600, size: '0.875rem' },
    heading: 'LeasePlan',
    vehicleList: {
      commercialLabel: {
        size: '12px',
      },
    },
    title: { weight: 700 },
    publicOrder: {
      savedQuoteDialog: {
        weight: 400,
      },
    },
  },
  colors: {
    velocity: {
      infoNotice: {
        borderLeft: '',
        background: '',
        color: Color.neutral800,
      },
      tooltip: {
        border: '#c4c4c4',
        before: {
          background: '#f6f8fb',
          border: '#c4c4c4',
        },
      },
      autocomplete: {
        focused: Color.midOrange,
      },
      slider: {
        thumb: 'rgba(240, 100, 0, 0.16)',
        track: '#f9c49e',
        color: Color.midOrange,
      },
      iconButton: {
        background: Color.primary400,
        color: Color.neutralWhite,
      },
      button: {
        label: Color.midOrange,
        border: 'rgba(240, 100, 0, 0.5)',
        closeButton: Color.midOrange,
        backgroundImage1: Color.lightOrange,
        backgroundImage2: Color.midOrange,
        background: Color.secondary400,
        hover: {
          border: Color.midOrange,
          background: 'rgba(240, 100, 0, 0.04)',
        },
      },
      select: {
        font: Color.thunder,
        selected: Color.midOrange,
        hoover: Color.midOrange,
      },
    },
    homePage: {
      background: Color.neutralWhite,
      border: Color.neutral300,
      heading: Color.petrolBlue.petrolBlue,
      subHeading: Color.petrolBlue.petrolBlue,
      option: { border: Color.neutral300, background: Color.neutral100 },
    },
    emptyCarListPage: {
      icon: Color.bloodOrange,
    },
    savedConfigurations: {
      icon: Color.bloodOrange,
    },
    home: {
      icon: Color.midOrange,
    },
    topNav: {
      borderBottom: Color.neutral300,
      font: Color.white,
      background: Color.bloodOrange,
      border: Color.white,
      item: { border: Color.orange.orange650, font: Color.white },
      topScroll: {
        background: Color.grey.grey470,
        upIcon: Color.white,
      },
    },
    customer: {
      font: Color.white,
      background: Color.bloodOrange,
      option: { font: Color.black },
    },
    languageSelect: {
      unselected: Color.neutralWhite,
      selected: Color.primary400,
      font: Color.white,
      background: Color.bloodOrange,
      expandButton: Color.neutralWhite,
      option: {
        font: Color.black,
      },
    },
    orderApproved: {
      font: Color.petrolBlue.petrolBlue,
    },
    scheduledMaintenance: {
      font: Color.petrolBlue.petrolBlue,
    },
    serviceInterruption: {
      font: Color.petrolBlue.petrolBlue,
    },
    unauthorisedError: {
      font: Color.petrolBlue.petrolBlue,
    },
    snackMessage: {
      font: Color.white,
      background: Color.grassGreen.grassGreen,
      image: {
        font: Color.grassGreen.grassGreen,
        background: Color.white,
      },
      card: {
        font: Color.white,
        background: Color.red.red650,
      },
      collapse: {
        background: Color.error.error40,
      },
      icon: {
        font: Color.white,
      },
    },
    pdf: {
      font: Color.petrolBlue.petrolBlue,
      footer: {
        note: Color.black,
        line: Color.black,
      },
    },
    spinner: {
      stop1: Color.petrolBlue.petrolBlue20,
      stop2: Color.midOrange,
    },
    useErrorMessage: {
      font: Color.white,
    },
    usePolicyValidationMessages: {
      font: Color.red.red650,
    },
    carInfo: {
      font: Color.grey.grey450,
    },
    priceInfo: {
      background: Color.neutral.neutral70,
      vat: { font: Color.steel.steel60 },
    },
    footer: {
      font: Color.white,
      background: Color.bloodOrange,
    },
    savedQuote: {
      carMissingIcon: Color.midOrange,
      font: Color.petrolBlue.petrolBlue,
      title: Color.midOrange,
      price: { deleteIcon: Color.steel.steel40 },
      carInfo: { deleteIcon: Color.steel.steel40 },
      moreInfo: {
        marker: Color.midOrange,
        button: {
          font: Color.midOrange,
          background: Color.white,
        },
        title: Color.neutral700,
      },
      pagination: {
        details: {
          font: Color.steel.steel,
        },
        itemPage: {
          font: Color.midOrange,
        },
        selected: {
          font: Color.white,
          background: Color.midOrange,
          hover: {
            font: Color.white,
          },
        },
      },
    },
    invalidQuoteError: {
      font: Color.petrolBlue.petrolBlue,
    },
    downloadLink: {
      font: Color.midOrange,
    },
    tileMessage: {
      font: Color.red.red650,
      content: {
        font: Color.petrolBlue.petrolBlue,
      },
    },
    configurator: {
      accessories: {
        deleteIcon: Color.steel.steel40,
      },
      specification: {
        font: Color.steel.steel,
        label: Color.petrolBlue.petrolBlue,
        description: {
          border: Color.thunder,
          item: Color.steel.steel40,
          upIcon: Color.steel.steel,
          downIcon: Color.steel.steel,
        },
        wrapper: Color.steel.steel40,
        upIcon: Color.steel.steel,
        downIcon: Color.steel.steel,
      },
      limetedOptions: {
        font: Color.petrolBlue.petrolBlue,
        background: Color.mist,
      },
      table: {
        background: Color.neutral.neutral20,
        border: Color.thunder,
        row: { border: Color.midOrange },
        description: {
          button: {
            font: Color.midOrange,
          },
          title: {
            font: Color.steel.steel,
          },
          item: {
            font: Color.midOrange,
          },
        },
      },
      favorites: {
        font: Color.thunder,
        background: Color.white,
        deleteIcon: Color.steel.steel40,
        card: Color.neutral.neutral120,
        policyLabel: { background: Color.midOrange },
        moreInfo: {
          marker: Color.midOrange,
          button: {
            font: Color.midOrange,
            background: Color.white,
          },
          title: Color.steel.steel,
        },
      },
      fullScreenBottomNav: Color.mist,
      buttonNav: Color.white,
      glqs_accordion: {
        font: Color.steel.steel,
        background: Color.steel.steel20,
        chevron: Color.steel.steel,
        header: {
          background: Color.yellow.yellow250,
        },
      },
      co2: {
        arrow: {
          font: Color.white,
          label: {
            aPlus: Color.grassGreen.grassGreen,
            a: Color.grassGreen.grassGreen80,
            b: Color.grassGreen.grassGreen80,
            c: Color.freshGreen.freshGreen80,
            d: Color.yellow.yellow300,
            e: Color.yellow.yellow500,
            f: Color.midOrange,
            g: Color.red.red600,
          },
        },
        single: { font: Color.white, background: Color.petrolBlue.petrolBlue },
      },
      services: {
        background: Color.mist,
        font: Color.steel.steel,
      },
      rules: {
        font: Color.petrolBlue.petrolBlue,
        closeButton: Color.steel.steel,
        item: {
          title: Color.steel.steel,
          operator: Color.white,
          and: Color.cyan.cyan450,
        },
        required: {
          font: Color.steel.steel,
          border: Color.steel.steel40,
          background: Color.bloodOrange,
          item: Color.steel.steel,
          operator: { font: Color.white, or: Color.amber.amber450 },
        },
      },
      priceBreakdown: {
        continueButton: {
          saveQuote: Color.midOrange,
          disabledSaveQuote: Color.steel.steel40,
        },
        closeIcon: Color.white,
        context: {
          removeAllButton: {
            font: Color.midOrange,
            weight: 600,
          },
          showAllButton: {
            font: Color.midOrange,
          },
          carHireNote: {
            font: Color.petrolBlue.petrolBlue,
            background: Color.aquaBlue.aquaBlue20,
            alertIcon: Color.petrolBlue.petrolBlue80,
            border: Color.teal.teal250,
            borderLeft: Color.semanticInfoHigh,
          },
          notice: {
            font: Color.steel.steel,
            background: Color.gallery,
            border: Color.steel.steel20,
          },
          separator: Color.black,
        },
        before: { background: Color.white },
        expandIcon: { font: Color.white },
        summary: {
          font: Color.white,
          background: Color.midOrange,
        },
        details: {
          background: Color.mist,
        },
      },
      contractOptions: {
        font: Color.neutral700,
        background: Color.mist,
      },
      optionCheckbox: {
        exclude: {
          background: Color.yellow.yellow370,
        },
        tooltip: {
          background: Color.white,
          font: Color.black,
          text: {
            font: Color.black,
            background: Color.white,
          },
        },
      },
      accordion: Color.steel.steel20,
      accordions: {
        background: Color.mist,
        font: Color.petrolBlue.petrolBlue,
      },
      colorItem: {
        item: {
          upIcon: Color.bloodOrange,
          downIcon: Color.bloodOrange,
          detailLabel: Color.bloodOrange,
          checkIcon: Color.freshGreen.freshGreen80,
        },
        withOptions: {
          pointer: Color.primary500,
          background: Color.midOrange,
          outline: Color.midOrange,
          border: Color.steel.steel20,
          optionsSwitch: {
            font: Color.bloodOrange,
          },
          downIcon: Color.bloodOrange,
          upIcon: Color.bloodOrange,
        },
        box: {
          border: Color.steel.steel20,
          checked: {
            outline: Color.midOrange,
            background: Color.midOrange,
          },
        },
      },
      imageGallery: {
        icon: '#f06400',
        moreInfo: {
          font: Color.midOrange,
          border: Color.midOrange,
        },
      },
      moreInfo: {
        background: Color.steel.steel20,
        drawer: {
          background: Color.white,
          border1: Color.steel.steel40,
          border2: Color.steel.steel40,
          topCloseButton: Color.black,
          closeButton: Color.midOrange,
        },
      },
      sideContainer: {
        background: Color.white,
      },
      accordionSummary: {
        font: Color.steel.steel,
        background: Color.steel.steel20,
        details: {
          background: Color.mist,
        },
        hover: {
          background: Color.gallery,
        },
        before: {
          background: Color.white,
        },
      },
      mainContainer: {
        background: Color.white,
      },
      closeIcon: {
        font: Color.white,
      },
    },
    vehicleGallery: {
      title: {
        font: Color.petrolBlue.petrolBlue,
      },
      electricRangeText: {
        font: Color.petrolBlue.petrolBlue,
      },
      infoText: {
        font: Color.neutral.neutral370,
      },
      rotate360: {
        font: Color.neutral.neutral370,
      },
    },
    publicOrder: {
      input: {
        background: Color.neutral200,
      },
      consent: Color.midOrange,
      form: {
        font: Color.petrolBlue.petrolBlue80,
        background: Color.aquaBlue.aquaBlue20,
        border: Color.teal.teal250,
        infoIcon: Color.petrolBlue.petrolBlue80,
        endIcon: Color.white,
      },
      disclaimer: {
        background: Color.gallery,
        font: Color.steel.steel,
        border: Color.steel.steel,
      },
    },
    quoteSummary: {
      wltp: {
        item: Color.steel.steel20,
        label: { color: Color.neutral.neutral100, weight: 600 },
      },
      template: {
        summary: Color.aquaBlue.aquaBlue20,
        content: { background: Color.white, border: Color.grey.grey350 },
        icon: {
          font: Color.petrolBlue.petrolBlue80,
        },
        review: {
          border: Color.teal.teal250,
          background: Color.aquaBlue.aquaBlue20,
          font: Color.petrolBlue.petrolBlue,
        },
      },
      content: {
        items: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        vehicle: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        price: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        option: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        footer: Color.midOrange,
        contract: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        accessory: {
          item: Color.steel.steel20,
          label: { color: Color.white, weight: 600 },
        },
        service: { weight: 600 },
        summary: {
          font: Color.petrolBlue.petrolBlue80,
          background: Color.aquaBlue.aquaBlue20,
          border: Color.teal.teal250,
          review: Color.aquaBlue.aquaBlue20,
        },
        icon: Color.petrolBlue.petrolBlue80,
      },
    },
    showroom: {
      vehicleListBody: {
        clearFilters: Color.midOrange,
      },
      vehicleList: {
        saveQuoteLinkCopy: Color.midOrange,
        iconButton: {
          background: Color.primary500,
        },
        title: { font: Color.emerald.emerald820 },
        vehicleSubtitle: {
          font: Color.emerald.emerald820,
        },
        vehiclePrice: {
          font: Color.emerald.emerald820,
        },
        button: {
          background1: Color.lightOrange,
          background2: Color.midOrange,
        },
        brandsView: {
          version: { closeIcon: Color.steel.steel40 },
          make: {
            upIcon: Color.black,
            downIcon: Color.black,
          },
        },
        closeButton: Color.steel.steel,
        body: { carMissingIcon: Color.midOrange },
        sorting: {
          label: Color.thunder,
          select: {
            background: Color.white,
            border: Color.steel.steel40,
            focus: Color.white,
          },
        },
        perPage: {
          label: Color.thunder,
          select: {
            border: Color.steel.steel40,
            background: Color.white,
            focus: Color.white,
          },
        },
        quoteSpinner: {
          background: 'rgba(255,255,255,0.5)',
        },
        pagination: {
          details: {
            font: Color.neutral700,
          },
        },
        selectedItem: {
          font: Color.white,
          background: Color.midOrange,
          hover: {
            font1: Color.white,
            font2: Color.midOrange,
          },
        },
        primaryLabel: {
          background: Color.midOrange,
          before: {
            background: Color.midOrange,
          },
        },
        secondaryLabel: {
          background: Color.petrolBlue.petrolBlue60,
          before: {
            background: Color.petrolBlue.petrolBlue60,
          },
        },
        tertiaryLabel: {
          background: Color.steel.steel,
          before: {
            background: Color.steel.steel,
          },
        },
        card: {
          background: Color.white,
          shadow: Color.neutral.neutral5,
        },
        font: Color.petrolBlue.petrolBlue,
        monthlyPrice: {
          font1: Color.bloodOrange,
          font2: Color.black,
        },
        labelText: {
          font: Color.white,
        },
        primaryLabelText: {
          font: Color.white,
        },
      },
      slider: Color.midOrange,
      background: Color.mist,
      modelLine: {
        font1: Color.midOrange,
        font2: Color.steel.steel60,
        border: Color.steel.steel40,
        hover: {
          background: Color.gallery,
        },
        downIcon: {
          font: Color.steel.steel60,
        },
        upIcon: {
          font1: Color.midOrange,
          font2: Color.steel.steel60,
        },
      },
      makeItem: {
        background: Color.white,
        brandName: {
          font: Color.petrolBlue.petrolBlue,
        },
        modelHeading: {
          border: Color.black,
        },
      },
      filters: {
        resetAll: Color.midOrange,
        background: Color.mist,
        icon: {
          background: Color.lightOrange,
          font: Color.white,
        },
      },
      questionMarkHint: {
        tooltip: {
          font: Color.black,
          background: Color.white,
          title: {
            background: Color.sand,
            border: Color.steel.steel20,
          },
        },
        content: {
          background: Color.petrolBlue.petrolBlue20,
          hover: {
            background: Color.midOrange,
            font: Color.white,
          },
        },
      },
      autocomplete: {
        background: Color.white,
      },
    },
  },
}
