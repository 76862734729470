import { makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { Quote } from '../../../../model/Quote'
import { isRebranding } from '../../../../utils/styles'
import { getVehicleMakeAndModel } from '../../util/vehicle'

interface Props {
  quote: Quote
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '6rem',
    justifyContent: 'center',
  },
})

const MakeAndModelSection: React.FC<Props> = ({ quote }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {isRebranding() ? (
        <Text data-e2e-id="VehicleMakeAndModel">
          {getVehicleMakeAndModel(quote)}
        </Text>
      ) : (
        <Headline variant="300" data-e2e-id="VehicleMakeAndModel">
          {getVehicleMakeAndModel(quote)}
        </Headline>
      )}
      {isRebranding() ? (
        <SubText data-e2e-id="LocalVersion">
          {quote.vehicle.localVersion}
        </SubText>
      ) : (
        <Headline variant="100" data-e2e-id="LocalVersion">
          {quote.vehicle.localVersion}
        </Headline>
      )}
    </div>
  )
}

const Text = styled.h3`
  margin: 0;
  line-height: 1.8rem;
  font-size: 32px;
  font-family: 'Chillax';
  font-weight: 500;
`

const SubText = styled.h3`
  margin: 0;
  line-height: 1.8rem;
  font-size: 20px;
  font-family: 'Chillax';
  font-weight: 500;
`

export default MakeAndModelSection
