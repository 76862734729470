import { Dispatch, RefObject, SetStateAction } from 'react'

import {
  addMouseDownEventListener,
  addMouseMoveEventListener,
  addMouseUpEventListener,
} from '../util/eventHandlers'
import { DraggingInfo } from './useIsDragging'

let elementRef: RefObject<HTMLDivElement>
let setDragging: Dispatch<SetStateAction<DraggingInfo>>

export const initState = (
  [draggingInfo, setDraggingInfo]: [
    DraggingInfo,
    Dispatch<SetStateAction<DraggingInfo>>,
  ],
  elementRefP: RefObject<HTMLDivElement>,
) => {
  elementRef = elementRefP
  setDragging = setDraggingInfo
  return { draggingInfo }
}

export const useEffectHandler = () => {
  const { current: element } = elementRef
  let mouseMoveListenerDisposer: () => void
  const onMouseDown = () => {
    mouseMoveListenerDisposer = addMouseMoveEventListener(
      document.body,
      onMouseMove,
    )
  }
  const onMouseUp = () => {
    mouseMoveListenerDisposer?.()
    setDragging({ isDragging: false, dragStartMousePositionX: null })
  }
  const onMouseMove = (e: PointerEvent) => {
    mouseMoveListenerDisposer?.()
    setDragging({ isDragging: true, dragStartMousePositionX: e.pageX })
  }
  const mouseDownListenerDisposer = addMouseDownEventListener(
    element,
    onMouseDown,
  )
  const mouseUpListenerDisposer = addMouseUpEventListener(
    document.body,
    onMouseUp,
  )
  return () => {
    mouseDownListenerDisposer?.()
    mouseUpListenerDisposer?.()
    mouseMoveListenerDisposer?.()
  }
}
