import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useEffect } from 'react'

import Spinner from '../../../components/spinner/Spinner'
import { DICTIONARY } from '../../../constants/dictionary'
import useErrorMessages from '../../../hooks/useErrorMessages'
import { andValue } from '../../../utils/defaults'
import { useShowroomFilters } from '../../../utils/reactQueryApi'
import { useShowroomConfig } from '../context/ShowroomContext'
import { SpinnerContainer } from '../vehicle-list/VehicleList.styled'
import BodyTypesFilter from './BodyTypesFilter'
import CategoryTypesFilter from './CategoryTypesFilter'
import CO2EmissionsFilter from './CO2EmissionsFilter'
import DrivetrainsFilter from './DrivetrainsFilter'
import DurationFilter from './DurationFilter'
import ElectricRangesFilter from './ElectricRangesFilter'
import FuelTypesFilter from './FuelTypesFilter'
import MakeAndModelFilters from './MakeAndModelFilters'
import MileageFilter from './MileageFilter'
import MonthlyPriceFilter from './MonthlyPriceFilter'
import { FiltersReset, Header, Title } from './ShowroomFiltersBody.styled'
import TrailerWeightFilter from './TrailerWeightFilter'
import TransmissionTypesFilter from './TransmissionTypesFilter'

const ShowroomFiltersBody: FC = () => {
  const { state, dispatch } = useShowroomConfig()
  const { locale } = useParams<{ locale: string }>()
  const { t } = useTranslation()
  const {
    data: filters,
    isSuccess: isFiltersLoaded,
    isLoading: isFiltersLoading,
    isError: isFiltersError,
    error: filtersError,
  } = useShowroomFilters(locale)

  useErrorMessages(isFiltersError, filtersError, t)

  useEffect(() => {
    filters &&
      dispatch({
        type: 'updateCO2Emission',
        filters: {
          co2Emission: {
            max: filters.maxCO2,
          },
        },
      })
  }, [dispatch, filters, state.filters.co2Emission.value])

  useEffect(() => {
    isFiltersLoaded &&
      dispatch({ type: 'setFiltersLoaded', filtersError: null })
  }, [isFiltersLoaded, dispatch])

  useEffect(() => {
    isFiltersError &&
      filtersError &&
      dispatch({
        type: 'setFiltersLoaded',
        filtersError: filtersError?.response?.data,
      })
  }, [isFiltersError, filtersError, dispatch])

  return isFiltersLoading ? (
    <SpinnerContainer data-e2e-id="filtersLoader">
      <Spinner size={7} />
    </SpinnerContainer>
  ) : (
    <>
      <Header>
        <Title>{t(DICTIONARY.FILTERS)}</Title>
        <FiltersReset
          onClick={() => {
            dispatch({ type: 'resetFilters' })
          }}
        >
          {t(DICTIONARY.RESET_ALL_FILTERS)}
        </FiltersReset>
      </Header>
      {andValue(
        filters?.models,
        <MakeAndModelFilters filtersResponse={filters} />,
      )}
      {andValue(
        filters?.bodyTypes?.length > 1,
        <BodyTypesFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.fuelTypes?.length > 1,
        <FuelTypesFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.carCategories?.length > 1,
        <CategoryTypesFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.transmissionTypes?.length > 1,
        <TransmissionTypesFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.drivetrains?.length > 1,
        <DrivetrainsFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.maxPrice > 0 &&
          state.profile?.product.displayShowroomMonthlyPricesFilter,
        <MonthlyPriceFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.maxCO2 > 0,
        <CO2EmissionsFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.maxElectricRange > 0,
        <ElectricRangesFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.yearlyMileages,
        <MileageFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.totalDurations,
        <DurationFilter filtersResponse={filters} />,
      )}
      {andValue(
        filters?.maxGrossTrailerWeightBraked,
        <TrailerWeightFilter filtersResponse={filters} />,
      )}
    </>
  )
}

export default ShowroomFiltersBody
