import { FC } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.optionCheckbox.tooltip.text

interface TooltipTextProps {
  value: string
}
const TooltipText: FC<TooltipTextProps> = ({ value }) => {
  return <Root>{value}</Root>
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  color: ${s().font};
  background: ${s().background};
  padding: 9px;
`

export default TooltipText
