import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { isValidEmail } from '../../utils/validation'
import InputField from './InputField'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}

const EmailInput: FC<Props> = ({ state, dispatch }) => {
  const { t } = useTranslation()

  const emailValidator = (
    value: string,
    setter: Dispatch<SetStateAction<string>>,
  ) => {
    let error = false

    if (!value) {
      setter(t(DICTIONARY.EMAIL_REQUIRED))
      error = true
    } else if (!isValidEmail(value)) {
      setter(t(DICTIONARY.ERROR_NOT_EMAIL))
      error = true
    } else {
      setter(null)
    }
    if (error) {
      dispatch({ type: 'updateEmailError', emailError: true })
    } else {
      dispatch({ type: 'updateEmailError', emailError: false })
    }
  }

  return (
    <InputField
      label={t(DICTIONARY.PUBLIC_ORDER_EMAIL_LABEL)}
      name="email"
      customHandler={emailValidator}
      field={state.email}
      setter={(v) => dispatch({ type: 'updateEmail', email: v })}
    />
  )
}

export default EmailInput
