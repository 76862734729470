import { Button, Modal } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { getMessage, getTitle } from '../util/driverContributionDialog'
import { Buttons, Text, Title } from './DriverContributionDialog.styled'

const DriverContributionDialog: FC = () => {
  const [open, setOpen] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  useEffect(() => {
    if (state.priceCalculation?.priceDetail?.contribution?.driver > 0) {
      if (!accepted) {
        dispatch({
          type: 'updateDriverContributionConfirmation',
          driverContributionConfirmationNeeded: true,
        })
        setOpen(true)
      }
    } else {
      setAccepted(false)
      dispatch({
        type: 'updateDriverContributionConfirmation',
        driverContributionConfirmationNeeded: false,
      })
    }
  }, [state.priceCalculation, accepted, dispatch])

  return (
    <div>
      <Modal isOpened={open} onClose={() => setOpen(false)}>
        <Modal.Body>
          <Title>
            {getTitle(
              state.priceCalculation?.priceDetail?.contribution
                ?.contributionType,
              t,
            )}
          </Title>
          <Text>
            {getMessage(
              state.priceCalculation?.priceDetail?.contribution
                ?.contributionType,
              t,
            )}
          </Text>
          <br />
          <Buttons>
            <Button
              variant="outlined"
              data-testid="drv-contrib-back"
              onClick={() => {
                setOpen(false)
              }}
            >
              {t(DICTIONARY.BACK_BUTTON)}
            </Button>
            <Button
              data-testid="drv-contrib-continue"
              variant="outlined"
              onClick={() => {
                setOpen(false)
                setAccepted(true)
                dispatch({
                  type: 'updateDriverContributionConfirmation',
                  driverContributionConfirmationNeeded: false,
                })
              }}
            >
              {t(DICTIONARY.CONTINUE_LABEL)}
            </Button>
          </Buttons>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DriverContributionDialog
