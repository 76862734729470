import { FC } from 'react'

import { useShowroomConfig } from '../context/ShowroomContext'
import { ViewMode } from './VehicleList'
import { sortVehicles } from './vehicleListUtil'
import VehicleTile from './VehicleTile'

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  vehicleList: any[]
  sortingValue: string
  itemsPerPage: number
  viewMode: ViewMode
}

const SectionView: FC<Props> = ({
  vehicleList,
  sortingValue,
  itemsPerPage,
  viewMode,
}) => {
  const { state } = useShowroomConfig()
  const sortByMonthlyPrice =
    state.profile?.product?.displayShowroomMonthlyPrices ||
    state.profile?.product?.sortShowroomByQuotePrice

  return (
    <>
      {sortVehicles(vehicleList, sortingValue, sortByMonthlyPrice)
        .slice(
          state.activePageNumber * itemsPerPage,
          state.activePageNumber * itemsPerPage + itemsPerPage,
        )
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        .map((quote: any) => {
          return (
            <VehicleTile
              key={quote.quotationIdentifier}
              quote={quote}
              viewMode={viewMode}
              animationOnHover={true}
            />
          )
        })}
    </>
  )
}

export default SectionView
