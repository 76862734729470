import { Button } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { capitalize } from '../../../utils/string'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'

const s = () => getStyles().colors.configurator.imageGallery.moreInfo

const MoreInfo: FC = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useVehicleConfig()
  const [elmWidth, setElmWidth] = useState()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const elm: any = React.createRef()

  useEffect(() => {
    if (elm.current?.clientWidth) {
      setElmWidth(elm.current.clientWidth)
    }
  }, [elm])

  return (
    <ButtonStyled
      ref={elm}
      $messageLength={elmWidth || 0}
      variant="outlined"
      onClick={() =>
        dispatch({
          type: 'updateMoreInfoOpen',
          moreInfoOpen: !state.moreInfoOpen,
        })
      }
    >
      {capitalize(t(DICTIONARY.MORE_INFO))}
    </ButtonStyled>
  )
}

interface ButtonStyledProps {
  $messageLength: number
}
const ButtonStyled = styled(Button)<ButtonStyledProps>`
  position: fixed;
  top: ${(props) => props.$messageLength + 80}px;
  left: 0;
  padding: 2px 20px;
  border-top-left-radius: 0;
  min-height: 30px;
  border-top-right-radius: 0;
  transform: rotate(-90deg);
  transform-origin: top left;
  border-color: ${s().border};
  .MuiButton-label {
    color: ${s().font};
  }
  text-transform: ${() => (isRebranding() ? 'none' : 'inherit')};
  @media (max-width: 768px) {
    position: static;
    align-self: flex-end;
    border-radius: 20px;
    transform: rotate(0deg);
  }
`

export default MoreInfo
