const addEventListener = (
  element: HTMLElement,
  eventName: keyof HTMLElementEventMap,
  eventHandler: (event: PointerEvent) => void,
) => {
  const handler = (event: Event) => {
    eventHandler(event as PointerEvent)
  }

  element.addEventListener(eventName, handler)

  return () => {
    element.removeEventListener(eventName, handler)
  }
}

export const addMouseDownEventListener = (
  element: HTMLElement,
  eventHandler: (event: PointerEvent) => void,
) => addEventListener(element, 'pointerdown', eventHandler)

export const addMouseUpEventListener = (
  element: HTMLElement,
  eventHandler: (event: PointerEvent) => void,
) => addEventListener(element, 'pointerup', eventHandler)

export const addMouseMoveEventListener = (
  element: HTMLElement,
  eventHandler: (event: PointerEvent) => void,
) => addEventListener(element, 'pointermove', eventHandler)
