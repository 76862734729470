import { FormField } from '@velocity/ui'
import styled from 'styled-components'

import { Color } from '../../utils/color'

export const InfoLines = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`
export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const FormFieldStyled = styled(FormField)`
  width: 48%;
  .MuiInputBase-input {
    height: 36px;
    font-weight: 100;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const Root = styled.div``

export const FormContent = styled.div``

export const InputStyled = styled.input`
  width: 100%;
  font-size: 16px;
  font-family: 'Source Sans 3';
  padding: 4px 12px;
  background-color: transparent;
  font-weight: 400;
  border: 1px solid;
  color: ${Color.neutral600};
  border: 1px solid ${Color.neutral700};
  &:focus-visible {
    outline: none;
    border: 1px solid ${Color.neutral700};
  }
`
