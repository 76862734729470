import { Input } from '@velocity/ui/draft'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { isRebranding } from '../../utils/styles'
import { FormFieldStyled, InputStyled } from './PublicOrderForm.styled'

interface Props {
  label: string
  name: string
  field: string
  setter: (a: string) => void
  customHandler?: (
    a: string,
    b: Dispatch<SetStateAction<string>>,
    name: string,
  ) => void
}

const InputField: FC<Props> = ({
  label,
  name,
  field,
  setter,
  customHandler = null,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const eventHandler = (value: string) => {
    if (customHandler) {
      customHandler(value, setErrorMessage, name)
    }
    setter(value)
  }

  return (
    <FormFieldStyled
      label={label}
      errorMessage={errorMessage}
      required={true}
      inputId={name}
    >
      {isRebranding() ? (
        <InputStyled
          data-testid={`${name}InputField`}
          value={field}
          onBlur={(e) => eventHandler(e.target.value)}
          onChange={(e) => {
            eventHandler(e.target.value)
          }}
        />
      ) : (
        <Input
          data-testid={`${name}InputField`}
          value={field}
          onBlur={(e) => eventHandler(e.target.value)}
          onChange={(e) => eventHandler(e.target.value)}
        />
      )}
    </FormFieldStyled>
  )
}

export default InputField
