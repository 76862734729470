import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import React, { FC, useState } from 'react'

import { getStyles } from '../../utils/styles'
import GlqsAccordionHeader from './GlqsAccordionHeader'

const s = () => getStyles().colors.configurator.glqs_accordion
interface Props {
  caption: string
  expand?: boolean
  colors?: {
    backgroundColor: string
    color: string
  }
  onExpandIcon?: React.ReactNode
  onCollapseIcon?: React.ReactNode
}

const GlqsAccordion: FC<Props> = (props) => {
  const {
    caption,
    expand = false,
    colors = {
      backgroundColor: s().background,
      color: s().font,
    },
    onExpandIcon = (
      <div>
        <ChevronUpIcon size="xs" color={s().chevron} />
      </div>
    ),
    onCollapseIcon = (
      <div>
        <ChevronDownIcon size="xs" color={s().chevron} />
      </div>
    ),
    children,
  } = props
  const [isOpened, setIsOpened] = useState(expand)
  return (
    <>
      <GlqsAccordionHeader
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        caption={caption}
        colors={colors}
        icon={isOpened ? onExpandIcon : onCollapseIcon}
      />
      {isOpened && <div>{children}</div>}
    </>
  )
}

export default GlqsAccordion
