import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import TopScroll from '../../../../components/topnav/TopScroll'
import { useShowroomQuotes } from '../../../../utils/reactQueryApi'
import { Order } from '../../../vehicle-configurator/util/tableHeadSort'
import { useShowroomConfig } from '../../context/ShowroomContext'
import {
  extractBrandData,
  sortByMakeAndModel,
  toJSXItem,
} from './brandsViewUtil'

const RootContainer = styled.ul`
  padding: 0;
`

export interface CarVersion {
  name: string
  quotePrice: number
  retailPrice: number
  monthlyPrice: number
  make: string
  model: string
  bodyType: { code: string }
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  quote: any
}

export interface Brand {
  makeName: string
  models: {
    name: string
    versions: CarVersion[]
  }[]
}

const BrandsView: FC = () => {
  const { state } = useShowroomConfig()
  const { data: vehicleList } = useShowroomQuotes(
    state.locale,
    state?.filters?.mileage?.value?.toString(),
    state?.filters?.duration?.value?.toString(),
  )

  const [order, setOrder] = useState<Order>('asc')
  const [brandsData, setBrandsData] = useState<Brand[]>([])

  useEffect(() => {
    if (vehicleList?.length > 0) {
      setBrandsData(extractBrandData(vehicleList))
    }
  }, [vehicleList])

  useEffect(() => {
    if (vehicleList?.length > 0) {
      setBrandsData(extractBrandData(vehicleList, state.filters))
    }
  }, [vehicleList, state.filters])

  return (
    <>
      {brandsData?.length > 0 && (
        <RootContainer>
          {sortByMakeAndModel(brandsData, order).map((i, index, list) =>
            toJSXItem(i, index, list, order, setOrder),
          )}
        </RootContainer>
      )}
      <TopScroll />
    </>
  )
}

export default BrandsView
