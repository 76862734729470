import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { FC, useEffect } from 'react'

import FrenchDecommissionAlert from '../../components/frenchDecommissionAlert/FrenchDecommissionAlert'
import LiveChatScript from '../../components/utils/LiveChatScript'
import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import { useBrandImages } from '../../hooks/useBrandImages'
import { ShowroomState } from '../../model/ShowroomState'
import { SessionItemEnum } from '../vehicle-configurator/util/sessionStorageUtil'
import {
  ShowroomConfigProvider,
  useShowroomConfig,
} from './context/ShowroomContext'
import ShowroomFilters from './filters/ShowroomFilters'
import { FiltersContainer, MainContainer, Root } from './Showroom.styled'
import VehicleList from './vehicle-list/VehicleList'
import { DefaultSortingOptions } from './vehicle-list/VehiclesSorting'

export const ShowroomPage: FC<RouteComponentProps> = () => {
  const { locale, product } = useParams<{ locale: string; product: string }>()
  const { t } = useTranslation()

  const profile = useProfileContext()
  const { dispatch } = useShowroomConfig()
  const images = useBrandImages()

  useEffect(() => {
    if (locale && profile) {
      dispatch({ type: 'loadData', locale, profile, images })
    }
  }, [locale, profile, images, dispatch])

  useEffect(() => {
    const st = window.sessionStorage.getItem(SessionItemEnum.SHOWROOM_STATE)
    if (st) {
      const parsed = JSON.parse(st) as ShowroomState
      dispatch({
        type: 'setShowroomState',
        filters: parsed.filters,
        activePageNumber: parsed.pageNumber,
        sortingValue: parsed.sortBy as DefaultSortingOptions,
        itemsPerPage: parsed.itemsPerPage,
      })
      window.sessionStorage.removeItem(SessionItemEnum.SHOWROOM_STATE)
    }
  }, [dispatch])

  return (
    <>
      <LiveChatScript />
      <Root container={true}>
        {process.env.REACT_APP_SHOW_FRENCH_DECOMMISSION_NOTICE &&
          profile.user.country === 'FR' && (
            <FrenchDecommissionAlert
              text={t(DICTIONARY.FRENCH_DECOMMISSION_NOTICE)}
            />
          )}
        <FiltersContainer item={true}>
          <ShowroomFilters />
        </FiltersContainer>
        <MainContainer item={true}>
          <VehicleList profile={profile} locale={locale} product={product} />
        </MainContainer>
      </Root>
    </>
  )
}

const Showroom = (props: RouteComponentProps) => (
  <ShowroomConfigProvider>
    <ShowroomPage {...props} />
  </ShowroomConfigProvider>
)
export default Showroom
