import IconButton from '@material-ui/core/IconButton'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import CO2Label from '../../components/co2Label/CO2Label'
import SingleCO2Label from '../../components/co2Label/SingleCO2Label'
import { getHomeLink } from '../../components/navigation/homeLinkUtil'
import Spinner from '../../components/spinner/Spinner'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import useErrorMessages from '../../hooks/useErrorMessages'
import usePolicyValidationMessages from '../../hooks/usePolicyValidationMessages'
import { Calculation } from '../../model/Calculation'
import { GenericError } from '../../model/Error'
import { Option } from '../../model/Quote'
import { ErrorType } from '../../types/error'
import apiClientService from '../../utils/api'
import { getCountryConfig } from '../../utils/config'
import { history } from '../../utils/historyStore'
import { useVehicle } from '../../utils/reactQueryApi'
import AccessoryDialog from './accessories/AccessoryDialog'
import DriverContributionDialog from './components/DriverContributionDialog'
import MoreInfoDrawer from './components/MoreInfoDrawer'
import {
  useVehicleConfig,
  VehicleConfigProvider,
} from './context/vehicleConfigContext'
import usePriceCalculation from './hooks/usePriceCalculation'
import PriceBreakdown from './price/PriceBreakdown'
import SavedQuoteDialog from './price/SavedQuoteDialog'
import SideContainer from './SideContainer'
import { initState } from './util/vehicleConfigurator'
import {
  CloseIconStyled,
  MainContainer,
  MoreInfoBlurBackground,
  Root,
  SideContainerStyled,
  SpinnerBox,
} from './VehicleConfigurator.styled'
import VehicleGallery from './VehicleGallery'

export const VehicleConfiguratorPage: React.FC<RouteComponentProps> = () => {
  const { locale } = useParams<{ locale: string }>()
  const [vehicleId, setVehicleId] = useState<string>()
  const [quote, setQuote] = useState<Calculation>()
  const { state, dispatch } = useVehicleConfig()
  const [isSavedQuote, setIsSavedQuote] = useState(false)
  const st = initState(useState(true))
  const { t } = useTranslation()
  const profile = useProfileContext()
  const config = getCountryConfig(locale)

  interface HistoryState {
    vehicleQuote: Calculation
  }
  const policyValidationMessages =
    state?.priceCalculation?.policyValidationMessages

  const {
    data: vehicle,
    isSuccess: isVehicleReady,
    isError,
    error,
  } = useVehicle(vehicleId, locale, !!vehicleId)
  const setQuoteDetails = (restoredQuote: Calculation) => {
    apiClientService.setVehicleIdParameter(restoredQuote.vehicleId)
    window.sessionStorage.setItem(
      'lastPriceCalculation',
      JSON.stringify(restoredQuote),
    )
    setVehicleId(restoredQuote.vehicleId)
    setIsSavedQuote(true)
    setQuote(restoredQuote)
  }

  useEffect(() => {
    const lastPriceCalculation = JSON.parse(
      window.sessionStorage.getItem('lastPriceCalculation'),
    )
    const stateQuote = (history?.location?.state as HistoryState)?.vehicleQuote

    if (
      stateQuote &&
      lastPriceCalculation &&
      stateQuote.vehicleId !== lastPriceCalculation.vehicleId
    ) {
      setQuoteDetails(stateQuote)
    } else if (lastPriceCalculation) {
      setQuoteDetails(lastPriceCalculation)
    } else if (stateQuote) {
      setQuoteDetails(stateQuote)
    } else {
      history.push(getHomeLink(profile, locale))
    }
  }, [locale, profile])

  const decimals = 2
  const currency = useCurrency(locale, profile?.user?.currencyCode, decimals)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = useCallback(
    (key: number) => (
      <IconButton
        onClick={() => {
          closeSnackbar(key)
        }}
      >
        <CloseIconStyled />
      </IconButton>
    ),
    [closeSnackbar],
  )

  useEffect(() => {
    if (state.errors?.length > 0) {
      state.errors.forEach((errorItem: GenericError) => {
        let message =
          (errorItem.message && t(errorItem.message)) ||
          'Something went wrong, please retry later'
        const errorOptions = errorItem.details?.options
        const vehicleOptions = state.vehicle?.options
        if (errorOptions?.length > 0 && vehicleOptions) {
          const options = vehicleOptions.filter((option: Option) =>
            errorOptions.includes(option.optionId.toString()),
          )
          const optionsNames = options
            ?.map((option: Option) => option.manufacturerName)
            .join(', ')
          message = `${message}: ${optionsNames}`
        }
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          preventDuplicate: true,
          persist: true,
          onClose: () => {
            dispatch({ type: 'removeError', id: state.errors[0].trace })
          },
          action,
        })
      })
    }
  }, [state, enqueueSnackbar, t, dispatch, action])

  useEffect(() => {
    if (profile) {
      dispatch({ type: 'updateProfile', profile })
    }
  }, [profile, locale, dispatch])

  usePriceCalculation(quote, isSavedQuote, setIsSavedQuote)
  usePolicyValidationMessages(
    policyValidationMessages,
    profile?.product?.publicProduct,
    t,
  )
  useErrorMessages(isError, error as ErrorType, t)
  return (
    <>
      <DriverContributionDialog />
      <AccessoryDialog />
      <SavedQuoteDialog />
      <MoreInfoDrawer />
      {state.moreInfoOpen && <MoreInfoBlurBackground />}
      <Root container={true}>
        {state.isPageLoading && (
          <SpinnerBox
            display="flex"
            height="calc(100vh - 32.3rem)"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size={10} />
          </SpinnerBox>
        )}
        {isVehicleReady && (
          <>
            <MainContainer item={true}>
              <VehicleGallery
                setPriceSectionExpanded={st.updatePriceSectionExpanded}
                vehicle={vehicle}
                isPriceSectionExpanded={st.isPriceSectionExpanded()}
                locale={locale}
              />
              {vehicle.efficiencyLabel?.text && (
                <CO2LabelContainer
                  isPriceExpanded={st.isPriceSectionExpanded()}
                >
                  <CO2Label
                    co2Band={vehicle.efficiencyLabel.text}
                    co2Value={vehicle.wltpCO2}
                  />
                </CO2LabelContainer>
              )}
              {config.showSingleCO2Label && vehicle.wltpCO2 > 0 && (
                <SingleCO2LabelContainer
                  isPriceExpanded={st.isPriceSectionExpanded()}
                >
                  <SingleCO2Label selectedValue={vehicle.wltpCO2} />
                </SingleCO2LabelContainer>
              )}
              <PriceBreakdown
                currency={currency}
                isPriceSectionExpanded={st.isPriceSectionExpanded()}
                setPriceSectionExpanded={st.updatePriceSectionExpanded}
              />
            </MainContainer>
            <SideContainerStyled item={true}>
              <SideContainer vehicle={vehicle} currency={currency} />
            </SideContainerStyled>
          </>
        )}
      </Root>
    </>
  )
}

const VehicleConfigurator = (props: RouteComponentProps) => (
  <VehicleConfigProvider>
    <VehicleConfiguratorPage {...props} />
  </VehicleConfigProvider>
)

interface CO2LabelContainerProps {
  isPriceExpanded: boolean
}

const right5 = 5
const right4 = 4
const CO2LabelContainer = styled.div<CO2LabelContainerProps>`
  position: fixed;
  bottom: ${(props) => (props.isPriceExpanded ? '58vh' : '12rem')};
  right: calc(40% + ${(props) => (props.isPriceExpanded ? right5 : right4)}%);
  @media (max-width: 768px) {
    display: flex;
    left: calc(60% - 12%);
    right: 0rem;
    bottom: 33rem;
    justify-content: flex-end;
    margin-right: 1em;
  }
`
const SingleCO2LabelContainer = styled.div<CO2LabelContainerProps>`
  position: fixed;
  bottom: ${(props) => (props.isPriceExpanded ? '58vh' : '12rem')};
  right: calc(40% + ${(props) => (props.isPriceExpanded ? right5 : right4)}%);
  @media (max-width: 768px) {
    display: flex;
    left: calc(60% - 12%);
    right: 0rem;
    bottom: calc(100vh / 2 + 30px);
    justify-content: flex-end;
    margin-right: 1em;
  }
`

export default VehicleConfigurator
