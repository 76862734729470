import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { iGen } from '../../../utils/indexGen'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  contractDetails: Entry[]
  parentStyles: typeof pdfDocStyles
}

const ContractDetailsSection: React.FC<Props> = ({
  contractDetails,
  parentStyles,
}) => {
  const { t } = useTranslation()
  return (
    !!contractDetails.length && (
      <View wrap={false}>
        <Text style={parentStyles.subTitle}>
          {t(DICTIONARY.QUOTE_SUMMARY_CONTRACT_DETAILS_LABEL)}
        </Text>
        {contractDetails.map((contractDetail, index) => (
          <View style={parentStyles.row} key={iGen(index)}>
            <Text style={parentStyles.description}>
              {t(contractDetail.description)}
            </Text>
            <Text style={parentStyles.quarter} />
            <Text style={parentStyles.quarter} />
            <Text style={parentStyles.value}>{contractDetail.value}</Text>
          </View>
        ))}
      </View>
    )
  )
}

export default ContractDetailsSection
