import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const f = () => getStyles().fonts.publicOrder.savedQuoteDialog
const s = () => getStyles().colors.publicOrder

export const Title = styled.h2`
  text-align: center;
  font-weight: ${f().weight};
`

export const Text = styled.div`
  font-weight: ${f().weight};
  padding-bottom: 5px;
`

export const ViewButtonStyled = styled(Button)`
  min-width: 36px;
  min-height: 36px;
  border-radius: 36px;
  padding: 0;
  margin-left: 10px;

  display: inline-flex;
  justify-content: center;

  & .MuiButton-startIcon {
    margin: 0;
  }

  & .MuiSvgIcon-root {
    font-size: 24px;
  }
`
export const InfoLines = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
`

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`
export const StyledInput = styled.input`
  font-family: 'Source Sans 3';
  font-size: 16px;
  width: 100%;
  background: transparent;
  padding: 8px 16px;
  background-color: ${s().input.background};
`
