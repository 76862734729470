import { useVelocityContext } from '@velocity/ui'
import { LoveIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import React from 'react'

import LoveIconAyvens from '../../assets/images/heart.png'
import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import { capitalize } from '../../utils/string'
import { isRebranding } from '../../utils/styles'
import { StyledNavItem, StyledNavItemText } from './TopNav.styled'

interface Props {
  locale: string
}

const FavouritesLink: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation()
  const profile = useProfileContext()
  const ctx = useVelocityContext()

  return profile?.user?.canSaveConfigurations ||
    profile?.user?.canSavePolicyConfigurations ? (
    <StyledNavItem>
      <Link
        to={`/${locale}/favourites`}
        title={t(DICTIONARY.MY_FAVOURITES_LABEL)}
      >
        {isRebranding() ? (
          <img src={LoveIconAyvens} alt="favorites" />
        ) : (
          <LoveIcon size="s" color={ctx.theme.styling.gray.white} />
        )}
        <StyledNavItemText>
          {capitalize(t(DICTIONARY.MY_FAVOURITES_LABEL))}
        </StyledNavItemText>
      </Link>
    </StyledNavItem>
  ) : null
}

export default FavouritesLink
