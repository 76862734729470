import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { CloseIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.priceBreakdown

export const PriceRoot = styled.div`
  position: relative;
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`

export const AccordionStyled = styled(Accordion)`
  position: fixed;
  width: 60%;
  bottom: 0;
  @media (max-width: 768px) {
    width: 100%;
    position: static;
  }
  .MuiAccordionSummary-root {
    min-height: 60px;
  }

  .MuiAccordionSummary-content.Mui-expanded,
  &.Mui-expanded {
    margin: 0;
  }

  &:before {
    background: ${s().before.background};
  }

  &.Mui-expanded:before {
    opacity: 1;
  }

  .MuiAccordionSummary-expandIcon {
    color: ${s().expandIcon.font};
  }

  .MuiAccordionSummary-content div {
    font-weight: ${getStyles().fonts.title.weight};
  }

  .MuiAccordionSummary-content div span:first-child {
    font-size: 1.2rem;
  }

  .MuiAccordionSummary-content > span {
    padding-top: 5px;
    font-weight: normal;
    font-size: 0.8rem;
    @media (max-width: 768px) {
      width: 5rem;
      margin-left: auto;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
  }
`

export const PriceBreakdownAccordionLabel = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

export const AccordionSummaryStyled = styled(AccordionSummary)`
  background: ${s().summary.background};
  color: ${s().summary.font};

  &:hover {
    cursor: pointer;
  }
`

export const AccordionDetailsStyled = styled(AccordionDetails)`
  flex-direction: column;
  background: ${s().details.background};
  padding: 0;
`
export const CloseIconStyled = styled(CloseIcon)`
  transform: scale(0.8);
`
export const ExpandLessIconStyled = styled(ExpandLess)`
  transform: scale(1.4);

  @media (max-width: 768px) {
    transform: rotate(180deg), scale(1.4);
  }
`
