import { makeStyles, Theme } from '@material-ui/core'
import React, { FC } from 'react'

import { getStyles } from '../../utils/styles'

interface Props {
  isOpened: boolean
  setIsOpened: (a: boolean) => void
  caption: string
  colors: {
    backgroundColor: string
    color: string
  }
  icon: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor:
      getStyles().colors.configurator.glqs_accordion.header.background,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}))

const GlqsAccordionHeader: FC<Props> = ({
  isOpened,
  setIsOpened,
  caption,
  colors,
  icon,
}) => {
  const classes = useStyles()

  return (
    <div
      style={{ backgroundColor: colors.backgroundColor, color: colors.color }}
      className={classes.root}
      onClick={() => {
        const state = !isOpened
        setIsOpened(state)
        icon = { icon }
      }}
    >
      <div>{caption}</div>
      {icon}
    </div>
  )
}

export default GlqsAccordionHeader
