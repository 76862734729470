import { FC } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.configurator.co2.single

interface Props {
  selectedValue: number
}

const SingleCO2Label: FC<Props> = ({ selectedValue }) => {
  return (
    <Root>
      <Body>
        <CO2Value
          data-e2e-id="co2-value"
          dangerouslySetInnerHTML={{
            __html: `${selectedValue}g CO<sub>2</sub>/km`,
          }}
        />
      </Body>
      <Triangle />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
`
const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
  padding-left: 0.2em;
  font-size: 1em;
  color: ${s().font};
  background-color: ${s().background};
  height: 1.6rem;
  width: 6.5rem;
`
const Triangle = styled.div`
  height: 0;
  width: 0;
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-left: 1rem solid ${s().background};
`
const CO2Value = styled.div`
  width: 100%;
  align-items: end;
  display: flex;
  justify-content: end;
  white-space: nowrap;
  padding-right: 2px;
  font-size: 0.8em;
  text-shadow: 1px 1px 1px black;
`
export default SingleCO2Label
