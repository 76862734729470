import { FC } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () =>
  getStyles().colors.configurator.priceBreakdown.context.carHireNote

const VelocityInfoIcon: FC = () => {
  return (
    <Root
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.0001 8.81816C11.498 8.81816 11.091 8.41115 11.091 7.90907C11.091 7.407 11.498 6.99998 12.0001 6.99998C12.5022 6.99998 12.9092 7.407 12.9092 7.90907C12.9092 8.41115 12.5022 8.81816 12.0001 8.81816ZM12.0001 17.4545C11.498 17.4545 11.091 17.0475 11.091 16.5454V11.0909C11.091 10.5888 11.498 10.1818 12.0001 10.1818C12.5022 10.1818 12.9092 10.5888 12.9092 11.0909V16.5454C12.9092 17.0475 12.5022 17.4545 12.0001 17.4545Z"
        fill="currentColor"
      ></path>
    </Root>
  )
}

const Root = styled.svg`
  width: 24px;
  color: ${s().alertIcon};
`

export default VelocityInfoIcon
