import { Backdrop, Drawer, makeStyles, Theme } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC, useEffect, useMemo, useState } from 'react'

import { DICTIONARY, DISPLAY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import useErrorMessages from '../../hooks/useErrorMessages'
import { VoVehicle } from '../../model/VoVehicle'
import { ErrorType } from '../../types/error'
import {
  useAccessories,
  useContractOptions,
  useServices,
} from '../../utils/reactQueryApi'
import { filterServices } from '../quoteSummary/util/service'
import OptionsTable from './components/OptionsTable'
import { useVehicleConfig } from './context/vehicleConfigContext'
import ContractOptions from './contract-options/ContractOptions'
import { extractDefaults } from './contract-options/contractOptionsUtil'
import RulesPopup from './rules/RulesPopup'
import {
  accessoriesTab,
  colorsTab,
  limitedOptions,
  servicesTab,
  unlimitedOptions,
} from './util/sideContainerUtil'
import VehicleSpecification from './vehicle-specification/VehicleSpecification'
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from './VehicleConfigurator.styled'

interface Props {
  vehicle: VoVehicle
  currency: Intl.NumberFormat
}

const useStyles = makeStyles((theme: Theme) => ({
  backdropRoot: {
    zIndex: theme.zIndex.drawer - 1,
  },
  paperAnchorRight: {
    right: 'auto',
    width: '40%',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  expanded: {
    margin: 0,
  },
  root: {
    color: 'red',
  },
}))

const SideContainer: FC<Props> = ({ vehicle, currency }) => {
  const [loadCounter, setLoadCounter] = useState(0)
  const { locale } = useParams<{ locale: string }>()
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()
  const {
    data: accessories,
    refetch: accessoriesRefetch,
    isError: isErrorAccessories,
    error: errorAccessories,
  } = useAccessories(vehicle?.uniqueId, locale)
  useErrorMessages(isErrorAccessories, errorAccessories as ErrorType, t)
  const {
    data: services,
    refetch: servicesRefetch,
    isError: isErrorServices,
    error: errorServices,
  } = useServices(vehicle?.uniqueId, locale)
  useErrorMessages(isErrorServices, errorServices as ErrorType, t)
  const {
    data: contractOptions,
    refetch: contractOptionsRefetch,
    isError: isErrorContractOptions,
    error: errorContractOptions,
  } = useContractOptions(vehicle?.uniqueId, locale)
  useErrorMessages(isErrorContractOptions, errorContractOptions as ErrorType, t)
  const conf = state.vehicleConfiguration?.configurationOptions
  const colors = useMemo(() => conf?.colours || null, [conf?.colours])
  const packOptions = useMemo(() => conf?.packs || [], [conf?.packs])
  const vehicleOptions = useMemo(() => conf?.options || [], [conf?.options])
  const packsAndOptions = useMemo(
    () => [...packOptions, ...vehicleOptions],
    [packOptions, vehicleOptions],
  )
  const servicesTabServices = useMemo(
    () => filterServices(services, DISPLAY.SERVICES),
    [services],
  )
  const isLimitedVehicleConfiguration = useMemo(
    () =>
      contractOptions && contractOptions.limitedVehicleConfiguration != null,
    [contractOptions],
  )
  const colorsAvailable = useMemo(
    () =>
      conf?.colours?.exteriorColours?.length > 0 ||
      conf?.colours?.interiorColours?.length > 0 ||
      conf?.colours?.trims?.length > 0,
    [conf],
  )

  const optionsTabServices = useMemo(
    () => filterServices(services, DISPLAY.OPTIONS_SERVICES),
    [services],
  )

  const profile = useProfileContext()

  const classes = useStyles()

  useEffect(() => {
    if (
      locale &&
      vehicle &&
      currency &&
      accessories &&
      contractOptions &&
      state &&
      loadCounter === 0
    ) {
      setLoadCounter((x) => x + 1)

      dispatch({
        type: 'loadData',
        locale: locale,
        vehicle: vehicle,
        currency,
        accessories,
        contractOptions: {
          ...extractDefaults(contractOptions),
          ...state.contractOptions,
        },
        recalculateOptionPrice: true,
        limitedVehicleConfiguration:
          contractOptions.limitedVehicleConfiguration,
      })
    }
  }, [
    locale,
    vehicle,
    currency,
    accessories,
    contractOptions,
    dispatch,
    services,
    loadCounter,
    state,
  ])

  useEffect(() => {
    if (vehicle?.uniqueId && locale) {
      servicesRefetch()
    }
  }, [vehicle?.uniqueId, locale, servicesRefetch])

  useEffect(() => {
    if (vehicle) {
      accessoriesRefetch()
    }
  }, [vehicle, accessoriesRefetch])

  useEffect(() => {
    if (vehicle?.uniqueId && locale) {
      contractOptionsRefetch()
    }
  }, [vehicle?.uniqueId, locale, contractOptionsRefetch])

  const isUnlimitedOptions = () =>
    !isLimitedVehicleConfiguration &&
    ((vehicleOptions && vehicleOptions.length > 0) ||
      optionsTabServices?.length > 0)

  const isLimitedOptions = () =>
    isLimitedVehicleConfiguration &&
    (packsAndOptions?.length > 0 || optionsTabServices?.length > 0)
  return (
    <>
      {vehicle && (
        <>
          <AccordionStyled square={true}>
            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
              {t(DICTIONARY.CONTRACT_OPTIONS_LABEL)}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <ContractOptions vehicle={vehicle} />
            </AccordionDetailsStyled>
          </AccordionStyled>
          {colorsAvailable &&
            colorsTab(
              colors,
              vehicle,
              currency,
              t,
              contractOptions?.limitedVehicleConfiguration?.excludePremiumTrims,
            )}
          {isLimitedOptions() &&
            limitedOptions(
              optionsTabServices,
              packsAndOptions,
              contractOptions,
              t,
            )}
          {!isLimitedVehicleConfiguration && packOptions?.length > 0 && (
            <AccordionStyled square={true}>
              <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                {t(DICTIONARY.PACKS)}
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <OptionsTable options={packOptions} />
              </AccordionDetailsStyled>
            </AccordionStyled>
          )}
          {isUnlimitedOptions() &&
            unlimitedOptions(optionsTabServices, vehicleOptions, t)}
          {servicesTabServices &&
            !!servicesTabServices.length &&
            servicesTab(
              state.isExpandedServices,
              dispatch,
              servicesTabServices,
              t,
            )}
          {(state.accessories?.length > 0 ||
            state.mandatoryAccessories?.length > 0 ||
            profile.product.customAccessoriesEnabled) &&
            accessoriesTab(t)}
          <AccordionStyled square={true}>
            <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
              {t(DICTIONARY.VEHICLE_SPECIFICATION_LABEL)}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <VehicleSpecification />
            </AccordionDetailsStyled>
          </AccordionStyled>
          <Drawer
            variant="persistent"
            anchor="right"
            open={state.showOptionRules}
            classes={{ paperAnchorRight: classes.paperAnchorRight }}
          >
            <RulesPopup />
          </Drawer>
          <Backdrop
            open={state.showOptionRules}
            classes={{ root: classes.backdropRoot }}
          />
        </>
      )}
    </>
  )
}

export default SideContainer
