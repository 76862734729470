import { Dispatch } from 'react'

import { DISCARDED_CALCULATION_REQUEST_MESSAGE } from '../../../constants/api'
import { Calculation } from '../../../model/Calculation'
import { Accessory } from '../../../model/Quote'
import apiClient from '../../../utils/api'
import { AppAction } from '../reducer/VehicleConfigReducer'
import { flattenServicesToIds } from '../services/service'

interface SelectionConfig {
  locale: string
  contractOptions?: Record<string, string>
  selectedAccessories: string[]
  selectedServices: Map<string, string>
  configHash: string
  customAccessories: Accessory[]
  vehicleId?: number
  isSavedQuote?: boolean
  savedQuote?: Calculation
  contractOptionList?: Record<string, string>
}

const sendSelections = async (
  config: SelectionConfig,
  dispatch: Dispatch<AppAction>,
) => {
  try {
    dispatch({
      type: 'isCalculationRequestLoading',
      isCalculationRequestLoading: true,
    })
    const { data: priceCalculation } = await apiClient.submitCalculation(
      config.locale,
      config.contractOptionList,
      config.configHash,
      config.selectedAccessories.join(','),
      flattenServicesToIds(config.selectedServices).join(','),
      config.customAccessories,
    )
    window.sessionStorage.setItem(
      'lastPriceCalculation',
      JSON.stringify(priceCalculation),
    )
    dispatch({
      type: 'updatePriceCalculation',
      priceCalculation: priceCalculation,
    })
    dispatch({
      type: 'isCalculationRequestLoading',
      isCalculationRequestLoading: false,
    })
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.message !== DISCARDED_CALCULATION_REQUEST_MESSAGE) {
      dispatch({
        type: 'addError',
        error: error?.response?.data,
      })
      // eslint-disable-next-line no-console
      console.dir(error)
    }
  }
}

const recalculateVehicleConfiguration = async (
  config: SelectionConfig,
  setIsSavedQuote: (isSavedQuote: boolean) => void,
  dispatch: Dispatch<AppAction>,
) => {
  const { data: vehicleConfiguration } =
    await apiClient.getVehicleConfiguration(
      config.vehicleId.toString(),
      config.locale,
      config.configHash,
    )
  if (vehicleConfiguration) {
    dispatch({
      type: 'updateVehicleConfiguration',
      vehicleConfiguration: vehicleConfiguration,
    })
    if (config.isSavedQuote) {
      dispatch({
        type: 'restoreSavedVehicleConfig',
        savedQuoteConfig: {
          configuration: config.savedQuote.configuration,
          selectedOptions: config.savedQuote.selectedOptions,
          mileage: config.savedQuote.mileage?.toString(),
          duration: config.savedQuote.duration?.toString(),
          discountAmount: config.savedQuote.discountAmount?.toString(),
          commissionAmount: config.savedQuote.commissionAmount?.toString(),
          downPaymentAmount: config.savedQuote.downPayment?.toString(),
          services: config.savedQuote.services,
          accessories: config.savedQuote.accessories,
          maintenanceFlag: config.savedQuote.maintenanceFlag?.toString(),
        },
      })
    }
    setIsSavedQuote(false)
  }
  vehicleConfiguration.valid &&
    config.contractOptionList.yearlyMileages &&
    sendSelections(config, dispatch)
}

const recalculatePrice = async (
  config: SelectionConfig,
  dispatch: Dispatch<AppAction>,
) => {
  sendSelections(config, dispatch)
}

export { recalculateVehicleConfiguration, recalculatePrice }
