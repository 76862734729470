import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { Profile } from '../../../model/Profile'
import { Quote } from '../../../model/Quote'
import { createDetail } from './quotation'

export const updateContractDetails = (
  quote: Quote,
  profile: Profile,
): Entry[] => {
  const contractDetails: Entry[] = []
  profile.product.quoteSummaryDisplayContract &&
    contractDetails.push(
      createDetail(
        DICTIONARY.SELECTED_CONTRACT_LABEL,
        quote.contract.contractId,
      ),
    )
  contractDetails.push(createDetail(DICTIONARY.DURATION, quote.duration))
  contractDetails.push(createDetail(DICTIONARY.MILEAGE, quote.mileage))
  contractDetails.push(
    createDetail(DICTIONARY.TOTAL_MILEAGE_LABEL, quote.totalMileage),
  )
  return contractDetails
}
