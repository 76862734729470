import Pagination from '@material-ui/lab/Pagination'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { capitalize } from '../../../utils/string'
import { useShowroomConfig } from '../context/ShowroomContext'
import { ViewMode } from './VehicleList'
import {
  PaginationDetails,
  VehicleCatalogPaginationPane,
} from './VehicleList.styled'
import { getCounterLabelOptions } from './vehicleListUtil'

interface Props {
  itemsPerPage: number
  viewMode: ViewMode
  count: number
}

const VehiclePagination: FC<Props> = ({ itemsPerPage, count }) => {
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handlePageChange = (_: any, pg: number) => {
    dispatch({ type: 'setActivePageNumber', activePageNumber: pg - 1 })
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const { from, to } = getCounterLabelOptions(
    state.activePageNumber,
    count,
    itemsPerPage,
  )

  return (
    <VehicleCatalogPaginationPane>
      <PaginationDetails>
        <span>{capitalize(t(DICTIONARY.SHOWING_LABEL))}</span>
        &nbsp;
        <span>
          <strong>{`${from}-${to}`}</strong>
        </span>
        &nbsp;
        <span>{t(DICTIONARY.OF_LABEL)}</span>
        &nbsp;
        <span>
          <strong>{count}</strong>
        </span>
      </PaginationDetails>
      <Pagination
        count={Math.ceil(count / itemsPerPage)}
        showFirstButton={true}
        showLastButton={true}
        onChange={handlePageChange}
        page={state.activePageNumber + 1}
      />
    </VehicleCatalogPaginationPane>
  )
}

export default VehiclePagination
