import { ShoppingCart } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ShoppingCartAyvens from '../../assets/images/cart.png'
import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import { isRebranding } from '../../utils/styles'
import { StyledNavItem, StyledNavItemText } from './TopNav.styled'

interface Props {
  locale: string
}

const SavedQuotesLink: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation()
  const profile = useProfileContext()
  if (!profile?.user?.canSaveQuotes) {
    return null
  }

  return (
    <StyledNavItem>
      <Link
        to={`/${locale}/saved-quotes`}
        title={t(DICTIONARY.SAVED_QUOTES_LABEL)}
      >
        {isRebranding() ? (
          <img src={ShoppingCartAyvens} alt="favorites" />
        ) : (
          <ShoppingCart />
        )}
        <StyledNavItemText>
          {t(DICTIONARY.SAVED_QUOTES_LABEL)}
        </StyledNavItemText>
      </Link>
    </StyledNavItem>
  )
}

export default SavedQuotesLink
