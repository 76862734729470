import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import React from 'react'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  // Removing strict mode until we update material ui to version where they fixed findDOMNode issue
  <App />,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
