import { Dispatch, FC, SetStateAction, useState } from 'react'

import { Order } from '../util/tableHeadSort'
import { VehicleSpecificationItemWrapper } from './VehicleSpecification.styled'
import VehicleSpecificationCategory from './VehicleSpecificationCategory'
import VehicleSpecificationDescription from './VehicleSpecificationDescription'

interface Props {
  category: string
  descriptionList: string[]
  descriptionOrder: Order
  setDescriptionOrder: Dispatch<SetStateAction<Order>>
}

const VehicleSpecificationItem: FC<Props> = ({
  category,
  descriptionList,
  descriptionOrder,
  setDescriptionOrder,
}) => {
  const [categoryOpen, setCategoryOpen] = useState(false)

  return (
    <VehicleSpecificationItemWrapper>
      <VehicleSpecificationCategory
        label={category}
        isOpen={categoryOpen}
        setCategoryOpen={setCategoryOpen}
      />
      {categoryOpen && (
        <VehicleSpecificationDescription
          order={descriptionOrder}
          setOrder={setDescriptionOrder}
          descriptions={descriptionList}
        />
      )}
    </VehicleSpecificationItemWrapper>
  )
}

export default VehicleSpecificationItem
