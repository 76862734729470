import styled from 'styled-components'

import { isRebranding } from '../../../utils/styles'

export const Buttons = styled.div`
  margin: 0 4rem;
  display: flex;
  justify-content: space-between;
  button {
    text-transform: ${isRebranding() ? 'none' : 'inherit'};
  }
`

export const Title = styled.h2`
  text-align: center;
`

export const Text = styled.div`
  font-weight: 600;
`
