import { useEffect, useState } from 'react'

import { Quote } from '../model/Quote'
import { useSavedQuote, useVehicleConfiguration } from '../utils/reactQueryApi'

export const useValidateSavedQuote = (locale: string, quote: Quote) => {
  const [isPolicyBreached, setIsPolicyBreached] = useState(false)
  const [isConfigurationInvalid, setIsConfigurationInvalid] = useState(false)
  const [isVehicleUnavailable, setIsVehicleUnavailable] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const {
    data: savedQuote,
    isSuccess: isSavedQuoteSuccess,
    isError: isSavedQuoteError,
  } = useSavedQuote(quote.identifier, locale, true)

  const {
    data: vehicleConfiguration,
    isSuccess: isVehicleConfigurationSuccess,
    isError: isVehicleConfigurationError,
  } = useVehicleConfiguration(
    savedQuote?.vehicleId,
    locale,
    savedQuote?.configuration,
    isSavedQuoteSuccess,
  )

  useEffect(() => {
    if (isSavedQuoteError) {
      setIsPolicyBreached(true)
      setIsChecked(true)
    }
  }, [isSavedQuoteError])

  useEffect(() => {
    if (isVehicleConfigurationError) {
      setIsConfigurationInvalid(true)
      setIsVehicleUnavailable(true)
      setIsChecked(true)
    }
  }, [isVehicleConfigurationError])

  useEffect(() => {
    if (isSavedQuoteSuccess && savedQuote) {
      setIsPolicyBreached(!savedQuote.policyValidated)
    }
  }, [isSavedQuoteSuccess, savedQuote])

  useEffect(() => {
    if (isVehicleConfigurationSuccess && vehicleConfiguration) {
      setIsConfigurationInvalid(!vehicleConfiguration.valid)
    }
  }, [isVehicleConfigurationSuccess, vehicleConfiguration])

  useEffect(() => {
    if (isSavedQuoteSuccess && isVehicleConfigurationSuccess) {
      setIsChecked(true)
    }
  }, [isSavedQuoteSuccess, isVehicleConfigurationSuccess])

  return {
    isPolicyBreached,
    isChecked,
    isConfigurationInvalid,
    isVehicleUnavailable,
  }
}
