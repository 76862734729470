import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.services

const StyledRoot = styled.div`
  color: ${s().font};
  background: ${s().background};
  label {
    font-family: '${getStyles().fonts.body.font}';
    font-weight: ${getStyles().fonts.body.weight};
  }
  .MuiInputBase-input {
    font-weight: ${getStyles().fonts.body.weight};
    font-family: '${getStyles().fonts.body.font}';
  }
  padding: 1rem;
  label ~ div {
    font-size: 0.9rem;
  }
  > div {
    margin-bottom: 1rem;
  }
`
export { StyledRoot }
