import { Box } from '@material-ui/core'
import { Button, ChevronRightIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../components/button/VelocityButton'
import Spinner from '../../components/spinner/Spinner'
import TopScroll from '../../components/topnav/TopScroll'
import { DICTIONARY } from '../../constants/dictionary'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import useErrorMessages from '../../hooks/useErrorMessages'
import { ErrorType } from '../../types/error'
import { usePublicQuote } from '../../utils/reactQueryApi'
import { capitalize } from '../../utils/string'
import { getStyles, isRebranding } from '../../utils/styles'
import CarInfo from './CarInfo'
import Consent from './Consent'
import ContractInfo from './ContractInfo'
import Disclaimer from './Disclaimer'
import FormHeader from './FormHeader'
import PersonalInfo from './PersonalInfo'
import { FormContent, Root } from './PublicOrderForm.styled'
import PublicOrderFormDialog from './PublicOrderFormDialog'
import {
  defaultPublicOrderFormState,
  PublicOrderFormReducer,
} from './PublicOrderFormReducer'
import { initState } from './util/publicOrderForm'

const s = () => getStyles().colors.publicOrder.form

const PublicOrderForm: FC = () => {
  const { t } = useTranslation()
  const params = useParams<{
    locale: string
    identifier: string
  }>()
  const { locale, identifier } = params

  const profile = useProfileContext()
  const maxDigits = 2
  const currency = useCurrency(locale, profile?.user.currencyCode, maxDigits)
  const reducer = useReducer(
    PublicOrderFormReducer,
    defaultPublicOrderFormState,
  )
  const [state, dispatch] = reducer
  const {
    refetch,
    data: quote,
    isError: isQuoteError,
    error: errorQuote,
    isFetching: isQuoteLoading,
  } = usePublicQuote(identifier, locale)
  const loading = useState(false)
  const [isLoading] = loading

  useErrorMessages(isQuoteError, errorQuote as ErrorType, t)

  useEffect(() => {
    if (locale) {
      refetch()
    }
  }, [locale, refetch])

  if (isQuoteLoading || !quote?.calculationNumber || !currency) {
    return <></>
  }

  const priceExclVAT = quote.priceDetail.monthly.total
  const quotePrice = priceExclVAT
    ? currency.format(priceExclVAT)
    : currency.format(quote.priceDetail.monthly.totalInclVat)
  const quotePriceLabel = priceExclVAT
    ? t(DICTIONARY.SALES_FORCE_LEAD_DESCRIPTION_QUOTE_PRICE_EXCL_VAT_LABEL)
    : t(DICTIONARY.SALES_FORCE_LEAD_DESCRIPTION_QUOTE_PRICE_INCL_VAT_LABEL)
  const st = initState(reducer, loading, params, profile)

  return (
    <>
      <PublicOrderFormDialog state={state} dispatch={dispatch} />
      {!state.publicOrderFormDialog && (
        <Root>
          {isLoading && (
            <SpinnerBox data-e2e-id="pageLoader">
              <div>
                <Spinner size={10} />
              </div>
            </SpinnerBox>
          )}
          <FormHeader calculationNumber={quote.calculationNumber} />
          <FormContent>
            <PersonalInfo state={state} dispatch={dispatch} />
            <CarInfo
              make={quote.vehicle.make.text}
              model={quote.vehicle.model.text}
              edition={quote.vehicle.localVersion}
            />
            <ContractInfo
              mileage={quote.mileage}
              duration={quote.duration}
              quote={quotePrice}
              quoteLabel={quotePriceLabel}
            />
          </FormContent>
          <Consent state={state} dispatch={dispatch} />
          <ButtonContainer>
            {isRebranding() ? (
              <VelocityButton
                testId="publicOrderFormSubmitButton"
                disabled={st.submitDisabled()}
                onClick={st.onSubmitButtonClick}
                endIcon={<ChevronRightIcon color={s().endIcon} size="xs" />}
              >
                {capitalize(t(DICTIONARY.SUBMIT))}
              </VelocityButton>
            ) : (
              <ButtonStyled
                disabled={st.submitDisabled()}
                onClick={st.onSubmitButtonClick}
                variant="contained"
                endIcon={<ChevronRightIcon color={s().endIcon} size="xs" />}
              >
                {t(DICTIONARY.SUBMIT)}
              </ButtonStyled>
            )}
          </ButtonContainer>
          <Disclaimer />
          <TopScroll />
        </Root>
      )}
    </>
  )
}

const ButtonStyled = styled(Button)`
  width: 20%;
  @media (max-width: 768px) {
    width: inherit;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  .MuiButton-endIcon {
    margin-left: auto;
  }
`
const SpinnerBox = styled(Box)`
  div {
    position: fixed;
    top: 120px;
    z-index: 5;
  }
  display: flex;
  justify-content: center;
  width: 100%;
`
export default PublicOrderForm
