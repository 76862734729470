import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC, useEffect, useState } from 'react'

import EmptyCarListPage from '../../components/emptyCarList/EmptyCarListPage'
import Spinner from '../../components/spinner/Spinner'
import { DICTIONARY } from '../../constants/dictionary'
import { Quote } from '../../model/Quote'
import { capitalize } from '../../utils/string'
import { PaginatorSection, SpinnerContainer, Title } from './SavedQuotes.styled'
import SavedQuotesCard from './SavedQuotesCard'
import SavedQuotesPagination, {
  ITEMS_PER_PAGE_MIN,
} from './SavedQuotesPagination'
import { initState } from './util/savedQuotes'

export const DEFAULT_CURRENT_PAGE = 1
export interface SavedQuotesState {
  currentPage: number
  itemsPerPage: number
  displayedQuotes: Quote[]
  quotes: Quote[]
  fromQuote: number
  toQuote: number
}
const SavedQuotes: FC = () => {
  const state = useState<SavedQuotesState>(defaultState)
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const { closeSnackbar } = useSnackbar()
  const st = initState(
    locale,
    useState<boolean>(false),
    useState<boolean>(false),
    state,
  )

  useEffect(() => {
    closeSnackbar()
  }, [closeSnackbar])

  // eslint-disable-next-line
  useEffect(() => st.quotesReadyEffect(), [])
  return (
    <>
      {st.showEmptyPage() && (
        <EmptyCarListPage
          name="no-saved-quotes-icon"
          text={t(DICTIONARY.NO_SAVED_QUOTES_LABEL)}
        />
      )}
      {st.showSpinner() && (
        <SpinnerContainer>
          <Spinner size={20} data-testid="saved-quote-spinner" />
        </SpinnerContainer>
      )}
      {st.showQuotes() && (
        <>
          <Title>{capitalize(t(DICTIONARY.SAVED_QUOTES_TITLE))}</Title>
          {st.getQuotes().map((quote: Quote) => (
            <SavedQuotesCard
              key={`favourite-${quote.identifier}`}
              quote={quote}
              onDelete={st.onQuoteDelete}
            />
          ))}
          {st.showPaginator() && (
            <PaginatorSection>
              <SavedQuotesPagination
                state={state[0]}
                onPageSelect={st.onPageSelect}
              />
            </PaginatorSection>
          )}
        </>
      )}
    </>
  )
}
const defaultState: SavedQuotesState = {
  currentPage: DEFAULT_CURRENT_PAGE,
  itemsPerPage: ITEMS_PER_PAGE_MIN,
  displayedQuotes: [],
  quotes: [],
  fromQuote: 0,
  toQuote: 0,
}

export default SavedQuotes
