import { Button, Modal } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import {
  Buttons,
  Text,
  Title,
} from '../components/DriverContributionDialog.styled'
import { useVehicleConfig } from '../context/vehicleConfigContext'

const AccessoryDialog: FC = () => {
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  return (
    <div>
      <Modal
        isOpened={state.showAccessoryDialog}
        onClose={() =>
          dispatch({
            type: 'cancelAccessoryDialog',
          })
        }
      >
        <Modal.Body>
          <Title>{t(DICTIONARY.ACCESSORIES_CONFIRMATION_DIALOG_TITLE)}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: t(DICTIONARY.ACCESSORIES_CONFIRMATION_DIALOG_MESSAGE),
            }}
          ></Text>
          <br />
          <Buttons>
            <Button
              data-testid="accessory-dialog-back"
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: 'cancelAccessoryDialog',
                })
              }}
            >
              {t(DICTIONARY.BACK_BUTTON)}
            </Button>
            <Button
              data-testid="accessory-dialog-continue"
              variant="outlined"
              onClick={() =>
                dispatch({
                  type: 'confirmAccessoryDialog',
                })
              }
            >
              {t(DICTIONARY.CONTINUE_LABEL)}
            </Button>
          </Buttons>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AccessoryDialog
