import React from 'react'

import { Calculation } from '../../../model/Calculation'
import { LimitedVehicleConfiguration } from '../../../model/ContractOptionsType'
import { GenericError } from '../../../model/Error'
import { Profile } from '../../../model/Profile'
import { Accessory, Option } from '../../../model/Quote'
import {
  SavedQuoteConfigAction,
  UserChoice,
  VehicleConfiguration,
} from '../../../model/VehicleConfiguration'
import { VoVehicle } from '../../../model/VoVehicle'
import { processVehicleConfiguration } from '../rules/rules'
import { OptionRulesModel } from '../rules/RulesPopup'
import RulesPopupItem from '../rules/RulesPopupItem'

export interface AppAction {
  type: string
  contractOptions?: Record<string, string>
  locale?: string
  currency?: Intl.NumberFormat
  vehicle?: VoVehicle
  vehicleConfiguration?: VehicleConfiguration
  isRulesConfirmed?: boolean
  selectedOption?: Option
  accessory?: Accessory
  accessories?: {
    mandatoryAccessories: Accessory[]
    availableAccessories: Accessory[]
  }
  selectedService?: Map<string, string>
  defaultService?: Map<string, string>
  hasLogoDeCoeur?: boolean
  priceCalculation?: Calculation
  driverContributionConfirmationNeeded?: boolean
  error?: GenericError
  id?: string
  profile?: Profile
  isCalculationRequestLoading?: boolean
  savedQuoteConfig?: SavedQuoteConfigAction
  selectedAccessories?: string[]
  recalculateOptionPrice?: boolean
  customAccessories?: Accessory[]
  quoteIdentifier?: string
  recalculatePrice?: boolean
  moreInfoOpen?: boolean
  limitedVehicleConfiguration?: LimitedVehicleConfiguration
}

export interface AppState {
  contractOptions: Record<string, string>
  selectedServices: Map<string, string>
  defaultServices: Map<string, string>
  selectedAccessories: string[]
  accessories: Accessory[]
  mandatoryAccessories: Accessory[]
  locale: string
  vehicle: VoVehicle
  currency: Intl.NumberFormat
  showOptionRules: boolean
  vehicleConfiguration: VehicleConfiguration
  lastVehicleConfiguration: VehicleConfiguration
  rules: OptionRulesModel[]
  isExpandedServices: boolean
  hasLogoDeCoeur: boolean
  priceCalculation: Calculation
  recalculatePrice: boolean
  recalculateOptionPrice: boolean
  selectedOption: Option
  showAccessoryDialog: boolean
  driverContributionConfirmationNeeded: boolean
  requires: number[][]
  pendingChoices: UserChoice[][]
  errors: GenericError[]
  rulesParentId: string
  isPageLoading: boolean
  profile: Profile
  isCalculationRequestLoading: boolean
  restoredVehicleConfig: boolean
  showSavedQuoteDialog: boolean
  customAccessories: Accessory[]
  quoteIdentifier: string
  temporaryQuote: boolean
  limitedVehicleConfiguration: LimitedVehicleConfiguration
  moreInfoOpen: boolean
}

const AppReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'updateMoreInfoOpen':
      return {
        ...state,
        moreInfoOpen: action.moreInfoOpen,
      }
    case 'updateCustomAccessory': {
      return {
        ...state,
        customAccessories: action.customAccessories,
        temporaryQuote: true,
        recalculatePrice: action.recalculatePrice,
      }
    }
    case 'updateProfile':
      return {
        ...state,
        profile: action.profile,
      }
    case 'startPageLoading':
      return {
        ...state,
        isPageLoading: true,
      }
    case 'endPageLoading':
      return {
        ...state,
        isPageLoading: false,
        temporaryQuote: false,
      }
    case 'loadData': {
      const ids = action.accessories.mandatoryAccessories.map((a) => a.id)
      return {
        ...state,
        locale: action.locale,
        vehicle: action.vehicle,
        currency: action.currency,
        mandatoryAccessories: [...action.accessories.mandatoryAccessories],
        accessories: [
          ...action.accessories.availableAccessories.filter((a) => {
            return !ids.includes(a.id)
          }),
        ],
        selectedServices: new Map([
          ...(state.selectedServices?.entries() || []),
          ...(action.selectedService?.entries() || []),
        ]),
        recalculateOptionPrice: action.recalculateOptionPrice,
        contractOptions: action.contractOptions,
        vehicleConfiguration: {
          ...(action.vehicleConfiguration || state.vehicleConfiguration),
        },
        priceCalculation: {
          ...(action.priceCalculation || state.priceCalculation),
        },
        selectedAccessories: action?.selectedAccessories || [],
        limitedVehicleConfiguration: action.limitedVehicleConfiguration,
      }
    }
    case 'endPriceRecalculate':
      return {
        ...state,
        recalculatePrice: false,
        recalculateOptionPrice: false,
      }
    case 'updateVehicleConfiguration':
      return {
        ...processVehicleConfiguration(
          state,
          action,
          state.selectedOption?.optionId
            ? [
                ...state.rules,
                {
                  optionId: state.selectedOption.optionId,
                  component: React.createElement(RulesPopupItem, {
                    optionId: state.selectedOption.optionId,
                  }),
                },
              ]
            : [
                ...state.rules,
                {
                  optionId: 0,
                  component: React.createElement(RulesPopupItem, {
                    optionId: 0,
                  }),
                },
              ],
        ),
      }
    case 'removeAllSelections':
      return {
        ...state,
        selectedOption: null,
        selectedAccessories: [],
        customAccessories: [],
        selectedServices: state.defaultServices,
        recalculateOptionPrice: true,
        vehicleConfiguration: {
          ...state.vehicleConfiguration,
          configuration: '',
        },
        priceCalculation: null,
      }
    case 'updatePriceCalculation':
      return {
        ...state,
        priceCalculation: action.priceCalculation,
      }
    case 'cancelAccessoryDialog':
      return {
        ...state,
        selectedAccessories: state.selectedAccessories.slice(
          0,
          state.selectedAccessories.length - 1,
        ),
        showAccessoryDialog: false,
      }
    case 'confirmAccessoryDialog':
      return {
        ...state,
        showAccessoryDialog: false,
      }
    case 'selectAccessory':
      return {
        ...state,
        selectedAccessories: [
          ...state.selectedAccessories,
          action.accessory.id.toString(),
        ],
        showAccessoryDialog: !action.accessory.price,
        selectedOption: null,
        recalculatePrice: true,
      }
    case 'deSelectAccessory':
      return {
        ...state,
        selectedAccessories: state.selectedAccessories.filter(
          (id) => id !== action.accessory.id,
        ),
        selectedOption: null,
        recalculatePrice: true,
      }
    case 'updateServices':
      return {
        ...state,
        selectedServices: new Map([
          ...(state.selectedServices?.entries() || []),
          ...(action.selectedService?.entries() || []),
        ]),
        defaultServices:
          action.defaultService != null
            ? action.defaultService
            : state.defaultServices,
        selectedOption: null,
        recalculatePrice: true,
      }
    case 'initServices': {
      return { ...state, hasLogoDeCoeur: action.hasLogoDeCoeur }
    }
    case 'expandServices': {
      return { ...state, isExpandedServices: !state.isExpandedServices }
    }
    case 'updateContractOptions':
      return {
        ...state,
        contractOptions: { ...action.contractOptions },
        selectedOption: null,
        recalculateOptionPrice: true,
      }
    case 'selectOption': {
      return {
        ...state,
        recalculateOptionPrice: true,
        selectedOption: action.selectedOption,
      }
    }
    case 'closeRulesPopupConfirmed': {
      const newRules: OptionRulesModel[] = []
      const newSelectedOption: Option = null
      return {
        ...state,
        showOptionRules: false,
        rules: newRules,
        selectedOption: newSelectedOption,
        rulesParentId: null,
        requires: [],
      }
    }
    case 'closeRulesPopupCanceled': {
      return {
        ...state,
        showOptionRules: false,
        selectedOption: null,
        vehicleConfiguration: state.lastVehicleConfiguration,
        rules: [],
        rulesParentId: null,
        recalculateOptionPrice: true,
      }
    }
    case 'updateDriverContributionConfirmation': {
      return {
        ...state,
        driverContributionConfirmationNeeded:
          action.driverContributionConfirmationNeeded,
      }
    }
    case 'addError': {
      return {
        ...state,
        errors: action.error
          ? state.errors.concat(action.error)
          : [...state.errors],
      }
    }
    case 'removeError': {
      return {
        ...state,
        errors: state.errors.filter((error) => error?.trace !== action.id),
      }
    }
    case 'isCalculationRequestLoading': {
      return {
        ...state,
        isCalculationRequestLoading: action.isCalculationRequestLoading,
      }
    }
    case 'restoreSavedVehicleConfig': {
      const selectedServices = new Map<string, string>()
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      action.savedQuoteConfig.services?.forEach((service: any) =>
        selectedServices.set(service.id, service.levels[0].id),
      )
      return {
        ...state,
        contractOptions: {
          ...state.contractOptions,
          yearlyMileages: action.savedQuoteConfig.mileage,
          durations: action.savedQuoteConfig.duration,
          discountAmount: action.savedQuoteConfig.discountAmount,
          commissionAmount: action.savedQuoteConfig.commissionAmount,
          downPaymentAmount: action.savedQuoteConfig.downPaymentAmount,
          includeMaintenance: action.savedQuoteConfig.maintenanceFlag,
        },
        vehicleConfiguration: {
          ...state.vehicleConfiguration,
          selectedOptions: action.savedQuoteConfig.selectedOptions,
          configuration: action.savedQuoteConfig.configuration,
        },
        selectedAccessories: action.savedQuoteConfig.accessories.map(
          (accessory: Accessory) => accessory.id,
        ),
        selectedServices: new Map([...(selectedServices?.entries() || [])]),
        restoredVehicleConfig: true,
      }
    }
    case 'showSavedQuoteDialog':
      return {
        ...state,
        isPageLoading: false,
        showSavedQuoteDialog: true,
        quoteIdentifier: action.quoteIdentifier,
      }
    case 'cancelSavedQuoteDialog':
      return {
        ...state,
        showSavedQuoteDialog: false,
        quoteIdentifier: '',
      }
  }

  return state
}

const defaultAppState: AppState = {
  contractOptions: null,
  selectedServices: new Map<string, string>(),
  defaultServices: new Map<string, string>(),
  selectedAccessories: [],
  currency: null,
  showOptionRules: false,
  vehicleConfiguration: null,
  lastVehicleConfiguration: null,
  rules: [],
  vehicle: null,
  locale: '',
  hasLogoDeCoeur: false,
  isExpandedServices: false,
  priceCalculation: null,
  recalculatePrice: false,
  recalculateOptionPrice: false,
  selectedOption: null,
  mandatoryAccessories: [],
  accessories: [],
  showAccessoryDialog: false,
  driverContributionConfirmationNeeded: false,
  requires: [],
  pendingChoices: [],
  errors: [],
  rulesParentId: null,
  isPageLoading: false,
  profile: null,
  isCalculationRequestLoading: false,
  restoredVehicleConfig: false,
  showSavedQuoteDialog: false,
  customAccessories: [],
  quoteIdentifier: '',
  temporaryQuote: false,
  moreInfoOpen: false,
  limitedVehicleConfiguration: null,
}

export { AppReducer as appReducer, defaultAppState }
