import { TableContainer, TableHead, TableRow } from '@material-ui/core'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.configurator.table
const f = () => getStyles().fonts

export const TableContainerStyled = styled(TableContainer)`
  font-family: '${f().body.font}';
  padding: 15px;
`

export const TableHeadStyled = styled(TableHead)`
  border-bottom: 3px solid ${s().border};

  .MuiTableCell-head {
    padding: 0 16px 4px;
  }
`
export const TableCellText = styled.p`
  margin: 0;
`

export const TableRowStyled = styled(({ isDescriptionExpanded, ...props }) => (
  <TableRow {...props} />
))<{ isDescriptionExpanded: boolean }>`
  svg {
    cursor: pointer;
  }
  vertical-align: top;

  ${(props) =>
    props.isDescriptionExpanded &&
    `{
    border-left: 5px solid ${s().row.border};
    background-color: ${s().background};
  }`}
`

export const OptionDescriptionContainer = styled.div`
  margin-top: 6px;
`

export const OptionDescriptionButton = styled.button`
  color: ${s().description.button.font};
  cursor: pointer;
  text-decoration: underline;
  background: none;
  border: none;
  font-size: 12px;
  padding: 6px 12px 6px 0;
`
export const OptionDescriptionTitle = styled.p`
  margin: 0;
  color: ${s().description.title.font};
  font-size: 12px;
  font-weight: 700;
`

export const OptionDescriptionList = styled.ul`
  padding: 0;
  margin-top: 2px;
  font-size: 12px;
  list-style-position: inside;
`

export const OptionDescriptionItem = styled.li`
  font-size: 12px;

  ::marker {
    color: ${s().description.item.font};
  }
`
export const DeleteButton = styled.div`
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
`
