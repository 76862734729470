import { ShowroomFiltersSelection } from '../../model/ShowroomFiltersSelection'
import { SessionItemEnum } from '../vehicle-configurator/util/sessionStorageUtil'
import { DefaultSortingOptions } from './vehicle-list/VehiclesSorting'

export const saveShowroomState = (
  filters: ShowroomFiltersSelection,
  activePageNumber: number,
  sortingValue: DefaultSortingOptions,
  itemsPerPage: number,
) => {
  const savedState = JSON.parse(
    window.sessionStorage.getItem(SessionItemEnum.SHOWROOM_STATE),
  )
  if (savedState) {
    window.sessionStorage.setItem(
      SessionItemEnum.SHOWROOM_STATE,
      JSON.stringify({
        ...savedState,
        filters: filters,
        pageNumber: activePageNumber,
        sortBy: sortingValue,
        itemsPerPage: itemsPerPage,
      }),
    )
    return
  }
  window.sessionStorage.setItem(
    SessionItemEnum.SHOWROOM_STATE,
    JSON.stringify({
      filters: filters,
      pageNumber: activePageNumber,
      sortBy: sortingValue,
      itemsPerPage: itemsPerPage,
    }),
  )
}
