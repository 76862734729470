import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Identifiers } from '../../../model/Quote'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import AutocompleteSelect from '../component/autocomplete/AutocompleteSelect'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const DrivetrainsFilter: FC<Props> = ({ filtersResponse }) => {
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()

  return (
    <AutocompleteSelect
      dataE2eId="DrivetrainsFilter"
      hint={{
        title: t(DICTIONARY.VEHICLE_DRIVETRAIN),
        text: t(DICTIONARY.DRIVETRAIN_FILTER_TOOLTIP),
      }}
      value={state.filters.drivetrains}
      onChange={(_, newValue: unknown) =>
        dispatch({
          type: 'updateDrivetrainTypes',
          filters: { drivetrains: newValue as Identifiers[] },
        })
      }
      getOptionLabel={(o) => (o as Identifiers).text}
      renderOption={(o: unknown) => <div>{(o as Identifiers).text}</div>}
      options={filtersResponse.drivetrains}
      renderInput={(params) => (
        <TextField
          {...(params as Record<string, unknown>)}
          label={t(DICTIONARY.VEHICLE_DRIVETRAIN)}
          placeholder={t(DICTIONARY.SELECT_DRIVETRAINS)}
        />
      )}
    />
  )
}

export default DrivetrainsFilter
