import { DICTIONARY } from '../../../constants/dictionary'
import { Calculation } from '../../../model/Calculation'
import { Accessory } from '../../../model/Quote'
import apiClient from '../../../utils/api'
import { getDriverContributionLabelTranslation } from '../../../utils/driver'
import { AppState } from '../reducer/VehicleConfigReducer'
import { flattenServicesToIds } from '../services/service'

const createTotalList = (
  priceInformation: Calculation,
): { label: string; value: number }[] => [
  {
    label: DICTIONARY.MONTHLY_TCO_EXCL_VAT_LABEL,
    value: priceInformation.priceDetail.totalCostOwnership?.totalNoVat,
  },
  {
    label: DICTIONARY.MONTHLY_TCO_INCL_VAT_LABEL,
    value: priceInformation.priceDetail.totalCostOwnership?.total,
  },
  {
    label: DICTIONARY.QUARTERLY_TCO_EXCL_VAT_LABEL,
    value: priceInformation.priceDetail.quarterly?.tcoTotalNoVat,
  },
  {
    label: DICTIONARY.QUARTERLY_TCO_INCL_VAT_LABEL,
    value: priceInformation.priceDetail.quarterly?.tcoTotal,
  },
  {
    label: DICTIONARY.YEARLY_TCO_EXCL_VAT_LABEL,
    value: priceInformation.priceDetail.yearly?.tcoTotalNoVat,
  },
  {
    label: DICTIONARY.YEARLY_TCO_INCL_VAT_LABEL,
    value: priceInformation.priceDetail.yearly?.tcoTotal,
  },
  {
    label: DICTIONARY.SUB_TOTAL_LABEL,
    value: priceInformation.priceDetail.monthly?.total,
  },
  {
    label: DICTIONARY.VAT_LABEL,
    value: priceInformation.priceDetail.monthly?.vat,
  },
  {
    label: DICTIONARY.MONTHLY_TOTAL_TAX_LABEL,
    value: priceInformation.priceDetail.monthly?.totalTax,
  },
  {
    label: DICTIONARY.INVESTMENT_TOTAL,
    value: priceInformation.priceDetail.contract?.investmentTotal,
  },
  {
    label: DICTIONARY.MONTHLY_WHOLE_LIFE_COST,
    value: priceInformation.priceDetail.monthly?.wholeLifeCost,
  },
  {
    label: DICTIONARY.WHOLE_LIFE_COST,
    value: priceInformation.priceDetail.contract?.wholeLifeCost,
  },
  {
    label: DICTIONARY.MONTHLY_PRICE_EXCL_RECOVERABLE_VAT_LABEL,
    value: priceInformation.priceDetail.monthly?.totalInclIrrecoverableVat,
  },
  {
    label: DICTIONARY.MONTHLY_PRICE_INCL_AVERAGE_VAT_LABEL,
    value: priceInformation.priceDetail.monthly?.totalInclAverageVat,
  },
  {
    label: getDriverContributionLabelTranslation(
      priceInformation.priceDetail.contribution,
      priceInformation.duration,
    ),
    value:
      priceInformation.priceDetail.contribution?.driver > 0
        ? priceInformation.priceDetail.contribution?.driver
        : null,
  },
  {
    label: DICTIONARY.ESTIMATED_TAX_BASE,
    value: priceInformation.priceDetail.taxBase?.estimated,
  },
  {
    label: DICTIONARY.TOTAL_RETAIL_COST_INCL_DELIVERY_LABEL,
    value: priceInformation.priceDetail.totalRetailCost?.totalInclDelivery,
  },
  {
    label: DICTIONARY.TOTAL_RETAIL_COST_EXCL_DELIVERY_LABEL,
    value: priceInformation.priceDetail.totalRetailCost?.totalExclDelivery,
  },
  {
    label: DICTIONARY.MAINTENANCE,
    value: priceInformation.priceDetail.monthly?.maintenance,
  },
  {
    label: DICTIONARY.MAINTENANCE,
    value: priceInformation.priceDetail.monthly?.maintenanceInclVat,
  },
  {
    label: DICTIONARY.DOWNPAYMENT,
    value: priceInformation.downPayment,
  },
  {
    label: DICTIONARY.BENEFIT_IN_KIND,
    value: priceInformation.priceDetail.yearly?.bik,
  },
  {
    label: DICTIONARY.VEHICLE_DISCOUNTED_PRICE,
    value: priceInformation.priceDetail.contract?.vehicleDiscountedPrice,
  },
  {
    label: DICTIONARY.OPTIONS_DISCOUNTED_PRICE,
    value: priceInformation.priceDetail.contract?.optionsDiscountedPrice,
  },
]

const getPermanentQuote = (state: AppState) => {
  return apiClient.saveConfiguration(
    {
      locale: state.locale,
      contractOptions: state.contractOptions,
      savedConfiguration: false,
      configHash: state.vehicleConfiguration.configuration,
      accessories: [
        ...state.mandatoryAccessories.map((a: Accessory) => a.id),
        ...state.selectedAccessories,
      ].join(','),
      services: flattenServicesToIds(state.selectedServices).join(','),
    },
    state.customAccessories,
  )
}

const getQuoteLink = (
  locale: string,
  identifier: string,
  productName: string,
): string => {
  return `${process.env.REACT_APP_GLQS}/${locale}/${productName}/quote/${identifier}`
}

const onCopyToClipboard = (link: string) => {
  navigator.clipboard
    .writeText(link)
    .then()
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.log(error),
    )
}

export { createTotalList, getPermanentQuote, onCopyToClipboard, getQuoteLink }
