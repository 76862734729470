import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

import { Product } from '../../../model/Profile'
import { iGen } from '../../../utils/indexGen'
import { getContactDetails, localeByName } from '../../../utils/locale'
import { getStyles } from '../../../utils/styles'

const richTextstyles = StyleSheet.create({
  contactDetails: {
    fontSize: 8,
    paddingBottom: 10,
    fontWeight: getStyles().fonts.pdf.weight.light,
  },
  line: {
    width: '100%',
    borderTop: 'none',
    borderBottom: '1.2px solid black',
    transform: 'scaleY(0.33)',
    paddingTop: 5,
    paddingBottom: 5,
  },
  bold: {
    fontWeight: getStyles().fonts.pdf.weight.bold,
  },
})

interface Props {
  locale: string
  product: Product
}

interface RichTextProps {
  richString: string
}

const RichText: React.FC<RichTextProps> = ({ richString }) => {
  return (
    <Text>
      {richString.split('<strong>').length ? (
        richString.split('<strong>').map((rich, index) => {
          const whenIncludStrong =
            rich.split('</strong>').length === 1 ? (
              <Text style={richTextstyles.bold}>
                {rich.split('</strong>')[0]}
              </Text>
            ) : (
              <>
                <Text style={richTextstyles.bold}>
                  {rich.split('</strong>')[0]}
                </Text>
                <Text>{rich.split('</strong>')[1]}</Text>
              </>
            )
          return (
            <div key={iGen(index)}>
              {rich.includes('</strong>') ? (
                whenIncludStrong
              ) : (
                <Text>{rich}</Text>
              )}
            </div>
          )
        })
      ) : (
        <Text> {richString}</Text>
      )}
    </Text>
  )
}

const ContactDetailsSection: React.FC<Props> = ({ locale, product }) => {
  return (
    <View style={richTextstyles.contactDetails} wrap={false}>
      {getContactDetails(
        localeByName(locale).isoCountryCode,
        product.productName,
      )
        .split('\n')
        .filter((detail: string) => detail.length)
        .map((detail: string, index: number) => (
          <Text key={iGen(index)}>
            <RichText key={iGen(index)} richString={detail} />
          </Text>
        ))}
      <Text style={richTextstyles.line} />
    </View>
  )
}

export default ContactDetailsSection
