import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import React from 'react'

import { getDisclaimerTerm } from '../../../../components/pdf/PDFFooter'
import { DICTIONARY } from '../../../../constants/dictionary'
import { Profile } from '../../../../model/Profile'
import { getCountryConfig } from '../../../../utils/config'
import { isRebranding } from '../../../../utils/styles'

const useStyles = makeStyles({
  root: {
    padding: '30px',
  },
  alert: {
    padding: '15px',
    marginBottom: '20px',
    border: '1px solid transparent',
    borderRadius: '0',
    backgroundColor: '#eee',
    borderColor: '#dddddd',
    color: '#545656',
    whiteSpace: 'pre-wrap',
  },
  disclaimer: {
    marginBottom: '15px',
  },
})

interface Props {
  profile: Profile
}

const AgreementNoticeSection: React.FC<Props> = ({ profile }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const config = getCountryConfig(locale)
  return (
    <div className={classes.root}>
      <div className={classes.alert}>
        <div className={classes.disclaimer}>
          <small>{t(getDisclaimerTerm(profile.product?.productName))}</small>
        </div>
        {config.showQuoteSummaryAgreementNotice && (
          <span>
            <small>
              {t(
                isRebranding()
                  ? DICTIONARY.QUOTE_SUMMARY_AGREEMENT_NOTICE_AYVENS
                  : DICTIONARY.QUOTE_SUMMARY_AGREEMENT_NOTICE,
              )}
            </small>
          </span>
        )}
      </div>
    </div>
  )
}

export default AgreementNoticeSection
