import { useWindowSize } from '@velocity/ui'
import { DeleteIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { SCREEN_SIZE } from '../../constants/display'
import { Option, Quote } from '../../model/Quote'
import apiClient from '../../utils/api'
import { getImaginImage } from '../../utils/imagin'
import { capitalize } from '../../utils/string'
import { getStyles } from '../../utils/styles'
import { flattenAccessoriesToOptions } from '../quoteSummary/util/accessory'
import { LabelText } from '../showroom/vehicle-list/VehicleList.styled'
import { PrintableItem } from '../vehicle-configurator/price/PriceSelectedOptions'
import { flattenServicesToOptions } from '../vehicle-configurator/services/service'
import {
  Label,
  LocalVersion,
  MiddleSection,
  StyledCarInfo,
  StyledCarInfoWrapper,
  Value,
} from './CarInfo.styled'
import { StyledDeleteActionButton } from './PriceInfo.styled'
import {
  ImageSection,
  PolicyLabel,
  StyledHeadline,
} from './SavedConfigurations.styled'
import SavedQuoteMoreInfo from './SavedQuoteMoreInfo'

const s = () => getStyles().colors.configurator.favorites

export default function CarInfo({
  quote,
  reFetch,
  invalidFavourite,
}: {
  quote: Quote
  reFetch: () => void
  invalidFavourite: boolean
}) {
  const { t } = useTranslation()
  const size = useWindowSize()
  const { locale } = useParams<{ locale: string }>()
  const [isMobileMode, setIsMobileMode] = useState(false)

  useEffect(() => {
    const mobileScreen = size.innerWidth < SCREEN_SIZE.MOBILE
    setIsMobileMode(mobileScreen)
  }, [size.innerWidth])

  const handleDeleteQuote = async () => {
    try {
      await apiClient.deleteSavedConfiguration(quote.identifier, locale)
      reFetch()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
  const formatOptions = (options: Option[]): PrintableItem[] =>
    options.map((o: Option) => ({
      optionId: o.identifier || o.optionId.toString(),
      manufacturerName: o.manufacturerName,
      price: o.price,
    }))
  return (
    <StyledCarInfoWrapper>
      <ImageSection>
        <img src={getImaginImage(quote.vehicle, locale)} alt="" />
        {quote.savedPolicyConfigurationFlag && !invalidFavourite && (
          <PolicyLabel>
            <LabelText>
              {capitalize(t(DICTIONARY.POLICY_CONFIGURATION_LABEL))}
            </LabelText>
          </PolicyLabel>
        )}
        {invalidFavourite && (
          <PolicyLabel>
            <LabelText>{t(DICTIONARY.FAVOURITE_UNAVAILABLE_LABEL)}</LabelText>
          </PolicyLabel>
        )}
      </ImageSection>
      <StyledCarInfo>
        <div>
          <StyledHeadline variant="200" data-e2e-id="carName">
            {`${quote.vehicle.make.text} ${quote.vehicle.model.text}`}
          </StyledHeadline>
          {isMobileMode && (
            <StyledDeleteActionButton
              onClick={handleDeleteQuote}
              data-testid="deleteQuoteButton"
            >
              <DeleteIcon size="xs" color={s().deleteIcon} />
            </StyledDeleteActionButton>
          )}
        </div>
        <LocalVersion>{quote.vehicle.localVersion}</LocalVersion>
        <MiddleSection>
          <div>
            <Label>{capitalize(t(DICTIONARY.VEHICLE_BODY_TYPE))}</Label>
            <Value>{quote.vehicle.bodyType.text}</Value>
          </div>
          <div>
            <Label>{t(DICTIONARY.FUEL)}</Label>
            <Value>{quote.vehicle.engineFuelType.text}</Value>
          </div>
        </MiddleSection>
        <SavedQuoteMoreInfo
          selectedItems={[
            ...formatOptions(quote.vehicle.selectedOptions),
            ...flattenAccessoriesToOptions(quote.accessories),
            ...flattenServicesToOptions(quote.services),
          ]}
        />
      </StyledCarInfo>
    </StyledCarInfoWrapper>
  )
}
