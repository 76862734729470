import { FC, ReactElement } from 'react'
import styled from 'styled-components'

import { getStyles, isRebranding } from '../../utils/styles'

const buttonSize = {
  s: `font-size: 15px;
      min-height: 40px;`,
  m: `font-size: 17px;
      min-height: 48px;`,
}
interface Props {
  endIcon?: ReactElement
  startIcon?: ReactElement
  disabled?: boolean
  onClick: () => void
  className?: string
  testId?: string
  size?: 's' | 'm'
}
const VelocityButton: FC<Props> = ({
  children,
  endIcon,
  startIcon,
  disabled = false,
  onClick,
  className,
  testId = 'velocityButton',
  size = 'm',
}) => {
  return (
    <Root
      size={size}
      data-testid={testId}
      data-e2e-id={testId}
      className={className}
      disabled={disabled}
      onClick={() => {
        onClick()
      }}
    >
      <Content>
        {startIcon && <Icon>{startIcon}</Icon>}
        {children}
        {endIcon && <Icon>{endIcon}</Icon>}
      </Content>
    </Root>
  )
}

const Icon = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;
`
const Content = styled.span`
  display: flex;
`
interface RootProps {
  disabled: boolean
  size: 's' | 'm'
}
const Root = styled.button<RootProps>`
  font-weight: ${getStyles().fonts.velocity.button.fontWeight};
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  padding: 8px 20px 8px 20px;
  border-radius: 20px;
  margin-bottom: 1rem;
  color: #ffffff;
  text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
  letter-spacing: 1.1px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: ${getStyles().colors.velocity.button.background};
  ${(props) => buttonSize[props.size]}
  ${(props) => {
    return props.disabled
      ? `
      background-color: #BBBBBB;
      background-image: none !important;
  `
      : `
      &:hover {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
          0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
      cursor: pointer;    
      `
  }}
`

export default VelocityButton
