import Grid from '@material-ui/core/Grid'
import React from 'react'

import TopNav from '../navigation/TopNav'

const FullScreenLayout: React.FC = ({ children }) => (
  <>
    <TopNav />
    <Grid container={true} component="main">
      {children}
    </Grid>
  </>
)

export default FullScreenLayout
