import { useState, useEffect, MutableRefObject } from 'react'

export const useIntersection = (
  ref: MutableRefObject<Element>,
  rootMargin: string,
) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      },
      { rootMargin },
    )

    const element = ref.current
    element && observer.observe(element)
    return () => {
      return element && observer.unobserve(element)
    }
  }, [ref, rootMargin])

  return isVisible
}
