import { datadogLogs } from '@datadog/browser-logs'
import { AxiosRequestConfig } from 'axios'

export const attachDdSessionId = (config: AxiosRequestConfig) => {
  // Check if the request is made to a specific domain
  if (config.baseURL.startsWith(process.env.REACT_APP_GLQS_API)) {
    const internalContext = datadogLogs.getInternalContext()
    if (internalContext?.session_id) {
      config.headers['ddSession'] = internalContext.session_id
    }
  }
  return config
}
