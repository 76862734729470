import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createContext, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

import Spinner from '../components/spinner/Spinner'
import { LocalesEnum } from '../constants/locale'
import useLocale from '../hooks/useLocale'
import { useAllTranslations } from '../utils/reactQueryApi'

const TranslationContext = createContext({})

const TranslationProvider = ({ children }: { children: ReactNode }) => {
  let locale = useLocale()
  locale = locale && locale.toUpperCase() in LocalesEnum ? locale : null
  const [userLocale, setUserLocale] = useState('')
  const [isI18nReady, setIsI18nReady] = useState(false)
  const { data: translations, refetch } = useAllTranslations(locale)

  useEffect(() => {
    setUserLocale(locale || 'en_IE')
  }, [locale])

  useEffect(() => {
    if (userLocale) {
      refetch()
    }
  }, [userLocale, refetch])

  useEffect(() => {
    if (translations) {
      initI18n(translations, userLocale)
      setIsI18nReady(true)
    }
  }, [translations, userLocale])

  return (
    <TranslationContext.Provider value="">
      {!isI18nReady && (
        <SpinnerContainer>
          <Spinner size={20} />
        </SpinnerContainer>
      )}
      {isI18nReady && children}
    </TranslationContext.Provider>
  )
}

const SpinnerContainer = styled.div`
  svg {
    position: fixed;
    left: 50%;
    top: 50%;
    font-size: 60px;
  }
`
const initI18n = (translations: Record<string, string>, language: string) => {
  const resources = {}
  resources[language] = { translation: translations }

  i18n.use(initReactI18next).init({
    resources,
    lng: language,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  })
}

export default TranslationProvider
