import { Box, Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { CORP, CORP_AYVENS } from '../../constants/locale'
import useLocale from '../../hooks/useLocale'
import { isValidLocale } from '../../utils/locale'
import { getStyles, isRebranding } from '../../utils/styles'
import { LeasePlanFooter } from './Footer'

export const FOOTER_TRANSLATIONS = {
  terms: 'TermsOfUseLabel',
  privacy: 'DataProtectionLabel',
  cookie: 'CookiePolicyLabel',
  contact: 'ContactLeasePlanLabel',
}

export const AYVENS_FOOTER_TRANSLATIONS = {
  terms: 'AyvensTermsOfUseLabel',
  privacy: 'AyvensDataProtectionLabel',
  cookie: 'AyvensCookiePolicyLabel',
  contact: 'AyvensContactLabel',
}

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 2rem;
  position: relative;
  color: ${getStyles().colors.configurator.buttonNav};
  margin-right: 20px;
`

const LinkWrapperSryled = styled(Box)`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const CopyrightStyled = styled.p`
  font-size: 12px;
  color: ${getStyles().colors.configurator.buttonNav};
  opacity: 0.5;
`

const AddressStyled = styled(Box)`
  p:first-child {
    font-weight: bold;
  }

  p {
    margin: 0;
    padding: 0 0 10px;
  }
`

const BottomNavStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
`
export interface LocaleRouteParams {
  params: {
    locale: string
  }
}

const BottomNav: React.FC = () => {
  const { t } = useTranslation()
  const routeLocale = useLocale()
  const locale = isValidLocale(routeLocale) ? routeLocale : 'en_IE'
  const corp = isRebranding() ? CORP_AYVENS : CORP
  const links = corp[locale.toLocaleUpperCase()]

  const brandName = isRebranding() ? 'Ayvens' : 'LeasePlan'
  const footerTranslations = isRebranding()
    ? AYVENS_FOOTER_TRANSLATIONS
    : FOOTER_TRANSLATIONS
  const companyLabel = isRebranding()
    ? DICTIONARY.AYVENS_LABEL
    : DICTIONARY.LEASE_PLAN_LABEL
  return (
    <LeasePlanFooter>
      <BottomNavStyled>
        <LinkWrapperSryled>
          {Object.keys(footerTranslations).map((linkKey) => (
            <StyledLink
              href={links[linkKey]}
              key={linkKey}
              data-e2e-id={`${linkKey}Link`}
            >
              {t(footerTranslations[linkKey])}
            </StyledLink>
          ))}
        </LinkWrapperSryled>

        <AddressStyled>
          <p>{t(companyLabel)}</p>
          <p>
            <span>{t(DICTIONARY.TELEPHONE_LABEL)}&nbsp;</span>
            {t(DICTIONARY.PHONE_NUMBER)}
          </p>
        </AddressStyled>
      </BottomNavStyled>
      <CopyrightStyled>
        &copy;&nbsp;{new Date().getFullYear()}&nbsp;{brandName}
      </CopyrightStyled>
    </LeasePlanFooter>
  )
}

export default BottomNav
