import { FC } from 'react'

import { useValidateFavourite } from '../../hooks/useValidateFavourite'
import { Quote } from '../../model/Quote'
import CarInfo from './CarInfo'
import PriceInfo from './PriceInfo'
import { StyledCard } from './SavedConfigurations.styled'

interface Props {
  locale: string
  quote: Quote
  reFetch: () => void
}

const FavouriteCard: FC<Props> = ({ locale, quote, reFetch }) => {
  const { isInvalid, isChecked } = useValidateFavourite(locale, quote)

  return (
    <StyledCard data-e2e-id="carPanel">
      <CarInfo quote={quote} reFetch={reFetch} invalidFavourite={isInvalid} />
      <PriceInfo
        quote={quote}
        refetch={reFetch}
        invalidFavourite={isInvalid}
        checkedFavourite={isChecked}
      />
    </StyledCard>
  )
}

export default FavouriteCard
