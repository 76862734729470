import { TFunction, useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import { PriceDetail } from '../../../../model/Quote'
import { capitalize } from '../../../../utils/string'
import DetailsSection from './DetailsSection'

interface Props {
  vehicleCost: Entry[]
  totalLease: Entry[]
  priceDetail: PriceDetail
}

export const getTranslation = (
  translationKey: string,
  translation: string,
  priceDetail: PriceDetail,
): string => {
  if (translationKey === 'DriverMonthlyContributionWithPeriodLabel') {
    const contributionPeriod = priceDetail?.contribution?.contributionPeriod

    return contributionPeriod
      ? translation.replace(/\{\w+\}/gi, contributionPeriod.toString())
      : translation
  }

  return translation
}

const translateEntry = (
  detail: Entry,
  priceDetail: PriceDetail,
  t: TFunction<'translation', undefined>,
): Entry => {
  return {
    description: capitalize(
      getTranslation(detail.description, t(detail.description), priceDetail),
    ),
    value: detail.value,
    key: detail.key,
  }
}

const PriceDetailsSection: React.FC<Props> = ({
  vehicleCost,
  totalLease,
  priceDetail,
}) => {
  const { t } = useTranslation()

  const details = vehicleCost
    .concat(totalLease)
    .map((detail) => translateEntry(detail, priceDetail, t))

  return (
    <DetailsSection
      title={DICTIONARY.QUOTE_SUMMARY_PRICING_LABEL}
      details={details}
      testId="priceDetail"
    />
  )
}

export default PriceDetailsSection
