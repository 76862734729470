import { useEffect } from 'react'

import { Calculation } from '../../../model/Calculation'
import { Accessory } from '../../../model/Quote'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import {
  recalculatePrice,
  recalculateVehicleConfiguration,
} from '../util/priceCalculation'
import {
  calcSelectedServices,
  contractOptionList,
  customAccessories,
} from './priceCaclulationUtil'

const usePriceCalculation = (
  savedQuote: Calculation,
  isSavedQuote: boolean,
  setIsSavedQuote: (isSavedQuote: boolean) => void,
) => {
  const { state, dispatch } = useVehicleConfig()

  useEffect(() => {
    const isLoading =
      (!state.recalculateOptionPrice && !state.recalculatePrice) ||
      !state.locale ||
      !state.contractOptions?.yearlyMileages ||
      !state.vehicle?.uniqueId ||
      !state.mandatoryAccessories ||
      state.selectedAccessories.length <= -1 ||
      state.selectedServices.size <= -1

    if (isLoading) {
      return
    }

    const mandatoryAccessories = state.mandatoryAccessories.map(
      (a: Accessory) => a.id,
    )

    const selectedServices = new Map<string, string>()
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    savedQuote?.services?.forEach((service: any) =>
      selectedServices.set(service.id, service.levels[0].id),
    )

    const configHash = () => {
      if (state.selectedOption) {
        return state.selectedOption.configHash
      }

      if (isSavedQuote) {
        return savedQuote.configuration
      }

      return state.vehicleConfiguration?.configuration || ''
    }

    if (isSavedQuote && state.profile.product.customAccessoriesEnabled) {
      dispatch({
        type: 'updateCustomAccessory',
        customAccessories: [],
        recalculatePrice: false,
      })
    }
    try {
      state.recalculateOptionPrice &&
        recalculateVehicleConfiguration(
          {
            locale: state.locale,
            contractOptionList: contractOptionList(isSavedQuote, {
              contractOptions: state.contractOptions,
              yearlyMileages: savedQuote.mileage?.toString(),
              durations: savedQuote.duration?.toString(),
              discountAmount: savedQuote.discountAmount?.toString(),
              commissionAmount: savedQuote.commissionAmount?.toString(),
              downPaymentAmount: savedQuote.downPayment?.toString(),
              includeMaintenance: savedQuote.maintenanceFlag?.toString(),
            }),
            vehicleId: state.vehicle.uniqueId,
            selectedAccessories: [
              ...mandatoryAccessories,
              ...(isSavedQuote
                ? savedQuote.accessories.map(
                    (accessory: Accessory) => accessory.id,
                  )
                : state.selectedAccessories),
            ],
            selectedServices: calcSelectedServices(
              isSavedQuote,
              selectedServices,
              state.selectedServices,
            ),
            configHash: configHash(),
            isSavedQuote,
            savedQuote: savedQuote,
            customAccessories: customAccessories(
              isSavedQuote,
              savedQuote.customAccessories,
              state.customAccessories,
            ),
          },
          setIsSavedQuote,
          dispatch,
        )
      state.recalculatePrice &&
        state.vehicleConfiguration?.valid &&
        state.contractOptions.yearlyMileages &&
        recalculatePrice(
          {
            locale: state.locale,
            contractOptionList: state.contractOptions,
            selectedAccessories: [
              ...mandatoryAccessories,
              ...state.selectedAccessories,
            ],
            selectedServices: state.selectedServices,
            configHash: state.vehicleConfiguration?.configuration || '',
            customAccessories: customAccessories(
              isSavedQuote,
              savedQuote.customAccessories,
              state.customAccessories,
            ),
          },
          dispatch,
        )
      dispatch({ type: 'endPriceRecalculate' })
    } catch (reason) {
      // eslint-disable-next-line no-console
      console.error(reason)
      dispatch({ type: 'endPriceRecalculate' })
    }
  }, [
    state.recalculateOptionPrice,
    state.recalculatePrice,
    dispatch,
    state.locale,
    state.contractOptions,
    state.vehicle?.uniqueId,
    state.selectedAccessories,
    state.mandatoryAccessories,
    state.selectedServices,
    state.selectedOption,
    state.vehicleConfiguration?.configuration,
    state.vehicleConfiguration?.valid,
    state.customAccessories,
    state.temporaryQuote,
    isSavedQuote,
    savedQuote,
    setIsSavedQuote,
    state.profile?.product.customAccessoriesEnabled,
    state.profile?.user.country,
  ])
}

export default usePriceCalculation
