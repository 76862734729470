import { Headline, Text } from '@velocity/ui'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.configurator.favorites
const f = () => getStyles().fonts

export const BaseRegularText = styled(Text)`
  font-family: '${f().body.font}';
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  color: ${s().font};
`
export const StyledHeadline = styled(Headline)`
  font-family: '${f().heading}';
  margin-bottom: 0.8rem;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`
export const ImageSection = styled.div`
  img {
    max-width: 310px;
  }
  position: relative;
`
export const StyledCard = styled.div`
  border: 1px solid ${s().card};
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08);
  background: ${s().background};
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 10px 10px 10px;
  }
`
export const Price = styled(StyledHeadline)`
  text-align: right;
  font-size: 2.5rem;
  margin-bottom: 7rem;
`
export const PolicyLabel = styled.div`
  position: absolute;
  top: -20px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  background-color: ${s().policyLabel.background};
  border-bottom-left-radius: 4px;
  height: 30px;
  min-width: 100px;
  text-align: center;
  z-index: 2;
  padding-left: 12px;
  padding-right: 24px;
  display: flex;
  justify-content: center;

  ::before {
    background-color: ${s().policyLabel.background};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    content: '';
    height: 30px;
    left: -6px;
    position: absolute;
    top: 0px;
    transform: skew(20deg) rotate(0deg);
    width: 14px;
    z-index: -1;
  }
`
