import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import styled from 'styled-components'

import { capitalize } from '../../utils/string'
import { getStyles } from '../../utils/styles'
import { PrintableItem } from '../vehicle-configurator/price/PriceSelectedOptions'
import { initState } from './util/savedQuotesMoreInfo'

const s = () => getStyles().colors.savedQuote.moreInfo

interface Props {
  selectedItems: PrintableItem[]
}
const SavedQuotesMoreInfo: FC<Props> = ({ selectedItems }) => {
  const st = initState(
    selectedItems,
    useState<boolean>(true),
    useState<boolean>(true),
    useTranslation(),
  )
  return (
    <Root>
      {st.showDetailsButton() && (
        <Toggle data-testid="showDetailsButton" onClick={st.onShowDetailsClick}>
          + {st.getDetailsLabel()}
        </Toggle>
      )}
      {st.showNoOptionsSelected() && <Title>{st.getNoOptionsSelected()}</Title>}
      {st.showDetails() && (
        <div>
          <Title>{capitalize(st.getSelectedConfigurationTitle())}</Title>
          <ul>{st.getSelectedItems()}</ul>
          {st.showAllButton() && (
            <ShowAll
              data-testid="showAllLessButton"
              onClick={st.onShowAllClick}
            >
              {st.getShowAllLabel()}
            </ShowAll>
          )}
        </div>
      )}
    </Root>
  )
}

const Root = styled.div`
  li {
    font-size: 12px;
  }
  li::marker {
    color: ${s().marker};
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 30px;
  }
`
const Button = styled.button`
  color: ${s().button.font};
  background-color: ${s().button.background};
  cursor: pointer;
  border: none;
`
const Toggle = styled(Button)`
  font-size: 12px;
  padding-left: 0;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`
const Title = styled.div`
  margin-top: 10px;
  font-size: 12px;
  font-weight: bold;
  color: ${s().title};
`
const ShowAll = styled(Button)`
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export default SavedQuotesMoreInfo
