import TagManager from 'react-gtm-module'

import { EVENT, USER_TYPE } from '../../constants/gtm'

export const sendPageViewEvent = async (productName = 'public') => {
  const tagManagerArgs = {
    dataLayer: {
      event: EVENT.PAGE_VIEW,
      meta: getMeta(productName),
    },
  }
  TagManager.dataLayer(tagManagerArgs)
}

export const sendFormEvent = async (
  formStep: string,
  productName = 'public',
  formErrorType?: string,
) => {
  const tagManagerArgs = {
    dataLayer: {
      event: EVENT.FORM_EVENT,
      meta: getMeta(productName),
      formName: 'callBack',
      formStep,
      formContext: productName,
      formErrorType,
    },
  }
  TagManager.dataLayer(tagManagerArgs)
}

export const getMeta = (data: unknown) => {
  const location = window.location
  const pageURL = location.href
  const pathName = location.pathname
  const countryStartP = 4
  const countryEndP = 6
  const country = pathName.substring(countryStartP, countryEndP)
  const languageEndP = 3
  const language = pathName.substring(1, languageEndP)
  const pageNameStartP = 7
  const pageName = pathName.substring(pageNameStartP)
  const itemName = `${pageName.charAt(0).toUpperCase()}${pageName.slice(
    1,
  )} Page`
  const hostname = location.hostname
  const referrer = window.document.referrer
  const valueStream = data
  const pageId = `${country}-${language} | ${valueStream} | ${itemName}`
  const pageTitle = window.document.title
  const redirect = referrer.length > 0 ? 1 : 0

  return {
    userType: USER_TYPE,
    pageURL,
    country,
    language,
    valueStream,
    itemName,
    displayName: itemName,
    pageId,
    pageTitle,
    pathName,
    hostname,
    redirect,
    referrer,
  }
}
