import { Headline } from '@velocity/ui'
import { Button } from '@velocity/ui/draft'
import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { getMoreInfoData } from './moreInfoUtil'

const s = () => getStyles().colors.configurator.moreInfo.drawer
const f = () => getStyles().fonts

const MoreInfoDrawerContent: FC = () => {
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  return (
    <Root>
      <HeadlineStyled variant="200">{t(DICTIONARY.MORE_INFO)}</HeadlineStyled>
      <TableStyled>
        <tbody>
          {state.vehicle &&
            getMoreInfoData(state.vehicle, t, state.currency).map((i) => (
              <tr key={i.name}>
                <td>
                  <b
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i.name),
                    }}
                  />
                </td>
                <td>{i.value}</td>
              </tr>
            ))}
        </tbody>
      </TableStyled>
      <ButtonContainer>
        <Button
          data-testid="more-info-bottom-close-button"
          variant="text"
          onClick={() => {
            dispatch({
              type: 'updateMoreInfoOpen',
              moreInfoOpen: false,
            })
          }}
        >
          {isRebranding()
            ? t(DICTIONARY.CLOSE)
            : t(DICTIONARY.CLOSE).toUpperCase()}
        </Button>
      </ButtonContainer>
    </Root>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 16px;
  button {
    font-size: 16px;
    font-weight: 600;
    color: ${s().closeButton};
  }
`
const Root = styled.div`
  width: 45vw;
  padding: 16px;
  background: ${s().background};
  @media (max-width: 768px) {
    width: 80vw;
  }
`
const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0;
  tr,
  td {
    border-top: 1px solid ${s().border1};
    padding: 8px 0;
  }
  td: last-child {
    text-align: right;
  }
  tr:last-child td {
    border-bottom: 1px solid ${s().border2};
  }
`
const HeadlineStyled = styled(Headline)`
  font-family: '${f().heading}';
  margin-bottom: 20px;
`

export default MoreInfoDrawerContent
