import React from 'react'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import DetailsSection from './DetailsSection'

interface Props {
  details: Entry[]
}

const ContractDetailsSection: React.FC<Props> = ({ details }) => {
  return (
    <DetailsSection
      title={DICTIONARY.QUOTE_SUMMARY_CONTRACT_DETAILS_LABEL}
      details={details}
      testId="contractDetail"
      translateDescription={true}
    />
  )
}

export default ContractDetailsSection
