import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { pendingChoicesToOptionsToExclude, toFlatOptionList } from './rules'

const ExcludedOptionsMessages: FC = () => {
  const { t } = useTranslation()
  const { state } = useVehicleConfig()

  const optionsToExclude = pendingChoicesToOptionsToExclude(
    state.vehicle.options,
    toFlatOptionList(state.vehicleConfiguration.configurationOptions),
    state.pendingChoices || [],
  )
    .reduce((prev, curr) => prev.concat(curr), [])
    .map((option) => option.manufacturerName)
    .join(', ')

  return (
    <>
      {optionsToExclude.length > 0 && (
        <Message>
          {t(DICTIONARY.EXCLUDES_RULES_HELP_MESSAGE)}: {optionsToExclude}
        </Message>
      )}
    </>
  )
}

const Message = styled.div`
  padding-bottom: 1.5em;
  font-weight: bold;
`

export default ExcludedOptionsMessages
