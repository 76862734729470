import { makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import { iGen } from '../../../../utils/indexGen'
import { getStyles, isRebranding } from '../../../../utils/styles'

interface Props {
  details: Entry[]
}

const s = () => getStyles().colors.quoteSummary.content.service

const useStyles = makeStyles({
  root: {
    marginBottom: '2.4rem',
  },
  item: {
    listStyleType: 'none',
  },
  items: {
    padding: '0',
    fontWeight: s().weight,
  },
})

const ServiceDetailsSection: React.FC<Props> = ({ details }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <section className={classes.root}>
      {isRebranding() ? (
        <Text>{t(DICTIONARY.QUOTE_SUMMARY_SERVICES_LABEL)}</Text>
      ) : (
        <Headline variant="200">
          {t(DICTIONARY.QUOTE_SUMMARY_SERVICES_LABEL)}
        </Headline>
      )}
      <ul className={classes.items}>
        {details.map((detail, index) => (
          <li
            className={classes.item}
            key={iGen(index)}
            data-e2e-id={detail.key}
          >
            <span>{detail.description}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}

const Text = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default ServiceDetailsSection
