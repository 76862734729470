import { Dispatch, SetStateAction } from 'react'

export const initState = (
  priceSectionExpanded: [boolean, Dispatch<SetStateAction<boolean>>],
) => {
  const [isPriceSectionExpanded, setPriceSectionExpanded] = priceSectionExpanded
  return {
    isPriceSectionExpanded: (): boolean => isPriceSectionExpanded,
    updatePriceSectionExpanded: (v: boolean) => {
      setPriceSectionExpanded(v)
    },
  }
}
