import { CarMissingIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC, useEffect } from 'react'

import CarMissingAyvensIcon from '../../../assets/images/car-missing.png'
import Spinner from '../../../components/spinner/Spinner'
import TopScroll from '../../../components/topnav/TopScroll'
import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useShowroomConfig } from '../context/ShowroomContext'
import BrandsView from './brandsView/BrandsView'
import SectionView from './SectionView'
import { ViewMode } from './VehicleList'
import {
  ClearFiltersButtonStyled,
  EmptyVehicleListBoldText,
  EmptyVehicleListContainer,
  EmptyVehicleListText,
  QuoteSpinnerContainer,
  VehicleCatalog,
} from './VehicleList.styled'
import VehiclePagination from './VehiclePagination'
import { DefaultSortingOptions } from './VehiclesSorting'

const s = () => getStyles().colors.showroom.vehicleList.body

interface Props {
  isQuotesReady: boolean
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  vehicleList: any[]
  viewMode: ViewMode
  sortingValue: DefaultSortingOptions
  itemsPerPage: number
  count: number
  isQuoteLoading: boolean
}
const VehicleListBody: FC<Props> = ({
  isQuotesReady,
  vehicleList,
  viewMode,
  sortingValue,
  itemsPerPage,
  count,
  isQuoteLoading,
}) => {
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()

  useEffect(() => {
    if (Math.floor(count / itemsPerPage) < state.activePageNumber) {
      dispatch({ type: 'setActivePageNumber', activePageNumber: 0 })
    }
  }, [count, itemsPerPage, state.activePageNumber, dispatch])
  const sectionView = () =>
    viewMode === 'section' ? (
      <BrandsView />
    ) : (
      <>
        <SectionView
          vehicleList={vehicleList}
          sortingValue={sortingValue}
          itemsPerPage={itemsPerPage}
          viewMode={viewMode}
        />
        {count > itemsPerPage && (
          <VehiclePagination
            viewMode={viewMode}
            itemsPerPage={itemsPerPage}
            count={count}
          />
        )}
      </>
    )
  const emptyVehiclesText = () => {
    if (state.filtersError) {
      return (
        <>
          <EmptyVehicleListText>
            {t(DICTIONARY.FILTERS_ERROR_MESSAGE)}
          </EmptyVehicleListText>
          <EmptyVehicleListBoldText>{`Error: ${state.filtersError.trace}`}</EmptyVehicleListBoldText>
        </>
      )
    }
    return (
      <EmptyVehicleListText>{t(DICTIONARY.NO_CAR_FOUND)}</EmptyVehicleListText>
    )
  }
  return (
    <>
      {isQuotesReady && vehicleList?.length > 0 && !state.filtersError ? (
        <VehicleCatalog viewMode={viewMode}>
          {sectionView()}
          <TopScroll />
          {isQuoteLoading && (
            <QuoteSpinnerContainer data-e2e-id="quoteLoader">
              <Spinner size={10} />
            </QuoteSpinnerContainer>
          )}
        </VehicleCatalog>
      ) : (
        <EmptyVehicleListContainer>
          {isRebranding() ? (
            <img src={CarMissingAyvensIcon} alt="vehicleListMissingCar" />
          ) : (
            <CarMissingIcon size="xxl" color={s().carMissingIcon} />
          )}
          <EmptyVehicleListText>{emptyVehiclesText()}</EmptyVehicleListText>
          <ClearFiltersButtonStyled
            variant="text"
            onClick={() => {
              dispatch({ type: 'resetFilters' })
            }}
          >
            {t(DICTIONARY.CLEAR_FILTERS)}
          </ClearFiltersButtonStyled>
        </EmptyVehicleListContainer>
      )}
    </>
  )
}

export default VehicleListBody
