export interface PublicOrderFormAction {
  type: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  companyName?: string
  contactConsent?: boolean
  updateConsent?: boolean
  firstNameError?: boolean
  lastNameError?: boolean
  emailError?: boolean
  phoneError?: boolean
  companyNameError?: boolean
  publicOrderFormDialog?: boolean
}

export interface PublicOrderFormState {
  firstNameError: boolean
  lastNameError: boolean
  emailError: boolean
  phoneError: boolean
  companyNameError: boolean
  firstName: string
  lastName: string
  email: string
  phone: string
  companyName: string
  contactConsent: boolean
  updateConsent: boolean
  publicOrderFormDialog: boolean
}

export const PublicOrderFormReducer = (
  state: PublicOrderFormState,
  action: PublicOrderFormAction,
): PublicOrderFormState => {
  switch (action.type) {
    case 'updatePublicOrderFormDialog':
      return {
        ...state,
        publicOrderFormDialog: action.publicOrderFormDialog,
      }
    case 'updateFirstNameError':
      return {
        ...state,
        firstNameError: action.firstNameError,
      }
    case 'updateLastNameError':
      return {
        ...state,
        lastNameError: action.lastNameError,
      }
    case 'updatePhoneError':
      return {
        ...state,
        phoneError: action.phoneError,
      }
    case 'updateEmailError':
      return {
        ...state,
        emailError: action.emailError,
      }
    case 'updateCompanyNameError':
      return {
        ...state,
        companyNameError: action.companyNameError,
      }
    case 'updateFirstName':
      return {
        ...state,
        firstName: action.firstName,
      }
    case 'updateLastName':
      return {
        ...state,
        lastName: action.lastName,
      }
    case 'updateEmail':
      return {
        ...state,
        email: action.email,
      }
    case 'updatePhone':
      return {
        ...state,
        phone: action.phone,
      }
    case 'updateCompanyName':
      return {
        ...state,
        companyName: action.companyName,
      }
    case 'updateContactConsent':
      return {
        ...state,
        contactConsent: action.contactConsent,
      }
    case 'updateUpdateConsent':
      return {
        ...state,
        updateConsent: action.updateConsent,
      }
  }
  return state
}
export const defaultPublicOrderFormState: PublicOrderFormState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  contactConsent: false,
  updateConsent: false,
  firstNameError: true,
  lastNameError: true,
  emailError: true,
  phoneError: true,
  companyNameError: true,
  publicOrderFormDialog: false,
}
