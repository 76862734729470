import { Theme, makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import { getStyles, isRebranding } from '../../../../utils/styles'

const s = () => getStyles().colors.quoteSummary.content.vehicle

interface Props {
  details: Entry[]
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '40px',
  },
  title: {
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
  },
  items: {
    columnCount: 2,
    columnGap: '2rem',
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    },
  },
  item: {
    whiteSpace: 'nowrap',
    height: '1.8rem',
    position: 'relative',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    '&::before': {
      borderBottom: `1px dotted ${s().item}`,
      bottom: '8px',
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
    },
  },
  itemLabel: {
    position: 'relative',
    fontWeight: s().label.weight,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      borderBottom: `1px solid ${s().label.color}`,
      bottom: '8px',
    },
  },
  name: {
    position: 'absolute',
    left: '0',
  },
  value: {
    fontWeight: 'bold',
    position: 'relative',
  },
}))

const VehicleDetailsSection: React.FC<Props> = ({ details }) => {
  const classes = useStyles({})
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      {isRebranding() ? (
        <Text className={classes.title}>
          {t(DICTIONARY.QUOTE_SUMMARY_VEHICLE_DETAILS_LABEL)}
        </Text>
      ) : (
        <Headline variant="200" className={classes.title}>
          {t(DICTIONARY.QUOTE_SUMMARY_VEHICLE_DETAILS_LABEL)}
        </Headline>
      )}
      <div className={classes.items}>
        {details.map((detail: Entry, index: number) => (
          <div className={classes.item} key={index}>
            <span className={classes.itemLabel}>{t(detail.description)}</span>
            <span
              className={clsx(classes.value, classes.itemLabel)}
              data-e2e-id={detail.description}
            >
              {detail.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

const Text = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default VehicleDetailsSection
