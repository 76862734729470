import styled from 'styled-components'

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`
export const ImageContainer = styled.div`
  display: grid;
  place-self: center;
  img {
    max-height: 37px;
  }
`
