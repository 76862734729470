import { Helmet } from 'react-helmet'
import { FC } from 'react'

import { isRebranding } from '../../utils/styles'

const RebrandingLoader: FC = () => {
  return isRebranding() ? (
    <Helmet>
      <link
        rel="icon"
        href={`${process.env.PUBLIC_URL}/favicons-new/favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.PUBLIC_URL}/favicons-new/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.PUBLIC_URL}/favicons-new/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.PUBLIC_URL}/favicons-new/favicon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.PUBLIC_URL}/favicons-new/safari-pinned-tab.svg`}
        color="#5bbad5"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.leaseplancdn.com/v1/ayvens/fonts.css"
      />
    </Helmet>
  ) : (
    <Helmet>
      <link
        rel="icon"
        href={`${process.env.PUBLIC_URL}/favicons/favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.PUBLIC_URL}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.PUBLIC_URL}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.PUBLIC_URL}/favicons/favicon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.PUBLIC_URL}/favicons/safari-pinned-tab.svg`}
        color="#5bbad5"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://fonts.leaseplancdn.com/v1/fonts-legacy.css"
      />
    </Helmet>
  )
}

export default RebrandingLoader
