import { datadogLogs } from '@datadog/browser-logs'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import React, { Component } from 'react'

interface Props extends RouteComponentProps {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error?: string
}

class ErrorBoundary extends Component<Props> {
  state: State = { hasError: false }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error: error?.message }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    const { hasError, error } = this.state
    const { history } = this.props

    if (!prevState.hasError && hasError) {
      datadogLogs.logger.error('Something Went Wrong', {
        error,
      })
      history.push('/something-went-wrong')
      this.turnErrorOf()
    }
  }

  turnErrorOf = (): void => {
    this.setState({ hasError: false })
  }

  render(): React.ReactNode {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return null
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
