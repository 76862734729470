import { AppState } from '@auth0/auth0-react'

import { cleanSessionStorage } from '../pages/vehicle-configurator/util/sessionStorageUtil'

export const setAppState = (appState: AppState) => {
  const caseId = appState?.caseId
  const customerId = appState?.customerId
  const policyLevel = appState?.policyLevel
  const draftPolicy = appState?.draftPolicy || 'false'
  const driverCase = appState?.driverCase === true
  const submittedQuoteNumber = appState?.submittedQuoteNumber

  if (caseId) {
    window.sessionStorage.setItem('caseId', caseId)
    cleanSessionStorage(['caseId'])
  }
  if (customerId) {
    window.sessionStorage.setItem('customerId', customerId)
    cleanSessionStorage(['customerId'])
  }
  if (policyLevel) {
    window.sessionStorage.setItem('policyLevel', policyLevel)
    cleanSessionStorage(['customerId', 'policyLevel'])
  }
  if (customerId && policyLevel && draftPolicy) {
    window.sessionStorage.setItem('draftPolicy', draftPolicy)
    cleanSessionStorage(['customerId', 'policyLevel', 'draftPolicy'])
  }
  if (submittedQuoteNumber) {
    window.sessionStorage.setItem('submittedQuoteNumber', submittedQuoteNumber)
    cleanSessionStorage(['submittedQuoteNumber'])
  }
  if (driverCase) {
    cleanSessionStorage([])
  }
}
