import { Dispatch, FC } from 'react'

import CompanyNameInput from './CompanyNameInput'
import EmailInput from './EmailInput'
import NameInput from './NameInput'
import PhoneInput from './PhoneInput'
import { InfoLines, Line } from './PublicOrderForm.styled'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}
const PersonalInfo: FC<Props> = ({ state, dispatch }) => {
  return (
    <InfoLines>
      <Line>
        <NameInput state={state} dispatch={dispatch} />
      </Line>
      <Line>
        <EmailInput state={state} dispatch={dispatch} />
        <PhoneInput state={state} dispatch={dispatch} />
      </Line>
      <Line>
        <CompanyNameInput state={state} dispatch={dispatch} />
      </Line>
    </InfoLines>
  )
}

export default PersonalInfo
