import { Tooltip } from '@velocity/ui'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { Option } from '../../../model/Quote'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { dataTagName } from '../util/dataTagUtil'
import {
  ExcludedBox,
  StyledCheckboxAllowedDisabled,
  StyledCheckboxAllowedEnabled,
  StyledCheckboxNotAllowedDisabled,
} from './OptionCheckbox.styled'
import {
  getDisabledAsIncluded,
  getDisabledAsRequired,
  getDisabledForSelection,
  initState,
  isDisabledForSelection,
  isExcluded,
  isRegular,
} from './optionCheckboxState'
import TooltipText from './TooltipText'

interface Props {
  option: Option
  onClick: () => void
  labelId?: string
  ruleDialog?: boolean
}
const OptionCheckbox: FC<Props> = ({
  option,
  onClick,
  labelId = '',
  ruleDialog = false,
}) => {
  const { isIncluded, isSelected, isDisabled } = initState(
    useVehicleConfig(),
    option,
    ruleDialog,
    useTranslation(),
  )
  return (
    <div data-e2e-id={dataTagName(option.manufacturerName, 'vehicleOption')}>
      {isRegular() && !isDisabled && (
        <StyledCheckboxAllowedEnabled
          aria-label="checkbox allowed enabled"
          checked={isSelected}
          aria-labelledby={labelId}
          onClick={onClick}
        />
      )}
      {isRegular() && isDisabled && (
        <StyledCheckboxAllowedDisabled
          aria-label="checkbox allowed disabled"
          checked={isSelected}
          aria-labelledby={labelId}
          onClick={onClick}
        />
      )}
      {isDisabledForSelection() && (
        <Tooltip
          text={
            <TooltipText
              value={
                isIncluded ? getDisabledAsIncluded() : getDisabledAsRequired()
              }
            />
          }
        >
          {isDisabled && (
            <StyledCheckboxNotAllowedDisabled
              aria-label="checkbox not allowed disabled"
              disabled={false}
              checked={true}
            />
          )}
        </Tooltip>
      )}
      {isExcluded() && (
        <Tooltip text={<TooltipText value={getDisabledForSelection()} />}>
          {isExcluded() && <ExcludedBox>!</ExcludedBox>}
        </Tooltip>
      )}
    </div>
  )
}

export default OptionCheckbox
