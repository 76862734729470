import IconButton from '@material-ui/core/IconButton'
import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'
import { BaseRegularText, StyledHeadline } from './SavedConfigurations.styled'

export const PriceButtonSection = styled.div`
  align-self: flex-end;
`
export const PriceHeadline = styled(StyledHeadline)`
  display: flex;
  justify-content: flex-end;
`
export const PriceLabel = styled(BaseRegularText)`
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  width: 100%;
  text-align: end;
`
export const PriceLabelBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledPriceInfo = styled.div`
  position: relative;
  padding: 20px 20px 26px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  background: ${getStyles().colors.priceInfo.background};
  @media (max-width: 768px) {
    div:first-child {
      display: flex;
      justify-content: space-between;
    }
    justify-content: space-around;
    width: 100%;
    padding: 0;
  }
`

export const Price = styled(PriceHeadline)`
  white-space: nowrap;
  margin: 0;

  @media (max-width: 768px) {
    padding-bottom: 26px;
  }
`

export const StyledDeleteActionButton = styled(IconButton)`
  position: absolute;
  left: -45px;
  @media (max-width: 768px) {
    left: 0;
    position: relative;
    padding-top: 0;
  }
`

export const StyledButton = styled(Button)`
  & span {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
    padding-right: 5px;

    span {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }
`

export const StyledPriceInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
