import { FC, useState } from 'react'

import { CarVersion } from './BrandsView'

import {
  RootContainer,
  ModelNameRow,
  ModelName,
  Versions,
  ModelLineStyled,
  ChevronDownIconStyled,
  ChevronUpIconStyled,
  VersionRow,
} from './ModelLine.styled'
import VersionLine from './VersionLine'

interface Props {
  item: {
    name: string
    versions: CarVersion[]
  }
  index: number
}
const ModelLine: FC<Props> = ({ item, index }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <RootContainer
      isExpanded={isExpanded}
      key={`model-${index}`}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsExpanded((ev) => !ev)
      }}
    >
      <ModelLineStyled isExpanded={isExpanded}>
        <ModelNameRow>
          <ModelName data-testid="model-name-label">{item.name}</ModelName>
          <ChevronDownIconStyled size="xs" />
          <ChevronUpIconStyled $isExpanded={isExpanded} size="xs" />
        </ModelNameRow>
        <span>{item.versions.length}</span>
      </ModelLineStyled>
      {isExpanded && (
        <Versions>
          {item.versions.map((i) => (
            <VersionRow key={i.name}>
              <VersionLine item={i} />
            </VersionRow>
          ))}
        </Versions>
      )}
    </RootContainer>
  )
}

export default ModelLine
