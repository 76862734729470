import { InfoIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import VelocityNotice from '../../components/notice/VelocityNotice'
import { DICTIONARY } from '../../constants/dictionary'
import { getStyles, isRebranding } from '../../utils/styles'

const s = () => getStyles().colors.publicOrder.form

const Root = styled.div`
  color: ${s().font};
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 32px;
  border: 1px solid ${s().border};
  border-radius: 0;
  background: ${s().background};
  svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }
  div {
    margin-right: 4px;
  }
  div:last-child {
    font-weight: bold;
  }
`
const InfoBoxText = styled.div`
  white-space: nowrap;
`

interface Props {
  calculationNumber: number
}

const FormHeader: FC<Props> = ({ calculationNumber }) => {
  const { t } = useTranslation()

  return (
    <>
      {isRebranding() ? (
        <VelocityNotice
          text={`${t(
            DICTIONARY.QUOTE_NUMBER_ORDER_LABEL,
          )} ${calculationNumber}`}
        />
      ) : (
        <Root>
          <InfoIcon size="s" color={s().infoIcon} />
          <InfoBoxText>{t(DICTIONARY.QUOTE_NUMBER_ORDER_LABEL)}</InfoBoxText>
          <div>{calculationNumber}</div>
        </Root>
      )}
    </>
  )
}

export default FormHeader
