import {
  createContext,
  useReducer,
  ReactNode,
  useContext,
  Dispatch,
} from 'react'

import {
  AppAction,
  appReducer,
  AppState,
  defaultAppState,
} from '../reducer/VehicleConfigReducer'

export interface VehicleConfig {
  state: AppState
  dispatch: Dispatch<AppAction>
}

export const VehicleConfigContext = createContext<VehicleConfig>({
  state: null,
  dispatch: null,
})

const VehicleConfigProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appReducer, defaultAppState)

  const value = { state, dispatch }

  return (
    <VehicleConfigContext.Provider value={value}>
      {children}
    </VehicleConfigContext.Provider>
  )
}

const useVehicleConfig = () => {
  const context = useContext(VehicleConfigContext)

  if (context === undefined) {
    throw new Error(
      'useVehicleConfig must be used within a VehicleConfigProvider',
    )
  }

  return context
}

export { VehicleConfigProvider, useVehicleConfig }
