import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import { Colours } from '../../../model/VehicleConfiguration'
import { VoVehicle } from '../../../model/VoVehicle'
import { useImaginSwatches } from '../../../utils/reactQueryApi'
import { getStyles } from '../../../utils/styles'
import { isPremiumTrim } from '../util/options'
import ColorAccordion from './ColorAccordion'
import { groupByColor } from './colors'
import { generateSwatchesStyles } from './swatches'

const s = () => getStyles().colors.configurator.accordions

interface Props {
  colours: Colours
  vehicle: VoVehicle
  currency: Intl.NumberFormat
  excludePremiumTrims: boolean
}

const ColorAccordions: FC<Props> = ({
  colours,
  vehicle,
  currency,
  excludePremiumTrims,
}) => {
  const [swatches, setSwatches] = useState<Record<string, string>>(null)
  const {
    data: imaginSwatches,
    isSuccess: isImaginSwatchesReady,
    refetch: getImaginSwatches,
  } = useImaginSwatches(
    vehicle,
    (colours.exteriorColours || [])
      .filter((option) => option.optionCode)
      .reduce((colors, option) => {
        return `${colors}${option.optionCode},`
      }, ''),
  )

  const { t } = useTranslation()
  const subAccordionColors = {
    backgroundColor: s().background,
    color: s().font,
  }

  const filterPremiumTrims = (o: Option) =>
    !excludePremiumTrims || !isPremiumTrim(o)

  const exterior = colours.exteriorColours || []
  const interior = colours.interiorColours
    ? groupByColor(colours.interiorColours.filter(filterPremiumTrims))
    : []
  const others = colours.trims
    ? groupByColor(colours.trims.filter(filterPremiumTrims))
    : []

  useEffect(() => {
    vehicle && colours.exteriorColours && getImaginSwatches()
  }, [vehicle, colours.exteriorColours, getImaginSwatches])

  useEffect(() => {
    if (isImaginSwatchesReady) {
      setSwatches(generateSwatchesStyles(imaginSwatches))
    }
  }, [isImaginSwatchesReady, imaginSwatches, setSwatches])

  return (
    <div style={{ position: 'relative' }}>
      {exterior && exterior.length > 0 && (
        <ColorAccordion
          label={t(DICTIONARY.EXTERIOR_TITLE_LABEL)}
          filteredOptions={exterior}
          colors={subAccordionColors}
          expand={true}
          swatches={swatches}
          currency={currency}
          grouped={false}
        />
      )}
      {interior && interior.length > 0 && (
        <ColorAccordion
          label={t(DICTIONARY.INTERIOR_TITLE_LABEL)}
          filteredOptions={interior}
          colors={subAccordionColors}
          currency={currency}
          grouped={true}
        />
      )}
      {others && others.length > 0 && (
        <ColorAccordion
          label={t(DICTIONARY.TRIM_OTHERS_TITLE_LABEL)}
          filteredOptions={others}
          colors={subAccordionColors}
          currency={currency}
          grouped={true}
        />
      )}
    </div>
  )
}

export default ColorAccordions
