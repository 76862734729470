import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'

import ImageGallery360 from '../../components/imageGallery360/ImageGallery360'
import rotate360 from '../../components/imageGallery360/images/rotate360.png'
import { Vehicle } from '../../model/Quote'
import MoreInfo from './components/MoreInfo'
import { useVehicleConfig } from './context/vehicleConfigContext'
import { initState } from './util/vehicleGallery'
import {
  ElectricRangeText,
  ImageGalleryCarInfoMessage,
  ImageGalleryContainer,
  ImageGalleryOverlayInfoMessage,
  ImageGalleryOverlayInfoText,
  InfoIconStyled,
  Rotate360Overlay,
} from './VehicleGallery.styled'

export interface ImageGalleryContainerProps {
  readonly isPriceSectionExpanded: boolean
}

export interface ImageUrlType {
  original: string
}

export interface VehicleGalleryProps {
  vehicle: Vehicle
  isPriceSectionExpanded: boolean
  setPriceSectionExpanded: (value: boolean) => void
  locale: string
}

const VehicleGallery = ({
  vehicle,
  isPriceSectionExpanded,
  setPriceSectionExpanded,
  locale,
}: VehicleGalleryProps) => {
  const st = initState(
    {
      vehicle,
      isPriceSectionExpanded,
      setPriceSectionExpanded,
      locale,
    },
    useVehicleConfig(),
    useState<ImageUrlType[]>(null),
    useState<HTMLImageElement[]>(null),
    useTranslation(),
    useState(''),
  )

  useEffect(() => st.imagePreloadEffect(), [st])

  if (!st.showComponent()) {
    return null
  }
  return (
    <ImageGalleryContainer
      data-testid="ImageGalleryContainer"
      isPriceSectionExpanded={isPriceSectionExpanded}
      onClick={st.onPriceSectionExpandClick}
    >
      <ImageGalleryCarInfoMessage>
        <h1>{st.getVehicleTitle()}</h1>
        {st.showElectricRange() && (
          <ElectricRangeText>{st.getElectricRange()}</ElectricRangeText>
        )}
        <MoreInfo />
      </ImageGalleryCarInfoMessage>
      <Rotate360Overlay>
        <img src={rotate360} alt="rotate360Image" />
        360&deg;
      </Rotate360Overlay>
      <ImageGalleryOverlayInfoMessage>
        <InfoIconStyled size="s" color="#a9a9a9" />
        <ImageGalleryOverlayInfoText>
          {st.getImageInfo()}
        </ImageGalleryOverlayInfoText>
      </ImageGalleryOverlayInfoMessage>
      <ImageGallery360
        isPriceSectionExpanded={isPriceSectionExpanded}
        items={st.getImages().map((i: ImageUrlType) => i.original)}
        preloadedImages={st.getPreloadedImages()}
      />
    </ImageGalleryContainer>
  )
}
export default memo(VehicleGallery)
