import { Select } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { CountryDefaultLanguage } from '../../constants/locale'
import useLocale from '../../hooks/useLocale'
import { getCountryConfig } from '../../utils/config'
import {
  getLangCountryFromLocale,
  updateLanguageInPath,
} from '../../utils/locale'
import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.languageSelect

const LanguageSelect: React.FC = () => {
  const locale = useLocale()
  const config = getCountryConfig(locale)

  const { language, country } = getLangCountryFromLocale(locale)

  const { t } = useTranslation()

  if (!config.showLanguageSelector) {
    return null
  }

  const handleLanguageChange = (event: ChangeEvent<{ value: unknown }>) => {
    const newLanguage = event.target.value
    if (typeof newLanguage === 'string') {
      window.location.replace(updateLanguageInPath(newLanguage))
    }
  }

  return (
    <StyledSelect
      value={language}
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      onChange={handleLanguageChange}
    >
      <option value={CountryDefaultLanguage[country].toUpperCase()}>
        {t(DICTIONARY.LANGUAGE_NAME)}
      </option>
      <option value="EN">{t(DICTIONARY.ENGLISH)}</option>
    </StyledSelect>
  )
}

const StyledSelect = styled(Select)`
  color: ${s().font};
  background-color: ${s().background};
  fieldset {
    border: 0 !important;
  }
  option {
    color: ${s().option.font};
    font-size: 0.9em;
  }
`

export default LanguageSelect
