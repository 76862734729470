import { CloseIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Calculation } from '../../../model/Calculation'
import { Option } from '../../../model/Quote'
import { capitalize } from '../../../utils/string'
import { flattenAccessoriesToOptions } from '../../quoteSummary/util/accessory'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { flattenServicesToOptions } from '../services/service'
import { dataTagName } from '../util/dataTagUtil'
import {
  OptionItem,
  RemoveAll,
  SelectedConfigurationTitle,
  SelectedOptions,
  ShowAll,
} from './PriceBreakdownContext.styled'

interface Props {
  priceInformation: Calculation
  currency: Intl.NumberFormat
}

export interface PrintableItem {
  optionId: string
  manufacturerName: string
  price: number
}

const PriceSelectedOptions: FC<Props> = ({ priceInformation, currency }) => {
  const [showAll, setShowAll] = useState(false)
  const { t } = useTranslation()
  const maxOptions = 4
  const { state, dispatch } = useVehicleConfig()
  const selectedOptions = priceInformation?.selectedOptions
    ? priceInformation.selectedOptions
    : []
  const mandatoryAccessoryIds = state.mandatoryAccessories.map((i) => i.id)
  let selectedAccessories =
    priceInformation?.accessories && state.mandatoryAccessories
      ? [
          ...state.mandatoryAccessories,
          ...priceInformation.accessories.filter(
            (a) => !mandatoryAccessoryIds.includes(a.id),
          ),
        ]
      : []
  if (state?.customAccessories) {
    selectedAccessories = selectedAccessories.concat(state?.customAccessories)
  }
  const selectedItems: PrintableItem[] = [
    ...selectedOptions.map((o: Option) => ({
      optionId: o.optionId.toString(),
      manufacturerName: o.manufacturerName,
      price: o.adjustedPrice ?? o.price,
    })),
    ...flattenAccessoriesToOptions(selectedAccessories),
    ...flattenServicesToOptions(priceInformation?.services),
  ]
  return (
    <>
      <SelectedConfigurationTitle>
        + {capitalize(t(DICTIONARY.SELECTED_CONFIGURATION_LABEL))}
      </SelectedConfigurationTitle>
      <SelectedOptions>
        {selectedItems
          .slice(0, showAll ? selectedItems.length : maxOptions)
          .map((o, index) => {
            return (
              <OptionItem key={index}>
                <div>{o.manufacturerName}</div>
                <div
                  data-e2e-id={dataTagName(
                    o.manufacturerName,
                    'priceSelectedOption',
                  )}
                >
                  {o.price != null &&
                    !isNaN(o.price) &&
                    currency.format(o.price)}
                </div>
              </OptionItem>
            )
          })}
      </SelectedOptions>
      {selectedItems.length > 0 && (
        <RemoveAll
          data-testid="price-sel-options-remove"
          variant="text"
          onClick={() => {
            dispatch({
              type: 'removeAllSelections',
            })
          }}
        >
          <CloseIcon size="xs" />
          <span>{t(DICTIONARY.REMOVE_ALL)}</span>
        </RemoveAll>
      )}
      {selectedItems.length > 4 && (
        <ShowAll
          data-testid="price-sel-options-show"
          variant="text"
          onClick={() =>
            setShowAll((s) => {
              return !s
            })
          }
        >
          {showAll ? t(DICTIONARY.SHOW_LESS) : t(DICTIONARY.SHOW_ALL)}
        </ShowAll>
      )}
    </>
  )
}

export default PriceSelectedOptions
