import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

export const Header = styled.div`
  margin: 2em 1.5em 0 0;
  display: flex;
  justify-content: space-between;
`
export const FiltersReset = styled.button`
  border: none;
  background: transparent;
  color: ${getStyles().colors.showroom.filters.resetAll};
  cursor: pointer;
  font-size: 0.9rem;
  :hover {
    text-decoration: underline;
  }
`
export const Title = styled.h2`
  margin: 0;
  font-weight: ${getStyles().fonts.title.weight};
`
