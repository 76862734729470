import { Box, Table, TableBody, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import EnhancedTableHead from '../components/EnhancedTableHead'
import OptionCheckbox from '../components/OptionCheckbox'
import OptionDescription from '../components/OptionDescription'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import {
  TableCellText,
  TableContainerStyled,
  TableRowStyled,
} from '../Table.styled'
import { onOptionClick } from '../util/options'
import { getComparator, Order, stableSort } from '../util/tableHeadSort'
import {
  disabledCheckbox,
  excludedCheckbox,
  selectedCheckbox,
} from './OptionCheckbox.styled'

interface OptionsTableProps {
  options: Option[]
}

const OptionsTable = ({ options }: OptionsTableProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('manufacturerName')
  const [expanded, setDescriptionExpanded] = useState<number[]>([])
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const isExpanded = (optionId: number) => expanded.includes(optionId)

  return (
    options && (
      <TableContainerStyled>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={[
              {
                id: 'manufacturerName',
                numeric: false,
                disablePadding: true,
                label: t(DICTIONARY.DESCRIPTION),
              },
              {
                id: 'price',
                numeric: true,
                disablePadding: true,
                label: t(DICTIONARY.PRICE),
              },
            ]}
          />
          <TableBody>
            {stableSort(options, getComparator(order, orderBy)).map(
              (row, index) => {
                const isSelected = [
                  'selected',
                  'required',
                  'included',
                ].includes(row.state)
                const isExcluded = row.state === 'excluded'

                const isDescriptionExpanded = isExpanded(row.optionId)
                const labelId = `enhanced-table-checkbox-${index}`
                const disabledStyle = ['required', 'included'].includes(
                  row.state,
                )
                  ? disabledCheckbox
                  : null

                const textStyle = isExcluded
                  ? excludedCheckbox
                  : ['required', 'included'].includes(row.state)
                  ? disabledCheckbox
                  : ['selected'].includes(row.state)
                  ? selectedCheckbox
                  : null

                return (
                  <TableRowStyled
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={row.identifier || row.optionId}
                    selected={isSelected}
                    isDescriptionExpanded={isDescriptionExpanded}
                  >
                    <TableCell padding="checkbox">
                      <Box p="16px 9px 9px">
                        <OptionCheckbox
                          option={row}
                          labelId={labelId}
                          onClick={() => {
                            onOptionClick(dispatch, row)
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TableCellText style={textStyle}>
                        {row.manufacturerName}
                      </TableCellText>
                      <OptionDescription
                        description={row.description}
                        optionId={row.optionId}
                        setRowExpanded={setDescriptionExpanded}
                        expanded={expanded}
                      />
                    </TableCell>
                    <TableCell
                      align="right"
                      style={isExcluded ? excludedCheckbox : disabledStyle}
                    >
                      <TableCellText>
                        {!isNaN(row.price) && state.currency?.format(row.price)}
                      </TableCellText>
                    </TableCell>
                  </TableRowStyled>
                )
              },
            )}
          </TableBody>
        </Table>
      </TableContainerStyled>
    )
  )
}

export default OptionsTable
