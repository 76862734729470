import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  accessoryDetails: Entry[]
  parentStyles: typeof pdfDocStyles
}

const AccessoryDetailsSection: React.FC<Props> = ({
  accessoryDetails,
  parentStyles,
}) => {
  const { t } = useTranslation()
  return (
    !!accessoryDetails.length && (
      <View wrap={false}>
        <Text style={parentStyles.subTitle}>
          {t(DICTIONARY.QUOTE_SUMMARY_ACCESSORIES_LABEL)}
        </Text>
        {accessoryDetails.map((accessory) => (
          <View style={parentStyles.row} key={accessory.description}>
            <Text style={parentStyles.halfDescription}>
              {accessory.description}
            </Text>
            <Text style={parentStyles.quarter} />
            <Text style={parentStyles.value}>{accessory.value}</Text>
          </View>
        ))}
      </View>
    )
  )
}

export default AccessoryDetailsSection
