import { Redirect } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'

import { useQueryParams } from '../../hooks/useQueryParams'

const Landing = (): ReactElement => {
  const query = useQueryParams()
  const [userLocale, setUserLocale] = useState<string>(null)

  const languageCode = query.get('languageCode')
  const countryCode = query.get('countryCode')

  useEffect(() => {
    if (languageCode && countryCode) {
      setUserLocale(`${languageCode}_${countryCode}`)
    } else {
      setUserLocale('en_IE')
    }
  }, [languageCode, countryCode])

  return userLocale && <Redirect to={`/${userLocale}/auth0`} />
}

export default Landing
