import { RouteProps } from 'react-router-dom'

import FullScreenLayout from '../components/layouts/FullScreenLayout'
import FullScreenWithBottomNavLayout from '../components/layouts/FullScreenWithBottomNavLayout'
import MainLayout from '../components/layouts/MainLayout'
import NoNavLayout from '../components/layouts/NoNavLayout'
import OrderApproved from '../components/navigation/OrderApproved'
import ScheduledMaintenance from '../components/navigation/ScheduledMaintenance'
import ServiceInterruption from '../components/navigation/ServiceInterruption'
import SomethingWentWrong from '../components/navigation/SomethingWentWrong'
import UnauthorisedError from '../components/navigation/UnauthorisedError'
import SavedConfigurations from '../pages/favourites/SavedConfigurations'
import Home from '../pages/home/Home'
import Landing from '../pages/landing/Landing'
import Login from '../pages/login/Login'
import Logout from '../pages/logout/Logout'
import PublicOrderForm from '../pages/publicOrder/PublicOrderForm'
import SavedQuote from '../pages/publicSavedQuote/SavedQuote'
import PublicQuoteSummary from '../pages/quoteSummary/PublicQuoteSummary'
import QuoteSummary from '../pages/quoteSummary/QuoteSummary'
import RedirectToHome from '../pages/redirectToHome/RedirectToHome'
import SavedQuotes from '../pages/savedQuotes/SavedQuotes'
import Showroom from '../pages/showroom/Showroom'
import VehicleConfigurator from '../pages/vehicle-configurator/VehicleConfigurator'

export interface ConfiguratorRouteProps extends RouteProps {
  path: string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  layout?: React.ComponentType<any>
  profileNeeded: boolean
}

export const routes: readonly ConfiguratorRouteProps[] = [
  {
    path: '/',
    exact: true,
    component: RedirectToHome,
    layout: NoNavLayout,
    profileNeeded: false,
  },
  {
    path: '/landing',
    exact: true,
    component: Landing,
    layout: NoNavLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/logout',
    exact: true,
    component: Logout,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/en_IE/view',
    component: Home,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/something-went-wrong',
    component: SomethingWentWrong,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/unauthorised',
    component: UnauthorisedError,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/order-approved',
    component: OrderApproved,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/:product/vehicle-configurator',
    component: VehicleConfigurator,
    layout: FullScreenLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/:product/showroom',
    component: Showroom,
    layout: FullScreenWithBottomNavLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/:product/quote-summary/:quoteParam',
    component: PublicQuoteSummary,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/:product/public-order/:identifier',
    component: PublicOrderForm,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/:product/quote/:quoteParam',
    component: SavedQuote,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/scheduled-maintenance',
    component: ScheduledMaintenance,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/service-interruption',
    component: ServiceInterruption,
    layout: MainLayout,
    profileNeeded: false,
  },
]

export const privateRoutes: readonly ConfiguratorRouteProps[] = [
  {
    path: '/auth0',
    exact: true,
    component: Login,
    layout: NoNavLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/auth0',
    exact: true,
    component: Login,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/fleetmanager-quote',
    exact: true,
    component: Login,
    layout: MainLayout,
    profileNeeded: false,
  },
  {
    path: '/:locale/favourites',
    component: SavedConfigurations,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/saved-quotes',
    component: SavedQuotes,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/quote-summary/:quoteParam?',
    component: QuoteSummary,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/order-summary/:quoteParam?',
    component: QuoteSummary,
    layout: MainLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/vehicle-configurator',
    component: VehicleConfigurator,
    layout: FullScreenLayout,
    profileNeeded: true,
  },
  {
    path: '/:locale/showroom',
    component: Showroom,
    layout: FullScreenWithBottomNavLayout,
    profileNeeded: true,
  },
]
