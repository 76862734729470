import { MutableRefObject } from 'react'

let currentIndex: number
let isPriceSectionExpanded: boolean
let items: string[]

export const initState = (
  wrapper: MutableRefObject<HTMLDivElement>,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  currentIndexP: number,
  i: string[],
  isPriceSectionExpandedP: boolean,
) => {
  items = i
  isPriceSectionExpanded = isPriceSectionExpandedP
  currentIndex = currentIndexP
  return { wrapper }
}

export const showImage = (i: number) => currentIndex === i

export const showNavigations = () => items.length > 1 && !isPriceSectionExpanded
