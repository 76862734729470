import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { CountryDefaultLanguage } from '../../constants/locale'
import useLocale from '../../hooks/useLocale'
import { getCountryConfig } from '../../utils/config'
import {
  getLangCountryFromLocale,
  updateLanguageInPath,
} from '../../utils/locale'
import { getStyles } from '../../utils/styles'
import ShortLogo from './images/short-logo-new.png'

const s = () => getStyles().colors.languageSelect

const LanguageSelect: React.FC = () => {
  const { t } = useTranslation()
  const [expand, setExpand] = useState(false)
  const locale = useLocale()
  const config = getCountryConfig(locale)
  const { language, country } = getLangCountryFromLocale(locale)
  if (!config.showLanguageSelector) {
    return null
  }
  const handleLanguageChange = (newLanguage: string) => {
    setExpand(false)
    window.location.replace(updateLanguageInPath(newLanguage))
  }

  return (
    <Root>
      <img src={ShortLogo} alt="LanguageSelect" />
      <Text data-testid="currentLanguage">{language}</Text>
      <ExpandButton
        data-testid="expandButton"
        onClick={() => {
          setExpand(!expand)
        }}
      >
        <ButtonLabel expand={expand}>&#9001;</ButtonLabel>
      </ExpandButton>
      <Options expand={expand}>
        <Option
          data-testid="languageButton"
          onClick={() =>
            handleLanguageChange(CountryDefaultLanguage[country].toUpperCase())
          }
          selected={language === CountryDefaultLanguage[country].toUpperCase()}
        >
          {t(DICTIONARY.LANGUAGE_NAME)}
        </Option>
        <Option
          data-testid="englishButton"
          onClick={() => handleLanguageChange('EN')}
          selected={language === 'EN'}
        >
          {t(DICTIONARY.ENGLISH)}
        </Option>
      </Options>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  img {
    background-color: ${s().background};
    height: 20px;
  }
  margin: 0 6px;
`
const Options = styled.ul<ButtonLabelProps>`
  display: ${(props) => (props.expand ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  color: #0f3549;
  padding: 8px 12px 8px 0;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 6px 0px;
  top: 25px;
  right: 0;
  list-style-type: none;
  gap: 6px;
  background-color: ${s().background};
`
interface OptionProps {
  selected: boolean
}
const Option = styled.li<OptionProps>`
  padding: 4px 0 4px 16px;
  border-left: 3px solid
    ${(props) => (props.selected ? s().selected : s().unselected)};
  font-weight: 700;
  cursor: pointer;
`
const ExpandButton = styled.button`
  background-color: ${s().expandButton};
  border: none;
  cursor: pointer;
`
interface ButtonLabelProps {
  expand: boolean
}
const ButtonLabel = styled.div<ButtonLabelProps>`
  transform: rotate(${(props) => (props.expand ? '' : '-')}90deg);
  font-weight: bold;
`
const Text = styled.div`
  color: ${s().font};
  font-weight: 800;
  letter-spacing: 0px;
  font-size: 18px;
`

export default LanguageSelect
