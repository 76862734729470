import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Identifiers } from '../../../model/Quote'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import { capitalize } from '../../../utils/string'
import AutocompleteSelect from '../component/autocomplete/AutocompleteSelect'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const FuelTypesFilter: FC<Props> = ({ filtersResponse }) => {
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()

  return (
    <AutocompleteSelect
      dataE2eId="FuelTypesFilter"
      hint={{
        title: t(DICTIONARY.VEHICLE_FUEL_TYPE),
        text: t(DICTIONARY.FUEL_FILTER_TOOLTIP),
      }}
      value={state.filters.fuelTypes}
      onChange={(_, newValue: unknown) =>
        dispatch({
          type: 'updateFuelTypes',
          filters: { fuelTypes: newValue as Identifiers[] },
        })
      }
      getOptionLabel={(o) => (o as Identifiers).text}
      renderOption={(option: unknown) => (
        <div>{(option as Identifiers).text}</div>
      )}
      options={filtersResponse.fuelTypes}
      renderInput={(params) => (
        <TextField
          {...(params as Record<string, unknown>)}
          label={capitalize(t(DICTIONARY.VEHICLE_FUEL_TYPE))}
          placeholder={t(DICTIONARY.SELECT_FUEL_TYPES)}
        />
      )}
    />
  )
}

export default FuelTypesFilter
