import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.tileMessage

export const MessageList = styled.ul`
  margin: 0;
  padding: 5px;
  z-index: 1;
  background-color: white;
  padding-top: 15px;
`

export const MessageItem = styled.li`
  padding-bottom: 2px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    color: ${s().font};
  }
`

export const MessageContent = styled.span`
  color: ${s().content.font};
  font-weight: bolder;
`
