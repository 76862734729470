import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const MileageFilter: FC<Props> = ({ filtersResponse }) => {
  const [currentPosition, setCurrentPosition] = useState<number>(
    filtersResponse.yearlyMileages[0],
  )
  const { t } = useTranslation()
  const { state, dispatch } = useShowroomConfig()
  const debouncedRequest = useDebounce(
    (newValue) => {
      dispatch({
        type: 'updateMileage',
        filters: {
          mileage: {
            value: newValue[0],
          },
        },
      })
    },
    1000,
    true,
  )

  useEffect(() => {
    if (
      filtersResponse.yearlyMileages.length > 1 &&
      !state.filters.mileage.value
    ) {
      const defaultValue = filtersResponse.yearlyMileages[0]
      dispatch({
        type: 'updateMileage',
        filters: {
          mileage: {
            value: defaultValue,
            min: defaultValue,
          },
        },
      })
    }
  }, [filtersResponse.yearlyMileages, dispatch, state.filters.mileage.value])

  useEffect(() => {
    if (state.filters.mileage) {
      setCurrentPosition(Number(state.filters.mileage.value))
    }
  }, [state.filters.mileage])

  if (filtersResponse.yearlyMileages.length <= 1) {
    return <></>
  }

  const range = {
    min: filtersResponse.yearlyMileages[0],
    max: filtersResponse.yearlyMileages[
      filtersResponse.yearlyMileages.length - 1
    ],
  }

  const title = {
    text: t(DICTIONARY.VEHICLE_MILEAGE),
    hint: t(DICTIONARY.DURATION_FILTER_TOOLTIP),
  }
  return (
    <>
      {filtersResponse.yearlyMileages.length > 1 && (
        <ShowroomSlider
          title={title}
          value={currentPosition}
          onChange={(_, newValue) => {
            setCurrentPosition(Number(newValue))
            debouncedRequest(newValue.toString())
          }}
          range={range}
          marks={filtersResponse.yearlyMileages.map((i) => ({
            value: i,
            label: i.toString(),
          }))}
          step={null}
        />
      )}
    </>
  )
}

export default MileageFilter
