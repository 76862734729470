import { useParams } from 'react-router-dom'
import { FC } from 'react'

import { useValidateSavedQuote } from '../../hooks/useValidateSavedQuote'
import { Quote } from '../../model/Quote'
import { StyledCard } from '../favourites/SavedConfigurations.styled'
import CarInfo from './CarInfo'
import PriceInfo from './PriceInfo'

interface Props {
  quote: Quote
  onDelete: () => void
}

const SavedQuotesCard: FC<Props> = ({ quote, onDelete }) => {
  const { locale } = useParams<{ locale: string }>()
  const {
    isPolicyBreached,
    isChecked,
    isConfigurationInvalid,
    isVehicleUnavailable,
  } = useValidateSavedQuote(locale, quote)

  return (
    <StyledCard data-e2e-id="savedQuotesCarPanel">
      <CarInfo
        quote={quote}
        onDelete={onDelete}
        isPolicyBreached={isPolicyBreached}
        isConfigurationInvalid={isConfigurationInvalid}
        isVehicleUnavailable={isVehicleUnavailable}
      />
      <PriceInfo
        quote={quote}
        isChecked={isChecked}
        isInvalid={isPolicyBreached || isConfigurationInvalid}
        onDelete={onDelete}
      />
    </StyledCard>
  )
}

export default SavedQuotesCard
