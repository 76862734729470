import { Box, Grid } from '@material-ui/core'
import { Button, CarMissingIcon } from '@velocity/ui/draft'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ReactElement, useEffect } from 'react'

import CarMissingAyvensIcon from '../../assets/images/car-missing.png'
import VelocityButton from '../../components/button/VelocityButton'
import { DICTIONARY } from '../../constants/dictionary'
import { Quote } from '../../model/Quote'
import { history } from '../../utils/historyStore'
import { useSavedConfigurations } from '../../utils/reactQueryApi'
import { capitalize } from '../../utils/string'
import { getStyles, isRebranding } from '../../utils/styles'
import FavouriteCard from './FavouriteCard'

const SavedConfigurations = (): ReactElement => {
  const { t } = useTranslation()
  const { locale } = useParams<{ locale: string }>()
  const {
    data: quotes,
    status: quotesStatus,
    refetch: reFetch,
  } = useSavedConfigurations(locale)
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    closeSnackbar()
  }, [closeSnackbar])

  const handleNoFavouritesRedirect = () => {
    history.push(`/${locale}/showroom`)
  }

  if (!quotes) return null

  const renderNoFavouritesView = () => (
    <Grid container={true} justify="space-between" alignItems="center">
      <Grid item={true} xs={12}>
        <Box textAlign="center">
          {isRebranding() ? (
            <img src={CarMissingAyvensIcon} alt="vehicleListMissingCar" />
          ) : (
            <CarMissingIcon
              size="xxl"
              color={getStyles().colors.savedConfigurations.icon}
            />
          )}
          <p>{t(DICTIONARY.NO_FAVOURITES_LABEL)}</p>
          {isRebranding() ? (
            <VelocityButton
              testId="noFavouritesRedirectButton"
              onClick={handleNoFavouritesRedirect}
            >
              {capitalize(t('ToConfigureVehicleLabel'))}
            </VelocityButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNoFavouritesRedirect}
              data-e2e-id="noFavouritesRedirectButton"
            >
              {t('ToConfigureVehicleLabel')}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <>
      {quotesStatus === 'success' && quotes.length === 0
        ? renderNoFavouritesView()
        : quotes.map((quote: Quote) => (
            <FavouriteCard
              key={`favourite-${quote.identifier}`}
              locale={locale}
              quote={quote}
              reFetch={reFetch}
            />
          ))}
    </>
  )
}

export default SavedConfigurations
