import { Checkbox } from '@velocity/ui'
import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Dispatch, FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { CORP, CORP_AYVENS } from '../../constants/locale'
import { getStyles, isRebranding } from '../../utils/styles'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}
const Consent: FC<Props> = ({ state, dispatch }) => {
  const { locale } = useParams<{ locale: string }>()
  const { t } = useTranslation()
  if (!locale) {
    return null
  }
  const corp = isRebranding() ? CORP_AYVENS : CORP
  const href = corp[locale.toLocaleUpperCase()].privacy
  const text = t(DICTIONARY.DATA_PROTECTION_LABEL)
  const optInLink = `<a href='${href}' title='${text}'>${text}</a>`

  return (
    <Root>
      <Title>{t(DICTIONARY.PUBLIC_ORDER_OPT_IN_LABEL)}</Title>
      <Item>
        <Checkbox
          checked={state.contactConsent}
          onChange={(e) =>
            dispatch({
              type: 'updateContactConsent',
              contactConsent: !state.contactConsent,
            })
          }
        />
        <div>
          {t(
            isRebranding()
              ? DICTIONARY.PUBLIC_ORDER_SUBMIT_FORM_CONSENT_TEXT_AYVENS
              : DICTIONARY.PUBLIC_ORDER_SUBMIT_FORM_CONSENT_TEXT,
          )}
        </div>
      </Item>
      <Item>
        <Checkbox
          checked={state.updateConsent}
          onChange={() =>
            dispatch({
              type: 'updateUpdateConsent',
              updateConsent: !state.updateConsent,
            })
          }
        />
        <ConsentWithLink
          dangerouslySetInnerHTML={{
            __html: sanitize(
              t(
                isRebranding()
                  ? DICTIONARY.PUBLIC_ORDER_OPT_IN_TEXT_AYVENS
                  : DICTIONARY.PUBLIC_ORDER_OPT_IN_TEXT,
                {
                  doc: optInLink,
                  interpolation: { escapeValue: false },
                },
              ),
            ),
          }}
        />
      </Item>
    </Root>
  )
}

const ConsentWithLink = styled.div`
  a {
    color: ${getStyles().colors.publicOrder.consent};
  }
`
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
`
const Title = styled.div`
  margin-bottom: 10px;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
`

export default Consent
