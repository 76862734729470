import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.rules.item

export const RequiresRulesHelpMessage = styled.div`
  padding-bottom: 1.5em;
  font-weight: bold;
`
export const TitleFont = styled.div`
  font-size: 0.85rem;
  color: ${s().title};
  font-weight: bold;
`
const Operator = styled.div`
  margin: 0 0 6px 39px;
  text-transform: uppercase;
  color: ${s().operator};
  font-size: 75%;
  font-weight: bold;
  padding: 0.2em 0.6em;
  border-radius: 0.25em;
`

export const AndOperator = styled(Operator)`
  background: ${s().and};
  display: inline-block;
`
export const ManufacturerName = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.8em;
  font-size: 1.3em;
  font-weight: bold;
`
