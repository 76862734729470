import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { Profile } from '../../model/Profile'
import { getCountryConfig } from '../../utils/config'
import { getStyles, isRebranding } from '../../utils/styles'

const s = () => getStyles().colors.pdf.footer

const pdfStyles = StyleSheet.create({
  note: {
    fontWeight: getStyles().fonts.pdf.weight.bold,
    border: `1px solid ${s().note}`,
    padding: '5px',
    marginBottom: '5px',
    width: '100%',
  },
  disclaimer: {
    fontWeight: getStyles().fonts.pdf.weight.medium,
    marginBottom: '8px',
  },
  text: {
    flexDirection: 'column',
    width: '100%',
    fontSize: 8,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'justify',
  },
  line: {
    width: '100%',
    borderStyle: 'dotted',
    borderBottom: `1.2px dotted ${s().line}`,
    transform: 'scaleY(0.33)',
    paddingTop: 5,
    paddingBottom: 5,
  },
  section: {
    marginTop: '10px',
    flexDirection: 'column',
    width: '100%',
    fontSize: 8,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'justify',
  },
})

interface Props {
  locale: string
  profile: Profile
}

export const getDisclaimerTerm = (product: string) => {
  return isRebranding()
    ? `PDF.${product || 'corporate'}.disclaimer.ayvens`
    : `PDF.${product || 'corporate'}.disclaimer`
}

const PDFFooter: React.FC<Props> = ({ profile, locale }) => {
  const config = getCountryConfig(locale)
  const { t } = useTranslation()
  return (
    <View style={pdfStyles.section} wrap={false}>
      {profile.product.displayPrivateCarHireNote && (
        <>
          <Text style={pdfStyles.note}>{t(DICTIONARY.PDF_QUOTE_NOTE)}</Text>
          {config.showExtendedPdfQuoteNote && (
            <>
              <Text style={pdfStyles.note}>
                {t(DICTIONARY.PDF_QUOTE_NOTE_2)}
              </Text>
              <Text style={pdfStyles.note}>
                {t(DICTIONARY.PDF_QUOTE_NOTE_3)}
              </Text>
              <Text style={pdfStyles.note}>
                {t(DICTIONARY.PDF_QUOTE_NOTE_4)}
              </Text>
            </>
          )}
        </>
      )}
      <Text style={pdfStyles.disclaimer}>
        {t(getDisclaimerTerm(profile.product.productName))
          .replace('<sub>', '')
          .replace('</sub>', '')}
      </Text>
      {config.showQuoteSummaryAgreementNotice && (
        <Text style={pdfStyles.disclaimer}>
          {t(
            isRebranding()
              ? DICTIONARY.QUOTE_SUMMARY_AGREEMENT_NOTICE_AYVENS
              : DICTIONARY.QUOTE_SUMMARY_AGREEMENT_NOTICE,
          )}
        </Text>
      )}
    </View>
  )
}

export default PDFFooter
