export const EVENT = {
  PAGE_VIEW: 'pageView',
  FORM_EVENT: 'formSubmission',
  GENERIC_EVENT: 'genericEvent',
}
export const FORM_STEP = {
  STEP: 'step',
  INTERACTION: 'interaction',
  SUBMISSION: 'submission',
  ERROR: 'error',
}
export const FORM_ERROR_TYPE = {
  VALIDATION: 'validation',
  REQUIRED: 'required',
}

export const USER_TYPE = 'visitor'
