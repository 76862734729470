import { FC } from 'react'
import styled from 'styled-components'

import CO2Arrow from './CO2Arrow'

interface Props {
  co2Band: string
  co2Value: number
  showSelected?: boolean
}

const CO2Label: FC<Props> = ({ co2Band, co2Value, showSelected = false }) => {
  const length = 8
  const indexes = [...Array(length).keys()]
  return (
    <Root>
      {indexes.map((i) => {
        return (
          <CO2Arrow
            key={i}
            index={i}
            band={co2Band}
            selectedValue={co2Value}
            showSelected={showSelected}
          />
        )
      })}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`
export default CO2Label
