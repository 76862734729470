module.exports = {
  createContext: () => {
    const context = require.context(
      '../assets/images',
      false,
      /\.(png|jpe?g|svg)$/,
    )
    const images = {}
    context.keys().forEach((item) => {
      images[item.replace('./', '')] = context(item)
    })
    return images
  },
}
