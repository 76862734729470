import { SERVICE } from './service'

export const DISPLAY = {
  SERVICES: [SERVICE.LOGO_DE_COEUR, SERVICE.REPLACEMENT_CAR, SERVICE.TYRES],
  OPTIONS_SERVICES: [SERVICE.WINTER_TYRES],
}

export const SCREEN_SIZE = {
  MOBILE: 768,
  TABLET: 991,
}
