import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const DurationFilter: FC<Props> = ({ filtersResponse }) => {
  const [currentPosition, setCurrentPosition] = useState<number>(
    filtersResponse.totalDurations[0],
  )
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()
  const debouncedRequest = useDebounce(
    (newValue) => {
      dispatch({
        type: 'updateDuration',
        filters: {
          duration: {
            value: Number(newValue[0]),
          },
        },
      })
    },
    1000,
    true,
  )

  useEffect(() => {
    if (
      filtersResponse.totalDurations.length > 1 &&
      !state.filters.duration.value
    ) {
      const defaultValue =
        filtersResponse.totalDurations[
          filtersResponse.totalDurations.length - 1
        ]
      setCurrentPosition(defaultValue)
      dispatch({
        type: 'updateDuration',
        filters: {
          duration: {
            value: defaultValue,
            max: defaultValue,
          },
        },
      })
    }
  }, [filtersResponse.totalDurations, dispatch, state.filters.duration.value])

  useEffect(() => {
    if (state.filters.duration) {
      setCurrentPosition(Number(state.filters.duration.value))
    }
  }, [state.filters.duration])

  if (filtersResponse.totalDurations.length <= 1) {
    return <></>
  }

  const range = {
    min: filtersResponse.totalDurations[0],
    max: filtersResponse.totalDurations[
      filtersResponse.totalDurations.length - 1
    ],
  }

  const title = {
    text: t(DICTIONARY.VEHICLE_DURATION),
    hint: t(DICTIONARY.DURATION_FILTER_TOOLTIP),
  }
  return (
    <ShowroomSlider
      track={false}
      title={title}
      value={currentPosition}
      onChange={(_, newValue) => {
        setCurrentPosition(Number(newValue))
        debouncedRequest(newValue.toString())
      }}
      range={range}
      marks={filtersResponse.totalDurations.map((i) => ({
        value: i,
        label: i.toString(),
      }))}
      step={null}
    />
  )
}

export default DurationFilter
