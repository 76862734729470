import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { Option } from '../../../model/Quote'
import OptionCheckbox from '../components/OptionCheckbox'
import {
  disabledCheckbox,
  excludedCheckbox,
} from '../components/OptionCheckbox.styled'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { onOptionClick } from '../util/options'
import { ItemInfo } from './ColorItem.styled'
import { CheckboxSpacer } from './ColorItemWithOptions.styled'
import ColorOptionDetails from './ColorOptionDetails'
import {
  CheckboxContainer,
  ChevronIconDown,
  ChevronIconUp,
  ColorBoxSpacer,
  OptionsContainer,
  OptionsDetailLabel,
  OptionsSwitchContainer,
} from './ColorOptionItem.styled'

interface Props {
  option: Option
  currency: Intl.NumberFormat
}

const ColorOptionItem: FC<Props> = ({ option, currency }) => {
  const profile = useProfileContext()
  const { t } = useTranslation()
  const { dispatch } = useVehicleConfig()
  const [isShowDetails, setIsShowDetails] = useState(false)
  const [showDetailsLabel, setShowDetailsLabel] = useState<string>(
    t(DICTIONARY.SHOW_DETAILS_LABEL),
  )
  const onShowDetailsClick = () => {
    setShowDetailsLabel(
      showDetailsLabel === t(DICTIONARY.SHOW_DETAILS_LABEL)
        ? t(DICTIONARY.HIDE_LABEL)
        : t(DICTIONARY.SHOW_DETAILS_LABEL),
    )
    setIsShowDetails((prev) => !prev)
  }
  const isExcluded = option.state === 'excluded'
  const disabledCheckboxStyle =
    option.state === 'included' ? disabledCheckbox : null
  return (
    <>
      <CheckboxContainer>
        {!profile.user.blsConfig && <ColorBoxSpacer />}
        <OptionCheckbox
          option={option}
          onClick={() => {
            onOptionClick(dispatch, option)
          }}
        />
        <ItemInfo style={{ marginLeft: '0.5em' }}>
          <div style={isExcluded ? excludedCheckbox : disabledCheckboxStyle}>
            {option.manufacturerName}
          </div>
          <div style={isExcluded ? excludedCheckbox : disabledCheckboxStyle}>
            {!isNaN(option?.colour?.optionMinimumRequiredPrice) &&
              currency.format(option?.colour?.optionMinimumRequiredPrice)}
          </div>
        </ItemInfo>
      </CheckboxContainer>
      {option?.description?.length > 0 && (
        <OptionsContainer>
          {!profile.user.blsConfig && <ColorBoxSpacer />}
          <CheckboxSpacer />
          <OptionsSwitchContainer
            data-testid="color-option-details"
            onClick={onShowDetailsClick}
          >
            <OptionsDetailLabel>{showDetailsLabel}</OptionsDetailLabel>
            {showDetailsLabel === t(DICTIONARY.SHOW_DETAILS_LABEL) && (
              <ChevronIconDown size="xs" />
            )}
            {showDetailsLabel === t(DICTIONARY.HIDE_LABEL) && (
              <ChevronIconUp size="xs" />
            )}
          </OptionsSwitchContainer>
        </OptionsContainer>
      )}
      {isShowDetails && option?.description?.length > 0 && (
        <ColorOptionDetails option={option} />
      )}
    </>
  )
}

export default ColorOptionItem
