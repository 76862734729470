import { Option } from '../../../model/Quote'
import { UserChoice } from '../../../model/VehicleConfiguration'

const filterPendingChoices = (
  inputPendingChoices: UserChoice[][],
  existingPendingChoices: UserChoice[][],
  selectedOptions: Option[],
): UserChoice[][] => {
  const newPendingChoices = inputPendingChoices.filter(
    (pendingChoice: UserChoice[]) =>
      !inPendingChoices(pendingChoice, existingPendingChoices),
  )
  const oldPendingChoices = existingPendingChoices.filter(
    (pendingChoice: UserChoice[]) =>
      inPendingChoices(pendingChoice, inputPendingChoices) ||
      pendingChoice.filter(
        (choice: UserChoice) =>
          selectedOptions
            .map((value) => value.identifier)
            .indexOf(choice.identifier) !== -1,
      ).length > 0,
  )
  return [...oldPendingChoices, ...newPendingChoices]
}

const filterRequires = (
  inpRequires: number[][],
  existingRequires: number[][],
  selectedOptions: Option[],
): number[][] => {
  const newRequires = inpRequires.filter(
    (orRequire: number[]) => !inRequires(orRequire, existingRequires),
  )
  const oldRequires = existingRequires.filter(
    (orRequire: number[]) =>
      inRequires(orRequire, inpRequires) ||
      orRequire.filter(
        (optionId) =>
          selectedOptions.map((value) => value.optionId).indexOf(optionId) !==
          -1,
      ).length > 0,
  )
  return [...oldRequires, ...newRequires]
}

const inRequires = (list: number[], requires: number[][]): boolean => {
  for (const elem of requires) {
    if (compareLists(list, elem)) return true
  }
  return false
}

const inPendingChoices = (
  list: UserChoice[],
  requires: UserChoice[][],
): boolean => {
  for (const elem of requires) {
    if (
      compareLists(
        list.map((value) => value.identifier),
        elem.map((value) => value.identifier),
      )
    )
      return true
  }
  return false
}

const compareLists = (
  list1: (number | string)[],
  list2: (number | string)[],
): boolean => {
  const sortedList1 = [...list1]
  sortedList1.sort((a, b) => ('' + a).localeCompare('' + b))
  const sortedList2 = [...list2]
  sortedList2.sort((a, b) => ('' + a).localeCompare('' + b))
  if (sortedList1.length !== sortedList2.length) {
    return false
  }

  for (let index = 0; index < sortedList1.length; index++) {
    if (sortedList1[index] !== sortedList2[index]) {
      return false
    }
  }
  return true
}

export { filterRequires, filterPendingChoices }
