import { DICTIONARY } from '../../../constants/dictionary'
import { Quote } from '../../../model/Quote'
import { createDetail } from './quotation'

export const updateCo2EmissionsDetails = (
  quote: Quote,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
) => {
  if (quote.co2Emissions) {
    return [
      createDetail(
        translations(DICTIONARY.WLTP_CO2),
        `${quote.co2Emissions} ${translations(DICTIONARY.CO2_UNIT)}`,
        DICTIONARY.WLTP_CO2,
      ),
    ]
  }
  return []
}
