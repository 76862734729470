import { Box, Container, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles } from '../../../utils/styles'

interface Props {
  component: React.ReactNode
}

const TitleStyled = styled(Typography)<Props>`
  font-size: 50px;
  font-weight: 700;
  color: ${getStyles().colors.invalidQuoteError.font};
`

const SubTitleStyled = styled(Typography)<Props>`
  font-size: 20px;
  font-weight: 200;
  margin-top: 24px;
`

const InvalidQuoteError = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Container>
      <Grid container={true} alignItems="center" direction="row">
        <Grid item={true} xs={12}>
          <Box mt={16} textAlign="left">
            <TitleStyled component="h1" data-testid="invalid-quote-title">
              {t(DICTIONARY.INVALID_QUOTE_ERROR_TITLE)}
            </TitleStyled>
            <SubTitleStyled component="h2" data-testid="invalid-quote-message">
              {t(DICTIONARY.INVALID_QUOTE_ERROR_MESSAGE)}
            </SubTitleStyled>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default InvalidQuoteError
