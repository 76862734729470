import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import {
  ShowroomFiltersResponse,
  ShowroomModel,
} from '../../../model/ShowroomFiltersResponse'
import AutocompleteSelect from '../component/autocomplete/AutocompleteSelect'
import { useShowroomConfig } from '../context/ShowroomContext'
import { GroupedModel, groupModels, sortModels } from './filtersUtil'
import { ImageContainer, RootContainer } from './MakeAndModelFilters.styled'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const MakeAndModelFilters: FC<Props> = ({ filtersResponse }) => {
  const [grouped, setGrouped] = useState([])
  const [models, setModels] = useState<GroupedModel[]>(grouped)
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()

  useEffect(() => {
    setGrouped(groupModels(sortModels(filtersResponse)))
  }, [filtersResponse])

  useEffect(() => {
    if (state.filters.make.length === 0) {
      setModels(grouped)
      return
    }
    setModels(grouped.filter((i) => state.filters.make.includes(i.make)))
  }, [grouped, state.filters.make, filtersResponse])

  return (
    <RootContainer>
      <AutocompleteSelect
        dataE2eId="MakeFilter"
        hint={{
          title: t(DICTIONARY.MAKE),
          text: t(DICTIONARY.MAKES_FILTER_TOOLTIP),
        }}
        value={Array.from(state.filters.make)}
        onChange={(_, newValue: unknown) => {
          const newValueStr = Array.from(new Set(newValue as string[]))
          dispatch({ type: 'updateMake', filters: { make: newValueStr } })
          dispatch({
            type: 'updateModel',
            filters: {
              model: state.filters.model.filter((i) =>
                newValueStr.includes(i.make),
              ),
            },
          })

          if (newValueStr.length === 0) {
            setModels(grouped)
            return
          }

          setModels(grouped.filter((i) => newValueStr.includes(i.make)))
        }}
        getOptionLabel={(o) => o as string}
        renderOption={(option: unknown) => {
          return (
            <>
              <ImageContainer>
                {state.images[`${option as string}.png`]?.default && (
                  <img
                    src={state.images[`${option as string}.png`].default}
                    alt=""
                  />
                )}
              </ImageContainer>
              <div style={{ paddingLeft: '0.8rem' }}>{option}</div>
            </>
          )
        }}
        options={sortModels(filtersResponse).map((i) => i.make)}
        renderInput={(params) => (
          <TextField
            {...(params as Record<string, unknown>)}
            label={t(DICTIONARY.MAKE)}
            placeholder={t(DICTIONARY.SELECT_MAKES)}
          />
        )}
      />
      <AutocompleteSelect
        dataE2eId="ModelFilter"
        hint={{
          title: t(DICTIONARY.MODEL),
          text: t(DICTIONARY.MODELS_FILTER_TOOLTIP),
        }}
        value={state.filters.model}
        onChange={(_, newValue) => {
          const value = newValue as GroupedModel[]
          dispatch({
            type: 'updateModel',
            filters: {
              model: value,
            },
          })

          if (state.filters.make.length === 0 && value.length === 1) {
            dispatch({ type: 'updateMake', filters: { make: [value[0].make] } })
            setModels(grouped.filter((i) => i.make === value[0].make))
          }
        }}
        groupBy={(o) => (o as ShowroomModel).make}
        options={models}
        getOptionLabel={(o) => (o as GroupedModel).model}
        renderOption={(o: unknown) => (
          <>
            <ImageContainer>
              {state.images[`${(o as GroupedModel).make}.png`]?.default && (
                <img
                  src={state.images[`${(o as GroupedModel).make}.png`].default}
                  alt=""
                />
              )}
            </ImageContainer>
            <div style={{ paddingLeft: '0.8rem' }}>
              {(o as GroupedModel).model}
            </div>
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...(params as Record<string, unknown>)}
            label={t(DICTIONARY.MODEL)}
            placeholder={t(DICTIONARY.SELECT_MODELS)}
          />
        )}
        getOptionSelected={(option) => {
          return state.filters.model
            .map((i) => i.model)
            .includes((option as GroupedModel).model)
        }}
      />
    </RootContainer>
  )
}

export default MakeAndModelFilters
