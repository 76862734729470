import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

import IconButton from '../../../components/button/IconButton'
import { SCREEN_SIZE } from '../../../constants/display'
import { getStyles, isRebranding } from '../../../utils/styles'
import { ViewMode } from './VehicleList'

const s = () => getStyles().colors.showroom.vehicleList
export const VehicleListTitle = styled.h1`
  font-size: 24px;
  font-weight: ${getStyles().fonts.title.weight};
`

export const VehicleCatalog = styled.div<ViewModeProps>`
  display: ${(props) => (props.viewMode === 'section' ? 'block' : 'flex')};
  flex-wrap: wrap;
`

export interface ViewModeProps {
  animationOnHover?: boolean
  viewDisabled?: string
  readonly viewMode: ViewMode
}

const vehicleCardDisabledView = (props: ViewModeProps) =>
  props.viewDisabled === 'true' &&
  `
     pointer-events: none;
     opacity: 0.7;
`

const animationOnHover = (props: ViewModeProps) =>
  props.animationOnHover &&
  `
    :hover {
      box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px 0px;

      img {
        transition: all 0.2s ease-out;
        transform: scale(1.1);
      }
    }
    `

const vehicleCardlistView = (props: ViewModeProps) =>
  props.viewMode === 'list' &&
  `
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: row;
    ${VehicleImage} img {
        height: 190px;
    }
`
const vehicleCardGridView = (props: ViewModeProps) =>
  props.viewMode === 'grid' &&
  `
  @media (min-width: ${SCREEN_SIZE.MOBILE}px) and (max-width: ${SCREEN_SIZE.TABLET}px) {
    width: calc(50% - (15px * 2));
  }
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    width: calc(100% - (15px * 2));
  }
  width: calc(25% - (15px * 2));
`

export const VehicleCard = styled.div<ViewModeProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding-bottom: 10px;
  background: ${s().card.background};
  box-shadow: ${s().card.shadow} 0px 2px 4px 0px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  ${animationOnHover}

  ${vehicleCardlistView}
  ${vehicleCardGridView}
  ${vehicleCardDisabledView}
`

const VehicleInformationListView = (props: ViewModeProps) =>
  props.viewMode === 'list' &&
  `
    flex-grow: 2;
    padding: 20px;
`
export const Root = styled.div<ViewModeProps>`
  padding: 0 15px 6px;
  color: ${s().font};
  font-weight: 700;

  ${VehicleInformationListView}
`

export const VehicleImage = styled.div`
  position: relative;

  img {
    width: 100%;
  }
`

export const VehicleTitle = styled.h2`
  font-size: 20px;
  font-weight: 800;
  color: ${s().title.font};
  margin: 5px 0 5px;
`

export const VehicleEdition = styled.div`
  padding-bottom: 5px;
  color: ${s().vehicleSubtitle.font};
`

export const VehiclePrice = styled.div`
  overflow-wrap: break-word;
  color: ${s().vehiclePrice.font};
`

const vehicleMonthlyPriceListView = (props: ViewModeProps) =>
  props.viewMode === 'list' &&
  `
    padding: 20px 30px;
`

export const VehicleMonthlyPrice = styled.div<ViewModeProps>`
  padding: 15px;
  color: ${s().monthlyPrice.font2};
  ${vehicleMonthlyPriceListView}
`

export const MonthlyPrice = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${s().monthlyPrice.font1};
`

export const PrimaryLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  background-color: ${s().primaryLabel.background};
  border-bottom-left-radius: 4px;
  height: 30px;
  min-width: 100px;
  text-align: center;
  z-index: 2;
  padding-left: 12px;
  padding-right: 24px;

  ::before {
    background-color: ${s().primaryLabel.before.background};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    content: '';
    height: 30px;
    left: -8px;
    position: absolute;
    top: 0px;
    transform: skew(20deg) rotate(0deg);
    width: 14px;
    z-index: -1;
  }
`

export const LabelText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${s().labelText.font};
  letter-spacing: 0.015em;
  line-height: 1.8;
  font-size: ${getStyles().fonts.vehicleList.commercialLabel.size};
  font-weight: 800;
`

export const PrimaryLabelText = styled(LabelText)`
  color: ${s().primaryLabelText.font};
`

export const SecondaryLabel = styled(PrimaryLabel)`
  background-color: ${s().secondaryLabel.background};
  color: ${s().labelText.font};
  ::before {
    background-color: ${s().secondaryLabel.before.background};
  }
`

export const TertiaryLabel = styled(PrimaryLabel)`
  background-color: ${s().tertiaryLabel.background};
  color: ${s().labelText.font};
  ::before {
    background-color: ${s().tertiaryLabel.before.background};
  }
`

export const IconButtonSection = styled.div`
  margin-right: 16px;
  width: 138px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconButtonStyled = styled(IconButton)`
  margin: 0;
  background-color: ${s().iconButton.background};
`

export const ViewButtonStyled = styled(Button)`
  min-width: 36px;
  min-height: 36px;
  border-radius: 36px;
  padding: 0;
  margin-left: 10px;
  display: inline-flex;
  justify-content: center;
  background-image: ${isRebranding() ? 'none' : 'inherit'};
  background-color: ${s().saveQuoteLinkCopy};
  & .MuiButton-startIcon {
    margin: 0;
  }

  & .MuiSvgIcon-root {
    font-size: 24px;
  }
`

export const FiltersButtonStyled = styled(Button)`
  display: none;
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    display: inline-flex;
    justify-content: center;
    max-height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 36px;
    padding: 0;
    margin-left: 10px;
    svg {
      font-size: 1.2rem;
      margin-left: 7px;
    }
  }
`

export const FilterButtonContainer = styled.div`
  margin-right: 11px;
`

export const VehicleCatalogControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    justify-content: flex-start;
  }
`
export const VehicleListSorting = styled.div``

export const VehicleListItemsPerPage = styled.div``

export const VehicleViewControls = styled.div`
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    button:nth-child(2) {
      display: none;
    }
  }
`
export const VehicleCatalogPaginationPane = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;

  & .MuiPaginationItem-page {
    font-weight: 800;
  }

  & .MuiPaginationItem-page.Mui-selected {
    background: ${s().selectedItem.background};
    color: ${s().selectedItem.font};
  }

  & .MuiPaginationItem-page.Mui-selected:hover {
    color: ${s().selectedItem.hover.font1};
  }

  & .MuiPaginationItem-page:hover {
    color: ${s().selectedItem.hover.font2};
  }
`
export const PaginationDetails = styled.div`
  color: ${s().pagination.details.font};
  text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
  padding-right: 16px;
`
export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 77px);
  flex: 1 0 auto;
`

export const QuoteSpinnerContainer = styled(SpinnerContainer)`
  position: fixed;
  right: 50%;
  transform: translate(50%, 0%);
  width: 100%;
  background: ${s().quoteSpinner.background};
  bottom: 0;
  align-items: center;
`
export const EmptyVehicleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`

export const EmptyVehicleListText = styled.div`
  margin: 14px 0;
`
export const EmptyVehicleListBoldText = styled.p`
  font-weight: bold;
  text-align: center;
`

export const ClearFiltersButtonStyled = styled(Button)`
  font-size: 16px;
  color: ${getStyles().colors.showroom.vehicleListBody.clearFilters};
`

export const PageDataControls = styled.div`
  display: flex;
  margin: 15px 0;
`

interface CO2ContainerProps {
  viewMode: ViewMode
}
export const CO2Container = styled.div<CO2ContainerProps>`
  display: flex;
  margin-top: ${(props) => (props.viewMode === 'list' ? '16' : '0')}px;
  position: ${(props) => (props.viewMode === 'grid' ? 'relative' : null)};
  top: ${(props) => (props.viewMode === 'grid' ? '-10' : null)}px;
  margin-left: ${(props) => (props.viewMode === 'grid' ? '1' : '0')}em;
`
export const SingleCO2LabelSpacer = styled.div`
  height: 1.5rem;
`

export const IconSection = styled.div`
  margin-left: 7px;
  button {
    margin: 0;
    padding: 0;
  }
`
