import { Input } from '@velocity/ui/draft'
import { FC } from 'react'

import { FormFieldStyled } from './PublicOrderForm.styled'

interface Props {
  label: string
  field: string
}
const DisabledInput: FC<Props> = ({ label, field }) => {
  return (
    <FormFieldStyled label={label} required={true}>
      <Input value={field} disabled={true} />
    </FormFieldStyled>
  )
}

export default DisabledInput
