import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.colorItem.item

export const CheckboxContainer = styled.div`
  display: flex;
  line-height: 0.9rem;
  align-items: center;
`
export const ChevronIconUp = styled(ChevronUpIcon)`
  width: 10px;
  color: ${s().upIcon};
  cursor: pointer;
`
export const ChevronIconDown = styled(ChevronDownIcon)`
  width: 10px;
  color: ${s().downIcon};
  cursor: pointer;
`
export const OptionsSwitchContainer = styled.div`
  display: inline-flex;
  margin-bottom: 0.5rem;
`
export const OptionsContainer = styled.div`
  display: flex;
`
export const OptionsDetailLabel = styled.div`
  font-size: 0.75rem;
  color: ${s().detailLabel};
  cursor: pointer;
  padding-right: 0.3rem;
`
export const description = styled.div`
  flexbasis: 73%;
`
export const price = styled.div`
  flexbasis: 17%;
  textalign: end;
`
export const ColorBoxSpacer = styled.div`
  min-width: 35px;
`
