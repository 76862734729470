import { Entry } from '../../../model/Entry'
import { Accessory, Quote } from '../../../model/Quote'
import { PrintableItem } from '../../vehicle-configurator/price/PriceSelectedOptions'
import { dataTagName } from '../../vehicle-configurator/util/dataTagUtil'
import { createDetail } from './quotation'

const updateAccessoryDetails = (
  quote: Quote,
  currency: Intl.NumberFormat,
): Entry[] => {
  const accessoryDetails: Entry[] = []
  const forEachAccessory = (accessory: Accessory) => {
    accessoryDetails.push(
      createDetail(
        accessory.name || '',
        accessory.price ? currency.format(accessory.price.amount) : '',
        dataTagName(accessory.name, 'vehicleAccessory'),
      ),
    )
  }

  quote.accessories && quote.accessories.forEach(forEachAccessory)
  quote.customAccessories && quote.customAccessories.forEach(forEachAccessory)

  return accessoryDetails
}

const flattenAccessoriesToOptions = (
  accessories: Accessory[],
): PrintableItem[] => {
  const result: PrintableItem[] = []
  accessories &&
    accessories.forEach((a) =>
      result.push({
        optionId: `${a.id}`,
        manufacturerName: a.name,
        price: a?.price?.amount,
      }),
    )
  return result
}

export { updateAccessoryDetails, flattenAccessoriesToOptions }
