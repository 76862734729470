import { Drawer } from '@material-ui/core'
import { CloseIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { SCREEN_SIZE } from '../../../constants/display'
import { getStyles } from '../../../utils/styles'

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  @media (max-width: ${SCREEN_SIZE.MOBILE}px) {
    display: none;
  }
`
export const SmallScreenRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  padding: 0 1em;
`
export const Icon = styled.div`
  margin: 2.5em 1em 0 1em;
  background: ${getStyles().colors.showroom.filters.icon.background};
  color: ${getStyles().colors.showroom.filters.icon.font};
  display: grid;
  svg {
    place-self: center;
  }
  height: 37px;
  width: 37px;
  border-radius: 50%;
  @media (min-width: ${SCREEN_SIZE.MOBILE}px) {
    display: none;
  }
  cursor: pointer;
  :hover {
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 30%);
  }
`
export const DrawerRoot = styled.div``
export const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paperAnchorLeft {
    width: 95%;
  }
`
export const CloseButton = styled(CloseIcon)`
  margin-top: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 18px;
  transform: scale(1.3);
  opacity: 0.3;
`
