import { InfoIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'
import { ImageGalleryContainerProps } from './VehicleGallery'

const s = () => getStyles().colors.vehicleGallery

export const ImageGalleryContainer = styled.div<ImageGalleryContainerProps>`
  position: relative;
  @media (max-width: 768px) {
    height: calc(50vh - 100px);
  }
`

export const ImageGalleryCarInfoMessage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  h1 {
    color: ${s().title.font};
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0 5px 15px;
    margin: 0;
  }
`

export const ElectricRangeText = styled.p`
  color: ${s().electricRangeText.font};
  font-size: 12px;
  font-weight: 700;
  padding: 0px 0 5px 15px;
  margin: 0;
  text-align: right;
`

export const InfoIconStyled = styled(InfoIcon)`
  width: 20px;
  padding-right: 5px;
`
export const ImageGalleryOverlayInfoText = styled.p`
  color: ${s().infoText.font};
  font-size: 14px;
  margin: 0;
`

export const ImageGalleryOverlayInfoMessage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 5px;
  @media (max-width: 768px) {
    bottom: 12px;
  }
`
export const Rotate360Overlay = styled.div`
  position: absolute;
  bottom: 30px;
  left: 6px;
  display: flex;
  align-items: center;
  font-size: 17px;
  gap: 8px;
  font-weight: bold;
  color: ${s().rotate360.font};
  img {
    height: 25px;
    width: 25px;
  }
  @media (max-width: 768px) {
    bottom: 57px;
  }
`
