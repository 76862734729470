import { SetStateAction } from 'react'

import { ShowroomQuote } from '../../../model/ShowroomQuote'
import { ternaryValue } from '../../../utils/defaults'
import { ShowroomState } from '../reducer/showroomReducer'
import { shouldIncludeItem } from './brandsView/brandsViewUtil'

export const sortVehicles = (
  quotes: ShowroomQuote[],
  sortingType: string,
  sortByQuotePrice = false,
) => {
  const quotesCopy = JSON.parse(JSON.stringify(quotes)) as ShowroomQuote[]

  if (sortingType === 'AZ_SORT_LABEL') {
    quotesCopy.sort(azSort)
  } else if (sortingType === 'ZA_SORT_LABEL') {
    quotesCopy.sort(zaSort)
  } else if (sortingType === 'HIGHEST_PRICE_SORT_LABEL') {
    quotesCopy.sort((a: ShowroomQuote, b: ShowroomQuote) =>
      highestPriceSort(a, b, sortByQuotePrice),
    )
  } else {
    quotesCopy.sort((a: ShowroomQuote, b: ShowroomQuote) =>
      defaultSort(a, b, sortByQuotePrice),
    )
  }

  return quotesCopy
}

interface CounterLabelOptions {
  from: number
  to: number
}

export const getCounterLabelOptions = (
  page: number,
  count: number,
  itemsPerPage: number,
): CounterLabelOptions => {
  let from
  let to

  if (page === 0) {
    from = 1
    to = count < itemsPerPage ? count : itemsPerPage
  } else {
    from = page * itemsPerPage + 1
    to = Math.min(count, (page + 1) * itemsPerPage)
  }

  return { from, to }
}

const azSort = (a: ShowroomQuote, b: ShowroomQuote) => {
  const recommendedVehicle = a.vehicle?.recommendedVehicle ? -1 : 1
  const az2 =
    b.vehicle.make.text + b.vehicle.model.text >
    a.vehicle.make.text + a.vehicle.model.text
  const az3 = az2 ? -1 : 0
  const az1 =
    a.vehicle.make.text + a.vehicle.model.text >
    b.vehicle.make.text + b.vehicle.model.text
      ? 1
      : az3
  return a.vehicle?.recommendedVehicle !== b.vehicle?.recommendedVehicle
    ? recommendedVehicle
    : az1
}

const zaSort = function (a: ShowroomQuote, b: ShowroomQuote) {
  const za2 = ternaryValue(
    b.vehicle.make.text + b.vehicle.model.text <
      a.vehicle.make.text + a.vehicle.model.text,
    -1,
    0,
  )
  const za1 = ternaryValue(
    a.vehicle.make.text + a.vehicle.model.text <
      b.vehicle.make.text + b.vehicle.model.text,
    1,
    za2,
  )
  const recommendedVehicle = a.vehicle?.recommendedVehicle ? -1 : 1
  return a.vehicle?.recommendedVehicle !== b.vehicle?.recommendedVehicle
    ? recommendedVehicle
    : za1
}

const highestPriceSort = (
  a: ShowroomQuote,
  b: ShowroomQuote,
  sortByQuotePrice: boolean,
) => {
  const recommendedVehicle = ternaryValue(a.vehicle.recommendedVehicle, -1, 1)
  const hp4 = ternaryValue(b.vehicle.retailPrice < a.vehicle.retailPrice, -1, 0)
  const hp3 = ternaryValue(
    a.vehicle.retailPrice < b.vehicle.retailPrice,
    1,
    hp4,
  )
  const hp2 =
    (sortByQuotePrice && (b.quotePrice || 0) < a.quotePrice) || 0 ? -1 : hp3
  const hp1 =
    (sortByQuotePrice && (a.quotePrice || 0) < b.quotePrice) || 0 ? 1 : hp2
  return a.vehicle?.recommendedVehicle !== b.vehicle?.recommendedVehicle
    ? recommendedVehicle
    : hp1
}

const defaultSort = (
  a: ShowroomQuote,
  b: ShowroomQuote,
  sortByQuotePrice: boolean,
) => {
  const recommendedVehicle = ternaryValue(a.vehicle?.recommendedVehicle, -1, 1)
  const others4 = ternaryValue(
    b.vehicle.retailPrice > a.vehicle.retailPrice,
    -1,
    0,
  )
  const others3 = ternaryValue(
    a.vehicle.retailPrice > b.vehicle.retailPrice,
    1,
    others4,
  )
  const others2 =
    (sortByQuotePrice && (b.quotePrice || 0) > a.quotePrice) || 0 ? -1 : others3
  const others1 =
    (sortByQuotePrice && (a.quotePrice || 0) > b.quotePrice) || 0 ? 1 : others2
  return a.vehicle?.recommendedVehicle !== b.vehicle?.recommendedVehicle
    ? recommendedVehicle
    : others1
}

export const quotesReady = (
  isQuotesReady: boolean,
  quotes: ShowroomQuote[],
  state: ShowroomState,
  setVehicleList: (value: SetStateAction<unknown[]>) => void,
  setVehicleListReady: (value: SetStateAction<boolean>) => void,
) => {
  if (!isQuotesReady) {
    return
  }

  setVehicleList(
    (quotes || []).filter((quote: ShowroomQuote) =>
      shouldIncludeItem(state.filters, quote),
    ),
  )
  setVehicleListReady(true)
}
