import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import {
  disabledCheckbox,
  excludedCheckbox,
} from '../components/OptionCheckbox.styled'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { dataTagName } from '../util/dataTagUtil'
import { ItemInfo, RootContainer } from './ColorItem.styled'
import {
  colorItemCheckBox,
  colorItemCheckedCheckBox,
  colorItemDisabledCheckBox,
} from './utils/ColorItem'

interface Props {
  option: Option
  swatches: Record<string, string>
  currency: Intl.NumberFormat
}

const ColorItem: FC<Props> = ({ option, swatches, currency }) => {
  const { state, dispatch } = useVehicleConfig()
  const isSelected = option.state === 'selected'
  const isDisabled = option.state === 'included'
  const isExcluded = option.state === 'excluded'
  const disabledCheckboxStyle = isDisabled ? disabledCheckbox : null
  const { t } = useTranslation()

  const blsColourConfiguration = state.profile?.user?.blsConfig
  const showDisabledCheckBox =
    !blsColourConfiguration && !isSelected && (isDisabled || isExcluded)
  const showNormalCheckBox =
    (!blsColourConfiguration && !isSelected && !isDisabled && !isExcluded) ||
    (blsColourConfiguration && !isSelected)
  const showCheckedCheckBox = isSelected && !isDisabled && !isExcluded

  return (
    <RootContainer
      data-e2e-id={dataTagName(option.manufacturerName, 'colorOption')}
    >
      {showNormalCheckBox &&
        colorItemCheckBox(swatches, option, state, dispatch)}
      {showDisabledCheckBox &&
        colorItemDisabledCheckBox(
          t(DICTIONARY.DISABLED_FOR_SELECTION_AS_EXCLUDED_TOOLTIP),
          isDisabled,
          isExcluded,
          swatches,
          option,
        )}
      {showCheckedCheckBox &&
        colorItemCheckedCheckBox(
          blsColourConfiguration,
          swatches,
          option,
          state,
          dispatch,
        )}
      <ItemInfo>
        <div style={isExcluded ? excludedCheckbox : disabledCheckboxStyle}>
          {option.manufacturerName}
        </div>
        <div style={isExcluded ? excludedCheckbox : disabledCheckboxStyle}>
          {!isNaN(option?.colour?.optionMinimumRequiredPrice) &&
            currency.format(option?.colour?.optionMinimumRequiredPrice)}
        </div>
      </ItemInfo>
    </RootContainer>
  )
}

export default ColorItem
