import { datadogLogs } from '@datadog/browser-logs'

import { sendFormEvent } from '../../../components/gtm/gtm'
import { FORM_ERROR_TYPE, FORM_STEP } from '../../../constants/gtm'
import { Profile } from '../../../model/Profile'
import apiClient from '../../../utils/api'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from '../PublicOrderFormReducer'

export const initState = (
  reducer: [PublicOrderFormState, React.Dispatch<PublicOrderFormAction>],
  loading: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  params: {
    locale: string
    identifier: string
  },
  profile: Profile,
) => {
  const [isLoading, setIsLoading] = loading
  const [state, dispatch] = reducer
  const { locale, identifier } = params
  return {
    submitDisabled: () => {
      return [
        isLoading,
        state.firstNameError,
        state.lastNameError,
        !state.contactConsent,
        state.emailError,
        state.phoneError,
        profile?.product?.displayPublicOrderCompanyField &&
          state.companyNameError,
      ].some((a) => a)
    },
    onSubmitButtonClick: () => {
      setIsLoading(true)
      apiClient
        .submitPublicOrderForm(identifier, locale, {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          phone: state.phone,
          company: state.companyName,
          commsOptIn: state.updateConsent,
        })
        .then(() => {
          setIsLoading(false)
          dispatch({
            type: 'updatePublicOrderFormDialog',
            publicOrderFormDialog: true,
          })
          sendFormEvent(FORM_STEP.SUBMISSION, profile?.product.productName)
        })
        .catch((error) => {
          datadogLogs.logger.error(
            `Failed to submit public order form for ${identifier} and '${locale}'`,
            {
              error,
            },
          )
          sendFormEvent(
            FORM_STEP.SUBMISSION,
            profile?.product.productName,
            FORM_ERROR_TYPE.VALIDATION,
          )
        })
    },
  }
}
