import { Modal } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useErrorMessages from '../../../hooks/useErrorMessages'
import { ErrorType } from '../../../types/error'
import { localeByName } from '../../../utils/locale'
import { usePublicQuote } from '../../../utils/reactQueryApi'
import DownloadLink from '../../quoteSummary/components/contentBody/DownloadLink'
import { quotationSummaryDetails } from '../../quoteSummary/util/quotation'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { getQuoteLink } from './price'
import QuoteLinkInput from './QuoteLinkInput'
import { InfoLines, Text, Title } from './SavedQuoteDialog.styled'

const SavedQuoteDialog: FC = () => {
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()
  const [link, setLink] = useState<string>()
  const {
    data: quote,
    refetch,
    isFetching: isQuoteLoading,
    error: errorQuote,
    isError: isQuoteError,
  } = usePublicQuote(state.quoteIdentifier, state.locale)

  useErrorMessages(isQuoteError, errorQuote as ErrorType, t)

  useEffect(() => {
    if (state.quoteIdentifier && state.locale) {
      setLink(
        getQuoteLink(
          state.locale,
          state.quoteIdentifier,
          state.profile?.product?.productName,
        ),
      )
      refetch()
    }
  }, [
    state.quoteIdentifier,
    state.locale,
    state.profile?.product?.productName,
    refetch,
  ])

  return (
    <div>
      <Modal
        isOpened={state.showSavedQuoteDialog}
        onClose={() =>
          dispatch({
            type: 'cancelSavedQuoteDialog',
          })
        }
      >
        <Modal.Body>
          <Title>{t(DICTIONARY.SAVE_QUOTE_LABEL)}</Title>
          <InfoLines>
            <Text
              dangerouslySetInnerHTML={{
                __html: t(DICTIONARY.QUOTE_CODE_TEXT),
              }}
            />
            <QuoteLinkInput link={link} />
            {!isQuoteLoading && !isQuoteError && quote && (
              <DownloadLink
                name={`${localeByName(
                  state.locale,
                ).isoCountryCode.toUpperCase()}-${quote.calculationNumber}`}
                quote={quote}
                quoteSummary={quotationSummaryDetails(
                  quote,
                  state.currency,
                  t,
                  state.profile,
                )}
                locale={state.locale}
                profile={state.profile}
                displayPrivateCarHireNote={
                  state.profile?.product?.displayPrivateCarHireNote
                }
              />
            )}
          </InfoLines>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SavedQuoteDialog
