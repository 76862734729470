import { makeStyles, Grid } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { Profile } from '../../../model/Profile'
import { Quote } from '../../../model/Quote'
import { getFormattedDate } from '../../../utils/date'
import { isRebranding } from '../../../utils/styles'
import { quotationSummaryDetails } from '../util/quotation'
import DownloadLink from './contentBody/DownloadLink'

interface Props {
  quote: Quote
  currency: Intl.NumberFormat
  profile: Profile
  locale: string
  quoteParam: string
}

const useStyles = makeStyles({
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
})

const ContentHeader: React.FC<Props> = ({
  quote,
  currency,
  profile,
  locale,
  quoteParam,
}: Props) => {
  const { t } = useTranslation()
  const quoteSummary = currency
    ? quotationSummaryDetails(quote, currency, t, profile)
    : undefined
  const classes = useStyles()
  return (
    <>
      {quote && (
        <Grid container={true} spacing={2} alignItems="center">
          <Grid item={true} xs={12} sm={3}>
            <DownloadLink
              name={quoteParam}
              quote={quote}
              quoteSummary={quoteSummary}
              locale={locale}
              profile={profile}
              displayPrivateCarHireNote={
                profile?.product?.displayPrivateCarHireNote
              }
            />
          </Grid>
          <Grid item={true} xs={12} sm={9}>
            {isRebranding() ? (
              <Title>{t(DICTIONARY.QUOTE_SUMMARY_TITLE)}</Title>
            ) : (
              <Headline variant="400">
                {t(DICTIONARY.QUOTE_SUMMARY_TITLE)}
              </Headline>
            )}
          </Grid>
          <Grid item={true} xs={12} sm={6} className={classes.left}>
            {isRebranding() ? (
              <SubTitle data-e2e-id="QuoteSummaryDate">
                {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_DATE)} ${getFormattedDate(
                  quote.creationDate,
                )}`}
              </SubTitle>
            ) : (
              <Headline variant="200" data-e2e-id="QuoteSummaryDate">
                {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_DATE)} ${getFormattedDate(
                  quote.creationDate,
                )}`}
              </Headline>
            )}
          </Grid>
          <Grid item={true} xs={12} sm={6} className={classes.right}>
            {isRebranding() ? (
              <SubTitle data-e2e-id="QuoteSummaryNumber">
                {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_NUMBER)} ${
                  quote.calculationNumber
                }`}
              </SubTitle>
            ) : (
              <Headline variant="200" data-e2e-id="QuoteSummaryNumber">
                {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_NUMBER)} ${
                  quote.calculationNumber
                }`}
              </Headline>
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

const Title = styled.h1`
  font-size: 2.6rem;
  margin: 0;
  font-weight: 500;
`
const SubTitle = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default ContentHeader
