import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { SnackbarContent, useSnackbar } from 'notistack'
import React, { forwardRef, useCallback, useState } from 'react'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.snackMessage

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: s().card.background,
    width: '100%',
    color: s().card.font,
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
    backgroundColor: s().collapse.background,
  },
  icon: {
    color: s().icon.font,
  },
}))

const SnackMessage = forwardRef<
  HTMLDivElement,
  {
    id: string | number
    message: string | React.ReactNode
    title: string
    showTitle: boolean
  }
>((props, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded)
  }, [])

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id)
  }, [props.id, closeSnackbar])

  return (
    <SnackbarContent ref={ref}>
      <Card className={classes.card}>
        {props.showTitle && (
          <CardActions classes={{ root: classes.actionRoot }}>
            {props.title}
            <div className={classes.icons}>
              <IconButton
                aria-label="Show more"
                className={classes.expand}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon
                  className={`${classes.icon} ${
                    expanded ? classes.expandOpen : ''
                  }`.trimEnd()}
                />
              </IconButton>
              <IconButton className={classes.expand} onClick={handleDismiss}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            </div>
          </CardActions>
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
          <Paper className={classes.collapse}>{props.message}</Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
})

export default SnackMessage
