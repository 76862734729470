import { Tooltip } from '@velocity/ui/core'
import styled from 'styled-components'

import { getStyles } from '../../../../utils/styles'

const s = () => getStyles().colors.showroom.questionMarkHint

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Content = styled.div`
  text-align: center;
  height: 1.3rem;
  width: 1.3rem;
  background: ${s().content.background};
  border-radius: 50%;
  :hover {
    color: ${s().content.hover.font};
    background: ${s().content.hover.background};
    cursor: pointer;
  }
`
export const TooltipStyled = styled(Tooltip)``
export const TooltipTextRoot = styled.div`
  display: flex;
  flex-direction: column;
  color: ${s().tooltip.font};
  background: ${s().tooltip.background};
`
export const TooltipTextTitle = styled.div`
  font-weight: bold;
  background: ${s().tooltip.title.background};
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-bottom: 1px solid ${s().tooltip.title.border};
`
export const TooltipTextContent = styled.div`
  padding: 0.5rem;
`
