import { Autocomplete } from '@material-ui/lab'
import styled from 'styled-components'

import { getStyles } from '../../../../utils/styles'

export const AutocompleteStyled = styled(Autocomplete)`
  background: ${getStyles().colors.showroom.autocomplete.background};
  width: 100%;
`
export const RootContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`
