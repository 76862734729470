import { Color } from '../utils/color'

export const styles = {
  fonts: {
    pdf: {
      page: 'Chillax',
      weight: {
        light: 400,
        medium: 400,
        bold: 500,
      },
      itemLabel: { weight: 400 },
      itemValue: { weight: 500 },
      title: { size: 32, font: 'Chillax', weight: 500 },
      pageTitle: { size: 20 },
      left: { size: 16 },
      right: { size: 16 },
    },
    velocity: {
      button: {
        fontWeight: '500',
        font: 'Chillax',
        size: '1rem',
      },
      typography: {
        body: 'Source Sans 3',
      },
      chip: 'Source Sans 3',
      input: 'Source Sans 3',
    },
    body: { font: 'Source Sans 3', weight: 400, size: '1rem' },
    heading: 'Chillax',
    vehicleList: {
      commercialLabel: {
        size: '16px',
      },
    },
    title: { weight: 500 },
    publicOrder: {
      savedQuoteDialog: {
        weight: 500,
      },
    },
  },
  colors: {
    velocity: {
      infoNotice: {
        borderLeft: Color.semanticInfoHigh,
        background: Color.semanticInfoLow,
        color: Color.neutral800,
      },
      tooltip: {
        border: Color.neutral300,
        before: {
          background: Color.neutral100,
          border: Color.neutral300,
        },
      },
      autocomplete: {
        focused: Color.primary500,
      },
      slider: {
        thumb: 'rgba(0, 98, 98, 0.16)',
        track: Color.primary100,
        color: Color.primary500,
      },
      iconButton: {
        background: Color.primary400,
        color: Color.neutralWhite,
      },
      button: {
        label: Color.primary400,
        border: Color.primary300,
        closeButton: Color.primary400,
        backgroundImage1: Color.primary200,
        backgroundImage2: Color.primary500,
        background: Color.secondary400,
        hover: {
          border: Color.primary500,
          background: Color.primary50,
        },
      },
      select: {
        font: Color.primary100,
        selected: Color.primary500,
        hoover: Color.primary500,
      },
    },
    homePage: {
      background: Color.neutralWhite,
      border: Color.neutral300,
      heading: Color.secondary400,
      subHeading: Color.secondary400,
      option: { border: Color.neutral300, background: Color.neutral100 },
    },
    home: {
      icon: Color.primary500,
    },
    emptyCarListPage: {
      icon: Color.secondary500,
    },
    savedConfigurations: {
      icon: Color.secondary500,
    },
    topNav: {
      font: Color.textDefault,
      background: Color.neutralWhite,
      border: Color.neutralWhite,
      borderBottom: Color.neutral300,
      item: {
        font: Color.primary500,
      },
      topScroll: {
        background: Color.neutral600,
        upIcon: Color.neutralWhite,
      },
    },
    customer: {
      font: Color.neutralWhite,
      background: Color.primary500,
      option: { font: Color.neutral900 },
    },
    languageSelect: {
      selected: Color.primary400,
      unselected: Color.neutralWhite,
      font: Color.secondary400,
      background: Color.neutralWhite,
      expandButton: Color.neutralWhite,
      option: {
        font: Color.neutral900,
      },
    },
    orderApproved: {
      font: Color.primary500,
    },
    scheduledMaintenance: {
      font: Color.primary500,
    },
    serviceInterruption: {
      font: Color.primary500,
    },
    unauthorisedError: {
      font: Color.primary500,
    },
    snackMessage: {
      font: Color.neutralWhite,
      background: Color.semanticSuccessHigh,
      image: {
        font: Color.semanticSuccessHigh,
        background: Color.neutralWhite,
      },
      card: {
        font: Color.neutralWhite,
        background: Color.semanticErrorHigh,
      },
      collapse: {
        background: Color.semanticErrorLow,
      },
      icon: {
        font: Color.neutralWhite,
      },
    },
    pdf: {
      font: Color.secondary500,
      footer: {
        note: Color.neutral900,
        line: Color.neutral900,
      },
    },
    spinner: {
      stop1: Color.secondary100,
      stop2: Color.primary500,
    },
    useErrorMessage: {
      font: Color.neutralWhite,
    },
    usePolicyValidationMessages: {
      font: Color.semanticErrorHigh,
    },
    carInfo: {
      font: Color.neutral600,
    },
    priceInfo: {
      background: Color.neutral200,
      vat: { font: Color.neutral600 },
    },
    footer: {
      font: Color.neutralWhite,
      background: Color.secondary400,
    },
    savedQuote: {
      carMissingIcon: Color.secondary500,
      font: Color.primary500,
      title: Color.secondary500,
      price: { deleteIcon: Color.neutral400 },
      carInfo: { deleteIcon: Color.neutral400 },
      moreInfo: {
        marker: Color.primary400,
        button: {
          font: Color.primary400,
          background: Color.neutralWhite,
        },
        title: Color.neutral700,
      },
      pagination: {
        details: {
          font: Color.neutral700,
        },
        itemPage: {
          font: Color.primary400,
        },
        selected: {
          font: Color.neutralWhite,
          background: Color.primary400,
          hover: {
            font: Color.neutralWhite,
          },
        },
      },
    },
    invalidQuoteError: {
      font: Color.primary500,
    },
    downloadLink: {
      font: Color.primary200,
    },
    tileMessage: {
      font: Color.semanticErrorHigh,
      content: {
        font: Color.primary500,
      },
    },
    configurator: {
      accessories: {
        deleteIcon: Color.neutral400,
      },
      specification: {
        font: Color.neutral700,
        label: Color.primary500,
        description: {
          border: Color.neutral800,
          item: Color.neutral400,
          upIcon: Color.neutral700,
          downIcon: Color.neutral700,
        },
        wrapper: Color.neutral400,
        upIcon: Color.neutral700,
        downIcon: Color.neutral700,
      },
      limetedOptions: {
        font: Color.primary500,
        background: Color.neutral100,
      },
      table: {
        background: Color.neutral100,
        border: Color.neutral800,
        row: { border: Color.primary400 },
        description: {
          button: {
            font: Color.primary500,
          },
          title: {
            font: Color.neutral700,
          },
          item: {
            font: Color.primary500,
          },
        },
      },
      favorites: {
        font: Color.neutral800,
        background: Color.neutralWhite,
        deleteIcon: Color.neutral400,
        card: Color.neutral200,
        policyLabel: { background: Color.brandSupport1C500 },
        moreInfo: {
          marker: Color.primary500,
          button: {
            font: Color.primary300,
            background: Color.neutralWhite,
          },
          title: Color.neutral700,
        },
      },
      fullScreenBottomNav: Color.neutral100,
      buttonNav: Color.neutralWhite,
      glqs_accordion: {
        font: Color.neutral700,
        background: Color.neutral300,
        chevron: Color.neutral700,
        header: {
          background: Color.brandSupport1C400,
        },
      },
      co2: {
        arrow: {
          font: Color.neutralWhite,
          label: {
            aPlus: Color.semanticSuccessHigh,
            a: Color.semanticSuccessHigh,
            b: Color.grassGreen100,
            c: Color.freshGreen100,
            d: Color.brandSupport1C400,
            e: Color.brandSupport1C500,
            f: Color.primary400,
            g: Color.semanticErrorHigh,
          },
        },
        single: {
          font: Color.neutralWhite,
          background: Color.primary500,
        },
      },
      services: {
        background: Color.neutral100,
        font: Color.neutral700,
      },
      rules: {
        font: Color.primary500,
        closeButton: Color.neutral700,
        item: {
          title: Color.neutral700,
          operator: Color.neutralWhite,
          and: Color.brandSupport2C500,
        },
        required: {
          font: Color.neutral700,
          border: Color.neutral400,
          background: Color.primary500,
          item: Color.neutral700,
          operator: { font: Color.neutralWhite, or: Color.brandSupport1C400 },
        },
      },
      priceBreakdown: {
        continueButton: {
          saveQuote: Color.primary500,
          disabledSaveQuote: Color.neutral400,
        },
        closeIcon: Color.neutralWhite,
        context: {
          removeAllButton: {
            font: Color.primary500,
            weight: 500,
          },
          showAllButton: {
            font: Color.primary500,
          },
          carHireNote: {
            font: Color.primary500,
            background: Color.semanticInfoLow,
            alertIcon: Color.semanticInfoHigh,
            border: Color.brandSupport2C300,
            borderLeft: Color.semanticInfoHigh,
          },
          notice: {
            font: Color.neutral700,
            background: Color.neutral200,
            border: Color.neutral300,
          },
          separator: Color.neutral900,
        },
        before: { background: Color.neutralWhite },
        expandIcon: { font: Color.neutralWhite },
        summary: {
          font: Color.neutralWhite,
          background: Color.secondary400,
        },
        details: {
          background: Color.neutral100,
        },
      },
      contractOptions: {
        font: Color.neutral700,
        background: Color.neutral100,
      },
      optionCheckbox: {
        exclude: {
          background: Color.brandSupport1C500,
        },
        tooltip: {
          background: Color.neutralWhite,
          font: Color.neutral900,
          text: {
            font: Color.neutral900,
            background: Color.neutralWhite,
          },
        },
      },
      accordion: Color.neutral300,
      accordions: {
        background: Color.neutral100,
        font: Color.primary500,
      },
      colorItem: {
        item: {
          upIcon: Color.primary500,
          downIcon: Color.primary500,
          detailLabel: Color.primary500,
          checkIcon: Color.freshGreen.freshGreen80,
        },
        withOptions: {
          background: Color.primary400,
          outline: Color.primary400,
          border: Color.neutral300,
          optionsSwitch: {
            font: Color.primary500,
          },
          downIcon: Color.primary500,
          upIcon: Color.primary500,
          pointer: Color.primary500,
        },
        box: {
          border: Color.neutral300,
          checked: {
            outline: Color.primary500,
            background: Color.primary500,
          },
        },
      },
      imageGallery: {
        icon: Color.primary500,
        moreInfo: {
          font: Color.primary500,
          border: Color.primary500,
        },
      },
      moreInfo: {
        background: Color.neutral300,
        drawer: {
          background: Color.neutralWhite,
          border1: Color.neutral400,
          border2: Color.neutral400,
          closeButton: Color.primary500,
          topCloseButton: Color.black,
        },
      },
      sideContainer: {
        background: Color.neutralWhite,
      },
      accordionSummary: {
        font: Color.neutral700,
        background: Color.neutral300,
        details: {
          background: Color.neutral100,
        },
        hover: {
          background: Color.neutral200,
        },
        before: {
          background: Color.neutralWhite,
        },
      },
      mainContainer: {
        background: Color.neutralWhite,
      },
      closeIcon: {
        font: Color.neutralWhite,
      },
    },
    vehicleGallery: {
      title: {
        font: Color.primary500,
      },
      electricRangeText: {
        font: Color.primary500,
      },
      infoText: {
        font: Color.neutral500,
      },
      rotate360: {
        font: Color.neutral500,
      },
    },
    publicOrder: {
      input: {
        background: Color.neutral200,
      },
      consent: Color.primary500,
      form: {
        font: Color.primary500,
        background: Color.brandSupport2C100,
        border: Color.brandSupport2C300,
        infoIcon: Color.primary500,
        endIcon: Color.neutralWhite,
      },
      disclaimer: {
        background: Color.neutral200,
        font: Color.neutral700,
        border: Color.neutral700,
      },
    },
    quoteSummary: {
      wltp: {
        item: Color.neutral300,
        label: { color: Color.neutral300, weight: 400 },
      },
      template: {
        summary: Color.brandSupport2C100,
        content: { background: Color.neutralWhite, border: Color.neutral400 },
        icon: {
          font: Color.primary500,
        },
        review: {
          border: Color.brandSupport2C300,
          background: Color.brandSupport2C100,
          font: Color.primary500,
        },
      },
      content: {
        items: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        vehicle: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        price: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        option: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        footer: Color.primary400,
        contract: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        accessory: {
          item: Color.neutral300,
          label: { color: Color.neutralWhite, weight: 400 },
        },
        service: { weight: 400 },
        summary: {
          font: Color.primary500,
          background: Color.brandSupport2C100,
          border: Color.brandSupport2C300,
          review: Color.brandSupport2C100,
        },
        icon: Color.primary500,
      },
    },
    showroom: {
      vehicleListBody: {
        clearFilters: Color.primary400,
      },
      vehicleList: {
        saveQuoteLinkCopy: Color.primary500,
        iconButton: {
          background: Color.primary500,
        },
        button: {
          background1: Color.lightOrange,
          background2: Color.midOrange,
        },
        title: { font: Color.primary500 },
        vehicleSubtitle: {
          font: Color.secondary500,
        },
        vehiclePrice: {
          font: Color.secondary500,
        },
        brandsView: {
          version: { closeIcon: Color.neutral400 },
          make: {
            upIcon: Color.neutral900,
            downIcon: Color.neutral900,
          },
        },
        closeButton: Color.neutral700,
        body: { carMissingIcon: Color.secondary500 },
        sorting: {
          label: Color.neutral800,
          select: {
            background: Color.neutralWhite,
            border: Color.neutral400,
            focus: Color.neutralWhite,
          },
        },
        perPage: {
          label: Color.neutral800,
          select: {
            border: Color.neutral400,
            background: Color.neutralWhite,
            focus: Color.neutralWhite,
          },
        },
        quoteSpinner: {
          background: 'rgba(255,255,255,0.5)',
        },
        pagination: {
          details: {
            font: Color.neutral700,
          },
        },
        selectedItem: {
          font: Color.neutralWhite,
          background: Color.primary500,
          hover: {
            font1: Color.neutralWhite,
            font2: Color.primary100,
          },
        },
        primaryLabel: {
          background: Color.brandSupport2C500,
          before: {
            background: Color.brandSupport2C500,
          },
        },
        secondaryLabel: {
          background: Color.secondary300,
          before: {
            background: Color.secondary300,
          },
        },
        tertiaryLabel: {
          background: Color.brandSupport1C500,
          before: {
            background: Color.brandSupport1C500,
          },
        },
        card: {
          background: Color.neutralWhite,
          shadow: 'rgba(0, 0, 0, 0.08)',
        },
        font: Color.primary500,
        monthlyPrice: {
          font1: Color.primary500,
          font2: Color.neutral900,
        },
        labelText: {
          font: Color.textOnBrandSupport1,
        },
        primaryLabelText: {
          font: Color.neutralWhite,
        },
      },
      slider: Color.primary400,
      background: Color.neutral100,
      modelLine: {
        font1: Color.primary500,
        font2: Color.neutral500,
        border: Color.neutral400,
        hover: {
          background: Color.neutral200,
        },
        downIcon: {
          font: Color.neutral600,
        },
        upIcon: {
          font1: Color.primary500,
          font2: Color.neutral600,
        },
      },
      makeItem: {
        background: Color.neutralWhite,
        brandName: {
          font: Color.primary500,
        },
        modelHeading: {
          border: Color.neutral900,
        },
      },
      filters: {
        resetAll: Color.primary500,
        background: Color.neutral100,
        icon: {
          background: Color.primary300,
          font: Color.neutralWhite,
        },
      },
      questionMarkHint: {
        tooltip: {
          font: Color.neutral900,
          background: Color.neutralWhite,
          title: {
            background: Color.neutral100,
            border: Color.neutral300,
          },
        },
        content: {
          background: Color.secondary100,
          hover: {
            background: Color.primary500,
            font: Color.neutralWhite,
          },
        },
      },
      autocomplete: {
        background: Color.neutralWhite,
      },
    },
  },
}
