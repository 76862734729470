import { useAuth0 } from '@auth0/auth0-react'
import { Box, Menu, MenuItem } from '@material-ui/core'
import { useVelocityContext } from '@velocity/ui'
import { HamburgerIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { history } from '../../utils/historyStore'
import { StyledButton, StyledNavItem } from './TopNav.styled'

interface Props {
  locale: string
}

const MainMenu: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation()
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const [anchorEl, setAnchorEl] = useState(null)
  const ctx = useVelocityContext()

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const MenuDropdown = (
    <StyledNavItem>
      {isAuthenticated && (
        <StyledButton
          data-e2e-id="menuButton"
          startIcon={
            <HamburgerIcon size="s" color={ctx.theme.styling.gray.white} />
          }
          onClick={handleClick}
        />
      )}
      <Menu
        data-e2e-id="topNavMenu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          data-testid="mainmenu-logout-btn"
          onClick={() => {
            history.push(`/${locale}/logout`)
          }}
        >
          <Box fontWeight="bold">{t(DICTIONARY.LOG_OUT_LABEL)}</Box>
        </MenuItem>
      </Menu>
    </StyledNavItem>
  )

  const LoginButton = (
    <StyledButton
      data-testid="mainmenu-login-btn"
      data-e2e-id="loginButton"
      onClick={() => loginWithRedirect({})}
    >
      Login
    </StyledButton>
  )

  return isAuthenticated ? MenuDropdown : LoginButton
}

export default MainMenu
