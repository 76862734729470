import { datadogLogs } from '@datadog/browser-logs'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../constants/dictionary'
import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.configurator.co2.arrow
const WIDTH_BASE = 1
const HEIGHT_BASE = 0.5

const selectedBorderTop = 0.8
const unselectedBorderTop = 0.4
const selectedBorderBottom = 0.8
const unselectedBorderBottom = 0.4
const unselectedBorderLeft = 0.6
const mobileSelectedBorderTop = 0.5
const mobileUnselectedBorderTop = 0.25
const mobileSelectedBorderBottom = 0.5
const mobileUnselectedBorderBottom = 0.25
const mobileSelectedBorderLeft = 0.65
const mobileUnselectedBorderLeft = 0.35
const selectedWith = 3.2
const unselectedWidthIndex = 0.5
const selectedHeight = 2

const co2BandsMap = {
  '+': 'A+',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
}
const co2Bands = Object.values(co2BandsMap)
const colors = [
  s().label.aPlus,
  s().label.a,
  s().label.b,
  s().label.c,
  s().label.d,
  s().label.e,
  s().label.f,
  s().label.g,
]

interface Props {
  index: number
  band: string
  selectedValue: number
  showSelected: boolean
}

const CO2Arrow: FC<Props> = ({ index, band, selectedValue, showSelected }) => {
  const { t } = useTranslation()
  const isValid = co2Bands.includes(band)
  if (!isValid) {
    datadogLogs.logger.error('Invalid CO2 Label Error', {
      receivedLabel: band,
    })
  }
  const selected = co2BandsMap[band] === co2Bands[index]
  if (showSelected && !selected) {
    return null
  }
  return (
    <Root>
      <Body
        color={colors[index]}
        selected={selected}
        width={
          selected
            ? WIDTH_BASE + selectedWith
            : WIDTH_BASE + index * unselectedWidthIndex
        }
        height={HEIGHT_BASE * (selected ? selectedHeight : 1)}
      >
        <div>{selected ? co2BandsMap[band] : co2Bands[index]}</div>
        {selected && (
          <CO2Value
            data-e2e-id="co2-value"
            dangerouslySetInnerHTML={{
              __html:
                selectedValue > 0
                  ? `${selectedValue}g ${t(DICTIONARY.CO2_LABEL)}/km`
                  : '',
            }}
          />
        )}
      </Body>
      <Triangle color={colors[index]} selected={selected} />
    </Root>
  )
}

const Root = styled.div`
  display: flex;
`
interface BodyProps {
  color: string
  width: number
  height: number
  selected: boolean
}
const selectedPadding = 0.2
const unselectedPadding = 0.5
const unselectedBodyFontSize = 0.6
const heightIndex = 1.6
const widthIndex = 1.6
const selectedFontSize = 0.8
const unselectedFontSize = 0.4
const Body = styled.div<BodyProps>`
  display: flex;
  align-items: center;
  gap: 0.4em;
  padding-left: ${(props) =>
    props.selected ? selectedPadding : unselectedPadding}em;
  font-size: ${(props) => (props.selected ? 1 : unselectedBodyFontSize)}em;
  color: ${s().font};
  background-color: ${(props) => props.color};
  height: ${(props) => props.height * heightIndex}rem;
  width: ${(props) => props.width * widthIndex}rem;
  @media (max-width: 768px) {
    height: ${(props) => props.height}rem;
    width: ${(props) => props.width}rem;
    font-size: ${(props) =>
      props.selected ? selectedFontSize : unselectedFontSize}em;
  }
  div: last-child {

  }
`
interface TriangleProps {
  color: string
  selected: boolean
}
const Triangle = styled.div<TriangleProps>`
  height: 0;
  width: 0;
  border-top: ${(props) =>
      props.selected ? selectedBorderTop : unselectedBorderTop}rem
    solid transparent;
  border-bottom: ${(props) =>
      props.selected ? selectedBorderBottom : unselectedBorderBottom}rem
    solid transparent;
  border-left: ${(props) => (props.selected ? 1 : unselectedBorderLeft)}rem
    solid ${(props) => props.color};
  @media (max-width: 768px) {
    border-top: ${(props) =>
        props.selected ? mobileSelectedBorderTop : mobileUnselectedBorderTop}rem
      solid transparent;
    border-bottom: ${(props) =>
        props.selected
          ? mobileSelectedBorderBottom
          : mobileUnselectedBorderBottom}rem
      solid transparent;
    border-left: ${(props) =>
        props.selected
          ? mobileSelectedBorderLeft
          : mobileUnselectedBorderLeft}rem
      solid ${(props) => props.color};
  }
`
const CO2Value = styled.div`
  width: 100%;
  align-items: end;
  display: flex;
  justify-content: end;
  white-space: nowrap;
  padding-right: 2px;
  font-size: 0.8em;
  text-shadow: 1px 1px 1px black;
  @media (max-width: 768px) {
    left: 1rem;
    top: 0.2rem;
    font-size: 0.6em;
  }
`
export default CO2Arrow
