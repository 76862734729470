import { CheckIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'
import { DisabledCheckboxProps } from '../components/OptionCheckbox.styled'

const s = () => getStyles().colors.configurator.colorItem

export const RootContainer = styled.div`
  display: flex;
  margin: 0.5em 0;
  gap: 3%;
`
export const ColorBox = styled.div<DisabledCheckboxProps>`
  width: 35px;
  height: 35px;
  padding: 2px;
  border: 1px solid ${s().box.border};
  cursor: pointer;
  flex-shrink: 0;
  &:hover {
    cursor: ${(props) => (props.$itemNotAllowed ? 'not-allowed' : 'pointer')};
  }
`
export const CheckedColorBox = styled(ColorBox)`
  position: relative;
  outline: ${s().box.checked.outline} solid 2px;
  outlineoffset: 1px;
  > svg {
    display: block;
    color: white;
    background: ${s().box.checked.background};
    max-width: 100%;
    height: auto;
    width: 10px;
    position: absolute;
    left: 23px;
    top: 0;
  }
`
export const Icon = styled(CheckIcon)`
  display: none;
`
export const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  > div {
    margin-right: 0.2rem;
  }
`
