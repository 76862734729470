import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'
import { co2EmissionTooltip } from './filtersUtil'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const CO2EmissionsFilter: FC<Props> = ({ filtersResponse }) => {
  const [currentPosition, setCurrentPosition] = useState<number>(
    filtersResponse.maxCO2,
  )
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()
  const debouncedRequest = useDebounce(
    (newValue) => {
      dispatch({
        type: 'updateCO2Emission',
        filters: {
          co2Emission: {
            value: Number(newValue[0]),
          },
        },
      })
    },
    1000,
    true,
  )

  useEffect(() => {
    if (state.filters.co2Emission) {
      setCurrentPosition(Number(state.filters.co2Emission.value))
    }
  }, [state.filters.co2Emission])

  return (
    <ShowroomSlider
      track="normal"
      htmlTitle={{
        text: {
          __html: sanitize(t(DICTIONARY.VEHICLE_CO2_EMISSIONS)),
        },
        hint: {
          __html: sanitize(t(co2EmissionTooltip(filtersResponse.co2Type))),
        },
      }}
      value={currentPosition}
      range={{ min: 0, max: filtersResponse.maxCO2 }}
      onChange={(_, newValue) => {
        setCurrentPosition(Number(newValue))
        debouncedRequest(newValue.toString())
      }}
      marks={[
        { value: 0, label: '0' },
        {
          value: filtersResponse.maxCO2,
          label: `${filtersResponse.maxCO2}`,
        },
      ]}
    />
  )
}

export default CO2EmissionsFilter
