import { Button, Input, PlusIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../../components/button/VelocityButton'
import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { capitalize } from '../../../utils/string'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { initState } from './util/addAccessory'

const AddAccessory: FC = () => {
  const { t } = useTranslation()
  const showAddButtonState = useState(true)
  const setShowAddButton = showAddButtonState[1]
  const descriptionState = useState('')
  const [description, setDescription] = descriptionState
  const priceState = useState(0)
  const [price, setPrice] = priceState
  const profile = useProfileContext()
  const st = initState(
    useVehicleConfig(),
    descriptionState,
    priceState,
    showAddButtonState,
  )
  if (!profile?.product.customAccessoriesEnabled) {
    return null
  }

  return (
    <>
      {!st.showAddButton() && (
        <InputContainer>
          <Inputs>
            {isRebranding() ? (
              <>
                <StyledInputName
                  data-testId="descriptionInput"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  autoFocus={true}
                />
                <StyledInputValue
                  data-testId="priceInput"
                  value={price}
                  onChange={(e) => {
                    const newPrice = Number(e.target.value)
                    setPrice(isNaN(newPrice) ? 0 : newPrice)
                  }}
                />
              </>
            ) : (
              <>
                <Input
                  data-testId="descriptionInput"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  autoFocus={true}
                />
                <Input
                  data-testId="priceInput"
                  value={price}
                  onChange={(e) => {
                    const newPrice = Number(e.target.value)
                    setPrice(isNaN(newPrice) ? 0 : newPrice)
                  }}
                />
              </>
            )}
          </Inputs>
          <Buttons>
            {isRebranding() ? (
              <>
                <VelocityButton onClick={st.onSaveClick}>Save</VelocityButton>
                <CancelButton variant="text" onClick={st.onCancelClick}>
                  {capitalize(t(DICTIONARY.CANCEL))}
                </CancelButton>
              </>
            ) : (
              <>
                <Button variant="contained" onClick={st.onSaveClick}>
                  Save
                </Button>
                <Button variant="outlined" onClick={st.onCancelClick}>
                  {capitalize(t(DICTIONARY.CANCEL))}
                </Button>
              </>
            )}
          </Buttons>
        </InputContainer>
      )}

      {st.showAddButton() && (
        <AddButton>
          {isRebranding() ? (
            <VelocityButton
              testId="addAccessoryAyvensButton"
              startIcon={<PlusIcon color="#FFFFF" size="xs" />}
              onClick={() => setShowAddButton(false)}
            >
              {t(DICTIONARY.ADD_ACCESSORY)}
            </VelocityButton>
          ) : (
            <Button
              data-testid="addAccessoryButton"
              variant="contained"
              startIcon={<PlusIcon color="#FFFFF" size="xs" />}
              onClick={() => setShowAddButton(false)}
            >
              {t(DICTIONARY.ADD_ACCESSORY)}
            </Button>
          )}
        </AddButton>
      )}
    </>
  )
}

const AyvensInput = styled.input`
  height: 32px;
  font-size: 16px;
  font-family: 'Source Sans 3';
  &:focus-visible {
    outline: none;
    border: 1px solid;
  }
`
const StyledInputName = styled(AyvensInput)`
  width: 80%;
`
const StyledInputValue = styled(AyvensInput)`
  width: 20%;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 13px;
`
export const AddButton = styled.div`
  width: calc(100%-100px);
  margin: 0 auto 16px auto;
`
export const Inputs = styled.div`
  display: flex;
  gap: 8px;
  height: 32px;
  svg {
    width: 19px;
    margin: auto;
    cursor: pointer;
  }
  div: last-child {
    width: 100px;
  }
`
export const Buttons = styled.div`
  display: flex;
  margin: 16px auto;
  gap: 128px;
`
const CancelButton = styled(Button)`
  color: ${getStyles().colors.configurator.rules.closeButton};
  font-size: 14px;
  font-weight: 500;
`

export default AddAccessory
