import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React from 'react'

import ChillaxMedium from '../../assets/fonts/Chillax-Medium.woff'
import ChillaxRegular from '../../assets/fonts/Chillax-Regular.woff'
import ChillaxSemibold from '../../assets/fonts/Chillax-Semibold.woff'
import LeasePlanBold from '../../assets/fonts/LeasePlan-Bold.woff'
import LeasePlanLight from '../../assets/fonts/LeasePlan-Light.woff'
import LeasePlanRegular from '../../assets/fonts/LeasePlan-Regular.woff'
import { Profile } from '../../model/Profile'
import { Quote, QuoteSummary } from '../../model/Quote'
import { getLangCountryFromLocale } from '../../utils/locale'
import { getStyles } from '../../utils/styles'
import PDFFooter from './PDFFooter'
import PDFHeader from './PDFHeader'
import AccessoryDetailsSection from './sections/AccessoryDetailsSection'
import ContactDetailsSection from './sections/ContactDetailsSection'
import ContractDetailsSection from './sections/ContractDetailsSection'
import MakeAndModelSection from './sections/MakeAndModelSection'
import OptionDetailsSection from './sections/OptionDetailsSection'
import { pageNumberTextRender } from './sections/pdfDocumentUtil'
import PriceDetailsSection from './sections/PriceDetailsSection'
import ServiceDetailsSection from './sections/ServiceDetailsSection'
import SummaryTitleSection from './sections/SummaryTitleSection'
import VehicleDetailsSection from './sections/VehicleDetailsSection'
import WltpSection from './sections/WltpSection'

Font.register({
  family: 'Chillax',
  fonts: [
    {
      src: ChillaxRegular,
      fontWeight: 400,
    },
    {
      src: ChillaxMedium,
      fontWeight: 500,
    },
    {
      src: ChillaxSemibold,
      fontWeight: 600,
    },
  ],
})
Font.register({
  family: 'LeasePlan',
  fonts: [
    {
      src: LeasePlanLight,
      fontWeight: 400,
    },
    {
      src: LeasePlanRegular,
      fontWeight: 600,
    },
    {
      src: LeasePlanBold,
      fontWeight: 800,
    },
  ],
})

export const pdfDocStyles = StyleSheet.create({
  page: {
    fontFamily: `${getStyles().fonts.pdf.page}`,
    paddingTop: 42,
    paddingRight: 60,
    paddingLeft: 60,
    paddingBottom: 42,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  title: {
    fontWeight: getStyles().fonts.pdf.title.weight,
    textAlign: 'center',
    color: getStyles().colors.pdf.font,
    width: '100%',
    fontSize: getStyles().fonts.pdf.title.size,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: getStyles().fonts.pdf.title.font,
  },
  subTitle: {
    flexDirection: 'row',
    color: getStyles().colors.pdf.font,
    width: '100%',
    fontSize: 12,
    fontWeight: getStyles().fonts.pdf.title.weight,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: getStyles().fonts.pdf.title.font,
  },
  priceSubTitle: {
    flexDirection: 'row',
    color: getStyles().colors.pdf.font,
    width: '100%',
    fontSize: 10,
    fontWeight: getStyles().fonts.pdf.title.weight,
    paddingTop: 10,
    paddingBottom: 10,
  },
  left: {
    fontSize: getStyles().fonts.pdf.left.size,
    textAlign: 'left',
    width: '100%',
  },
  right: {
    fontSize: getStyles().fonts.pdf.right.size,
    textAlign: 'right',
    width: '100%',
  },
  wideDescription: {
    width: '100%',
    fontSize: 8,
    fontWeight: getStyles().fonts.pdf.itemLabel.weight,
    paddingTop: 2,
    paddingBottom: 2,
  },
  wideValue: {
    width: '100%',
    fontSize: 8,
    fontWeight: getStyles().fonts.pdf.itemValue.weight,
    paddingTop: 2,
    paddingBottom: 2,
  },
  halfDescription: {
    width: '50%',
    fontSize: 8,
    fontWeight: getStyles().fonts.pdf.itemLabel.weight,
    paddingTop: 2,
    paddingBottom: 2,
  },
  description: {
    width: '25%',
    fontSize: 8,
    fontWeight: getStyles().fonts.pdf.itemLabel.weight,
    paddingTop: 2,
    paddingBottom: 2,
  },
  value: {
    width: '25%',
    fontSize: 8,
    fontWeight: getStyles().fonts.pdf.itemValue.weight,
    paddingTop: 2,
    paddingBottom: 2,
  },
  quarter: {
    width: '25%',
  },
  half: {
    width: '50%',
  },
  pageNumber: {
    fontSize: 8,
    textAlign: 'right',
  },
  footer: {
    position: 'absolute',
    bottom: 15,
    left: 50,
    right: 50,
  },
})

interface Props {
  quote: Quote
  quoteSummary: QuoteSummary
  locale: string
  profile: Profile
}

const PDFDocument: React.FC<Props> = ({
  quote,
  quoteSummary,
  locale,
  profile,
}) => {
  const getStylesByCountry = () =>
    'PT' === getLangCountryFromLocale(locale).language
      ? { ...pdfDocStyles.page, paddingBottom: 150 }
      : pdfDocStyles.page

  return (
    <Document>
      <Page size="A4" style={getStylesByCountry()}>
        <PDFHeader parentStyles={pdfDocStyles} product={profile.product} />
        <View>
          <ContactDetailsSection locale={locale} product={profile.product} />
          <SummaryTitleSection quote={quote} parentStyles={pdfDocStyles} />
          <MakeAndModelSection quote={quote} parentStyles={pdfDocStyles} />
          <VehicleDetailsSection
            vehicleDetails={quoteSummary.vehicleDetails}
            parentStyles={pdfDocStyles}
          />
          <WltpSection
            co2Emissions={quoteSummary.co2Emissions}
            parentStyles={pdfDocStyles}
          />
          <OptionDetailsSection
            optionDetails={quoteSummary.optionDetails}
            parentStyles={pdfDocStyles}
          />
          <AccessoryDetailsSection
            accessoryDetails={quoteSummary.accessoryDetails}
            parentStyles={pdfDocStyles}
          />
          <ServiceDetailsSection
            serviceDetails={quoteSummary.serviceDetails}
            parentStyles={pdfDocStyles}
          />
          <ContractDetailsSection
            contractDetails={quoteSummary.contractDetails}
            parentStyles={pdfDocStyles}
          />
          <PriceDetailsSection
            vehicleCost={quoteSummary.vehicleCost || []}
            totalLease={quoteSummary.totalLease || []}
            parentStyles={pdfDocStyles}
            priceDetail={quote.priceDetail}
          />
          <PDFFooter profile={profile} locale={locale} />
        </View>
        <View wrap={false} fixed={true} style={pdfDocStyles.footer}>
          <Text style={pdfDocStyles.pageNumber} render={pageNumberTextRender} />
        </View>
      </Page>
    </Document>
  )
}

export default PDFDocument
