import { DISPLAY } from '../../../constants/display'
import { Entry } from '../../../model/Entry'
import { Quote, Service } from '../../../model/Quote'
import { dataTagName } from '../../vehicle-configurator/util/dataTagUtil'
import { createDetail } from './quotation'
import { createServiceDetails, filterServices } from './service'

export const updateOptionDetails = (
  quote: Quote,
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): Entry[] => {
  const vehicle = quote.vehicle
  const optionDetails: Entry[] = []

  if (!vehicle || !vehicle.selectedOptions) return optionDetails

  vehicle.selectedOptions.forEach(function (selectedOption) {
    optionDetails.push(
      createDetail(
        selectedOption.manufacturerName,
        currency.format(selectedOption.adjustedPrice ?? selectedOption.price),
        dataTagName(selectedOption.manufacturerName, 'vehicleOption'),
      ),
    )
  })

  const services: Service[] = filterServices(
    quote.services,
    DISPLAY.OPTIONS_SERVICES,
  )

  return optionDetails.concat(
    createServiceDetails(services, currency, translations),
  )
}
