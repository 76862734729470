import { datadogLogs } from '@datadog/browser-logs'
import { useState, useEffect } from 'react'

const defaultCurrencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
})

export const useCurrency = (
  locale: string,
  currencyCode: string,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
) => {
  const [currency, setCurrency] = useState(null)
  useEffect(() => {
    if (locale && currencyCode) {
      const localeFormatted = locale.split('_').join('-')
      try {
        const currencyFormatter = new Intl.NumberFormat(localeFormatted, {
          style: 'currency',
          currency: currencyCode,
          minimumFractionDigits,
          maximumFractionDigits:
            minimumFractionDigits > maximumFractionDigits
              ? minimumFractionDigits
              : maximumFractionDigits,
        })
        setCurrency(currencyFormatter)
      } catch (error) {
        datadogLogs.logger.error(
          `Failed to format currency for '${currencyCode}' ${
            (error as Error)?.message || ''
          })`,
        )
        setCurrency(defaultCurrencyFormatter)
      }
    } else {
      setCurrency(defaultCurrencyFormatter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, currencyCode])

  return currency
}
