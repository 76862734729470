import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router, Switch } from 'react-router-dom'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import PrivateRoute from './components/auth/PrivateRoute'
import PublicRoute from './components/auth/PublicRoute'
import MainLayout from './components/layouts/MainLayout'
import ErrorBoundary from './components/utils/ErrorBoundary'
import HeaderManager from './components/utils/HeaderManager'
import { privateRoutes, routes as publicRoutes } from './config/routes'
import { AppConfigProvider, useAppConfig } from './context/AppConfigContext'
import TranslationProvider from './context/TranslationContext'
import RedirectToHome from './pages/redirectToHome/RedirectToHome'
import MasterTemplate from './pages/root/MasterTemplate'
import { setAppState } from './utils/authAppState'
import { history } from './utils/historyStore'
import { getStyles, saveCountry } from './utils/styles'

const queryClient = new QueryClient()
const v = () => getStyles().colors.velocity

if (process.env.REACT_APP_DATADOG_API_CLIENT_KEY) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_API_CLIENT_KEY,
    site: process.env.REACT_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    env: process.env.REACT_APP_DATADOG_ENV,
  })
}

const defaultMeta = {
  dd_env: process.env.REACT_APP_DATADOG_ENV,
  dd_source: process.env.REACT_APP_DATADOG_SERVICE_NAME,
}

datadogLogs.setLoggerGlobalContext(defaultMeta)

if (process.env.REACT_APP_DATADOG_ENV === 'preprod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_ID,
    site: process.env.REACT_APP_DATADOG_SITE,
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    sessionSampleRate: 100,
    trackUserInteractions: false,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const LOCAL_DOMAINS = ['localhost', '127.0.0.1']

if (LOCAL_DOMAINS.includes(window.location.hostname)) {
  datadogLogs.logger.setHandler('silent')
}

const App: React.FC = () => {
  const { auth } = useAppConfig()

  const onRedirectCallback = (appState: AppState) => {
    setAppState(appState)
    history.replace(appState?.returnTo || window.location.pathname)
  }
  saveCountry()

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <Auth0Provider
        domain={auth?.domain}
        clientId={auth?.clientId}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth?.audience,
          organization: process.env.REACT_APP_AUTH_ORGANIZATION,
        }}
      >
        <Router history={history}>
          <HeaderManager>
            <TranslationProvider>
              <ErrorBoundary>
                <MasterTemplate>
                  <Switch>
                    {publicRoutes.map((route) => (
                      <PublicRoute key={route.path} {...route} />
                    ))}
                    {privateRoutes.map((route) => (
                      <PrivateRoute key={route.path} {...route} />
                    ))}
                    <PublicRoute
                      path="*"
                      component={RedirectToHome}
                      layout={MainLayout}
                      profileNeeded={false}
                    />
                  </Switch>
                </MasterTemplate>
              </ErrorBoundary>
            </TranslationProvider>
          </HeaderManager>
        </Router>
      </Auth0Provider>
    </QueryClientProvider>
  )
}

const AppWithConfig = () => (
  <AppConfigProvider>
    <App />
  </AppConfigProvider>
)

const GlobalStyles = createGlobalStyle`
  body, p {
    font-size:${getStyles().fonts.body.size};
    font-family: '${getStyles().fonts.body.font}';
    font-weight: ${getStyles().fonts.body.weight};
  }
  h1, h2, h3 {
    font-family: '${getStyles().fonts.heading}';
  }
  .MuiAutocomplete-option{
    font-weight: ${getStyles().fonts.body.weight}; 
  }  
  .MuiButton-root, button {
    font-family: '${getStyles().fonts.velocity.button.font}';
    font-weight: ${getStyles().fonts.velocity.button.fontWeight};
    font-size: ${getStyles().fonts.velocity.button.size};
  }
  .MuiTypography-body1,.MuiTypography-body2 {
    font-family: '${getStyles().fonts.velocity.typography.body}';
    font-weight: ${getStyles().fonts.body.weight};
  }
  .MuiChip-root {
    font-family: '${getStyles().fonts.velocity.chip}';
  }

  .MuiInputLabel-root {
    font-family: '${getStyles().fonts.velocity.input}';
    font-weight: ${getStyles().fonts.body.weight};
  }
  
  .MuiInputBase-input {
    font-family: '${getStyles().fonts.velocity.input}';
    font-weight: ${getStyles().fonts.body.weight};
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px ${v().slider.thumb};
  }
  .MuiSlider-trackInverted .MuiSlider-track {
    background-color: ${v().slider.track};
  }
  .vehicle-image-gallery .image-gallery-icon:hover {
    color: ${getStyles().colors.configurator.imageGallery.icon};
  }

  .MuiTooltip-tooltip {
    padding: 0;
    border: 1px solid ${v().tooltip.border};
    font-weight: ${getStyles().fonts.body.weight};
  }

  .MuiTooltip-tooltip span::before {
    background-color: ${v().tooltip.before.background};
    border: 1px solid ${v().tooltip.before.border};
  }

  .MuiListItem-root {
    font-weight: ${getStyles().fonts.body.weight};
    color: ${v().select.font};
  }
  .MuiListItem-root.Mui-selected {
    border-left-color: ${v().select.selected};
  }

  .MuiListItem-root.MuiListItem-button:hover {
    color: ${v().select.hoover};
  }
  .MuiButton-outlinedPrimary:hover {
    border: 1px solid ${v().button.hover.border};
    background-color: ${v().button.hover.background};
  }
  .MuiDialogTitle-root {
    button svg {
      color: ${v().button.closeButton};
    }
  }
  .MuiButton-outlinedPrimary {
    color: ${v().button.label};
    border: 1px solid ${v().button.border};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${getStyles().colors.velocity.autocomplete.focused};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${getStyles().colors.velocity.autocomplete.focused};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${getStyles().colors.velocity.autocomplete.focused};
  }
`
export default AppWithConfig
