import Card from '@material-ui/core/Card'
import { FC } from 'react'
import styled from 'styled-components'

import { Color } from '../../utils/color'

interface Props {
  text: string
}
const FrenchDecommissionAlert: FC<Props> = ({ text }) => {
  return (
    <AlertCard
      data-e2e-id="FrenchDecommissionAlert"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

const AlertCard = styled(Card)`
  width: 100%;
  margin: 8px 0px;
  padding: 10px;
  background-color: ${Color.orange.orange50} !important;
  border: 1px solid ${Color.orange.orange500} !important;
`

export default FrenchDecommissionAlert
