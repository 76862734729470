import { User } from '@auth0/auth0-spa-js'

import { APP_METADATA_KEY } from '../constants/user'
import { getCountryDefaultLocale, isValidLocale } from './locale'

export const getUserData = (user: User) => user[APP_METADATA_KEY]

export const getUserLocale = (user: User) => {
  if (user) {
    const userData = getUserData(user)
    const userLanguage = userData?.default_locale
    const userCountryCode = userData?.country_code

    if (userLanguage && userCountryCode) {
      const userLocale = `${userLanguage}_${userCountryCode.substring(
        userCountryCode.length - 2,
      )}`
      if (isValidLocale(userLocale)) {
        return userLocale
      }
    }
    if (userCountryCode) {
      const userLocale = getCountryDefaultLocale(
        userCountryCode.substring(userCountryCode.length - 2),
      )
      if (userLocale) {
        return userLocale
      }
    }
  }
  return 'en_IE'
}
