interface BuildUrlOptions {
  host?: string
  path?: string
  queryParams?: Record<string, unknown>
}

export const buildUrl = (options: BuildUrlOptions): string => {
  let url = (options.host || '') + (options.path || '')

  if (!options.queryParams) {
    return url
  }

  const params = serializeParams(options.queryParams)

  if (params !== '') {
    url = url + `?${params}`
  }

  return url
}

export const serializeParams = (
  queryParams: Record<string, unknown>,
): string => {
  const queryString: string[] = []

  for (const key in queryParams) {
    if (!queryParams[key]) {
      continue
    }

    if (Array.isArray(queryParams[key])) {
      const optionList = queryParams[key] as string[]

      optionList.forEach((value) => {
        if (value) {
          queryString.push(`${key}=${encodeURIComponent(String(value).trim())}`)
        }
      })
      continue
    }

    queryString.push(
      `${key}=${encodeURIComponent(String(queryParams[key]).trim())}`,
    )
  }

  if (queryString.length > 0) {
    return queryString.join('&')
  } else {
    return ''
  }
}
