import { makeStyles, Theme } from '@material-ui/core'
import { InfoIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import VelocityNotice from '../../../../components/notice/VelocityNotice'
import { DICTIONARY } from '../../../../constants/dictionary'
import { Profile } from '../../../../model/Profile'
import { Quote } from '../../../../model/Quote'
import { ternaryValue } from '../../../../utils/defaults'
import { getStyles, isRebranding } from '../../../../utils/styles'
import { quotationSummaryDetails } from '../../util/quotation'
import AccessoryDetailsSection from './AccessoryDetailsSection'
import AgreementNoticeSection from './AgreementNoticeSection'
import ContractDetailsSection from './ContractDetailsSection'
import MakeAndModelSection from './MakeAndModelSection'
import OptionDetailsSection from './OptionDetailsSection'
import PriceDetailsSection from './PriceDetailsSection'
import ServiceDetailsSection from './ServiceDetailsSections'
import VehicleDetailsSection from './VehicleDetailsSection'
import WltpReport from './WltpReport'

const s = () => getStyles().colors.quoteSummary.content

interface Props {
  quote: Quote
  currency: Intl.NumberFormat
  profile: Profile
}

const useStyles = makeStyles((theme: Theme) => ({
  summaryReview: {
    display: 'flex',
    padding: '15px',
    marginBottom: '20px',
    border: `1px solid ${s().summary.border}`,
    borderRadius: '0',
    backgroundColor: s().summary.background,
    color: s().summary.font,
  },
  summaryIcon: {
    marginRight: '10px',
    color: s().icon,
  },
}))

const ContentBody: React.FC<Props> = ({ quote, currency, profile }) => {
  const { t } = useTranslation()
  const quoteSummary = ternaryValue(
    !!currency,
    quotationSummaryDetails(quote, currency, t, profile),
    undefined,
  )
  const classes = useStyles()

  return (
    <>
      <MakeAndModelSection quote={quote} />

      {[quoteSummary, quoteSummary?.vehicleDetails?.length > 0].every(
        (a) => a,
      ) && <VehicleDetailsSection details={quoteSummary.vehicleDetails} />}

      {[
        quoteSummary?.co2Emissions,
        quoteSummary?.co2Emissions.length > 0,
      ].every((a) => a) && (
        <WltpReport emissions={quoteSummary?.co2Emissions} />
      )}

      {[quoteSummary, quoteSummary?.optionDetails?.length > 0].every(
        (a) => a,
      ) && <OptionDetailsSection details={quoteSummary.optionDetails} />}

      {[quoteSummary, quoteSummary?.accessoryDetails.length > 0].every(
        (a) => a,
      ) && <AccessoryDetailsSection details={quoteSummary.accessoryDetails} />}

      {[quoteSummary, quoteSummary?.serviceDetails?.length > 0].every(
        (a) => a,
      ) && <ServiceDetailsSection details={quoteSummary.serviceDetails} />}

      {[quoteSummary, quoteSummary?.contractDetails?.length > 0].every(
        (a) => a,
      ) && <ContractDetailsSection details={quoteSummary.contractDetails} />}

      {[
        quoteSummary,
        [
          quoteSummary?.vehicleCost.length > 0,
          quoteSummary?.totalLease.length > 0,
        ].some((a) => a),
      ].every((a) => a) && (
        <PriceDetailsSection
          priceDetail={quote.priceDetail}
          vehicleCost={quoteSummary.vehicleCost}
          totalLease={quoteSummary.totalLease}
        />
      )}

      {profile?.product.displayPrivateCarHireNote && (
        <>
          {ternaryValue(
            isRebranding(),
            <VelocityNotice text={t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE)} />,
            <div className={classes.summaryReview} color={s().summary.review}>
              <Icon size="s" className={classes.summaryIcon} />
              <div>{t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE)}</div>
            </div>,
          )}
        </>
      )}

      {profile?.product.quoteSummaryAgreementNoteEnabled && (
        <AgreementNoticeSection profile={profile} />
      )}
    </>
  )
}

const Icon = styled(InfoIcon)`
  width: 60px;
`
export default ContentBody
