import * as countries from '../config/countries'
import { CountryConfigType } from '../model/CountryConfigType'
import { getLangCountryFromLocale } from './locale'

export const getCountryConfig = (locale: string): CountryConfigType => {
  if (!locale) return {}
  const countryCode = getLangCountryFromLocale(locale).country.toLowerCase()
  /*eslint import/namespace: ['error', { allowComputed: true }]*/
  const config = countries[countryCode]
  if (!config) {
    return {}
  }
  return config
}
