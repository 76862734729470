import { Dispatch, SetStateAction } from 'react'

import { addMouseMoveEventListener } from '../util/eventHandlers'
import { ButtonOnType } from '../util/navigations'
import { DraggingInfo } from './useIsDragging'

let isDragging: boolean
let setCurrentIndex: Dispatch<SetStateAction<number>>
let imageCount: number
let currentIndex: number
let previousPosition = 0
let positionCounter = 0
let rotateDirection = '' as ButtonOnType

export const initState = (
  [currentIndexP, setCurrentIndexP]: [number, Dispatch<SetStateAction<number>>],
  imageCountP: number,
  { isDragging: d }: DraggingInfo,
) => {
  currentIndex = currentIndexP
  isDragging = d
  setCurrentIndex = setCurrentIndexP
  imageCount = imageCountP
  return {
    isDragging,
    setCurrentIndex,
    rotateDirection,
  }
}
export const useEffectHandler = () => {
  if (!isDragging) {
    rotateDirection = ''
    return null
  }
  const onMove = (e: PointerEvent) => {
    e.preventDefault()
    e.pageX !== previousPosition && setCurrentIndex(getNextImageIndex(e.pageX))
  }

  return addMouseMoveEventListener(document.body, onMove)
}

const getNextImageIndex = (currentPosition: number): number => {
  updatePositionCounter(currentPosition)
  let newImageIndex = getNewImageIndex(currentIndex)
  updateDirection(currentPosition)
  previousPosition = currentPosition
  newImageIndex = newImageIndex >= imageCount ? 0 : newImageIndex
  return newImageIndex < 0 ? imageCount - 1 - currentIndex : newImageIndex
}

const updatePositionCounter = (currentPosition: number) => {
  if (currentPosition > previousPosition) {
    if (rotateDirection === 'right') {
      positionCounter++
    }
    if (rotateDirection === 'left') {
      positionCounter = 0
    }
  }
  if (currentPosition < previousPosition) {
    if (rotateDirection === 'left') {
      positionCounter++
    }
    if (rotateDirection === 'right') {
      positionCounter = 0
    }
  }
}
const updateDirection = (currentPosition: number) => {
  if (currentPosition > previousPosition) {
    rotateDirection = 'right'
  }
  if (currentPosition < previousPosition) {
    rotateDirection = 'left'
  }
}
const getNewImageIndex = (index: number) => {
  let result = index
  const POSITION_THRESHOLD = 10
  const REDUCER = 2
  if (positionCounter % POSITION_THRESHOLD > POSITION_THRESHOLD - REDUCER) {
    if (rotateDirection === 'right') {
      result++
    }
    if (rotateDirection === 'left') {
      result--
    }
  }
  return result
}
