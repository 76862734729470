import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import { PHONE_REG_EXP } from '../../constants/validation'
import InputField from './InputField'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}

const PhoneInput: FC<Props> = ({ state, dispatch }) => {
  const { t } = useTranslation()

  const phoneValidator = (
    value: string,
    setter: Dispatch<SetStateAction<string>>,
  ) => {
    let error = false

    if (!value) {
      setter(t(DICTIONARY.ALERT_23))
      error = true
    } else if (!PHONE_REG_EXP.test(value)) {
      setter(t(DICTIONARY.INVALID_PHONE_NUMBER))
      error = true
    } else {
      setter(null)
    }
    if (error) {
      dispatch({ type: 'updatePhoneError', phoneError: true })
    } else {
      dispatch({ type: 'updatePhoneError', phoneError: false })
    }
  }

  return (
    <InputField
      label={t(DICTIONARY.PUBLIC_ORDER_TELEPHONE_LABEL)}
      name="phone"
      customHandler={phoneValidator}
      field={state.phone}
      setter={(v) => dispatch({ type: 'updatePhone', phone: v })}
    />
  )
}

export default PhoneInput
