import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import { capitalize } from '../../../utils/string'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const ElectricRangesFilter: FC<Props> = ({ filtersResponse }) => {
  const { state, dispatch } = useShowroomConfig()
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const { t } = useTranslation()
  const debouncedRequest = useDebounce(
    (newValue) => {
      dispatch({
        type: 'updateElectricRange',
        filters: {
          electricRange: {
            value: Number(newValue[0]),
          },
        },
      })
    },
    1000,
    true,
  )

  useEffect(() => {
    if (state.filters.electricRange) {
      setCurrentPosition(Number(state.filters.electricRange.value))
    }
  }, [state.filters.electricRange])

  useEffect(() => {
    if (
      filtersResponse.maxElectricRange &&
      !state.filters.electricRange.value
    ) {
      dispatch({
        type: 'updateElectricRange',
        filters: {
          electricRange: {
            value: 0,
            max: filtersResponse.maxElectricRange,
          },
        },
      })
    }
  }, [
    filtersResponse.maxElectricRange,
    dispatch,
    state.filters.electricRange.value,
  ])

  return (
    <ShowroomSlider
      track="inverted"
      title={{
        text: capitalize(t(DICTIONARY.ELECTRIC_RANGE)),
        hint: t(DICTIONARY.ELECTRIC_RANGE_FILTER_TOOLTIP),
      }}
      value={currentPosition}
      range={{ min: 0, max: filtersResponse.maxElectricRange }}
      onChange={(_, newValue) => {
        setCurrentPosition(Number(newValue))
        debouncedRequest(newValue.toString())
      }}
      marks={[
        {
          value: filtersResponse.maxElectricRange,
          label: `${filtersResponse.maxElectricRange}`,
        },
        { value: 0, label: '0' },
      ]}
    />
  )
}

export default ElectricRangesFilter
