import { ImaginSwatches } from '../../../model/ImaginSwatches'

const generateSwatchesStyles = (imaginSwatches: ImaginSwatches) => {
  const result = {}
  if (imaginSwatches && imaginSwatches.paints) {
    for (const paint in imaginSwatches.paints) {
      if (imaginSwatches.paints[paint].primarySprayCan) {
        const primaryHigh =
          imaginSwatches.paints[paint].primarySprayCan
            .primarySprayCanHighLightRGB
        const primaryLow =
          imaginSwatches.paints[paint].primarySprayCan.primarySprayCanRGB

        result[
          paint.trim().toUpperCase()
        ] = `linear-gradient(135deg,${primaryHigh},${primaryLow})`
      }
    }
  }
  return result
}

export { generateSwatchesStyles }
