import FileCopyIcon from '@material-ui/icons/FileCopy'
import { Tooltip } from '@velocity/ui/core'
import { Input } from '@velocity/ui/draft'
import { FC } from 'react'

import IconButton from '../../../components/button/IconButton'
import { isRebranding } from '../../../utils/styles'
import {
  IconSection,
  ViewButtonStyled,
} from '../../showroom/vehicle-list/VehicleList.styled'
import TooltipText from '../components/TooltipText'
import { onCopyToClipboard } from './price'
import { Line, StyledInput } from './SavedQuoteDialog.styled'

interface Props {
  link: string
}

const QuoteLinkInput: FC<Props> = ({ link }) => {
  return (
    <Line>
      {isRebranding() ? (
        <StyledInput value={link} disabled={true} />
      ) : (
        <Input value={link} disabled={true} />
      )}
      <Tooltip text={<TooltipText value="Copy to clipboard" />}>
        {isRebranding() ? (
          <IconSection>
            <IconButton
              startIcon={<FileCopyIcon />}
              onClick={() => onCopyToClipboard(link)}
            />
          </IconSection>
        ) : (
          <ViewButtonStyled
            data-testid="copy-quote-to-clipboard-button"
            onClick={() => onCopyToClipboard(link)}
            variant="contained"
            startIcon={<FileCopyIcon />}
          />
        )}
      </Tooltip>
    </Line>
  )
}

export default QuoteLinkInput
