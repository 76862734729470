import { DICTIONARY } from '../../../constants/dictionary'
import { DISPLAY } from '../../../constants/display'
import { Entry } from '../../../model/Entry'
import { Quote, Service } from '../../../model/Quote'
import { createDetail } from './quotation'

export const filterServices = (
  services: Service[],
  displayServices: string[],
): Service[] => {
  const result: Service[] = []

  if (!services || !services.length) return result
  services.forEach((service: Service) => {
    if (displayServices.indexOf(service.id) !== -1) {
      result.push(service)
    }
  })
  return result
}

export const updateServiceDetails = (
  quote: Quote,
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): Entry[] => {
  const services = filterServices(quote.services, DISPLAY.SERVICES)
  return createServiceDetails(services, currency, translations)
}

export const createServiceDetails = (
  services: Service[],
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
): Entry[] => {
  const serviceDetails: Entry[] = []
  services.forEach((service) => {
    service.levels.forEach((level) => {
      const description = 'Services.' + service.id + '.Label'

      if (Object.values(DICTIONARY).find((i) => i === description)) {
        serviceDetails.push(
          createDetail(
            translations(description) + ` - ${level.description}`,
            level.price ? currency.format(level.price.amount) : null,
            service.id,
          ),
        )
      }
    })
  })

  return serviceDetails
}
