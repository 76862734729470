import { Container } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import BottomNav from '../navigation/BottomNav'
import TopNav from '../navigation/TopNav'

const AppContentWrapper = styled.div`
  padding-bottom: 10.4rem;
  padding-top: 6.4rem;
  min-height: calc(100vh - 14.1rem);
`

const MainLayout: React.FC = ({ children }) => (
  <>
    <TopNav />
    <Container>
      <AppContentWrapper>
        <main role="main">{children}</main>
      </AppContentWrapper>
    </Container>
    <BottomNav />
  </>
)

export default MainLayout
