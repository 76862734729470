import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Quote } from '../../../model/Quote'
import { getFormattedDate } from '../../../utils/date'
import { pdfDocStyles } from '../PDFDocument'

interface Props {
  quote: Quote
  parentStyles: typeof pdfDocStyles
}

const SummaryTitleSection: React.FC<Props> = ({ quote, parentStyles }) => {
  const { t } = useTranslation()
  return (
    <View>
      <View style={parentStyles.title} wrap={false}>
        <Text>{t(DICTIONARY.QUOTE_SUMMARY_TITLE)}</Text>
      </View>
      <View style={parentStyles.subTitle} wrap={false}>
        <Text style={parentStyles.left}>
          {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_DATE)} ${getFormattedDate(
            quote.creationDate,
          )}`}
        </Text>
        <Text style={parentStyles.right}>
          {`${t(DICTIONARY.QUOTE_SUMMARY_QUOTE_NUMBER)} ${
            quote.calculationNumber
          }`}
        </Text>
      </View>
    </View>
  )
}

export default SummaryTitleSection
