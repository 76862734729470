import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import React, { useEffect } from 'react'

import TopScroll from '../../components/topnav/TopScroll'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import useErrorMessages from '../../hooks/useErrorMessages'
import { ErrorType } from '../../types/error'
import { usePublicQuote } from '../../utils/reactQueryApi'
import ContentBody from './components/contentBody/ContentBody'
import QuoteSummaryTemplate from './components/QuoteSummaryTemplate'

const PublicQuoteSummary: React.FC = () => {
  const { t } = useTranslation()
  const { locale, quoteParam } = useParams<{
    locale: string
    quoteParam: string
  }>()
  const {
    data: quote,
    isError: isQuoteError,
    error: errorQuote,
    isFetching: isQuoteLoading,
    refetch: refetchQuote,
  } = usePublicQuote(quoteParam, locale)

  useErrorMessages(isQuoteError, errorQuote as ErrorType, t)

  const profile = useProfileContext()

  const maxDigits = 2
  const currency = useCurrency(locale, profile?.user.currencyCode, maxDigits)

  useEffect(() => {
    quoteParam && locale && refetchQuote()
  }, [quoteParam, locale, refetchQuote])

  return (
    <>
      {[!isQuoteLoading, quote, quote?.vehicle, currency, profile].every(
        (a) => a,
      ) && (
        <QuoteSummaryTemplate
          quote={quote}
          profile={profile}
          locale={locale}
          currency={currency}
          quoteParam={quoteParam}
        >
          <ContentBody quote={quote} currency={currency} profile={profile} />
          <TopScroll />
        </QuoteSummaryTemplate>
      )}
    </>
  )
}

export default PublicQuoteSummary
