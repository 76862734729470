import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { useCurrency } from '../../../hooks/useCurrency'
import useDebounce from '../../../hooks/useDebounce'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import ShowroomSlider from '../component/ShowroomSlider'
import { useShowroomConfig } from '../context/ShowroomContext'
import { getPriceFilterTitle } from './PriceFilterUtil'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const MonthlyPriceFilter: FC<Props> = ({ filtersResponse }) => {
  const [currentPosition, setCurrentPosition] = useState<{
    min: number
    max: number
  }>({
    min: null,
    max: null,
  })
  const { t } = useTranslation()
  const { state, dispatch } = useShowroomConfig()

  const title = {
    text: t(getPriceFilterTitle(filtersResponse.priceParameter)),
    hint: t(DICTIONARY.PRICE_FILTER_TOOLTIP),
  }

  const displayMax =
    state.profile?.product?.showroomMaxDisplayMonthlyPricesFilter ||
    filtersResponse.maxPrice

  const maxLimited =
    !!state.profile?.product?.showroomMaxDisplayMonthlyPricesFilter

  const actualMax = filtersResponse.maxPrice

  const currency = useCurrency(
    state.locale,
    state.profile.user.currencyCode,
    0,
    0,
  )

  const debouncedDispatch = useDebounce(
    (newValue) => {
      const minSelected = newValue[0][0] as number
      const maxSelected = newValue[0][1] as number

      dispatch({
        type: 'updateMonthlyPrice',
        filters: {
          monthlyPrice: {
            start: minSelected,
            end: maxSelected !== displayMax ? maxSelected : actualMax,
          },
        },
      })
    },
    1000,
    true,
  )

  useEffect(() => {
    if (filtersResponse.maxPrice && !state.filters.monthlyPrice) {
      const minBudget =
        filtersResponse.minPrice && Math.floor(filtersResponse.minPrice)
      const maxBudget =
        filtersResponse.maxPrice && Math.ceil(filtersResponse.maxPrice)
      dispatch({
        type: 'updateMonthlyPrice',
        filters: {
          monthlyPrice: {
            start: minBudget,
            end: maxBudget,
            min: minBudget,
            max: maxBudget,
          },
        },
      })
    }
  }, [
    filtersResponse.minPrice,
    filtersResponse.maxPrice,
    dispatch,
    state.filters.monthlyPrice,
  ])

  useEffect(() => {
    if (state.filters.monthlyPrice) {
      setCurrentPosition({
        min: state.filters.monthlyPrice.start,
        max: state.filters.monthlyPrice.end,
      })
    }
  }, [state.filters.monthlyPrice])

  const labelFormatter = (value: number) => {
    return `${maxLimited && value === displayMax ? '+' : ''}${currency.format(
      value,
    )}`
  }

  return (
    <>
      {currency && state.filters.monthlyPrice && (
        <ShowroomSlider
          track="normal"
          title={title}
          value={[
            currentPosition.min || state.filters.monthlyPrice.min,
            currentPosition.max ||
              Math.min(displayMax, state.filters.monthlyPrice.max),
          ]}
          onChange={(_, newValue) => {
            setCurrentPosition({ min: newValue[0], max: newValue[1] })
            debouncedDispatch(newValue)
          }}
          range={{
            min: state.filters.monthlyPrice.min,
            max: Math.min(displayMax, state.filters.monthlyPrice.max),
          }}
          marks={[
            {
              value: state.filters.monthlyPrice.min,
              label: `${currency.format(state.filters.monthlyPrice.min)}`,
            },
            {
              value: displayMax,
              label: `${maxLimited ? '+' : ''}${currency.format(displayMax)}`,
            },
          ]}
          labelFormatter={labelFormatter}
        />
      )}
    </>
  )
}

export default MonthlyPriceFilter
