import { Dispatch } from 'react'

import { Accessory } from '../../../model/Quote'
import { AppAction, AppState } from '../reducer/VehicleConfigReducer'
import { descendingComparator, Order } from '../util/tableHeadSort'

const getComparator = (
  order: Order,
  orderBy: string,
): ((a: Accessory, b: Accessory) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const onAccessoryClick = (
  accessory: Accessory,
  state: AppState,
  dispatch: Dispatch<AppAction>,
) => {
  const checked = state.selectedAccessories.includes(accessory.id)

  if (checked) {
    dispatch({
      type: 'deSelectAccessory',
      accessory: accessory,
    })
    return
  }
  dispatch({
    type: 'selectAccessory',
    accessory: accessory,
  })
}

const newAccessories = (a: AppAction, s: AppState) => {
  if (!a.customAccessories && !s.customAccessories) {
    throw new Error(
      `newAccessories: invalid parameters, (${s.customAccessories}, ${a.customAccessories})`,
    )
  }
  return a.customAccessories || s.customAccessories
}

export { getComparator, onAccessoryClick, newAccessories }
