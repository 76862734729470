import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const useLocaleFromPath = () => {
  const [locale, setLocale] = useState(null)
  const [language, setLanguage] = useState(null)
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname
    const match = path.match(/^\/([a-z]{2}(?:_[A-Z]{2})?)\//)
    if (match) {
      const matchedLocale = match[1]
      setLocale(matchedLocale)
      setLanguage(matchedLocale.split('_')[0])
    }
  }, [location.pathname])

  return { locale, language }
}
