import { Box } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { Button, ChevronLeftIcon } from '@velocity/ui/draft'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../../components/button/VelocityButton'
import Spinner from '../../../components/spinner/Spinner'
import { DICTIONARY } from '../../../constants/dictionary'
import { Profile } from '../../../model/Profile'
import { Quote } from '../../../model/Quote'
import { capitalize } from '../../../utils/string'
import { getStyles, isRebranding } from '../../../utils/styles'
import { initState } from './util/contentFooter'

export interface ContentFooterProps {
  quote: Quote
  locale: string
  profile: Profile
  onSubmitQuote: () => void
}

const ContentFooter: React.FC<ContentFooterProps> = (props) => {
  const { t } = useTranslation()
  const isQuoteSummary = window.location.pathname.indexOf('quote-summary') > -1
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const st = initState(
    props,
    {
      enqueueSnackbar,
      closeSnackbar,
      text: t(DICTIONARY.ADDED_TO_SAVED_QUOTES_MESSAGE),
    },
    isQuoteSummary,
    useState<boolean>(false),
    useState(props.profile.user.canSubmitOrder && isQuoteSummary),
    t,
  )
  return (
    <Root>
      <Line>
        {st.showSpinner() && (
          <SpinnerSection
            display="flex"
            height="calc(100vh - 32.3rem)"
            justifyContent="center"
            alignItems="center"
            data-e2e-id="pageLoader"
          >
            <Spinner size={10} />
          </SpinnerSection>
        )}
        {isQuoteSummary && (
          <StyledButton
            data-testid="content-footer-back"
            disabled={st.isDisabled()}
            onClick={st.goBack}
            variant="outlined"
            color="secondary"
            startIcon={<ChevronLeftIcon color="#FFFFF" size="xs" />}
          >
            {t(DICTIONARY.BACK_BUTTON)}
          </StyledButton>
        )}
        {st.showSubmitButton() &&
          (isRebranding() ? (
            <SubmitButton
              size="s"
              testId="content-footer-submit"
              disabled={st.isDisabled()}
              onClick={st.submitQuote}
            >
              {t(DICTIONARY.ORDER_SUBMIT_LABEL)}
            </SubmitButton>
          ) : (
            <Button
              variant="contained"
              data-testId="content-footer-submit"
              disabled={st.isDisabled()}
              onClick={st.submitQuote}
            >
              {t(DICTIONARY.CONFIRM_SELECTION_TITLE)}
            </Button>
          ))}
      </Line>
      {st.showSaveQuoteButton() && (
        <Line>
          <SaveQuote data-testid="save-quote-btn" onClick={st.saveQuote}>
            <SaveIcon />
            <div>
              {isRebranding()
                ? capitalize(t(DICTIONARY.ADD_TO_SAVED_QUOTES))
                : t(DICTIONARY.ADD_TO_SAVED_QUOTES)}
            </div>
          </SaveQuote>
        </Line>
      )}
    </Root>
  )
}

const SubmitButton = styled(VelocityButton)``
const SaveQuote = styled.div`
  color: ${getStyles().colors.quoteSummary.content.footer};
  display: flex;
  cursor: pointer;
  & > svg {
    height: 1.2rem;
  }
  & > div {
    text-transform: ${isRebranding() ? 'inherit' : 'uppercase'};
    font-weight: ${getStyles().fonts.body.weight};
  }
`
const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
const Line = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  height: 40px;
`
const SpinnerSection = styled(Box)`
  position: fixed;
  top: 8rem;
  zindex: 5;
`
const StyledButton = styled(Button)`
  text-transform: ${() => (isRebranding() ? 'none' : 'inherit')};
`

export default ContentFooter
