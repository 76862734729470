import { FC, useRef, useState } from 'react'
import styled from 'styled-components'

import { useHandleDragImage } from './hook/useHandleDragImage'
import Navigations from './Navigations'
import { initState, showImage, showNavigations } from './util/imageGallery360'

interface Props {
  items: string[]
  isPriceSectionExpanded: boolean
  preloadedImages: HTMLImageElement[]
}

const ImageGallery360: FC<Props> = ({
  items,
  isPriceSectionExpanded,
  preloadedImages,
}) => {
  const index = useState(0)
  const { wrapper } = initState(
    useRef<HTMLDivElement>(),
    index[0],
    items,
    isPriceSectionExpanded,
  )
  const { isDragging, rotateDirection } = useHandleDragImage(
    wrapper,
    index,
    items.length,
  )

  return (
    <Root isPriceSectionExpanded={isPriceSectionExpanded}>
      <Wrapper ref={wrapper}>
        {preloadedImages.map((item: HTMLImageElement, i: number) => (
          <ImageSection
            isPriceSectionExpanded={isPriceSectionExpanded}
            show={showImage(i)}
            key={item.src}
            className="image-gallery-360-image"
            data-testid="image-section"
          >
            <ImageWrapper
              dangerouslySetInnerHTML={{ __html: item.outerHTML }}
            />
            {showNavigations() && (
              <Navigations
                rotateDirection={rotateDirection}
                isDragging={isDragging}
              />
            )}
          </ImageSection>
        ))}
      </Wrapper>
    </Root>
  )
}

const Wrapper = styled.div`
  touch-action: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const Root = styled.div<{ isPriceSectionExpanded: boolean }>`
  position: relative;
  height: ${(p) =>
    p.isPriceSectionExpanded ? `calc(50vh - 100px)` : `calc(100vh - 110px)`};
  @media (max-width: 768px) {
    height: 100%;
  }
`
const ImageWrapper = styled.div`
  height: 100%;
`
const ImageSection = styled.div<{
  show: boolean
  isPriceSectionExpanded: boolean
}>`
  display: ${(p) => (p.show ? 'block' : 'none')};
  div img {
    position: relative;
    pointer-events: none;
    cursor: ew-resize;
    z-index: 2;
    margin: 0 auto;
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
    left: 0;
    bottom: ${(p) => (p.isPriceSectionExpanded ? `-38` : `47`)}px;
    @media (max-width: 768px) {
      height: 375px;
      bottom: 46px;
    }
  }
`

export default ImageGallery360
