import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react'

import {
  defaultShowroomState,
  ShowroomAction,
  showroomReducer,
  ShowroomState,
} from '../reducer/showroomReducer'

export interface ShowroomConfig {
  state: ShowroomState
  dispatch: Dispatch<ShowroomAction>
}

const ShowroomConfigContext = createContext<ShowroomConfig>({
  state: null,
  dispatch: null,
})

const ShowroomConfigProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(showroomReducer, defaultShowroomState)

  const value = { state, dispatch }

  return (
    <ShowroomConfigContext.Provider value={value}>
      {children}
    </ShowroomConfigContext.Provider>
  )
}

const useShowroomConfig = () => {
  const context = useContext(ShowroomConfigContext)

  if (context === undefined) {
    throw new Error(
      'useShowroomConfig must be used within a ShowroomConfigProvider',
    )
  }

  return context
}
export { ShowroomConfigProvider, useShowroomConfig, ShowroomConfigContext }
