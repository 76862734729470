import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../constants/dictionary'
import DisabledInput from './DisabledInput'
import { InfoLines, Line } from './PublicOrderForm.styled'

interface Props {
  make: string
  model: string
  edition: string
}

const CarInfo: FC<Props> = ({ make, model, edition }) => {
  const { t } = useTranslation()

  return (
    <InfoLines>
      <Line>
        <DisabledInput label={t(DICTIONARY.MAKE)} field={make}></DisabledInput>
        <DisabledInput
          label={t(DICTIONARY.MODEL)}
          field={model}
        ></DisabledInput>
      </Line>
      <Line>
        <DisabledInput
          label={t(DICTIONARY.EDITION)}
          field={edition}
        ></DisabledInput>
      </Line>
    </InfoLines>
  )
}

export default CarInfo
