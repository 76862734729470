import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.specification

export const CategoryLabel = styled.h4`
  color: ${s().label};
`
export const DescriptionRoot = styled.div``
export const DescriptionLabel = styled.div`
  margin-right: 7px;
  font-weight: bold;
`
export const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const CategoryRoot = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${s().font};
  cursor: pointer;
`
export const DescriptionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`
export const DescriptionsHeader = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0 8px 8px 18px;
  border-bottom: 3px solid ${s().description.border};
`
export const DescriptionChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  svg {
    position: absolute;
    width: 13px;
  }
  svg:last-child {
    top: 7px;
  }
`
export const DescriptionItem = styled.li`
  padding: 8px 8px 8px 50px;
  border-bottom: 1px solid ${s().description.item};

  &:last-child {
    border: none;
  }
`
export const VehicleSpecificationWrapper = styled.div`
  padding: 0 15px;
`
export const VehicleSpecificationItemWrapper = styled.div`
  border-bottom: 1px solid ${s().wrapper};
`
