import { useParams } from 'react-router'
import React, { useEffect } from 'react'

import TopScroll from '../../components/topnav/TopScroll'
import { useProfileContext } from '../../context/ProfileContext'
import { useCurrency } from '../../hooks/useCurrency'
import { useQuote } from '../../utils/reactQueryApi'
import ContentBody from './components/contentBody/ContentBody'
import InvalidQuoteError from './components/InvalidQuoteError'
import QuoteSummaryTemplate from './components/QuoteSummaryTemplate'

const QuoteSummary: React.FC = () => {
  const { locale, quoteParam } = useParams<{
    locale: string
    quoteParam: string
  }>()
  const {
    data: quote,
    isError: isQuoteError,
    isFetching: isQuoteLoading,
  } = useQuote(quoteParam, locale)

  const profile = useProfileContext()
  const maxDigits = 2
  const currency = useCurrency(locale, profile?.user.currencyCode, maxDigits)

  useEffect(() => {
    window.sessionStorage.setItem('countryCode', locale.split('_')[1])
  }, [locale])

  return (
    <>
      {[!isQuoteLoading, quote, quote?.vehicle, currency, profile].every(
        (a) => a,
      ) && (
        <QuoteSummaryTemplate
          quote={quote}
          profile={profile}
          locale={locale}
          currency={currency}
          quoteParam={quoteParam}
        >
          <ContentBody quote={quote} currency={currency} profile={profile} />
          <TopScroll />
        </QuoteSummaryTemplate>
      )}
      {isQuoteError && <InvalidQuoteError />}
    </>
  )
}

export default QuoteSummary
