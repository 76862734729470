import GlqsAccordion from '../../../components/glqsAccordion/GlqsAccordion'
import { Option } from '../../../model/Quote'
import { getStyles } from '../../../utils/styles'
import OptionsTable from './OptionsTable'

const s = () => getStyles().colors.configurator.limetedOptions

interface OptionsTableProps {
  groupedOptions: Map<string, Option[]>
  optionGroupNames: Map<string, string>
}

const LimitedVehicleConfigurationOptionsTable = ({
  groupedOptions,
  optionGroupNames,
}: OptionsTableProps) => {
  return (
    <>
      {Array.from(
        optionGroupNames || [],
        ([optionGroupId, optionGroupName]) => {
          return (
            <div key={optionGroupId}>
              <GlqsAccordion
                caption={optionGroupName}
                expand={true}
                colors={{
                  backgroundColor: s().background,
                  color: s().font,
                }}
              >
                <OptionsTable options={groupedOptions.get(optionGroupId)} />
              </GlqsAccordion>
            </div>
          )
        },
      )}
    </>
  )
}

export default LimitedVehicleConfigurationOptionsTable
