import { ChangeEvent, Dispatch } from 'react'

import { SERVICE } from '../../../constants/service'
import { CountryConfigType } from '../../../model/CountryConfigType'
import { Service } from '../../../model/Quote'
import { PrintableItem } from '../price/PriceSelectedOptions'
import { AppAction } from '../reducer/VehicleConfigReducer'

const flattenServicesToIds = (services: Map<string, string>): string[] => {
  const result: string[] = []
  if (!services) {
    return []
  }
  services.forEach(
    (value: string, key: string) => value && result.push(`${key}.${value}`),
  )
  return result
}

const flattenServicesToOptions = (services: Service[]): PrintableItem[] => {
  const result: PrintableItem[] = []
  if (!services) {
    return []
  }
  services.forEach((s) =>
    s.levels.forEach((l) =>
      result.push({
        optionId: `${s.id}.${l.id}`,
        manufacturerName: l.description,
        price: l.price?.amount,
      }),
    ),
  )
  return result
}

const isLogoDeCoeur = (services: Service[]): boolean => {
  if (!services) return false
  return !!services.filter(
    (service: Service) => service.id === SERVICE.LOGO_DE_COEUR,
  ).length
}

const onServiceClick = (
  event: ChangeEvent<{
    name?: string
    value: unknown
  }>,
  service: Service,
  dispatch: Dispatch<AppAction>,
) => {
  const selectedService = new Map<string, string>()
  if (service?.id) {
    selectedService.set(
      service.id,
      (event.target.value as string).split('.')[1],
    )
  }
  dispatch({
    type: 'updateServices',
    selectedService: selectedService,
  })
}

const isNeededEmptyServiceLevel = (
  serviceId: string,
  config: CountryConfigType,
): boolean => {
  return (
    serviceId === SERVICE.LOGO_DE_COEUR ||
    (serviceId === SERVICE.WINTER_TYRES && config?.showEmptyWinterTyreSelect)
  )
}

export {
  flattenServicesToIds,
  flattenServicesToOptions,
  onServiceClick,
  isLogoDeCoeur,
  isNeededEmptyServiceLevel,
}
