import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.contractOptions

const StyledRoot = styled.div`
  color: ${s().font};
  background: ${s().background};
  label {
    font-weight: 600;
  }
  padding: 1rem;
  label ~ div {
    font-size: 0.9rem;
  }
  > div {
    margin-bottom: 1rem;
  }
`

const TooltipStoryContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 20px;
`

export { StyledRoot, TooltipStoryContainer }
