import { useRouteMatch } from 'react-router-dom'

import { normalizeLocale } from '../utils/locale'

const useLocale = () => {
  const match = useRouteMatch('/:locale')
  return normalizeLocale(match?.params['locale']) || null
}

export default useLocale
