import { useQueryClient } from 'react-query'
import { useEffect } from 'react'

export const useCancelShowroomQuote = (
  selectedQuoteIdentifier: string,
  currentQuoteIdentifier: string,
) => {
  const queryClient = useQueryClient()

  useEffect(() => {
    if (
      selectedQuoteIdentifier &&
      selectedQuoteIdentifier !== currentQuoteIdentifier
    ) {
      queryClient.cancelQueries(['showroom-quote', currentQuoteIdentifier])
    }
  }, [selectedQuoteIdentifier, currentQuoteIdentifier, queryClient])
}
