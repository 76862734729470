import { Dispatch } from 'react'

import { Option } from '../../../model/Quote'
import { AppAction, AppState } from '../reducer/VehicleConfigReducer'

export type GroupedOptionsType = (Option | Option[])[]

const onColorBoxClick = (
  state: AppState,
  dispatch: Dispatch<AppAction>,
  option: Option,
) => {
  dispatch({
    type: 'selectOption',
    selectedOption: option,
  })
}

const groupByColor = (options: Option[]): GroupedOptionsType => {
  try {
    return options
      .slice() // Create a shallow copy of the array to avoid mutating the original input
      .sort((a, b) => a.colour.colourName.localeCompare(b.colour.colourName))
      .reduce((acc, option) => {
        const lastGroup = acc[acc.length - 1]

        if (Array.isArray(lastGroup)) {
          const lastGroupColor = lastGroup[0].colour.colourName

          if (lastGroupColor === option.colour.colourName) {
            lastGroup.push(option)
          } else {
            acc.push([option])
          }
        } else if (
          lastGroup &&
          lastGroup.colour.colourName === option.colour.colourName
        ) {
          acc[acc.length - 1] = [lastGroup, option]
        } else {
          acc.push([option])
        }
        return acc
      }, [])
  } catch {
    return options
  }
}
export { onColorBoxClick, groupByColor }
