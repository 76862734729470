import { FormField } from '@velocity/ui'
import { Input, Select } from '@velocity/ui/draft'
import { sanitize } from 'dompurify'
import styled from 'styled-components'

import { ContractOptionsType } from '../../../model/ContractOptionsType'
import { ternaryValue } from '../../../utils/defaults'
import { iGen } from '../../../utils/indexGen'
import { getStyles, isRebranding } from '../../../utils/styles'
import { Root } from '../components/TooltipText'
import { dataTagName } from '../util/dataTagUtil'

interface ContractOption {
  code: string
  text: string
}

const extractDefaults = (
  contractOptions: ContractOptionsType,
): Record<string, string> => {
  const result: Record<string, string> = {}

  result.yearlyMileages = contractOptions.yearlyMileages[0]?.toString() || '0'
  result.durations =
    contractOptions.durations[
      contractOptions.durations.length - 1
    ]?.toString() || '0'
  result.contractId =
    contractOptions.availableContracts &&
    contractOptions.availableContracts[0]?.contractId
  result.discountAmount =
    contractOptions.discountAmountConfig &&
    (contractOptions.discountAmountConfig.min?.toString() || '0')
  result.commissionAmount =
    contractOptions.commissionAmountConfig &&
    (contractOptions.commissionAmountConfig.min?.toString() || '0')
  result.downPaymentAmount =
    (contractOptions.downPaymentAmountConfig &&
      (contractOptions.downPaymentAmountConfig.min?.toString() || '0')) ||
    (contractOptions.downPaymentConfig &&
      contractOptions.downPaymentConfig.length &&
      contractOptions.downPaymentConfig[0].value.toString())
  result.includeMaintenance =
    contractOptions.maintenanceConfig &&
    (contractOptions.maintenanceConfig.defaultSelection?.toString() || 'false')

  return result
}

const StyledFormField = styled(FormField)`
  label {
    font-weight: ${getStyles().fonts.title.weight};
  }
`

const StyledSelect = styled(Select)`
  font-weight: ${getStyles().fonts.title.weight};
  width: 100%;
  height: 40px;
  background-color: white;
  font-size: 16px;
`

const StyledFormFieldAyvens = styled.div`
  label {
    font-weight: ${getStyles().fonts.title.weight};
  }
`

const StyledSelectAyvens = styled.select`
  font-weight: ${getStyles().fonts.title.weight};
  width: 100%;
  height: 40px;
  background-color: white;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Source Sans 3';
  &:focus-visible {
    outline: none;
    border: 1px solid;
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-family: 'Source Sans 3';
  &:focus-visible {
    outline: none;
    border: 1px solid;
  }
`
const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Source Sans 3';
  margin-bottom: 4px;
`

const selectItem = (
  onChange: (a: string, b: string) => void,
  initialValue: string,
  label: string,
  options: ContractOption[],
  key: string,
  hintText?: string,
) => (
  <>
    {isRebranding() ? (
      <StyledFormFieldAyvens>
        <Label>{label}</Label>
        <StyledSelectAyvens
          data-e2e-id={dataTagName(label?.split('(')[0], 'contractOption')}
          onChange={(e) => onChange(e.target.value.toString(), key)}
          value={initialValue}
        >
          {options.map((e, i) => (
            <option key={iGen(i)} value={e.code}>
              {e.text}
            </option>
          ))}
        </StyledSelectAyvens>
      </StyledFormFieldAyvens>
    ) : (
      <StyledFormField
        label={label}
        required={true}
        tooltip={
          hintText && {
            text: (
              <Root dangerouslySetInnerHTML={{ __html: sanitize(hintText) }} />
            ),
          }
        }
      >
        <StyledSelect
          data-e2e-id={dataTagName(label?.split('(')[0], 'contractOption')}
          fullWidth={true}
          onChange={(e) => onChange(e.target.value.toString(), key)}
          value={initialValue}
        >
          {options.map((e, i) => (
            <option key={iGen(i)} value={e.code}>
              {e.text}
            </option>
          ))}
        </StyledSelect>
      </StyledFormField>
    )}
  </>
)

const inputItem = (
  onChange: (a: string, b: string) => void,
  initialValue: string,
  label: string,
  key: string,
  max: number,
  min: number,
) => {
  const onBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let value = (e.target.value && parseInt(e.target.value, 10)) || 0
    if ([max != null, value > max].every((a) => a)) {
      e.target.value = max.toString()
      value = max
    }
    if ([min != null, value < min].every((a) => a)) {
      e.target.value = min.toString()
      value = min
    }
    onChange(value.toString(), key)
  }
  return (
    <div data-e2e-id={dataTagName(label?.split('(')[0], 'contractOption')}>
      {ternaryValue(
        isRebranding(),
        <>
          <Label>{label}</Label>
          <StyledInput
            data-e2e-id={key}
            type="number"
            placeholder={label}
            onBlur={onBlur}
            defaultValue={initialValue}
          />
        </>,
        <>
          <div>{label}</div>
          <Input
            data-e2e-id={key}
            type="number"
            placeholder={label}
            onBlur={onBlur}
            defaultValue={initialValue}
          />
        </>,
      )}
    </div>
  )
}

export { selectItem, extractDefaults, inputItem }
