import { useEffect, useState } from 'react'

import { createContext } from './hooks.js'

export const useBrandImages = () => {
  const [images, setImages] = useState({})

  useEffect(() => {
    const loadImages = () => {
      const data = createContext()
      setImages(data)
    }
    loadImages()
  }, [])

  return images
}
