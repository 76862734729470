import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  & span {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 5px;
    max-width: 230px;

    span {
      position: absolute;
      right: 3px;
      top: 9px;
    }
  }
`
export const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
export const PriceSetSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const StyledPriceInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
