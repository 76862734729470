import { Select } from '@velocity/ui/draft'
import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { useProfileContext } from '../../context/ProfileContext'
import { getCountryDefaultLocale } from '../../utils/locale'
import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.customer

const StyledSelect = styled(Select)`
  color: ${s().font};
  background-color: ${s().background};
  fieldset {
    border: 0 !important;
  }
  option {
    color: ${s().option.font};
    font-size: 0.9em;
  }
`

export const Customers = () => {
  const profile = useProfileContext()

  const handleChangeAccount = (event: ChangeEvent<{ value: unknown }>) => {
    const selectedCustomer = event.target.value
    const selectedCustomerOption = profile?.user.availableCustomers.find(
      (customer) =>
        `${customer.countryCode}-${customer.customerId}` === selectedCustomer,
    )
    if (selectedCustomerOption) {
      window.location.replace(
        `/${getCountryDefaultLocale(
          selectedCustomerOption.countryCode,
        )}/auth0?customerId=${selectedCustomerOption.customerId}`,
      )
    }
  }

  return profile && profile?.user?.availableCustomers?.length > 1 ? (
    <StyledSelect
      value={`${profile.user?.customer.countryCode}-${profile.user?.customer.customerId}`}
      onChange={handleChangeAccount}
      data-testid="CustomersSelect"
    >
      {profile?.user.availableCustomers.map((customerOption) => (
        <option
          key={`option-customer-${customerOption.customerId}`}
          value={`${customerOption.countryCode}-${customerOption.customerId}`}
        >
          {`${customerOption.name} (${customerOption.customerId})`}
        </option>
      ))}
    </StyledSelect>
  ) : (
    <></>
  )
}
