import { makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { Entry } from '../../../../model/Entry'
import { iGen } from '../../../../utils/indexGen'
import { getStyles, isRebranding } from '../../../../utils/styles'

const s = () => getStyles().colors.quoteSummary.content.items

interface Props {
  title: string
  details: Entry[]
  testId: string
  translateDescription?: boolean
}

const useStyles = makeStyles({
  root: {
    marginBottom: '2.4rem',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    listStyleType: 'none',
    height: '1.8rem',
    position: 'relative',
    '&::before': {
      borderBottom: `1px dotted ${s().item}`,
      bottom: '8px',
      clear: 'both',
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: '100%',
    },
  },
  itemLabel: {
    position: 'relative',
    fontWeight: s().label.weight,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      borderBottom: `1px solid ${s().label.color}`,
      bottom: '8px',
    },
  },
  items: {
    padding: '0',
  },
  value: {
    fontWeight: 'bold',
  },
})
const DetailsSection: React.FC<Props> = ({
  title,
  details,
  testId,
  translateDescription = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <section className={classes.root}>
      {isRebranding() ? (
        <Text>{t(title)}</Text>
      ) : (
        <Headline variant="200">{t(title)}</Headline>
      )}
      <ul className={classes.items}>
        {details.map((detail: Entry, index: number) => (
          <li key={iGen(index)} data-testid={testId} className={classes.item}>
            <span className={classes.itemLabel}>
              {translateDescription
                ? t(detail.description)
                : detail.description}
            </span>
            <span
              className={clsx(classes.value, classes.itemLabel)}
              data-e2e-id={detail.key}
            >
              {detail.value}
            </span>
          </li>
        ))}
      </ul>
    </section>
  )
}

const Text = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default DetailsSection
