import { CheckIcon } from '@velocity/ui/draft'
import {
  OptionsObject,
  SnackbarContent,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack'

import { capitalize } from '../../utils/string'
import { MessageBarContent } from './SnackMessage.styled'

export interface SnackbarInfo {
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject,
  ) => SnackbarKey
  closeSnackbar: (key?: SnackbarKey) => void
  text: string
}

const showSnackbar = (snackbarInfo: SnackbarInfo) => {
  snackbarInfo.closeSnackbar()
  snackbarInfo.enqueueSnackbar(capitalize(snackbarInfo.text), {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    autoHideDuration: 3000,
    content: (key, message) => {
      return (
        <SnackbarContent>
          <MessageBarContent>
            <CheckIcon size="m" />
            <div>{message}</div>
          </MessageBarContent>
        </SnackbarContent>
      )
    },
    variant: 'info',
    preventDuplicate: true,
  })
}

export { showSnackbar }
