import { makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import { iGen } from '../../../../utils/indexGen'
import { getStyles, isRebranding } from '../../../../utils/styles'

const s = () => getStyles().colors.quoteSummary.wltp

interface Props {
  emissions: Entry[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2.4rem',
  },
  mainTitle: {
    height: '2rem',
  },
  list: {
    padding: '0',
  },
  item: {
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '&::before': {
      borderBottom: `1px dotted ${s().item}`,
      bottom: '0',
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: '100%',
    },
  },
  itemLabel: {
    position: 'relative',
    fontWeight: s().label.weight,
  },
  value: {
    fontWeight: 'bold',
  },
}))

const WltpReport = ({ emissions }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <section className={classes.root}>
      {isRebranding() ? (
        <Text className={classes.mainTitle}>{t(DICTIONARY.HEADER)}</Text>
      ) : (
        <Headline variant="200" className={classes.mainTitle}>
          {t(DICTIONARY.HEADER)}
        </Headline>
      )}
      {emissions.map((emission, index) => {
        return (
          <ul key={iGen(index)} className={classes.list}>
            <li key={iGen(index)} data-testid="entry" className={classes.item}>
              <span className={classes.itemLabel} data-testid="description">
                {parse(emission.description)}
              </span>
              <span
                className={clsx(classes.value, classes.itemLabel)}
                data-testid="value"
                data-e2e-id="wltp-co2"
              >
                {emission.value}
              </span>
            </li>
          </ul>
        )
      })}
    </section>
  )
}

const Text = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default WltpReport
