import { FC, useEffect, useState } from 'react'

import { useVehicleSpecification } from '../../../utils/reactQueryApi'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { Order } from '../util/tableHeadSort'
import { VehicleSpecificationWrapper } from './VehicleSpecification.styled'
import VehicleSpecificationItem from './VehicleSpecificationItem'

const VehicleSpecification: FC = () => {
  const [descriptionOrder, setDescriptionOrder] = useState<Order>('asc')
  const { state } = useVehicleConfig()
  const { data, refetch } = useVehicleSpecification(
    state.locale,
    state.vehicle?.uniqueId.toString(),
  )

  useEffect(() => {
    if (state.locale && state.vehicle?.uniqueId) {
      refetch()
    }
  }, [state.vehicle?.uniqueId, state.locale, refetch])

  const categoryComparator = (a: string, b: string): number => {
    if (a === '-') {
      return 1
    }
    if (b === '-') {
      return -1
    }
    return a.localeCompare(b)
  }
  return (
    <VehicleSpecificationWrapper>
      {data &&
        Object.keys(data)
          .sort(categoryComparator)
          .map((category: string) => {
            return (
              <VehicleSpecificationItem
                key={category}
                category={category}
                descriptionList={data[category]}
                descriptionOrder={descriptionOrder}
                setDescriptionOrder={setDescriptionOrder}
              />
            )
          })}
    </VehicleSpecificationWrapper>
  )
}

export default VehicleSpecification
