import { useVelocityContext } from '@velocity/ui'
import { HomeIcon } from '@velocity/ui/draft'
import { Link } from 'react-router-dom'
import React from 'react'

import HomeAyvensIcon from '../../assets/images/home.png'
import { useProfileContext } from '../../context/ProfileContext'
import { isRebranding } from '../../utils/styles'
import { getHomeLink } from './homeLinkUtil'
import { StyledNavItem } from './TopNav.styled'

interface Props {
  locale: string
}

const HomeLink: React.FC<Props> = ({ locale }) => {
  const ctx = useVelocityContext()
  const profile = useProfileContext()

  return (
    <StyledNavItem>
      <Link data-testid="home-link" to={getHomeLink(profile, locale)}>
        {isRebranding() ? (
          <img src={HomeAyvensIcon} alt="homeIcon" />
        ) : (
          <HomeIcon size="m" color={ctx.theme.styling.gray.white} />
        )}
      </Link>
    </StyledNavItem>
  )
}

export default HomeLink
