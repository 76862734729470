import { Entry } from '../../../model/Entry'
import { Profile } from '../../../model/Profile'
import { Quote } from '../../../model/Quote'
import { updateAccessoryDetails } from './accessory'
import { updateContractDetails } from './contract'
import { updateCo2EmissionsDetails } from './emission'
import { updateOptionDetails } from './options'
import { updateServiceDetails } from './service'
import { totalLease } from './totalLease'
import { updateVehicleDetails } from './vehicle'
import { vehicleCost } from './vehicleCost'

export const quotationSummaryDetails = (
  quote: Quote | undefined,
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
  profile: Profile,
) =>
  quote
    ? {
        vehicleDetails: updateVehicleDetails(quote.vehicle, translations),
        co2Emissions: updateCo2EmissionsDetails(quote, translations),
        optionDetails: updateOptionDetails(quote, currency, translations),
        accessoryDetails: updateAccessoryDetails(quote, currency),
        serviceDetails: updateServiceDetails(quote, currency, translations),
        contractDetails: updateContractDetails(quote, profile),
        vehicleCost: vehicleCost(quote, currency),
        totalLease: totalLease(quote, currency, translations),
      }
    : {
        vehicleDetails: [],
        co2Emissions: [],
        optionDetails: [],
        accessoryDetails: [],
        serviceDetails: [],
        contractDetails: [],
        vehicleCost: [],
        totalLease: [],
      }

export const createDetail = (
  description: string,
  value: string | number | null = null,
  key?: string,
): Entry => {
  return {
    description: description,
    value: value,
    key: key || description,
  }
}
