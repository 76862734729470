import { makeStyles } from '@material-ui/core'
import { Headline } from '@velocity/ui'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import React from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../../constants/dictionary'
import { Entry } from '../../../../model/Entry'
import { getStyles, isRebranding } from '../../../../utils/styles'

const s = () => getStyles().colors.quoteSummary.content.accessory

interface Props {
  details: Entry[]
}

const useStyles = makeStyles({
  root: {
    marginBottom: '2.4rem',
  },
  underlinedItem: {
    listStyleType: 'none',
    height: '1.8rem',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '&::before': {
      borderBottom: `1px dotted ${s().item}`,
      bottom: '8px',
      clear: 'both',
      content: '" "',
      display: 'block',
      position: 'absolute',
      width: '100%',
    },
  },
  item: {
    listStyleType: 'none',
    height: '1.8rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemLabel: {
    position: 'relative',
    fontWeight: s().label.weight,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      borderBottom: `1px solid ${s().label.color}`,
      bottom: '8px',
    },
  },
  items: {
    padding: '0',
  },
  value: {
    fontWeight: 'bold',
  },
})

const AccessoryDetailsSection: React.FC<Props> = ({ details }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <section className={classes.root}>
      {isRebranding() ? (
        <Text>{t(DICTIONARY.QUOTE_SUMMARY_ACCESSORIES_LABEL)}</Text>
      ) : (
        <Headline variant="200">
          {t(DICTIONARY.QUOTE_SUMMARY_ACCESSORIES_LABEL)}
        </Headline>
      )}
      <ul className={classes.items}>
        {details.map((accessory) => (
          <li
            className={accessory.value ? classes.underlinedItem : classes.item}
            data-testid="description"
            data-e2e-id={accessory.key}
            key={accessory.description}
          >
            <span className={classes.itemLabel}>{accessory.description}</span>
            <span className={clsx(classes.value, classes.itemLabel)}>
              {accessory.value}
            </span>
          </li>
        ))}
      </ul>
    </section>
  )
}

const Text = styled.h4`
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  font-family: Chillax;
`

export default AccessoryDetailsSection
