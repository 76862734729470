import { Button, HomeIcon, Modal } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Dispatch, FC } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../components/button/VelocityButton'
import { DICTIONARY } from '../../constants/dictionary'
import { history } from '../../utils/historyStore'
import { capitalize } from '../../utils/string'
import { isRebranding } from '../../utils/styles'
import {
  PublicOrderFormAction,
  PublicOrderFormState,
} from './PublicOrderFormReducer'

interface Props {
  state: PublicOrderFormState
  dispatch: Dispatch<PublicOrderFormAction>
}

const PublicOrderFormDialog: FC<Props> = ({ state, dispatch }) => {
  const { locale, product } = useParams<{ locale: string; product: string }>()
  const { t } = useTranslation()

  return (
    <Modal
      isOpened={state.publicOrderFormDialog}
      onClose={() =>
        dispatch({
          type: 'updatePublicOrderFormDialog',
          publicOrderFormDialog: false,
        })
      }
    >
      <Modal.Body>
        <Title>{t(DICTIONARY.THANK_YOU_LABEL)}</Title>
        <Text>{t(DICTIONARY.ORDER_MAIL_SENT_MESSAGE)}</Text>
        <br />
        <Buttons>
          {isRebranding() ? (
            <VelocityButton
              startIcon={<HomeIcon color="#FFFFF" size="s" />}
              testId="drv-contrib-back"
              onClick={() => {
                dispatch({
                  type: 'updatePublicOrderFormDialog',
                  publicOrderFormDialog: false,
                })
                history.push(`/${locale}/view`)
              }}
            >
              {t(DICTIONARY.GO_TO_HOME_PAGE)}
            </VelocityButton>
          ) : (
            <HomeButton
              startIcon={<HomeIcon color="#FFFFF" size="s" />}
              variant="contained"
              data-testid="drv-contrib-back"
              onClick={() => {
                dispatch({
                  type: 'updatePublicOrderFormDialog',
                  publicOrderFormDialog: false,
                })
                history.push(`/${locale}/view`)
              }}
            >
              {t(DICTIONARY.GO_TO_HOME_PAGE)}
            </HomeButton>
          )}
          <div>{t(DICTIONARY.OR)}</div>
          {isRebranding() ? (
            <CancelButton>
              <VelocityButton
                testId="drv-contrib-continue"
                onClick={() => {
                  dispatch({
                    type: 'updatePublicOrderFormDialog',
                    publicOrderFormDialog: false,
                  })
                  history.push(`/${locale}/${product}/showroom`)
                }}
              >
                {capitalize(t(DICTIONARY.GO_BACK))}
              </VelocityButton>
            </CancelButton>
          ) : (
            <Button
              data-testid="drv-contrib-continue"
              variant="outlined"
              onClick={() => {
                dispatch({
                  type: 'updatePublicOrderFormDialog',
                  publicOrderFormDialog: false,
                })
                history.push(`/${locale}/${product}/showroom`)
              }}
            >
              {capitalize(t(DICTIONARY.GO_BACK))}
            </Button>
          )}
        </Buttons>
      </Modal.Body>
    </Modal>
  )
}

const CancelButton = styled.div`
  button {
    text-transform: ${isRebranding() ? 'none' : 'inherit'};
    background-color: white;
    color: #006262;
    border: 1px solid rgb(51, 155, 155);
  }
`
const Title = styled.h1`
  text-align: center;
`
const Text = styled.div`
  font-weight: 600;
  text-align: center;
`
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  div:nth-child(2) {
    display: flex;
    align-items: center;
    margin: 0 7px;
  }
`
const HomeButton = styled(Button)`
  svg {
    width: 16px;
  }
`

export default PublicOrderFormDialog
