import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import React from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { iGen } from '../../../utils/indexGen'
import { pdfDocStyles } from '../PDFDocument'

const endItemPos = 3
const startItemPos = 3

interface Props {
  vehicleDetails: Entry[]
  parentStyles: typeof pdfDocStyles
}

const styles = StyleSheet.create({
  column: {
    width: '50%',
  },
})

const VehicleDetailsSection: React.FC<Props> = ({
  vehicleDetails,
  parentStyles,
}) => {
  const { t } = useTranslation()
  return (
    !!vehicleDetails.length && (
      <View wrap={false}>
        <Text style={parentStyles.subTitle}>
          {t(DICTIONARY.QUOTE_SUMMARY_VEHICLE_DETAILS_LABEL)}
        </Text>
        <View style={parentStyles.row}>
          <View style={styles.column}>
            {vehicleDetails.slice(0, endItemPos).map((vehicleDetail, index) => (
              <View key={iGen(index)} style={parentStyles.row}>
                <Text style={parentStyles.wideDescription}>
                  {t(vehicleDetail.description)}
                </Text>
                <Text style={parentStyles.wideValue}>
                  {vehicleDetail.value}
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.column}>
            {vehicleDetails.slice(startItemPos).map((vehicleDetail, index) => (
              <View key={iGen(index)} style={parentStyles.row}>
                <Text style={parentStyles.wideDescription}>
                  {t(vehicleDetail.description)}
                </Text>
                <Text style={parentStyles.wideValue}>
                  {vehicleDetail.value}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    )
  )
}

export default VehicleDetailsSection
