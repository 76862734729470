import { Box, Grid } from '@material-ui/core'
import { CarMissingIcon } from '@velocity/ui/draft'
import { FC } from 'react'

import CarMissingAyvensIcon from '../../assets/images/car-missing.png'
import { getStyles, isRebranding } from '../../utils/styles'

interface Props {
  name: string
  text: string
}
const EmptyCarListPage: FC<Props> = ({ name, text }) => {
  return (
    <Grid container={true} justify="space-between" alignItems="center">
      <Grid item={true} xs={12}>
        <Box textAlign="center">
          {isRebranding() ? (
            <img src={CarMissingAyvensIcon} alt="vehicleListMissingCar" />
          ) : (
            <CarMissingIcon
              size="xxl"
              color={getStyles().colors.emptyCarListPage.icon}
              data-testid={name}
            />
          )}
          <p>{text}</p>
        </Box>
      </Grid>
    </Grid>
  )
}

export default EmptyCarListPage
