import React, { FC, useEffect } from 'react'

import { getLangCountryFromLocale } from '../../utils/locale'

interface Props {
  locale: string
}

export const RebrandingRedirect: FC<Props> = ({ locale }) => {
  useEffect(() => {
    if (locale) {
      const country = getLangCountryFromLocale(locale).country
      const url = window.location.href
      const host = window.location.host
      if (
        process.env.REACT_APP_ENABLE_REDIRECT?.split(' ').includes(
          country.toUpperCase(),
        )
      ) {
        window.location.assign(
          url.replace(host, process.env.REACT_APP_REDIRECT_DOMAIN),
        )
      }
    }
  }, [locale])
  return <></>
}
