import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import useErrorMessages from '../../../hooks/useErrorMessages'
import {
  AvailableContract,
  DownPaymentStep,
} from '../../../model/ContractOptionsType'
import { VoVehicle } from '../../../model/VoVehicle'
import { ErrorType } from '../../../types/error'
import { useContractOptions } from '../../../utils/reactQueryApi'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { StyledRoot } from './ContractOptions.styled'
import { inputItem, selectItem } from './contractOptionsUtil'

interface Props {
  vehicle: VoVehicle
}

const ContractOptionsComponent: FC<Props> = ({ vehicle }) => {
  const { locale } = useParams<{ locale: string }>()
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()
  const {
    data: contractOptions,
    isError,
    error,
  } = useContractOptions(vehicle.uniqueId, locale)
  useErrorMessages(isError, error as ErrorType, t)
  const onChange = (value: string, key: string) => {
    const newContractOptions = { ...state.contractOptions }
    newContractOptions[key] = value
    dispatch({
      type: 'updateContractOptions',
      contractOptions: newContractOptions,
    })
  }

  const getDownPaymentLabel = (downPaymentLabel: string) => {
    return `${downPaymentLabel} (${state.currency.format(
      +state.contractOptions.downPaymentAmount,
    )})`
  }
  return (
    state.restoredVehicleConfig &&
    state.contractOptions && (
      <StyledRoot>
        {contractOptions &&
          selectItem(
            onChange,
            state.contractOptions.yearlyMileages ||
              contractOptions.yearlyMileages[0].toString(),
            t(DICTIONARY.CONTRACT_MILEAGE_LABEL),
            contractOptions.yearlyMileages.map((item: number) => {
              return { code: item.toString(), text: item.toString() }
            }),
            'yearlyMileages',
          )}
        {contractOptions &&
          selectItem(
            onChange,
            state.contractOptions.durations ||
              contractOptions.durations[
                contractOptions.durations.length - 1
              ].toString(),
            t(DICTIONARY.DURATION),
            contractOptions.durations.map((item: number) => {
              return { code: item.toString(), text: item.toString() }
            }),
            'durations',
          )}
        {contractOptions?.availableContracts &&
          selectItem(
            onChange,
            state.contractOptions.contractId ||
              contractOptions.availableContracts[0].toString(),
            t(DICTIONARY.CONTRACT_NUMBER_LABEL),
            contractOptions.availableContracts.map(
              (item: AvailableContract) => {
                return {
                  code: item.contractId,
                  text: item.description || item.contractId,
                }
              },
            ),
            'contractId',
          )}
        {contractOptions?.maintenanceConfig?.allowSelection &&
          selectItem(
            onChange,
            state.contractOptions.includeMaintenance ||
              contractOptions.maintenanceConfig.defaultSelection.toString(),
            t(DICTIONARY.INCLUDE_MAINTENANCE_LABEL),
            [
              {
                code: 'true',
                text: t(DICTIONARY.YES),
              },
              {
                code: 'false',
                text: t(DICTIONARY.NO),
              },
            ],
            'includeMaintenance',
            t(DICTIONARY.VEHICLE_CONFIGURATION_MAINTENANCE_TOOLTIP_TEXT),
          )}
        {contractOptions?.discountAmountConfig &&
          inputItem(
            onChange,
            state.contractOptions.discountAmount ||
              contractOptions.discountAmountConfig.min.toString(),
            t(DICTIONARY.CONTRACT_DISCOUNT_AMOUNT_LABEL),
            'discountAmount',
            contractOptions.discountAmountConfig.max,
            contractOptions.discountAmountConfig.min,
          )}
        {contractOptions?.commissionAmountConfig &&
          inputItem(
            onChange,
            state.contractOptions.commissionAmount ||
              contractOptions.commissionAmountConfig.min.toString(),
            t(DICTIONARY.CONTRACT_COMMISSION_AMOUNT_LABEL),
            'commissionAmount',
            contractOptions.commissionAmountConfig.max,
            contractOptions.commissionAmountConfig.min,
          )}
        {contractOptions?.downPaymentAmountConfig &&
          inputItem(
            onChange,
            state.contractOptions.downPaymentAmount ||
              contractOptions.downPaymentAmountConfig.min.toString(),
            t(DICTIONARY.DOWNPAYMENT),
            'downPaymentAmount',
            contractOptions.downPaymentAmountConfig.max,
            contractOptions.downPaymentAmountConfig.min,
          )}
        {contractOptions?.downPaymentConfig &&
          selectItem(
            onChange,
            state.contractOptions.downPaymentAmount ||
              contractOptions.downPaymentConfig[0].value?.toString(),
            getDownPaymentLabel(t(DICTIONARY.DOWNPAYMENT)),
            contractOptions.downPaymentConfig.map((item: DownPaymentStep) => {
              return { code: item.value.toString(), text: item.text }
            }),
            'downPaymentAmount',
          )}
      </StyledRoot>
    )
  )
}

export default ContractOptionsComponent
