import React, { createContext, useEffect, useState } from 'react'

import Spinner from '../components/spinner/Spinner'
import { CountryDefaultLocale } from '../constants/locale'
import { Profile } from '../model/Profile'
import { SpinnerContainer } from '../pages/showroom/vehicle-list/VehicleList.styled'
import { history } from '../utils/historyStore'
import { getCountryDefaultLocale, isValidLocale } from '../utils/locale'
import { useProfile } from '../utils/reactQueryApi'

type ProfileContextProps = {
  children: React.ReactNode
  locale: string
  profileNeeded: boolean
  isOrderSummaryPage: boolean
}

export const ProfileContext = createContext<Profile>({} as Profile)

const validateUserProfile = (
  profile: Profile,
  locale: string,
  isOrderSummaryPage: boolean,
): boolean => {
  const profileCountry = profile?.user?.country
  const localeCountry = locale?.split('_').length > 1 && locale.split('_')[1]

  if ([localeCountry, !isValidLocale(locale)].every((a) => a)) {
    history.push(`/${CountryDefaultLocale[localeCountry]}/showroom`)
    return false
  }

  if (
    [profileCountry, localeCountry, profileCountry !== localeCountry].every(
      (a) => a,
    )
  ) {
    const defaultLocaleForCountry =
      getCountryDefaultLocale(profileCountry) ||
      `en_${profileCountry.toLocaleUpperCase()}`
    history.push(`/${defaultLocaleForCountry}/showroom`)
    return false
  } else if (profile?.user?.caseStatus === 'Approved') {
    history.push(`/${locale}/order-approved`)
    return false
  } else if (
    [!isOrderSummaryPage, profile?.user?.quoteSubmitted].every((a) => a)
  ) {
    history.push(
      `/${locale}/order-summary/${profile.user.submittedQuoteNumber}`,
    )
    return false
  } else {
    return true
  }
}

function ProfileContextProvider({
  children,
  locale,
  profileNeeded,
  isOrderSummaryPage,
}: ProfileContextProps) {
  const {
    data: profile,
    isSuccess: isProfileReady,
    isError: isProfileError,
    error: profileError,
    refetch: fetchProfile,
  } = useProfile(locale)

  const [profileLoaded, setProfileLoaded] = useState(false)

  useEffect(() => {
    locale && profileNeeded && fetchProfile()
  }, [locale, profileNeeded, fetchProfile])

  useEffect(() => {
    if (
      [
        isProfileReady,
        validateUserProfile(profile, locale, isOrderSummaryPage),
      ].every((a) => a)
    ) {
      setProfileLoaded(true)
    }
  }, [locale, isProfileReady, profile, isOrderSummaryPage])

  useEffect(() => {
    if (isProfileError) {
      switch (profileError?.response?.data?.errorCode) {
        case 'SCHEDULED_MAINTENANCE':
          history.push(`/${locale}/scheduled-maintenance`)
          break
        case 'SERVICE_INTERRUPTION':
          history.push(`/${locale}/service-interruption`)
          break
        default:
          history.push(`/${locale}/unauthorised`, {
            error: profileError?.response?.data,
          })
      }
    }
  }, [locale, isProfileError, profileError])

  return [!profileNeeded, profileLoaded].some((a) => a) ? (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  ) : (
    <SpinnerContainer>
      <Spinner size={20} data-testid="profile-context-spinner" />
    </SpinnerContainer>
  )
}

function useProfileContext() {
  return React.useContext(ProfileContext)
}

export { ProfileContextProvider, useProfileContext }
