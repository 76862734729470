import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles, isRebranding } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.priceBreakdown.continueButton

export const ContinueButtonRoot = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  z-index: 10;
  transform: ${(props) =>
    props.expanded ? 'none' : 'translateY(-50%) translateX(-50%)'};
  @media (max-width: 768px) {
    margin: 1rem 0;
    position: static;
    transform: none;
  }
`

export const ContinueButtonStyled = styled(Button)`
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 0.9rem;
  width: 9.5rem;
`

export const SaveQuote = styled.div`
  color: ${s().saveQuote};
  display: flex;
  cursor: pointer;
  & > svg {
    height: 1.2rem;
  }
  & > div {
    text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
    font-weight: ${getStyles().fonts.title.weight};
  }
`

export const DisabledSaveQuote = styled.div`
  color: ${s().disabledSaveQuote};
  display: flex;
  cursor: default;
  & > svg {
    height: 1.2rem;
  }
  & > div {
    text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
    font-weight: bold;
  }
`
