import { makeStyles, Theme, Container } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

import { getStyles, isRebranding } from '../../utils/styles'
import FooterLogo2x from './images/lp-logo-footer@2x.png'

const s = () => getStyles().colors.footer

const top = 4
const bottom = 2
const leaseplanTop = 8
const leaseplanBottom = 2
const leaseplanMobileTop = 4
export interface Props {
  children: NonNullable<ReactNode>
  className?: string
  /**
   * Class name for the container inside the footer.
   */
  containerClassName?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: s().background,
    position: 'relative',
    color: s().font,
  },
  container: {
    paddingTop: theme.spacing(top),
    paddingBottom: theme.spacing(bottom),
  },
}))

const useLeasePlanStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(leaseplanTop),
    paddingBottom: theme.spacing(leaseplanBottom),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(leaseplanMobileTop),
    },
  },
  logo: {
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1,
    left: '50%',
    right: '67px',
    transform: 'translateX(-50%)',
    width: '227px',
    top: '-75px',

    [theme.breakpoints.up('md')]: {
      left: 'auto',
      transform: 'none',
    },
  },
}))

export const Footer: FC<Props> = ({
  className,
  children,
  containerClassName,
}) => {
  const classes = useStyles({})
  return (
    <footer className={clsx(classes.root, className)}>
      <Container
        maxWidth="lg"
        className={clsx(classes.container, containerClassName)}
      >
        {children}
      </Container>
    </footer>
  )
}

export const LeasePlanFooter: React.FC<Pick<Props, 'className'>> = ({
  className,
  children,
}) => {
  const classes = useLeasePlanStyles()
  return (
    <Footer className={className} containerClassName={classes.container}>
      {!isRebranding() ? (
        <img
          src={FooterLogo2x}
          className={classes.logo}
          data-e2e-component="footer-logo"
          alt="LeasePlan Logo"
        />
      ) : null}
      {children}
    </Footer>
  )
}
