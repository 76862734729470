import { makeStyles } from '@material-ui/core'
import { InfoIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'

import VelocityNotice from '../../../components/notice/VelocityNotice'
import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { getStyles, isRebranding } from '../../../utils/styles'

const s = () =>
  getStyles().colors.configurator.priceBreakdown.context.carHireNote

const useStyles = makeStyles(() => ({
  notice: {
    fontSize: '0.8rem',
    fontWeight: 300,
    color: s().font,
    padding: '4px 16px',
    margin: '10px 15px',
    display: 'flex',
    border: `1px solid ${s().border}`,
    borderRadius: '0',
    backgroundColor: s().background,
    '&>div': { margin: 'auto 0' },
  },
  alertIcon: {
    marginRight: '10px',
    color: s().alertIcon,
    minWidth: '24px',
    minHeight: '24px',
  },
  root: {
    marginBottom: '32px',
  },
}))

const PriceCarHireNote: React.FC = () => {
  const classes = useStyles()
  const country = useProfileContext()?.user.country
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      {isRebranding() ? (
        <>
          <VelocityNotice text={t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_AYVENS)} />
          {country === 'IE' && (
            <>
              <VelocityNotice
                text={t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_2_AYVENS)}
              />
              <VelocityNotice text={t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_3)} />
              <VelocityNotice text={t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_4)} />
            </>
          )}
        </>
      ) : (
        <>
          <div className={classes.notice}>
            <InfoIcon size="s" className={classes.alertIcon} />
            <div>{t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE)}</div>
          </div>
          {country === 'IE' && (
            <>
              <div className={classes.notice}>
                <InfoIcon size="s" className={classes.alertIcon} />
                <div>{t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_2)}</div>
              </div>
              <div className={classes.notice}>
                <InfoIcon size="s" className={classes.alertIcon} />
                <div>{t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_3)}</div>
              </div>
              <div className={classes.notice}>
                <InfoIcon size="s" className={classes.alertIcon} />
                <div>{t(DICTIONARY.PRIVATE_CAR_HIRE_NOTE_4)}</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default PriceCarHireNote
