import { SvgIcon } from '@material-ui/core'
import { ReactElement } from 'react'
import styled, { keyframes } from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.spinner

interface SpinnerProps {
  size?: number
}

const roll = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerStyled = styled(SvgIcon)<SpinnerProps>`
  max-height: ${(props) => props.size}rem;
  max-width: ${(props) => props.size}rem;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s 0.2s ease-in-out;
  animation: ${roll} 1s linear infinite;
`

const Spinner = ({ size }: { size: number }): ReactElement => (
  <SpinnerStyled viewBox="0 0 120 120" size={size} data-testid="loader">
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="10"
        y1="60"
        x2="110"
        y2="60"
      >
        <stop offset="0.3" style={{ stopColor: s().stop1 }} />
        <stop offset="0.9" style={{ stopColor: s().stop2 }} />
      </linearGradient>
      <path
        style={{ fill: 'url(#SVGID_1_)' }}
        d="M60,18c23.2,0,42,18.8,42,42s-18.8,42-42,42S18,83.2,18,60S36.8,18,60,18 M60,10c-27.6,0-50,22.4-50,50 s22.4,50,50,50s50-22.4,50-50S87.6,10,60,10L60,10z"
      />
    </g>
  </SpinnerStyled>
)

export default Spinner
