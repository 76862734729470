import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { TFunction } from 'i18next'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import styled from 'styled-components'

import { ErrorType } from '../types/error'
import { getStyles } from '../utils/styles'

const CloseIconStyled = styled(CloseIcon)`
  color: ${getStyles().colors.useErrorMessage.font};
`

const useErrorMessages = (
  isError: boolean,
  error: ErrorType,
  translations: TFunction,
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const action = useCallback(
    (key: number) => (
      <>
        <IconButton
          onClick={() => {
            closeSnackbar(key)
          }}
        >
          <CloseIconStyled />
        </IconButton>
      </>
    ),
    [closeSnackbar],
  )

  if (isError) {
    const errorMessage = error.response?.data?.message
    const message = errorMessage
      ? translations(errorMessage)
      : 'Something went wrong, please retry later'
    enqueueSnackbar(message, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      persist: true,
      preventDuplicate: true,
      action,
    })
  }
}

export default useErrorMessages
