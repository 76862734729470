import { useLocation } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'

import { LocalesEnum } from '../../constants/locale'
import { useProfileContext } from '../../context/ProfileContext'
import { sendPageViewEvent } from './gtm'

const GTMPageEvent: FC = () => {
  const profile = useProfileContext()
  const location = useLocation()
  const [lastLocation, setLastLocation] = useState('')

  useEffect(() => {
    const endPosition = 6
    if (
      profile &&
      location &&
      location.pathname !== lastLocation &&
      location.pathname.substring(1, endPosition).toUpperCase() in LocalesEnum
    ) {
      setLastLocation(location.pathname)
      sendPageViewEvent(profile?.product?.productName)
    }
  }, [location, lastLocation, profile])

  return null
}

export default GTMPageEvent
