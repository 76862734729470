import { DICTIONARY } from '../../../constants/dictionary'
import { DISPLAY } from '../../../constants/display'
import { Entry } from '../../../model/Entry'
import { Quote } from '../../../model/Quote'
import { checkValue, n } from './priceUtil'
import { createDetail } from './quotation'
import { filterServices } from './service'

export const vehicleCost = (
  quote: Quote,
  currency: Intl.NumberFormat,
): Entry[] => {
  if (!quote) {
    return []
  }
  const priceDetail = quote.priceDetail
  let result: Entry[] = []

  result = addVehicleRetailPriceDetails(quote, result, currency)

  checkValue(
    DICTIONARY.OPTIONS_DISCOUNTED_PRICE,
    n(priceDetail.contract?.optionsDiscountedPrice),
    result,
    currency,
  )

  checkValue(
    DICTIONARY.VEHICLE_DISCOUNTED_PRICE,
    n(priceDetail.contract?.vehicleDiscountedPrice),
    result,
    currency,
  )

  result = addAccessoriesRetailPrice(quote, result, currency)
  checkValue(
    DICTIONARY.TAX_BASE,
    n(priceDetail.taxBase?.actual),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.ESTIMATED_TAX_BASE,
    n(priceDetail.taxBase?.estimated),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.TOTAL_RETAIL_COST_INCL_DELIVERY_LABEL,
    n(priceDetail.totalRetailCost?.totalInclDelivery),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.TOTAL_RETAIL_COST_EXCL_DELIVERY_LABEL,
    n(priceDetail.totalRetailCost?.totalExclDelivery),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.CONTRACT_DISCOUNT_AMOUNT_LABEL,
    n(priceDetail.contract?.discount),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.DISCOUNT_AMOUNT,
    n(quote.discountAmount),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.COMMISSION_AMOUNT,
    n(quote.commissionAmount),
    result,
    currency,
  )
  checkValue(DICTIONARY.DOWNPAYMENT, n(quote.downPayment), result, currency)
  checkValue(
    DICTIONARY.MONTHLY_WHOLE_LIFE_COST,
    n(priceDetail.monthly?.wholeLifeCost),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.WHOLE_LIFE_COST,
    n(priceDetail.contract?.wholeLifeCost),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.INVESTMENT_TOTAL,
    n(priceDetail.contract?.investmentTotal),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.RESIDUAL_VALUE,
    n(priceDetail.contract?.residualValue),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.EXCESS_MILEAGE_RATE,
    n(priceDetail.contract?.excessMileageRate),
    result,
    currency,
  )
  checkValue(
    DICTIONARY.UNDER_MILEAGE_RATE,
    n(priceDetail.contract?.underMileageRate),
    result,
    currency,
  )
  return result
}

export const calculateOptionsTotalPrice = (quote: Quote): number => {
  let optionsRetailPrice = 0
  let optionsServicesPrice = 0

  if (quote.vehicle?.selectedOptions) {
    quote.vehicle.selectedOptions.forEach((option) => {
      optionsRetailPrice += option.price || 0
    })
  }

  if (quote.services) {
    const services = filterServices(quote.services, DISPLAY.OPTIONS_SERVICES)

    services.forEach((service) => {
      service.levels.forEach((level) => {
        optionsServicesPrice += (level.price && level.price.amount) || 0
      })
    })
  }

  return n(optionsRetailPrice) + n(optionsServicesPrice)
}

function addVehicleRetailPriceDetails(
  quote: Quote,
  pricingDetails: Entry[],
  currency: Intl.NumberFormat,
): Entry[] {
  const result = [].concat(pricingDetails)
  const vehicleRetailPrice = n(quote.vehicle?.retailPrice)
  const optionsTotalPrice = calculateOptionsTotalPrice(quote)

  checkValue(
    DICTIONARY.VEHICLE_RETAIL_PRICE,
    vehicleRetailPrice,
    result,
    currency,
  )

  checkValue(
    DICTIONARY.OPTIONS_RETAIL_PRICE,
    optionsTotalPrice,
    result,
    currency,
  )

  checkValue(
    DICTIONARY.VEHICLE_WITH_OPTIONS_RETAIL_PRICE,
    vehicleRetailPrice + optionsTotalPrice,
    result,
    currency,
  )

  return result
}

const addAccessoriesRetailPrice = (
  quote: Quote,
  pricingDetails: Entry[],
  currency: Intl.NumberFormat,
): Entry[] => {
  const result = [].concat(pricingDetails)
  if (!quote.accessories) {
    return pricingDetails
  }

  let accessoriesRetailPrice = 0

  quote.accessories.forEach((accessory) => {
    accessoriesRetailPrice +=
      (accessory.price ? accessory.price.amount : 0) || 0
  })

  quote.customAccessories?.forEach((accessory) => {
    accessoriesRetailPrice +=
      (accessory.price ? accessory.price.amount : 0) || 0
  })

  if (accessoriesRetailPrice <= 0) {
    return pricingDetails
  }

  result.push(
    createDetail(
      DICTIONARY.ACCESSORIES_RETAIL_PRICE,
      currency.format(n(accessoriesRetailPrice)),
    ),
  )

  return result
}
