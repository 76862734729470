import { FC } from 'react'

import { RootContainer } from './ShowroomFilters.styled'
import ShowroomFiltersBody from './ShowroomFiltersBody'

const ShowroomFilters: FC = () => {
  return (
    <>
      <RootContainer>
        <ShowroomFiltersBody />
      </RootContainer>
    </>
  )
}

export default ShowroomFilters
