import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import ViewComfySharpIcon from '@material-ui/icons/ViewComfySharp'
import ViewListSharpIcon from '@material-ui/icons/ViewListSharp'
import ViewStreamSharpIcon from '@material-ui/icons/ViewStreamSharp'
import { useWindowSize } from '@velocity/ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import Spinner from '../../../components/spinner/Spinner'
import { DICTIONARY } from '../../../constants/dictionary'
import { SCREEN_SIZE } from '../../../constants/display'
import useErrorMessages from '../../../hooks/useErrorMessages'
import usePolicyValidationMessages from '../../../hooks/usePolicyValidationMessages'
import { Profile } from '../../../model/Profile'
import { ErrorType } from '../../../types/error'
import { history } from '../../../utils/historyStore'
import { useShowroomQuotes } from '../../../utils/reactQueryApi'
import { capitalize } from '../../../utils/string'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useShowroomConfig } from '../context/ShowroomContext'
import {
  CloseButton,
  DrawerRoot,
  DrawerStyled,
  SmallScreenRoot,
} from '../filters/ShowroomFilters.styled'
import ShowroomFiltersBody from '../filters/ShowroomFiltersBody'
import {
  FilterButtonContainer,
  FiltersButtonStyled,
  IconButtonSection,
  IconButtonStyled,
  PageDataControls,
  SpinnerContainer,
  VehicleCatalogControls,
  VehicleListItemsPerPage,
  VehicleListSorting,
  VehicleListTitle,
  VehicleViewControls,
  ViewButtonStyled,
} from './VehicleList.styled'
import VehicleListBody from './VehicleListBody'
import { quotesReady } from './vehicleListUtil'
import { ItemsPerPage } from './VehiclesPerPage'
import { VehiclesSorting } from './VehiclesSorting'

interface VehicleListProps {
  profile: Profile
  locale: string
  product?: string
}

export type ViewMode = 'grid' | 'list' | 'section'

const VehicleList = ({ profile, locale, product }: VehicleListProps) => {
  const [vehicleList, setVehicleList] = useState([])
  const [isVehicleListReady, setVehicleListReady] = useState(false)
  const [viewMode, setViewMode] = useState<ViewMode>('grid')
  const [isMobileMode, setIsMobileMode] = useState(false)
  const { state, dispatch } = useShowroomConfig()
  const size = useWindowSize()
  const {
    data: quotes,
    isSuccess: isQuotesReady,
    isLoading: isQuotesLoading,
    isError: isQuotesError,
    error: quotesError,
    refetch: refetchQuotes,
  } = useShowroomQuotes(
    locale,
    state.filters.mileage?.value?.toString(),
    state.filters.duration?.value?.toString(),
  )

  const { t } = useTranslation()

  const count = vehicleList?.length

  useErrorMessages(isQuotesError, quotesError as ErrorType, t)

  const handleViewModeChange = (viewModeSelected: ViewMode) => () => {
    setViewMode(viewModeSelected)
  }

  useEffect(() => {
    state.filtersLoaded && refetchQuotes()
  }, [
    refetchQuotes,
    state.filtersLoaded,
    state.filters.mileage?.value,
    state.filters.duration?.value,
  ])

  useEffect(() => {
    const mobileScreen = size.innerWidth < SCREEN_SIZE.MOBILE
    setIsMobileMode(mobileScreen)
    if (mobileScreen) {
      setViewMode('grid')
    }
  }, [size.innerWidth])

  useEffect(() => {
    if (state?.selectedQuote?.policyValidated) {
      const vehicleQuote = state?.selectedQuote
      const productPathParameter = `${product ? '/' + product : ''}`
      history.push(`/${locale}${productPathParameter}/vehicle-configurator`, {
        vehicleQuote,
      })
    }
  }, [state?.selectedQuote, locale, product])

  useEffect(() => {
    quotesReady(
      isQuotesReady,
      quotes,
      state,
      setVehicleList,
      setVehicleListReady,
    )
  }, [quotes, isQuotesReady, state])

  usePolicyValidationMessages(
    state?.selectedQuote?.policyValidationMessages,
    profile?.product?.publicProduct,
    t,
  )

  return isQuotesLoading || !isVehicleListReady ? (
    <SpinnerContainer data-e2e-id="quotesLoader">
      <Spinner size={10} />
    </SpinnerContainer>
  ) : (
    <section>
      <VehicleListTitle>{t(DICTIONARY.FIND_YOUR_VEHICLE)}</VehicleListTitle>
      <VehicleCatalogControls>
        <FilterButtonContainer>
          <FiltersButtonStyled
            data-testid="filters-menu-button"
            onClick={() => dispatch({ type: 'openFiltersSlider' })}
            variant="contained"
            startIcon={<MenuOpenIcon />}
          />
        </FilterButtonContainer>
        {viewMode !== 'section' && !isMobileMode && (
          <>
            <VehicleListSorting>
              <VehiclesSorting
                sortingLabel={capitalize(t(DICTIONARY.SORT_BY))}
              />
            </VehicleListSorting>
            <VehicleListItemsPerPage>
              <ItemsPerPage labelItemsPerPage={t(DICTIONARY.SHOW)} />
            </VehicleListItemsPerPage>
          </>
        )}
        <VehicleViewControls>
          {isRebranding() ? (
            <IconButtonSection>
              <IconButtonStyled
                testId="grid-layout-button"
                onClick={handleViewModeChange('grid')}
                startIcon={<ViewComfySharpIcon />}
              />
              <IconButtonStyled
                testId="list-layout-button"
                onClick={handleViewModeChange('list')}
                startIcon={<ViewListSharpIcon />}
              />
              <IconButtonStyled
                testId="make-model-layout-button"
                onClick={handleViewModeChange('section')}
                startIcon={<ViewStreamSharpIcon />}
              />
            </IconButtonSection>
          ) : (
            <>
              <ViewButtonStyled
                data-testid="grid-layout-button"
                onClick={handleViewModeChange('grid')}
                variant="contained"
                startIcon={<ViewComfySharpIcon />}
              />
              <ViewButtonStyled
                data-testid="list-layout-button"
                onClick={handleViewModeChange('list')}
                variant="contained"
                startIcon={<ViewListSharpIcon />}
              />
              <ViewButtonStyled
                data-testid="make-model-layout-button"
                onClick={handleViewModeChange('section')}
                variant="contained"
                startIcon={<ViewStreamSharpIcon />}
              />
            </>
          )}
        </VehicleViewControls>
      </VehicleCatalogControls>

      {isMobileMode && (
        <PageDataControls>
          <VehicleListSorting>
            <VehiclesSorting sortingLabel={t(DICTIONARY.SORT_BY)} />
          </VehicleListSorting>
          <VehicleListItemsPerPage>
            <ItemsPerPage labelItemsPerPage={t(DICTIONARY.SHOW)} />
          </VehicleListItemsPerPage>
        </PageDataControls>
      )}

      <VehicleListBody
        isQuotesReady={isQuotesReady}
        vehicleList={vehicleList}
        viewMode={viewMode}
        sortingValue={state.sortingValue}
        itemsPerPage={state.itemsPerPage}
        count={count}
        isQuoteLoading={state?.quoteLoading}
      />
      <DrawerStyled
        variant="persistent"
        anchor="left"
        open={state.showFiltersSlider}
      >
        <DrawerRoot>
          <CloseButton
            size="xs"
            color={getStyles().colors.showroom.vehicleList.closeButton}
            onClick={() => {
              dispatch({
                type: 'closeFiltersSlider',
              })
            }}
          />
          <SmallScreenRoot>
            <ShowroomFiltersBody />
          </SmallScreenRoot>
        </DrawerRoot>
      </DrawerStyled>
    </section>
  )
}

export default VehicleList
