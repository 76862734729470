import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Identifiers } from '../../../model/Quote'
import { ShowroomFiltersResponse } from '../../../model/ShowroomFiltersResponse'
import AutocompleteSelect from '../component/autocomplete/AutocompleteSelect'
import { useShowroomConfig } from '../context/ShowroomContext'

interface Props {
  filtersResponse: ShowroomFiltersResponse
}

const TransmissionTypesFilter: FC<Props> = ({ filtersResponse }) => {
  const { state, dispatch } = useShowroomConfig()
  const { t } = useTranslation()

  return (
    <AutocompleteSelect
      dataE2eId="TransmissionTypesFilter"
      hint={{
        title: t(DICTIONARY.VEHICLE_TRANSMISSION_TYPE),
        text: t(DICTIONARY.TRANSMISSION_FILTER_TOOLTIP),
      }}
      value={state.filters.transmissionTypes}
      onChange={(_, newValue: unknown) =>
        dispatch({
          type: 'updateTransmissionTypes',
          filters: { transmissionTypes: newValue as Identifiers[] },
        })
      }
      getOptionLabel={(o) => (o as Identifiers).text}
      renderOption={(option: unknown) => (
        <div>{(option as Identifiers).text}</div>
      )}
      options={filtersResponse.transmissionTypes}
      renderInput={(params) => (
        <TextField
          {...(params as Record<string, unknown>)}
          label={t(DICTIONARY.VEHICLE_TRANSMISSION_TYPE)}
          placeholder={t(DICTIONARY.SELECT_TRANSMISSION_TYPES)}
        />
      )}
    />
  )
}

export default TransmissionTypesFilter
