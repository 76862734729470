import { DICTIONARY } from '../../../constants/dictionary'
import { Entry } from '../../../model/Entry'
import { Quote, Vehicle } from '../../../model/Quote'
import { createDetail } from './quotation'

export const getVehicleMakeAndModel = (quote: Quote): string =>
  quote.vehicle.make.text + ' ' + quote.vehicle.model.text

export const updateVehicleDetails = (
  vehicle: Vehicle,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
) => {
  const vehicleDetails: Entry[] = []

  if (!vehicle) return vehicleDetails

  if (vehicle.bodyType) {
    vehicleDetails.push(
      createDetail(DICTIONARY.VEHICLE_BODY_TYPE, vehicle.bodyType.text || ' '),
    )
  }

  if (vehicle.transmissionType) {
    vehicleDetails.push(
      createDetail(
        DICTIONARY.TRANSMISSION,
        vehicle.transmissionType.text || ' ',
      ),
    )
  }

  if (vehicle.engineFuelType) {
    vehicleDetails.push(
      createDetail(
        DICTIONARY.VEHICLE_FUEL_TYPE,
        vehicle.engineFuelType.text || ' ',
      ),
    )
  }

  if (vehicle.horsePower) {
    vehicleDetails.push(
      createDetail(
        DICTIONARY.ENG_POWER,
        `${vehicle.horsePower} ${translations(DICTIONARY.HORSE_POWER)}`,
      ),
    )
  }

  if (vehicle.doors) {
    vehicleDetails.push(createDetail(DICTIONARY.DOORS, vehicle.doors || ' '))
  }

  if (vehicle.seatingCapacity) {
    vehicleDetails.push(
      createDetail(DICTIONARY.SEATS, vehicle.seatingCapacity || ' '),
    )
  }

  return vehicleDetails
}
