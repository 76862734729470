import { useEffect, useState } from 'react'

import { Profile } from '../model/Profile'

export const useVehicleTileConfig = (profile: Profile) => {
  const [vehicleTileConfig, setVehicleTileConfig] = useState<{
    currencyCode: string
    displayShowroomMonthlyPrices: boolean
    displayShowroomExclVatLabel: boolean
  }>({
    currencyCode: null,
    displayShowroomMonthlyPrices: null,
    displayShowroomExclVatLabel: null,
  })

  const [isConfigSet, setIsConfigSet] = useState(false)

  useEffect(() => {
    if (!isConfigSet && profile && profile.user && profile.product) {
      setVehicleTileConfig({
        currencyCode: profile.user.currencyCode,
        displayShowroomExclVatLabel:
          !!profile.product.displayShowroomExclVatLabel,
        displayShowroomMonthlyPrices:
          !!profile.product.displayShowroomMonthlyPrices,
      })
      setIsConfigSet(true)
    }
  }, [isConfigSet, profile])

  return vehicleTileConfig
}
