import { ChevronDownIcon, ChevronUpIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { getStyles } from '../../../utils/styles'
import {
  CategoryLabel,
  CategoryRoot,
  ChevronContainer,
} from './VehicleSpecification.styled'

const s = () => getStyles().colors.configurator.specification

interface Props {
  label: string
  isOpen: boolean
  setCategoryOpen: Dispatch<SetStateAction<boolean>>
}
const VehicleSpecificationCategory: FC<Props> = ({
  label,
  isOpen,
  setCategoryOpen,
}) => {
  const { t } = useTranslation()
  let localLabel = label

  if (label === '-') {
    localLabel = t(DICTIONARY.MORE_INFORMATION)
  }
  return (
    <CategoryRoot
      data-testid="vehicle-specification-open-category-trigger"
      onClick={() => {
        setCategoryOpen((e) => !e)
      }}
    >
      <CategoryLabel>{localLabel}</CategoryLabel>
      <ChevronContainer data-testid="model-sort-btn">
        <ChevronUpIcon
          size="xs"
          color={s().upIcon}
          style={{ display: isOpen ? 'flex' : 'none' }}
        />
        <ChevronDownIcon
          size="xs"
          color={s().downIcon}
          style={{ display: isOpen ? 'none' : 'flex' }}
        />
      </ChevronContainer>
    </CategoryRoot>
  )
}

export default VehicleSpecificationCategory
