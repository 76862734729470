import { sanitize } from 'dompurify'
import { useTranslation } from 'react-i18next'
import { FC, useState, useEffect } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Calculation } from '../../../model/Calculation'
import { isRebranding } from '../../../utils/styles'
import { getTranslation } from '../../quoteSummary/components/contentBody/PriceDetailsSection'
import ContinueButton from './ContinueButton'
import { createTotalList } from './price'
import {
  ContinueButtonStyled,
  ContractDuration,
  Item,
  Notice,
  PriceBreakdownRoot,
  TotalItem,
} from './PriceBreakdownContext.styled'
import PriceSelectedOptions from './PriceSelectedOptions'
import PrivateCarHireNote from './PrivateCarHireNote'

export const getNoticeMarkupText = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  translations: any,
  isPublicFlow: boolean,
) => {
  const disclaimer = isRebranding()
    ? translations(DICTIONARY.BOTTOM_QUOTE_DISCLAIMER_AYVENS)
    : translations(DICTIONARY.BOTTOM_QUOTE_DISCLAIMER)
  return {
    __html: sanitize(
      isPublicFlow
        ? disclaimer
        : translations(DICTIONARY.CORPORATE_BOTTOM_QUOTE_DISCLAIMER),
    ),
  }
}

interface Props {
  priceInformation: Calculation
  currency: Intl.NumberFormat
  displayPrivateCarHireNote: boolean
  isPublicFlow: boolean
}

const PriceBreakdownContext: FC<Props> = ({
  priceInformation,
  currency,
  displayPrivateCarHireNote = false,
  isPublicFlow,
}) => {
  const [totalList, setTotalList] = useState([])
  const { t } = useTranslation()

  const getValue = (label: string, value: number) => {
    if (label === DICTIONARY.MAINTENANCE && value === 0) {
      return t(DICTIONARY.NOT_INCLUDED)
    }
    return !isNaN(value) && currency.format(value)
  }

  useEffect(() => {
    if (priceInformation?.priceDetail) {
      setTotalList(
        createTotalList(priceInformation).filter(
          (i) => i.value != null && i.value >= 0,
        ),
      )
    } else {
      setTotalList([])
    }
  }, [currency, priceInformation])

  return (
    <PriceBreakdownRoot>
      <PriceSelectedOptions
        priceInformation={priceInformation}
        currency={currency}
      />
      <hr />
      {totalList && totalList.length > 0 && (
        <>
          {totalList.map((i, index) => {
            return (
              <Item key={index}>
                <div>
                  {getTranslation(
                    i.label,
                    t(i.label),
                    priceInformation?.priceDetail,
                  )}
                </div>
                <div data-e2e-id={i.label}>{getValue(i.label, i.value)}</div>
              </Item>
            )
          })}
          <hr />
        </>
      )}
      {!isNaN(priceInformation?.priceDetail?.monthly.totalInclVat) && (
        <TotalItem>
          <div>
            <h1>{t(DICTIONARY.TOTAL)}</h1>
            <span> / {t(DICTIONARY.MONTH_LABEL)}</span>
          </div>
          <h1 data-e2e-id="TotalPrice">
            {currency.format(
              priceInformation?.priceDetail?.monthly.totalInclVat,
            )}
          </h1>
        </TotalItem>
      )}
      <ContractDuration>
        <div>{t(DICTIONARY.CONTRACT_DURATION_LABEL)}</div>
        <div data-e2e-id="ContractDuration">
          {priceInformation?.duration}{' '}
          {priceInformation?.duration && t(DICTIONARY.MONTHS_LABEL)}
        </div>
      </ContractDuration>
      <ContinueButtonStyled>
        <ContinueButton />
      </ContinueButtonStyled>

      {displayPrivateCarHireNote && <PrivateCarHireNote />}

      <Notice dangerouslySetInnerHTML={getNoticeMarkupText(t, isPublicFlow)} />
    </PriceBreakdownRoot>
  )
}

export default PriceBreakdownContext
