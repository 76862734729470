import { Slider } from '@material-ui/core'
import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'
import QuestionMarkHint from '../component/QuestionMarkHint/QuestionMarkHint'

interface Props {
  title?: {
    text: string
    hint: string
  }
  value: number | number[]
  onChange: (event: ChangeEvent<{}>, value: number | number[]) => void
  marks: { value: number; label: string }[]
  htmlTitle?: {
    text: {
      __html: string
    }
    hint: {
      __html: string
    }
  }
  range: {
    max: number
    min: number
  }
  track?: 'normal' | false | 'inverted'
  step?: number
  labelFormatter?: (value: number, index?: number) => string
}

const SliderContainer = styled.div`
  padding: 1.9rem 1rem 0 1rem;
  .MuiSlider-root {
    color: ${getStyles().colors.velocity.slider.color};
  }
  .MuiSlider-thumb span span span {
    color: ${getStyles().colors.velocity.slider.color};
  }
`
const Title = styled.div`
  display: flex;
  gap: 0.5rem;
`
const SliderStyled = styled(Slider)`
  .MuiSlider-thumb span {
    font-weight: ${getStyles().fonts.body.weight};
    background: transparent;
  }
  .MuiSlider-thumb span span {
    color: ${getStyles().colors.showroom.slider};
  }

  .MuiSlider-markLabel {
    font-weight: ${getStyles().fonts.body.weight};
  }
`

const ShowroomSlider: FC<Props> = ({
  title,
  value,
  onChange,
  marks,
  htmlTitle,
  range,
  track = false,
  step,
  labelFormatter,
}) => {
  const dataE2eId = (s: string): string => {
    return (
      s
        .split('(')[0]
        .replace(/<[a-z/]*>/g, '')
        .replace(' ', '')
        .trim() + 'Slider'
    )
  }
  return (
    <div>
      {htmlTitle && (
        <Title>
          <div dangerouslySetInnerHTML={htmlTitle.text} />
          {htmlTitle.hint.__html !== '' && (
            <QuestionMarkHint htmlText={htmlTitle.hint} />
          )}
        </Title>
      )}
      {title && (
        <Title>
          <div>{title.text}</div>
          <QuestionMarkHint text={title.hint} />
        </Title>
      )}

      <SliderContainer
        data-e2e-id={dataE2eId(htmlTitle?.text.__html || title?.text)}
      >
        <SliderStyled
          track={track}
          min={range.min}
          max={range.max}
          step={step}
          value={value}
          onChange={onChange}
          valueLabelDisplay="on"
          valueLabelFormat={(v, i) =>
            labelFormatter ? labelFormatter(v, i) : v
          }
          aria-labelledby="track-inverted-range-slider"
          marks={marks}
        />
      </SliderContainer>
    </div>
  )
}

export default ShowroomSlider
