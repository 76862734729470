import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { Option } from '../../../model/Quote'
import OptionCheckbox from '../components/OptionCheckbox'
import {
  CheckboxContainer,
  disabledCheckbox,
  excludedCheckbox,
} from '../components/OptionCheckbox.styled'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { onOptionClick } from '../util/options'
import {
  Item,
  Label,
  OrOperator,
  Price,
  RootContainer,
} from './Required.styled'
import { AndOperator } from './RulesPopupItem.styled'

interface Props {
  requires: Option[][]
}

interface SectionProps {
  options: Option[]
}

const Required: FC<Props> = ({ requires }) => {
  const { t } = useTranslation()
  return (
    <>
      {requires.map((options, index) => {
        return (
          <div key={index}>
            {index > 0 && <AndOperator>{t(DICTIONARY.AND)}</AndOperator>}
            <RequiredSection options={options} />
          </div>
        )
      })}
    </>
  )
}

const RequiredSection: FC<SectionProps> = ({ options }) => {
  const { state, dispatch } = useVehicleConfig()
  const { t } = useTranslation()

  return (
    <RootContainer>
      {options.map((option, index) => {
        const disabledStyle =
          option.state === 'included' ? disabledCheckbox : null
        const exclOrDisabledStyle =
          option.state === 'excluded' ? excludedCheckbox : disabledStyle
        return (
          <div key={index}>
            {index > 0 && <OrOperator>{t(DICTIONARY.OR)}</OrOperator>}
            <Item>
              <CheckboxContainer>
                <OptionCheckbox
                  option={option}
                  onClick={() => {
                    onOptionClick(dispatch, option)
                  }}
                  ruleDialog={true}
                />
              </CheckboxContainer>
              <Label style={exclOrDisabledStyle}>
                {option.manufacturerName}
              </Label>
              <Price style={exclOrDisabledStyle}>
                {!isNaN(option.price) && state.currency.format(option.price)}
              </Price>
            </Item>
          </div>
        )
      })}
    </RootContainer>
  )
}

export default Required
