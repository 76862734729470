import {
  Box,
  InputBase,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, ReactElement } from 'react'
import styled from 'styled-components'

import { DICTIONARY } from '../../../constants/dictionary'
import { capitalize } from '../../../utils/string'
import { getStyles } from '../../../utils/styles'
import { useShowroomConfig } from '../context/ShowroomContext'

const s = () => getStyles().colors.showroom.vehicleList.sorting

const SortingWrapper = styled.div`
  display: flex;
  align-items: center;

  & .MuiSelect-select.MuiSelect-select {
    padding-right: 34px;
    font-weight: ${getStyles().fonts.body.weight};
  }
`
const SortingLabel = styled(Typography)`
  color: ${s().label};
  margin-right: 10px;
`

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  select: {
    backgroundColor: s().select.background,
    minWidth: '75px',
    boxSizing: 'border-box',
    padding: '12px 16px',
    border: `1px solid ${s().select.border}`,
    fontSize: '14px',
    '&:focus': {
      backgroundColor: s().select.focus,
    },
  },
  icon: {
    width: '20px',
    height: '27px',
    right: '10px',
  },
}))

const defaultSortingOptions = [
  'AZ_SORT_LABEL',
  'ZA_SORT_LABEL',
  'LOWEST_PRICE_SORT_LABEL',
  'HIGHEST_PRICE_SORT_LABEL',
] as const

export type DefaultSortingOptions = typeof defaultSortingOptions[number]

interface VehiclesSortingProps {
  sortingLabel?: string
  sortingOptions?: DefaultSortingOptions[]
}

export const VehiclesSorting = (props: VehiclesSortingProps): ReactElement => {
  const { state, dispatch } = useShowroomConfig()
  const { sortingLabel = 'Sort by', sortingOptions = defaultSortingOptions } =
    props
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChangeSorting = (event: ChangeEvent<{ value: unknown }>) => {
    const sortingTypeSelected = event.target.value
    dispatch({
      type: 'setSortingValue',
      sortingValue: sortingTypeSelected as DefaultSortingOptions,
    })
  }

  return (
    sortingOptions.length > 1 && (
      <SortingWrapper data-testid="SortingWrapper">
        <SortingLabel color="inherit" variant="body1">
          {sortingLabel}
        </SortingLabel>
        <Box mr={2}>
          <Select
            input={<InputBase />}
            value={state.sortingValue}
            onChange={handleChangeSorting}
            inputProps={{ 'aria-label': sortingLabel }}
            IconComponent={KeyboardArrowDown}
            classes={classes}
            data-testid="VehiclesSortingSelect"
          >
            {/*eslint-disable-next-line  @typescript-eslint/no-explicit-any */}
            {sortingOptions.map((sortingOption: any) => (
              <MenuItem key={sortingOption} value={sortingOption}>
                {capitalize(t(DICTIONARY[sortingOption]))}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </SortingWrapper>
    )
  )
}
