import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack'
import { TFunction, UseTranslationResponse } from 'react-i18next'
import { Dispatch } from 'react'

import {
  SnackbarInfo,
  showSnackbar,
} from '../../../../components/notification/snackMessageUtil'
import { DICTIONARY } from '../../../../constants/dictionary'
import { GenericError } from '../../../../model/Error'
import { Profile } from '../../../../model/Profile'
import apiClient from '../../../../utils/api'
import { history } from '../../../../utils/historyStore'
import { capitalize } from '../../../../utils/string'
import { isRebranding } from '../../../../utils/styles'
import { VehicleConfig } from '../../context/vehicleConfigContext'
import { AppAction, AppState } from '../../reducer/VehicleConfigReducer'
import { flattenServicesToIds } from '../../services/service'
import { getPermanentQuote } from '../price'

let product: string
let vState: AppState
let vDispatch: Dispatch<AppAction>
let enqueueSnackbar: (
  message: SnackbarMessage,
  options?: OptionsObject,
) => SnackbarKey
let closeSnackbar: (key?: SnackbarKey) => void
let t: TFunction<'translation', undefined>
let profile: Profile

export const initState = (
  {
    product: p,
  }: {
    product: string
  },
  { state, dispatch }: VehicleConfig,
  { enqueueSnackbar: e, closeSnackbar: c }: ProviderContext,
  { t: tP }: UseTranslationResponse<'translation', undefined>,
  profileP: Profile,
) => {
  product = p
  vState = state
  vDispatch = dispatch
  enqueueSnackbar = e
  closeSnackbar = c
  t = tP
  profile = profileP
}

export const onContinueButtonClick = async () => {
  if (!vState.vehicleConfiguration) {
    return
  }
  try {
    vDispatch({ type: 'startPageLoading' })
    const { data: quote } = await getPermanentQuote(vState)
    window.sessionStorage.setItem('lastPriceCalculation', JSON.stringify(quote))
    vDispatch({
      type: 'updatePriceCalculation',
      priceCalculation: quote,
    })
    vDispatch({ type: 'endPageLoading' })

    if (quote.policyValidated) {
      history.push({
        pathname: `${history.location.pathname.replace(
          'vehicle-configurator',
          product ? 'public-order' : 'quote-summary',
        )}/${!product ? quote.calculationNumber : quote.identifier}`,
        search: '',
      })
    }
  } catch (error) {
    const typedError = error as { response: { data: GenericError } }
    vDispatch({ type: 'endPageLoading' })
    vDispatch({ type: 'addError', error: typedError?.response?.data })
  }
}
export const onSaveQuoteClick = () => {
  saveQuote(
    {
      enqueueSnackbar,
      closeSnackbar,
      text: t(DICTIONARY.ADDED_TO_FAVOURITES_MESSAGE),
    },
    false,
  )
}
export const onSaveQuoteWithDialogClick = () => {
  saveQuoteAndShowDialog()
}
export const onAddPolicyFavoritesClick = () =>
  saveQuote(
    {
      enqueueSnackbar,
      closeSnackbar,
      text: t(DICTIONARY.ADDED_TO_POLICY_FAVOURITES_MESSAGE),
    },
    true,
  )

export const continueButtonDisabled = () =>
  vState.isCalculationRequestLoading ||
  !vState.priceCalculation?.policyValidated ||
  vState.driverContributionConfirmationNeeded
export const showSaveQuote = () => vState.vehicleConfiguration?.valid
export const showDisabledAddFavorites = () =>
  vState.profile?.user.canSaveConfigurations
export const showDisabledAddPolicyFavorites = () =>
  profile?.user.canSavePolicyConfigurations
export const showAddFavorites = () => vState.profile?.user.canSaveConfigurations
export const showAddPolicyFavorites = () =>
  profile?.user.canSavePolicyConfigurations
export const showSaveQuoteWithDialog = () =>
  vState.vehicleConfiguration?.valid && vState.profile?.product.publicProduct

export const getSaveQuoteLabel = () => t(DICTIONARY.SAVE_QUOTE_LABEL)
export const getContinueButtonLabel = () => t(DICTIONARY.CONTINUE_LABEL)
export const getAddPolicyFavoritesLabel = () =>
  capitalize(t(DICTIONARY.ADD_POLICY_FAVORITES))
export const getAddFavoritesLabel = () =>
  isRebranding()
    ? capitalize(t(DICTIONARY.ADD_FAVORITES))
    : t(DICTIONARY.ADD_FAVORITES)

const saveConfiguration = (savedPolicy: boolean) => {
  vDispatch({ type: 'startPageLoading' })
  const selectedAccessories = vState.selectedAccessories.filter(
    (sa) => !vState.customAccessories.map((ca) => ca.description).includes(sa),
  )
  return apiClient.saveConfiguration(
    {
      locale: vState.locale,
      contractOptions: vState.contractOptions,
      savedConfiguration: !savedPolicy,
      savedPolicyConfiguration: savedPolicy,
      configHash: vState.vehicleConfiguration.configuration,
      accessories: selectedAccessories.join(','),
      services: flattenServicesToIds(vState.selectedServices).join(','),
    },
    vState.customAccessories,
  )
}
const saveQuoteAndShowDialog = () => {
  saveConfiguration(false)
    .then((response) => {
      vDispatch({
        type: 'showSavedQuoteDialog',
        quoteIdentifier: response?.data.identifier,
      })
    })
    .catch(() => {
      vDispatch({ type: 'endPageLoading' })
    })
}
const saveQuote = (snackbarInfo: SnackbarInfo, savedPolicy: boolean) => {
  saveConfiguration(savedPolicy)
    .then(() => {
      vDispatch({ type: 'endPageLoading' })
      if (snackbarInfo.text) {
        showSnackbar(snackbarInfo)
      }
    })
    .catch((error) => {
      const typedError = error as { response: { data: GenericError } }
      vDispatch({ type: 'endPageLoading' })
      const errorCode = typedError?.response?.data?.errorCode
      const errorMessage =
        errorCode && t(errorCode)
          ? t(errorCode)
          : 'Something went wrong, please retry later'
      snackbarInfo.enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      })
    })
}
