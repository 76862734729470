import { DICTIONARY } from '../../../constants/dictionary'

export const getPriceFilterTitle = (priceParameter: string) => {
  switch (priceParameter || '') {
    case 'priceDetail.monthly.total':
      return DICTIONARY.MONTHLY_PRICE_EXCL_VAT_LABEL
    case 'priceDetail.monthly.totalInclVat':
      return DICTIONARY.MONTHLY_PRICE_INCL_VAT_LABEL
    default:
      return DICTIONARY.MONTHLY_PRICE_LABEL
  }
}
