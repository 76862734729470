import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Dispatch } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { ContractOptionsType } from '../../../model/ContractOptionsType'
import { Option, Service } from '../../../model/Quote'
import { Colours } from '../../../model/VehicleConfiguration'
import { VoVehicle } from '../../../model/VoVehicle'
import AccessoriesTable from '../accessories/AccessoriesTable'
import ColorAccordions from '../colors/ColorAccordions'
import AddAccessory from '../components/AddAccessory'
import LimitedVehicleConfigurationOptionsTable from '../components/LimitedVehicleConfigurationOptionsTable'
import OptionsTable from '../components/OptionsTable'
import { AppAction } from '../reducer/VehicleConfigReducer'
import Services from '../services/Services'
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from '../VehicleConfigurator.styled'
import { getLimitedOptionsConfiguration } from './limitedOptionsUtil'

export const limitedOptions = (
  optionsTabServices: Service[],
  packsAndOptions: Option[],
  contractOptions: ContractOptionsType,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  t: any,
) => {
  const { groupedOptions, optionGroupNames, show } =
    getLimitedOptionsConfiguration(
      packsAndOptions,
      contractOptions.limitedVehicleConfiguration,
      optionsTabServices,
    )

  return (
    show && (
      <AccordionStyled square={true}>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
          {t(DICTIONARY.OPTIONS)}
        </AccordionSummaryStyled>
        {optionsTabServices?.length > 0 && (
          <AccordionDetailsStyled>
            <Services services={optionsTabServices} />
          </AccordionDetailsStyled>
        )}
        {optionGroupNames.size > 0 && (
          <AccordionDetailsStyled>
            <LimitedVehicleConfigurationOptionsTable
              groupedOptions={groupedOptions}
              optionGroupNames={optionGroupNames}
            />
          </AccordionDetailsStyled>
        )}
      </AccordionStyled>
    )
  )
}

export const unlimitedOptions = (
  optionsTabServices: Service[],
  vehicleOptions: Option[],
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  t: any,
) => (
  <AccordionStyled square={true}>
    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
      {t(DICTIONARY.OPTIONS)}
    </AccordionSummaryStyled>
    {optionsTabServices && optionsTabServices.length > 0 && (
      <AccordionDetailsStyled>
        <Services services={optionsTabServices} />
      </AccordionDetailsStyled>
    )}
    {vehicleOptions?.length > 0 && (
      <AccordionDetailsStyled>
        <OptionsTable options={vehicleOptions} />
      </AccordionDetailsStyled>
    )}
  </AccordionStyled>
)

export const servicesTab = (
  isExpandedServices: boolean,
  dispatch: Dispatch<AppAction>,
  servicesTabServices: Service[],
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  t: any,
) => (
  <AccordionStyled
    square={true}
    expanded={isExpandedServices}
    onChange={() => dispatch({ type: 'expandServices' })}
  >
    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
      {t(DICTIONARY.SERVICES_TAB_TITLE)}
    </AccordionSummaryStyled>
    <AccordionDetailsStyled>
      <Services services={servicesTabServices} />
    </AccordionDetailsStyled>
  </AccordionStyled>
)

export const colorsTab = (
  colors: Colours,
  vehicle: VoVehicle,
  currency: Intl.NumberFormat,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  t: any,
  excludePremiumTrims = false,
) => (
  <AccordionStyled square={true} defaultExpanded={true}>
    <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
      {t(DICTIONARY.COLORS_LABEL)}
    </AccordionSummaryStyled>
    <AccordionDetailsStyled>
      <ColorAccordions
        colours={colors}
        vehicle={vehicle}
        currency={currency}
        excludePremiumTrims={excludePremiumTrims}
      />
    </AccordionDetailsStyled>
  </AccordionStyled>
)

export const accessoriesTab = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  t: any,
) => (
  <>
    <AccordionStyled square={true}>
      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
        {t(DICTIONARY.ACCESSORIES)}
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <AccessoriesTable />
        <AddAccessory />
      </AccordionDetailsStyled>
    </AccordionStyled>
  </>
)
