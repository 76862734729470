import { datadogLogs } from '@datadog/browser-logs'
import { TFunction } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'

import {
  SnackbarInfo,
  showSnackbar,
} from '../../../../components/notification/snackMessageUtil'
import { GenericError } from '../../../../model/Error'
import { SessionItemEnum } from '../../../../pages/vehicle-configurator/util/sessionStorageUtil'
import apiClient from '../../../../utils/api'
import { history } from '../../../../utils/historyStore'
import { ContentFooterProps } from '../ContentFooter'

export const initState = (
  props: ContentFooterProps,
  snackbarInfo: SnackbarInfo,
  isQuoteSummary: boolean,
  [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>],
  [isSubmitButton, setIsSubmitButton]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ],
  t: TFunction<'translation', undefined>,
) => {
  return {
    goBack: () => {
      history.goBack()
    },
    submitQuote: async () => {
      try {
        setIsLoading(true)
        const result = await apiClient.submitQuote(
          props.quote.calculationNumber.toString(),
          props.locale,
        )
        setIsLoading(false)
        const successCode = 200
        if (result.status !== successCode) {
          throw new Error('Failed to submit quote')
        }

        const orderSummaryPath = `/${props.locale}/order-summary/${props.quote.calculationNumber}`
        if (window.sessionStorage.getItem(SessionItemEnum.CASE_ID) !== null) {
          history.push(
            `${orderSummaryPath}?submittedQuoteNumber=${props.quote.calculationNumber}`,
          )
        } else {
          history.push(orderSummaryPath)
        }

        setIsSubmitButton(false)
        props.onSubmitQuote()
      } catch (error) {
        setIsLoading(false)
        datadogLogs.logger.error(
          `Failed to submit quote(${props.quote.identifier}), error: ${error}`,
        )
      }
    },
    saveQuote: () => {
      try {
        setIsLoading(true)
        apiClient
          .setSavedQuotes(props.quote.identifier, props.locale)
          .then(() => {
            showSnackbar(snackbarInfo)
          })
          .catch((error) => {
            datadogLogs.logger.error(
              `Failed to save quote(${props.quote.identifier}, ${props.locale}), error: ${error}`,
            )
          })

        setIsLoading(false)
      } catch (error) {
        const typedError = error as { response: { data: GenericError } }
        const errorCode = typedError?.response?.data?.errorCode
        const errorMessage =
          errorCode && t(errorCode)
            ? t(errorCode)
            : 'Something went wrong, please retry later'
        snackbarInfo.enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        })
        setIsLoading(false)
        datadogLogs.logger.error(
          `Failed to save quote(${props.quote.identifier}), error: ${error}`,
        )
      }
    },
    showSpinner: () => isLoading,
    showBackButton: () => isQuoteSummary,
    showSubmitButton: () => isSubmitButton,
    showSaveQuoteButton: () => props.profile.user.canSaveQuotes,
    isDisabled: () => isLoading,
  }
}
